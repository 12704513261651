import { useFloating, autoUpdate, offset, FloatingPortal, useDismiss, useInteractions } from "@floating-ui/react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { PiFloppyDisk, PiPolygon, PiTextAa, PiTrash, PiX } from "react-icons/pi";
import { BNBOOmrådeType, getMatrikelId, TurfFeature } from "./BNBOState";
import Nestable, { Item as NestableItem } from "react-nestable";
import { actionSetMapInteractionState, MapInteractionState, MapitStateContext } from "src/states/MapitState";
import area from "@turf/area";
import { featureCollection } from "@turf/helpers";
import intersect from "@turf/intersect";
import { truncate, difference, union, bbox, bboxPolygon, center, squareGrid, transformRotate, transformScale, transformTranslate, dissolve } from "@turf/turf";
import { constructMaskObj, nonOverlappingGeoAdv, sortFeatureBytype } from "./BNBOAreaEditorExtra";
import { GeoJSONSource } from "maplibre-gl";
import { BNBOAreaEditorStyling } from "./BNBOAreaEditorStyling";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { ColorBoxEditableV2 } from "src/components/ComponentUtils";
import { GlassCheckbox } from "src/components/MitGlassComponents";
import { BNBOBeregninger } from "./BNBOBeregninger";
import { GlassButton, GlassButtonRow } from "src/components/GlassButtons";
import { ProtectedFeature } from "src/components/ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { openWindow, WindowId } from "src/WindowManager/WindowState";
import { Stack } from "react-bootstrap";
import { Localization, SettingsManager } from "@viamap/viamap2-common";

type FeatureCollection = {
   type: "FeatureCollection"
   features: TurfFeature[]
}

type GeoJSON = any;

export function BNBOAreaEditorV3(props:{
   lodsejerId?: string,
   SFEGeom?: TurfFeature[],
   clipGeom?: TurfFeature[],
   delGeom?: TurfFeature[],
   bnboGeom?: TurfFeature[],
   hiearchy?: any,
   onCommit?: (layers: TurfFeature[]) => void
}) {

   const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
   const {hasAccessToFeature } = useContext(ApplicationStateContext)
   const {dispatch: windowDispatch} = useWindowContext();
   
   const [selectedLayer, setSelectedLayer] = useState(null)
   
   const [dele, _setDele] = useState(props.delGeom || [])
   const [unconfirmedDele, setUnconfirmedDele] = useState<boolean>(false)
   const drawRef = useRef<MapboxDraw | null>(null);
   const [clipGeom, setClipGeom] = useState(props.clipGeom || [])
   const [selectedGeom, setSelectedGeom] = useState<{feature: TurfFeature, hiearchyId: number} | null>(null)
   const [hiearchy, setHiearchy] = useState<NestableItem[]>(constructHiearchy(dele, props.clipGeom || []))
   const [arronderingsGrid, setArronderingsGrid] = useState<GeoJSON>();
   const [arronderingsSettings, setArronderingsSettings] = useState<{sproejteBredde:number, vinkelFraNord: number, offset:number, buffer:number, isShown:boolean}>(
      {
         sproejteBredde:30,  
         vinkelFraNord: 0, 
         offset:0, 
         buffer:0, 
         isShown:false
      }
   );

   function setDele(a) {
      setUnconfirmedDele(true)
      _setDele(a)
   }

   function onCommit() {
      setUnconfirmedDele(false)
      props.onCommit?.(dele)
   }

   function updateArrondationGrid(next?: {sproejteBredde:number, vinkelFraNord: number, offset:number, buffer:number, isShown:boolean} | undefined) {
      let map = mapitState.map.getMapPop()
      if (next == undefined || !(selectedGeom && selectedGeom.feature.properties.type == "Mark")) {
         map?.getSource("ArronGeneraterSrc") ? (map.getSource("ArronGeneraterSrc") as GeoJSONSource).setData(featureCollection([]))
      : map.addSource("ArronGeneraterSrc", {type: 'geojson', data: featureCollection([])})
         setArronderingsGrid(undefined)
         return 
      }
      let fc = featureCollection([selectedGeom.feature]);
      let box = transformScale(bboxPolygon(bbox(fc)),2);
      let grid = squareGrid(bbox(box), next.sproejteBredde, {units:"meters", properties:{"color":"pink"}});
      let rotatedGrid = transformRotate(grid, next.vinkelFraNord, {pivot: center(fc)});
      let translatedGrid = transformTranslate(rotatedGrid, next.offset, next.vinkelFraNord, {units:"meters"});

      
      map?.getSource("ArronGeneraterSrc") ? (map.getSource("ArronGeneraterSrc") as GeoJSONSource).setData(featureCollection(translatedGrid.features))
      : map.addSource("ArronGeneraterSrc", {type: 'geojson', data: featureCollection(translatedGrid.features)})
      map?.getLayer("ArronGenerater") || map?.addLayer(createLineLayer("ArronGenerater", "ArronGeneraterSrc") as any);
      setArronderingsGrid(featureCollection(translatedGrid.features))
   }


   useEffect(() => {
      let map = mapitState.map.getMapPop()
      
      mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Override));
      
      let maskObj = constructMaskObj(clipGeom || []);
      let clippedDele = nonOverlappingGeoAdv(dele || [], true, props.SFEGeom, maskObj || {});
      map?.hasControl(mapitState.drawContent) && map?.removeControl(mapitState.drawContent);
      map?.getSource("deleSrc") ? (map.getSource("deleSrc") as GeoJSONSource).setData(featureCollection(clippedDele))
      : map.addSource("deleSrc", {type: 'geojson', data: featureCollection(clippedDele)})
      map?.getLayer("dele") || map?.addLayer(createFillLayer("dele", "deleSrc"));
      setUpDrawMode(map);

      map?.getSource("bnboSrc") ? (map.getSource("bnboSrc") as GeoJSONSource).setData(featureCollection(props.bnboGeom as any))
      : map.addSource("bnboSrc", {type: 'geojson', data: featureCollection(props.bnboGeom as any)})
      map?.getLayer("bnbo") || map?.addLayer(createLineLayer("bnbo", "bnboSrc") as any);

      map?.getSource("clipSrc") ? (map.getSource("clipSrc") as GeoJSONSource).setData(featureCollection(props.clipGeom as any))
      : map.addSource("clipSrc", {type: 'geojson', data: featureCollection(props.clipGeom as any)})
      map?.getLayer("clip") || map?.addLayer(createLineLayer("clip", "clipSrc") as any);

      map?.getSource("SFESrc") ? (map.getSource("SFESrc") as GeoJSONSource).setData(featureCollection(props.SFEGeom as any))
      : map.addSource("SFESrc", {type: 'geojson', data: featureCollection(props.SFEGeom as any)})
      map?.getLayer("SFE") || map?.addLayer(createLineLayer("SFE", "SFESrc") as any);

      map?.getSource("selectedGeomSrc") || map.addSource("selectedGeomSrc", {type: 'geojson', data: featureCollection([])})
      map?.getLayer("selectedGeom") || map.addLayer(createMaskLayer("selectedGeom","selectedGeomSrc") as any)

      map?.getSource("ArronGeneraterSrc") ? (map.getSource("ArronGeneraterSrc") as GeoJSONSource).setData(featureCollection([] as any))
      : map.addSource("ArronGeneraterSrc", {type: 'geojson', data: featureCollection([] as any)})
      map?.getLayer("ArronGenerater") || map?.addLayer(createLineLayer("ArronGenerater", "ArronGeneraterSrc") as any);

      return () => {
         mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Normal));
         map?.removeLayer("dele");
         map?.removeSource("deleSrc");
         map?.removeControl(drawRef.current);

         map?.removeLayer("clip");
         map?.removeSource("clipSrc");

         map?.removeLayer("bnbo");
         map?.removeSource("bnboSrc");

         map?.removeLayer("SFE");
         map?.removeSource("SFESrc");

         map?.removeLayer("selectedGeom")
         map?.removeSource("selectedGeomSrc")

         map?.removeLayer("ArronGenerater")
         map?.removeSource("ArronGeneraterSrc")

         // drawRef.current?.deleteAll();
         mapitState.drawContent && map?.addControl(mapitState.drawContent)
      }
   },[mapitState.map, props.clipGeom, props.SFEGeom])

   useEffect(() => {
      let map = mapitState.map.getMapPop()
      let maskObj = constructMaskObj(clipGeom || []);
      let clippedDele = nonOverlappingGeoAdv(dele || [], true, props.SFEGeom, maskObj || {});
      map?.getSource("deleSrc") ? (map.getSource("deleSrc") as GeoJSONSource).setData(featureCollection(clippedDele))
      : map.addSource("deleSrc", {type: 'geojson', data: featureCollection(clippedDele)})
      map?.getLayer("dele") || map?.addLayer(createFillLayer("dele", "deleSrc"));
   },[dele])

   useEffect(() => {
      updateArrondationGrid()
      if (props.SFEGeom || selectedGeom) {
         let map = mapitState.map.getMapPop()
      let feature = selectedGeom?.feature ? [selectedGeom.feature] : props.SFEGeom
      let [ba,bb,bc,bd] = bbox(featureCollection(feature || []));



      map.fitBounds([[ba-0.0005,bb-0.0005],[bc+0.0005,bd+0.0005]])
         const onMoveEnd = () => {
            let map = mapitState.map.getMapPop()
            let [a,b,c,d] = map.getBounds().toArray().flat() as [number,number,number,number]
            let nextFeature = bboxPolygon([a-1,b-1,c+1,d+1])
            let intersected = difference(featureCollection([nextFeature, ...feature as any]))
            let arr = intersected ? [intersected] : []
               map?.getSource("selectedGeomSrc") ? (map.getSource("selectedGeomSrc") as GeoJSONSource).setData(featureCollection(arr))
               : map.addSource("selectedGeomSrc", {type: 'geojson', data: featureCollection(arr)})  
         }
         onMoveEnd()
         map.on('moveend', onMoveEnd)
         return () => {
            // let map = mapitState.map.getMapPop()
            map?.off('moveend', onMoveEnd)

         }
      }
   },[selectedGeom])

   function goBack() {
      setSelectedGeom(null)
   }
   function goToClipping(clipGeom, hiearchyId) {
      setSelectedGeom({feature: clipGeom, hiearchyId: hiearchyId})
   }

   useEffect(() => {
      
   },[hiearchy, selectedGeom])

   function setUpDrawMode(map) {
      const drawObj = new MapboxDraw({
         displayControlsDefault: false,
         styles: BNBOAreaEditorStyling
      });
      drawRef.current = drawObj;
      map.addControl(drawObj);
   }

   const NettoAreas = useMemo(() => {
      let maskObj = constructMaskObj(clipGeom || []);
      let clippedDele = nonOverlappingGeoAdv(dele || [], true, props.SFEGeom, maskObj || {}).filter((a) => a?.properties).map((a) => {return{id:a.properties.id, area: a.properties.arealNetto}});
      return clippedDele;
   },[dele, props.clipGeom]);

   function handleEditMap(a) {
      onSave()
      // setEditText((b) => a.properties?.note || a.properties?.type || "test")
      drawRef.current.set(featureCollection([a]));
      drawRef.current.changeMode('simple_select', { featureIds: a.id })
   }
   
   function addToHiearchy(id, mask) {
      console.log(id, mask)
      setHiearchy((oldHiearchy) => {
         console.log(oldHiearchy)
         if (idInHiearchy(id, oldHiearchy)) {
            return oldHiearchy
         }
         if (!mask) {
            return [...oldHiearchy, {id: id, type: "layer"}]
         }
         let maskGroup = oldHiearchy.findIndex((a) => a.maskKey == mask)
         console.log(maskGroup)
         let next = oldHiearchy.toSpliced(maskGroup, 1, {...oldHiearchy[maskGroup], children: [...oldHiearchy[maskGroup].children, {id: id, type: "layer"}]}) 
         console.log(next)
         return next
      })
   }

   function onSave() {
      let x = drawRef.current.getAll() as FeatureCollection
      if (x.features?.[0]) {
         let ffProp = x.features.find((a) => "id" in a.properties)?.properties
         let ff = x.features?.[0] as any
         if (x.features.length > 1) {
            ff = union(x as any, {properties:ffProp}) as any
         }
         if (ff?.properties == undefined) {
            return
         }
         ff.properties["arealNetto"] = area(x as any);
         ff.properties["arealBrutto"] = area(x as any);
         
         ff.properties["id"] ??= Math.round(Math.random()*100000);
         (ff.id as any) = ff.properties["id"]
         ff.properties["type"] ??= BNBOOmrådeType.UlempeAreal;
         ff.properties["mask"] ??= selectedGeom?.feature.properties.maskKey ?? "";
         ff.properties["note"] ??= "";

         if ("geometry" in ff && (ff as any).geometry?.coordinates?.[0]?.length > 3) {
            setDele((a) => {
               let id = a.findIndex((val) => val.properties.id == ff.id)
               if (id !== -1) {
                  return a.toSpliced(id, 1, ff as any)
               }
               return [...a, ff as any]
            })
            addToHiearchy(ff.properties.id, ff.properties.mask)
         }
         drawRef.current.deleteAll()
      }
   }

   return (
      <>
      <ButtonMenu  >
      <ProtectedFeature feature={Feature.BNBOProjectWrite} contentsIfNoAccess={<></>} >
      <button title="Gem aktiv redigering" style={{border:"unset", minWidth:"50px"}} className="OnMapButton " onClick={() => {
         mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Override));
         onSave()

      } 
      } ><PiFloppyDisk /></button>
      <button title="Fortryd aktiv redigering" style={{border:"unset", minWidth:"50px"}} className="OnMapButton " onClick={(e) => {
         mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Override));
         drawRef.current.deleteAll();
      }} ><PiX /></button>
      <button title="Gem og Tegn ny Polygon" style={{border:"unset", minWidth:"50px"}} className="OnMapButton " onClick={(e) => {
         mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Override));
         onSave();
         drawRef.current.changeMode("draw_polygon")
      }} ><PiPolygon /></button>
      {/* <button style={{border:"unset", minWidth:"50px"}} className="OnMapButton " onClick={(e) => {
         onSave();
         drawRef.current.changeMode("draw_point")
      }} ><PiTextAa /></button> */}
      <button title="Slet" style={{border:"unset", minWidth:"50px"}} className="OnMapButton " onClick={(e) => {
         mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Override));
         drawRef.current.trash()
      }} ><PiTrash /></button>
      </ProtectedFeature>
      </ButtonMenu>
         <div style={{display:"flex", flexDirection:"column", paddingInline: "0.2em", textAlign:"left"}}>
            <div style={{flex:"1", overflowY:"scroll"}}>
            <div className={"BnboEditorTitle "+(hiearchy.find((a) => a.id == selectedGeom?.hiearchyId)?.clipType ?? "Ejendom")} style={{display:"flex"}}>
            <h5 style={{paddingInline: "0.2em", textDecoration:"underline"}}>
               {selectedGeom?.hiearchyId !== undefined ? <MaskTilte item={hiearchy.find((a) => a.id == selectedGeom.hiearchyId)} /> : <>Ejendom (SFE: {props.lodsejerId})</>}
            </h5>
            {selectedGeom ? <button className="bnboGotoButton" onClick={goBack}>{"\<- Tilbage"}</button> : <></>}
            </div>
            <div style={{paddingInline: "1em"}}>
            <Nestable 
               group="hiearchy"
               items={selectedGeom?.hiearchyId !== undefined ? hiearchy.find((a) => a.id == selectedGeom.hiearchyId)?.children || [] : hiearchy}
               onChange={(newHiearchy) => {
                  selectedGeom?.hiearchyId ? setHiearchy((OldHierarchy) => OldHierarchy.map((a) => a.id == selectedGeom.hiearchyId ? {...a, children: newHiearchy.items} : a) ) : setHiearchy(newHiearchy.items);
                  setDele((a) => {
                     let newId = locationInDel(newHiearchy.items, newHiearchy.dragItem.id)
                     let b = a.find((b) => b.id == newHiearchy.dragItem.id)
                     return a.filter((b) => !(newHiearchy.dragItem.id == b.id)).toSpliced(newId, 0, 
                        {...b, properties: {...b.properties, mask: selectedGeom?.feature.properties.maskKey || (newHiearchy.targetPath[0] !== undefined && newHiearchy.items[newHiearchy.targetPath[0]].maskKey) || ""}}
                     )
                  })
               }}
               maxDepth={2}
               disableDrag={(item) => hasAccessToFeature(Feature.BNBOProjectWrite) && item.item.type == 'layer'}
               confirmChange={(options) => {
                  return !(options.dragItem.type == options.destinationParent?.type || options.destinationParent?.type == 'layer')
               }}
               renderItem={({item}) => {
                  if (item.type == 'clipMask') {
                     return (
                        <div 
                           className={"folderType-"+item.clipType}
                           style={{paddingInline: "0.2em"}}
                           onMouseEnter={(e) => {
                              mapitState.map.setFeatureState(
                                 {source: 'clipSrc', id: item.id},
                                 {hover: true}
                             );
                           }}
                           onMouseLeave={(e) => {
                              mapitState.map.setFeatureState(
                                 {source: 'clipSrc', id: item.id},
                                 {hover: false}
                             );
                           }}
                           onDoubleClick={() => {
                              let [ba,bb,bc,bd] = bbox(props.clipGeom?.find((a) => a.id == item.id) as any);
                              mapitState.map.getMapPop().fitBounds([[ba,bb],[bc,bd]]);
                        }}
                        >
                           <div style={{display:"flex", paddingTop:"0.2em", paddingLeft:"0.3em"}}>
                           <div>{item.clipType} {item.maskKey}</div>
                           <button className="bnboGotoButton" onClick={(e) => goToClipping(props.clipGeom?.find((a) => a.properties.maskKey == item.maskKey), item.id)}>Gå til</button>
                           </div>
                        </div>
                     )
                  }
                  return (
                     <div
                        onMouseEnter={(e) => {
                           mapitState.map.setFeatureState(
                              {source: 'deleSrc', id: item.id},
                              {hover: true}
                        );
                        }}
                        onMouseLeave={(e) => {
                           mapitState.map.setFeatureState(
                              {source: 'deleSrc', id: item.id},
                              {hover: false}
                        );
                        }}
                        onDoubleClick={() => {
                              let [ba,bb,bc,bd] = bbox(dele.find((a) => a.id == item.id) as any);
                              mapitState.map.getMapPop().fitBounds([[ba,bb],[bc,bd]]);
                        }}
                     >
                     <DeleView 
                     disabled={!hasAccessToFeature(Feature.BNBOProjectWrite)}
                     onTrash={() => {
                        setDele((a) => a.toSpliced(a.findIndex((b) => b.id == item.id), 1))
                        drawRef.current.delete(item.id)
                     }}
                     onEdit={(a) => handleEditMap(dele.find((b) => b.id == item.id))}
                     nettoAreas={NettoAreas.find((a) => a.id == item.id)?.area || 0}
                     selected={selectedLayer == item.id} del={dele.find((a) => a.properties.id == item.id)} onChange={(a) => setDele((b) => b.toSpliced(b.findIndex((c) => c.properties.id == a.properties.id), 1, a))}  />
                     </div>
                  )
               }}
            />
            </div>
         </div>
         {selectedGeom?.feature?.properties.type == "Mark" ? <ProtectedFeature feature={[Feature.BNBOToolArrondationCalc, Feature.BNBOProjectWrite]} contentsIfNoAccess={<></>}>
         <h6 style={{color:"var(--viamap-green)", marginBottom:"2px"}} >Arronderingsgenerator{" "}
            <GlassButtonRow>

            {arronderingsGrid?.features ? <GlassButton
               onClick={() => {
                  updateArrondationGrid()
               }}
               >Skjul</GlassButton> : <GlassButton
               onClick={() => {
                  updateArrondationGrid(arronderingsSettings)
               }}
               >Vis</GlassButton>}
            <GlassButton
               disabled={!arronderingsGrid?.features}
               onClick={() => {
                  let ftArrAreal:any[]=[];
                  let updatedFeatures = arronderingsGrid?.features.map((box) => {
                     for (let feature of dele) {
                        if (feature.properties["type"] === BNBOOmrådeType.ServitutAreal) {
                           let overlap = intersect(featureCollection([box, feature]));
                           if (overlap) {
                              ftArrAreal.push(box);
                           }
                        }
                     }
                     return box;
                  });
                  let arronderingsAreal = dissolve(featureCollection(ftArrAreal));
                  let idx = Math.round(Math.random()*100000);
                  arronderingsAreal.features.forEach((ft) => {
                     ft.properties = {
                        ...ft.properties,
                        type : BNBOOmrådeType.UlempeAreal,
                        note : "Arronderingsareal",
                        id:idx,
                        mask: selectedGeom?.feature.properties.maskKey,
                     }
                     ft.id = idx
                     // ft.farve =  ft.properties["color"];
                  })
                  addToHiearchy(idx, selectedGeom?.feature.properties.maskKey)
                  setDele((e) => [...e, ...(arronderingsAreal.features as any)]);
               }}
            >Apply</GlassButton>
            </GlassButtonRow>
         </h6>
         <div style={{textAlign:"right", display:"flex", justifyContent:"space-between", alignItems:"center"}} >
         Sprøjtebredde <input className="showMinMaxSlider" style={{width:"100%", display:"flex", accentColor:"var(--viamapGreen)"}} type="range" value={arronderingsSettings.sproejteBredde || "0"} min={10} max={50} step={0.5} 
            onChange={
               (a) => { let val = parseFloat(a.target.value) || 0.0;
                  setArronderingsSettings({...arronderingsSettings, sproejteBredde:val});
                  updateArrondationGrid({...arronderingsSettings, sproejteBredde:val});
               }
            }/>
            </div>
         <div style={{textAlign:"right", display:"flex", justifyContent:"space-between", alignItems:"center"}} >
         Vinkel <input className="showMinMaxSlider" style={{width:"100%", display:"flex", accentColor:"var(--viamapGreen)"}} type="range" value={arronderingsSettings.vinkelFraNord || "0"} min={0} max={180} step={0.5} 
            onChange={
               (a) => { let val = parseFloat(a.target.value) || 0.0;
                  setArronderingsSettings({...arronderingsSettings, vinkelFraNord:val});
                  updateArrondationGrid({...arronderingsSettings, vinkelFraNord:val});               }
            }/>
         </div>
            <div style={{textAlign:"right", display:"flex", justifyContent:"space-between", alignItems:"center"}} >
         Offset <input className="showMinMaxSlider" style={{width:"100%", display:"flex", accentColor:"var(--viamapGreen)"}} type="range" value={arronderingsSettings.offset} min={-50} max={50} step={0.5} 
            onChange={
               (a) => { let val = parseFloat(a.target.value) || 0.0;
                  setArronderingsSettings({...arronderingsSettings, offset:val});
                  updateArrondationGrid({...arronderingsSettings, offset:val});               }
            }/>
         </div>
         </ProtectedFeature>:<></>}
         <ProtectedFeature feature={Feature.BNBOProjectWrite} contentsIfNoAccess={<></>} >
         <Stack gap={3} direction={"horizontal"}>
            
         <GlassButton disabled={!unconfirmedDele} style={{marginTop:"12px"}} onClick={() => {
            onCommit();
         }}
         >Godkend</GlassButton>
         <GlassButton disabled={unconfirmedDele} style={{marginTop:"12px"}} 
            onClick={(e) => windowDispatch(openWindow(WindowId.BNBOOpgørelse, {lodsejerId: props.lodsejerId}))}
            >Opgørelse</GlassButton>
            </Stack>
         </ProtectedFeature> 
      </div>
      
      </>
   )



}


function ButtonMenu(props: {children}) {
   const [tooltipOpen, setTooltipOpen] = useState(true);
   const {refs, floatingStyles, context: tooltipContext} = useFloating({
      whileElementsMounted(referenceEl, floatingEl, update) {
         const cleanup = autoUpdate(referenceEl, floatingEl, update, {
           elementResize: false,
           animationFrame: true,
         });
         return cleanup;
       },
       placement: "left-start",
       open: tooltipOpen,
       middleware: [offset(10)]
     });

       
    return (
      <>
      <div ref={refs.setReference} style={{position:"relative", zIndex:"1", height:"100%"}} >
          
      </div>
      {(tooltipOpen) &&
      <FloatingPortal>
      <div ref={refs.setFloating} className="BnboButtonStack" style={floatingStyles} >
         {props.children}
      </div>
      </ FloatingPortal>
      }
      </>
  )
} 









function createPointLayer(id: string, source: string) {
   return {
      id: id,
      type: 'symbol',
      source: source,
      layout: {
         'text-field': ['get', 'note'],
         'text-font': ['Noto Sans Regular'],
         'text-size': 12,
         'text-offset': [0, 1.5],
         'text-allow-overlap': true,
      },
      paint: {
         'text-color': 'black',
         'text-halo-color': 'white',
      }
   }
}

function createLineLayer(id: string, source: string) {
   return {
      id: id,
      type: 'line',
      source: source,
      paint: {
         'line-color': ['match', ['get', 'type'],
            'Jordstykke', 'red',
            'Ejendom', 'red',
            'Mark', 'yellow',
            'blue'
         ],
         'line-width': ["*",['match', ['get', 'type'],'Ejendom', 4, 2], ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1]],
      }
   }
}

function createMaskLayer(id: string, source: string) {
   return {
      id: id,
      type: 'fill' as any,
      source: source,
      paint: {
         'fill-color': '#ffffffbb',
      }
   }
}

function createFillLayer(id: string, source: string):any {
   return {
      id: id,
      type: 'fill' as any,
      source: source,
      paint: {
         'fill-color': ['coalesce',["get",'farve'],[
            'match', ['get', 'type'],
            'Vejareal','brown',
            'ServitutAreal','blue',
            'UlempeAreal','lightblue',
            "AndetAreal",'red',
            "FredskovsAreal",'green',
            "Par3Areal",'purple',
            "FradragsAreal",'yellow',
            "Restareal",'orange',
            "MarkServitutareal",'blue',
            "Inaktive","#0000",
            'grey'
         ],'grey'],
         'fill-opacity': ["case", 
            ["!",["boolean",["get","visible"],true]],0.1,
            ['boolean', ['feature-state', 'hover'], false],1,0.6],
         'fill-outline-color': ["case", ['boolean', ['feature-state', 'hover'], false],"white",['coalesce',["get",'color'],'lightblue']]
      } as any
   }
}

function constructHiearchy(dele, clipGeom: any[]) {
   let maskGroups = clipGeom.map((a) => ({
      id: a.id ?? Math.floor(Math.random()*50000),
      maskKey: a.properties.maskKey,
      clipType: a.properties.type,
      type: "clipMask",
      children: dele.filter((b) => b.properties.mask == a.properties.maskKey).map((b) => ({id: b.properties.id, type: "layer"}))
   }))
   let hiearchy = dele.filter((a) => (!a.properties.mask) || maskGroups.find((b) => b.children[0]?.id == a.properties.id)).map((a) => {
      if (a.properties.mask) {
         let idx = maskGroups.findIndex((b) => b.maskKey == a.properties.mask)
         let formWith = maskGroups.splice(0, idx + 1)
         let toIdx = maskGroups.findIndex((b) => b.children.length == 1)
         let to = maskGroups.splice(0, toIdx == -1 ? maskGroups.length : toIdx)
         return [...formWith, ...to];
      }
      return {id: a.properties.id, type: "layer"}
   }).flat()
   return [...hiearchy, ...maskGroups]
}

function locationInDel(hiearchy, id) {
   let idx = 0
   for (let i = 0; i < hiearchy.length; i++) {
      if (hiearchy[i].id == id) {
         return idx
      }
      if (hiearchy[i].children) {
         for (let j = 0; j < hiearchy[i].children.length; j++) {
            if (hiearchy[i].children[j].id == id) {
               return idx
            }
            idx += 1
         }
      }
      idx += 1
   }
   return idx
}

function idInHiearchy(id, hiearchy) {
   for (let i = 0; i < hiearchy.length; i++) {
      if (hiearchy[i].id == id) {
         return true;
      }
      if (hiearchy[i].children) {
         if (idInHiearchy(id, hiearchy[i].children)) {
            return true;
         }
      }
   }  
}

function DeleView(props: {
   disabled: boolean,
   nettoAreas: number, onTrash: () => void,
   selected:boolean, del: TurfFeature, onChange:(del: TurfFeature) => void, onEdit: (a) => void}) {

   const [isOpen, setIsOpen] = useState(false);

   const {refs, floatingStyles, context} = useFloating({
      open: isOpen,
      whileElementsMounted: autoUpdate,
      onOpenChange: setIsOpen,
      placement: "bottom-end",
   });
   
   const dismiss = useDismiss(context);
   
   const {getReferenceProps, getFloatingProps} = useInteractions([
      dismiss,
   ]);

   

   function editProperty(changes: {[key:string]: any}) {
      props.onChange({...props.del, properties: {...props.del.properties, ...changes}})
   }

   if (props?.del?.properties == undefined) {
      return <></>
   }


   return (
      <>
      <div 
      className="LayerViewr"
      style={{display:"grid", alignItems:"center", columnGap:"2px", padding:"2px", paddingLeft:"6px", marginBlock:"1px", background:"#eee", borderRadius:"8px",
         gridTemplateColumns:"20px 90px 1fr" + (props.disabled ? "" : " 30px")
      }}>
         {/* <BsCaretUp style={{opacity:"0.2",fontSize:"8px"}} onClick={props.moveUp} /> */}
         <GlassCheckbox checked={(props.del.properties.visible == undefined) || props.del.properties.visible} onClick={(e,checked) => {
            editProperty({"visible": checked});
         }} />
         {/* <GlassInputControl> */}
            <select disabled={props.disabled} defaultValue={props.del.properties.type} onChange={(e) => {
                  editProperty({"type": e.target.value, farve: undefined, faktor: undefined})
                  // editProperty("faktor": undefined)
               }} >
                  <option value={BNBOOmrådeType.Vejareal} >Vejareal</option>
                  <option value={BNBOOmrådeType.Par3Areal} >§3</option>
                  <option value={BNBOOmrådeType.FredskovsAreal} >Fredskov</option>
                  <option value={BNBOOmrådeType.FradragsAreal}>Fradrag</option>
                  <option value={BNBOOmrådeType.UlempeAreal}>Ulempe</option>
                  <option value={BNBOOmrådeType.Restareal} >Rest</option>
                  <option value={BNBOOmrådeType.ServitutAreal}>Omdrift</option>
                  <option value={BNBOOmrådeType.Inaktive}>Inaktiv</option>
            </select>
         {/* </GlassInputControl> */}
         {/* <div onClick={() => {props.del.properties.type == "Servitut" || editProperty("type": toogleUlempeFradrag(props.del.properties.type))}}>
            {props.del.properties.type}
         </div> */}
         <input disabled={props.disabled} type="text" placeholder="note.." style={{minWidth:"0px"}} value={props.del.properties.note}
            onChange={(e) => editProperty({"note": e.target.value || ""}) }
         />
         {props.disabled ? <></> :
         <button style={{border:"unset", width:"16px"}} onClick={props.onEdit}>🖋️</button>
         }
         {/* <BsCaretDown style={{opacity:"0.2",fontSize:"8px"}} onClick={props.moveDown} /> */}
         <ColorBoxEditableV2 disabled={props.disabled} onCommit={(e) => editProperty({"farve": e})} color={props.del.properties.farve || colorTypeList[props.del.properties.type]}
            
         />
         {
            props.del.properties.isManualTakst ? 
            <div style={{textAlign:"right", fontSize:"0.8em"}} onDoubleClick={(e) => e.stopPropagation?.()}>
            {/* <span>Sats:</span> */}
            <input disabled={props.disabled} style={{width:"6ex", padding:"0px"}} type="number" value={props.del.properties.takst ?? SettingsManager.getSystemSetting("bnbo.defaultManualTakst")} step={0.1} min={0} max={SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.max",24)} onChange={(e) => editProperty({"takst": parseFloat(e.target.value) || 0})} />
            <small>kr/m²</small>
            </div>
            :
            <div style={{textAlign:"right", fontSize:"0.8em"}} onDoubleClick={(e) => e.stopPropagation?.()}>
            <span>Faktor:</span>
            <input disabled={props.disabled} style={{width:"6ex", padding:"0px"}} type="number" value={props.del.properties.faktor ?? BNBOBeregninger.faktorByOmrådeType(props.del.properties.type)} step={0.1} min={0} max={1} onChange={(e) => editProperty({"faktor": parseFloat(e.target.value) || 0})} />
            </div>
         }
         <div style={{marginLeft:"10px", display:"flex", gap:"2px", alignItems:"center"}}>
            {props.nettoAreas.toLocaleString("da-dk",{maximumFractionDigits:0})} m² <small style={{fontSize:"0.6em"}}>(Netto)</small>
            {props.disabled ? <></> :<button  ref={refs.setReference} {...getReferenceProps()}  onClick={() => setIsOpen((e) => !e)} style={{marginLeft:"auto", border:"unset", width:"25px"}}>⚙️</button>}
         </div>
         {props.disabled ? <></> :
         <button style={{border:"unset", width:"16px"}} onClick={props.onTrash}>🗑️</button>
         }
      </div>
      {isOpen && (
         <FloatingPortal>
         <div
            ref={refs.setFloating}
            style={{...floatingStyles, zIndex: 1000}}
            className="LayerListDotDotMenu"
            {...getFloatingProps()}
         >
            <button onClick={() => {
               editProperty({isManualTakst: props.del.properties.isManualTakst ? false : true})
            }} >{!props.del.properties.isManualTakst ? "Benyt takst" : "Benyt faktor"}</button>
         </div>
         </FloatingPortal>
         )}
      </>
   )

}

const colorTypeList = {
   'Vejareal':'brown',
   'ServitutAreal':'blue',
   'UlempeAreal':'lightblue',
   "AndetAreal":'red',
   "FredskovsAreal":'green',
   "Par3Areal":'purple',
   "FradragsAreal":'yellow',
   "Restareal":'orange',
   "MarkServitutareal":'blue',
}

function MaskTilte(props: {item: any}) {
   return (
      <div>{props.item.clipType} {props.item.maskKey}</div>
   )
}