import { feature, featureCollection } from "@turf/helpers";
import { bbox, intersect, union } from "@turf/turf";
import { GeoJSONSource } from "maplibre-gl";
import React, { useEffect, useRef } from "react";
import { LayerType } from "src/common/managers/Types";
import { MapFacadeMaplibre, MitLayerHandleMaplibre } from "src/managers/MapFacadeMaplibre";
import { actionSetSingleFeatureLayerVisibility, MapitStateContext, SetPrintLayers } from "src/states/MapitState";
import { GlassButton } from "src/components/GlassButtons";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { openWindow, sendParams, WindowId } from "src/WindowManager/WindowState";
import { BNBOContext, BNBOLodsejer, BNBOOmrådeType, BNBOState } from "./BNBOState";
import { VectorLayer } from "src/managers/VectorLayerFunc";
import { GeojsonWFS } from "src/managers/WFSGeojsonLayerFunc";
import { FeatureLayer } from "src/managers/WmsLayerFunc";
import { PromiseImage } from "src/functions/PaperCanvas";
import { formatMatrikelNumre } from "./BNBOFunc";
import { SettingsManager } from "@viamap/viamap2-common";
import { GenerateGeom } from "src/managers/GenerateGeom";
import { bnboActiveFilter, colorByType, textByType } from "./BNBOAreaEditorExtra";

// eslint-disable-next-line react-refresh/only-export-components
export enum ViewerModus {
   Overblik = 0,
   ERids = 1,
   ERidsUU = 2,
   Kortbilag = 3,
   Ingen = 4,
   Ingen2 = 5
}

export function BNBOAreaViewer(props: {
   lodsejerId: string,
   features: any[]
   bnboGeom: any[]
   markGeom: any[]
   lodsGeom: any[],
   defaultModus?: ViewerModus
   }) {
   const {state: mapitState, dispatch: mapitStateDispatch } = React.useContext(MapitStateContext);
   const map = useRef<MapFacadeMaplibre | null>(null);
   const { dispatch:WindowDispatch } = useWindowContext();
   const {state: bnboState} = React.useContext(BNBOContext);
   const [modus, setModus] = React.useState<ViewerModus>(ViewerModus.Overblik);

   const legend = getLegendURLbyModus(modus);

   const [legendImg, setLegendImg] = React.useState<string[]>([]);
   useEffect(() => {
      getLegendPerLayer(props.features, modus).then((a) => setLegendImg(a))
   },[modus, props.features])

   useEffect(() => {
      setModus(props.defaultModus || ViewerModus.Overblik)
   },[props.defaultModus]);

   function turnAllLayersOff() {
      mapitState.selectedFeatureLayerKeys.forEach(key => {
         mapitStateDispatch(actionSetSingleFeatureLayerVisibility(key, false))
      });
   }

   function turnLayersOn(key: string): VectorLayer | GeojsonWFS | FeatureLayer | undefined {
      let layerSpec = mapitState.featureLayers[key];
      if (layerSpec instanceof VectorLayer || layerSpec instanceof GeojsonWFS) {
         if (true) {
            map.current?.addVectorLayer(layerSpec)
            return layerSpec
         }
      } else if (layerSpec instanceof FeatureLayer) {
         if (true) {
            map.current?.addFeatureLayer(layerSpec.layer.label, layerSpec)
            return layerSpec
         }
      }
   }
   
   useEffect(() => {
      let printObject: typeof mapitState["printLayers"] = [];
      map.current = mapitState.map
      let [ba,bb,bc,bd] = bbox(featureCollection([...props.features, ...props.lodsGeom]));
      map.current.getMapPop().fitBounds([[ba-0.0005,bb-0.0005],[bc+0.0005,bd+0.0005]])
      

      

      let BNBOLayerSourceViewbnboGeom = emptySource(props.bnboGeom) as any;
      let BNBOLayerViewbnboGeom = bnboLayerStylingbnboGeom("BNBOLayerViewbnboGeomfill", "BNBOLayerSourceViewbnboGeom") as any;
      // let BNBOLayerViewbnboGeomFill = bnboLayerStylingbnboGeomFill("BNBOLayerViewbnboGeomFill", "BNBOLayerSourceViewbnboGeom") as any;
      if (!(modus === ViewerModus.ERids || modus === ViewerModus.ERidsUU)) {
         let BNBOLayerSourceViewmarkGeom = emptySource(props.markGeom) as any;
         let BNBOLayerViewmarkGeom = bnboLayerStylingmarkGeom("BNBOLayerViewmarkGeomline", "BNBOLayerSourceViewmarkGeom") as any;
         let BNBOLayerViewmarkGeomFill = bnboLayerStylingmarkGeomFill("BNBOLayerViewmarkGeomfill", "BNBOLayerSourceViewmarkGeom") as any;

         let ViewMark = new MitLayerHandleMaplibre()
         ViewMark.addSource("BNBOLayerSourceViewmarkGeom",BNBOLayerSourceViewmarkGeom)
         ViewMark.addLayer(BNBOLayerViewmarkGeom)
         ViewMark.addLayer(BNBOLayerViewmarkGeomFill)

         printObject = [...printObject,{handle:ViewMark, type: "MitLayer"}]
      }
      let BNBOLayerSourceViewlodsGeom = emptySource(props.lodsGeom) as any;
      let BNBOLayerViewlodsGeom = bnboLayerStylinglodsGeom("BNBOLayerViewlodsGeomline", "BNBOLayerSourceViewlodsGeom") as any;
      let BNBOLayerViewlodsGeomFill = bnboLayerStylinglodsGeomFill("BNBOLayerViewlodsGeomfill", "BNBOLayerSourceViewlodsGeom") as any;

      
      let BNBOLayerSourceView = emptySource(FeaturesByModus(props.features ,modus)) as any;
      
      let BNBOLayerView = (modus == ViewerModus.ERids || modus == ViewerModus.ERidsUU) ? bnboLayerStylingeRids("BNBOLayerViewfill", "BNBOLayerSourceView") as any : bnboLayerStyling("BNBOLayerViewfill", "BNBOLayerSourceView") as any 
      let View = new MitLayerHandleMaplibre()
      View.addSource("BNBOLayerSourceView",BNBOLayerSourceView)
      View.addLayer(BNBOLayerView)

      // let BNBOLayerSourceView = emptySource(FeaturesByModus(modus)) as any;
      // let BNBOLayerViewSymbol = bnboLayerStylingSymbol("BNBOLayerViewSymbol", "BNBOLayerSourceView") as any;
      // map.current?.getLayer("BNBOLayerViewSymbol") || map.current.addLayer(BNBOLayerViewSymbol);
      // let View = new MitLayerHandleMaplibre()
      // View.addSource("BNBOLayerSourceView",BNBOLayerSourceView)
      // View.addLayer(BNBOLayerViewSymbol)
      // map.current?.getSource("BNBOLayerSourceView") || map.current.addSource("BNBOLayerSourceView",BNBOLayerSourceView)

      let ViewBnbo = new MitLayerHandleMaplibre()
      ViewBnbo.addSource("BNBOLayerSourceViewbnboGeom",BNBOLayerSourceViewbnboGeom)
      ViewBnbo.addLayer(BNBOLayerViewbnboGeom)
      // ViewBnbo.addLayer(BNBOLayerViewbnboGeomFill)

      let ViewLods = new MitLayerHandleMaplibre()
      ViewLods.addSource("BNBOLayerSourceViewlodsGeom",BNBOLayerSourceViewlodsGeom)
      ViewLods.addLayer(BNBOLayerViewlodsGeom)
      ViewLods.addLayer(BNBOLayerViewlodsGeomFill)


      printObject = [
         {type: "MitLayer", handle:View}
      ]
      
      printObject.forEach((a) => {
         map.current && (((a as any).handle as MitLayerHandleMaplibre).addTo(map.current))
      });
      let layers = getLayersByModus(modus).map((a) => turnLayersOn(a)).filter((a) => a);
      layers.forEach((a,idx) => {
         if (!a) {return}
         if (a instanceof VectorLayer || a instanceof GeojsonWFS) {
            printObject = [...printObject, {type: "Vector", handle: a as VectorLayer}]
         } else if (a instanceof FeatureLayer) {
            printObject = [{type: "Raster", handle: a as FeatureLayer}, ...printObject,]
         }
      });
      let lastLayers: typeof mapitState["printLayers"] = [{type: "MitLayer", handle:ViewLods}, {type: "MitLayer", handle:ViewBnbo}]
      lastLayers.forEach((a) => {
         map.current && (((a as any).handle as MitLayerHandleMaplibre).addTo(map.current))
      })
      printObject = [...printObject, ...lastLayers]


      mapitStateDispatch(SetPrintLayers(printObject))
      return () => {
         WindowDispatch(sendParams(WindowId.PlotScreen,{}))
         mapitStateDispatch(SetPrintLayers([]))
         layers.forEach((a) => {
            if (a instanceof VectorLayer || a instanceof GeojsonWFS) {
               map.current?.removeVectorLayer(a)
            } else if (a instanceof FeatureLayer) {
               map.current?.removeFeatureLayer(a.layer.label)
            }
         })
         Object.values(printObject).forEach((a) => {
            a?.["type"] === "MitLayer" && map.current?.removeLayer((a as any).handle)
         });
      }
   },[modus])
   
   useEffect(() => {
      (map.current?.getMapPop().getSource("BNBOLayerSourceView") as GeoJSONSource)?.setData(featureCollection(props.features));
      (map.current?.getMapPop().getSource("BNBOLayerSourceViewbnboGeom") as GeoJSONSource)?.setData(featureCollection(props.bnboGeom));
      (map.current?.getMapPop().getSource("BNBOLayerSourceViewmarkGeom") as GeoJSONSource)?.setData(featureCollection(props.markGeom));
      (map.current?.getMapPop().getSource("BNBOLayerSourceViewlodsGeom") as GeoJSONSource)?.setData(featureCollection(props.lodsGeom));
   },[props.features, props.bnboGeom, props.markGeom, props.lodsGeom])
   
   useEffect(() => {
      let [ba,bb,bc,bd] = bbox(featureCollection([...props.features, ...props.lodsGeom]));
      map.current?.getMapPop().fitBounds?.([[ba-0.0005,bb-0.0005],[bc+0.0005,bd+0.0005]]);
      setModus(ViewerModus.Overblik)
   },[props.lodsejerId])
   
   return (
      <>
      
      <div className="GlassInputField">
      <select
         value={modus}
         className="ModusSelect"
         
         onChange={(e) => { 
            turnAllLayersOff()
            

            setModus(parseInt(e.target.value) as ViewerModus)
          }} >
         <option value={ViewerModus.Overblik}>{ModusToString(ViewerModus.Overblik)}</option>
         <option value={ViewerModus.ERids}>{ModusToString(ViewerModus.ERids)}</option>
         <option value={ViewerModus.ERidsUU}>{ModusToString(ViewerModus.ERidsUU)}</option>
         <option value={ViewerModus.Kortbilag}>{ModusToString(ViewerModus.Kortbilag)}</option>
         {/* <option value={ViewerModus.Ingen}>{ModusToString(ViewerModus.Ingen)}</option> */}
         {/* <option value={ViewerModus.Ingen2}>{ModusToString(ViewerModus.Ingen2)}</option> */}
         </select>
      </div>
      
      <br/>
      
      
      <h6>Signaturforklaring</h6>
      {legend.map((a, idx) => (<img key={idx} src={a} alt="Signaturforklaring" />))}
      {legendImg.map((a, idx) => (<img key={idx} src={a} alt="Signaturforklaring" />))}
      <div style={{display:"flex", marginTop:"auto", justifyContent:"right", gap:"5px"}} >
      {/* <GlassButton 
         onClick={(e) => window.open(new URL("/demoDataBNBO/BNBO Kortbilag.pdf", import.meta.url).href, "_blank")}
      >Kortbilag</GlassButton>
      <BNBOGenerateERids lodsEjer={props.lodsejerId} /> */}
      <GlassButton 
         onClick={(e) => {
            let description = getDescriptionByType(modus, bnboState.lodsejer[props.lodsejerId], bnboState, props.features);
            WindowDispatch(openWindow(WindowId.PlotScreen, {externalSettings: {
               format: "A3L",
               title: "",
               includeDate: false,
               description: description,
               legendImg: [...legend.map((a) => ({img: PromiseImage(a), ftl:{layer:{}}})), ...legendImg.map((a) => ({img: PromiseImage(a), ftl:{layer:{}}}))],
            }}))
         }} >
         Plot {ModusToString(modus)}</GlassButton>
      </div>
      </>
   )
}


function bnboLayerStylingeRids(id: string, source: string) {
   return {
      id: id,
      type: 'fill',
      source: source,
      paint: {
         'fill-color': ["match",["get",'type'],
         'ServitutAreal','lightblue',
         'UlempeAreal','lightblue',
         "AndetAreal",'lightblue',
         "FredskovsAreal",'lightblue',
         "Par3Areal",'lightblue',
         "FradragsAreal",'lightblue',
         "Restareal",'lightblue',
         "MarkServitutareal",'lightblue',
         '#0000'],
         'fill-opacity': ["case",
            ["!",["boolean",["get","visible"],true]],0.1,
            ['boolean', ['feature-state', 'hover'], false],1,0.6],
            // 'fill-outline-color': ["case", ['boolean', ['feature-state', 'hover'], false],"white",['coalesce',["get",'color'],'lightblue']]
         }
      }
   }


function bnboLayerStyling(id: string, source: string) {
   return {
      id: id,
      type: 'fill',
      source: source,
      paint: {
         'fill-color':["to-color",["get","farve"],["match",["get",'type'],
         'ServitutAreal','blue',
         'UlempeAreal','lightblue',
         "AndetAreal",'red',
         "FredskovsAreal",'green',
         "Par3Areal",'purple',
         "FradragsAreal",'yellow',
         "Restareal",'orange',
         "MarkServitutareal",'blue',
         'grey']],
         'fill-opacity': ["case",
            ["!",["boolean",["get","visible"],true]],0.1,
            ['boolean', ['feature-state', 'hover'], false],1,0.6],
            // 'fill-outline-color': ["case", ['boolean', ['feature-state', 'hover'], false],"white",['coalesce',["get",'color'],'lightblue']]
         }
      }
   }

   function bnboLayerStylingSymbol(id: string, source: string) {
      return {
         id: id,
         type: 'symbol',
         source: source,
         layout: {
            "symbol-placement": "line",
            "text-field": ["get","type"],
            "text-size": 12,
            "text-offset": [0,1],
            "text-font": ["Noto Sans Regular"],
            'text-ignore-placement' : true,
         },
         paint: {
            'text-color': "black",
               
            'text-opacity': ["case",
               ["!",["boolean",["get","visible"],true]],0.1,
               ['boolean', ['feature-state', 'hover'], false],1,0.6],
               // 'fill-outline-color': ["case", ['boolean', ['feature-state', 'hover'], false],"white",['coalesce',["get",'color'],'lightblue']]
            }
         }
      }
   
   function bnboLayerStylingbnboGeomFill(id: string, source: string) {
      return {
         id: id,
         type: 'fill',
         source: source,
         paint: {
            // 'fill-pattern': "mit-patt-line-blue-#ddd",
         }
      }
   }
   function bnboLayerStylingbnboGeom(id: string, source: string) {
      return {
         id: id,
         type: 'line',
         source: source,
         paint: {
            'line-color': "red",
            'line-width': 3,
         }
      }
   }
   function bnboLayerStylingmarkGeomFill(id: string, source: string) {
      return {
         id: id,
         type: 'fill',
         source: source,
         paint: {
            'fill-pattern': "mit-patt-line-#add86f-#ddd",
         }
      }
   }
   function bnboLayerStylingmarkGeom(id: string, source: string) {
      return {
         id: id,
         type: 'line',
         source: source,
         paint: {
            'line-color': "#add86f",
            'line-width': 3,
         }
      }
   }
   function bnboLayerStylinglodsGeomFill(id: string, source: string) {
      return {
         id: id,
         type: 'fill',
         source: source,
         paint: {
            'fill-color': "yellow",
            'fill-opacity':0
         }
      }
   }
   function bnboLayerStylinglodsGeom(id: string, source: string) {
      return {
         id: id,
         type: 'line',
         source: source,
         paint: {
            'line-color': "yellow",
            'line-width': 3,
         }
      }
   }
   
   function emptySource(a: any[]) {
      return {
         'type':'geojson',
         'tolerance': SettingsManager.getSystemSetting("bnbo.geojsonPrintTolerance", 0.2) || 0.1,
         'data': featureCollection(a)
      }
   }
   
   
   
   
   function ModusToString(modus: ViewerModus) {
      return {
         [ViewerModus.Overblik]:"Overblik",
         [ViewerModus.ERids]:"eRids - Med Ulempeareal",
         [ViewerModus.ERidsUU]:"eRids - Uden Ulempeareal",
         [ViewerModus.Kortbilag]:"Kortbilag",
         [ViewerModus.Ingen]:"Ingen",
         [ViewerModus.Ingen2]:"Ingen2"
      }[modus] || "Ukendt modus"
   }


   function getDescriptionByType(modus: ViewerModus, lodsEjer: BNBOLodsejer, bnboState: BNBOState, features: any[]) {
      const dele = FeaturesByModus(features, modus)
   
      const deleUnion = (() => {
         if (dele.length > 1)
            return [union(featureCollection(dele))]
            return dele
      })();
      const matrikel_aftale = lodsEjer.geometry.features.filter((a) => intersect(featureCollection([a, ...deleUnion])))
      const Matrikel_Liste_aftale = matrikel_aftale && formatMatrikelNumre(matrikel_aftale) || "[[Matrikel liste BNBO]]"
      

      switch (modus) {
         case ViewerModus.Overblik: return `Overblik over ${lodsEjer.sfeNummer}`
         case ViewerModus.ERidsUU:
         case ViewerModus.ERids: return `eRids udfærdiget i forbindelse med tinglysning af servitut
      
      SFE: ${lodsEjer.sfeNummer}
      Sagsbehandler: ${lodsEjer.sagsbehandler} \n
      Matr.nre. \n ${Matrikel_Liste_aftale}
      
      Kommune: ${SettingsManager.getSystemSetting("bnbo.kommuneNavn") || ""} 
      Dato: ${new Date().toLocaleDateString("da-dk")}`
         case ViewerModus.Kortbilag: return `Kortbilag for ${lodsEjer.sfeNummer}`
      default: return "Missing"

      }
   }

   async function getLegendPerLayer(inFeatures, modus: ViewerModus):Promise<string[]> {
      if (modus !== ViewerModus.Kortbilag && modus !== ViewerModus.Overblik) {
         return await []
      }
      let features = FeaturesByModus(inFeatures, modus)
      let reducedFeat = features.reduce((acc, feature) => {
         if (acc.length === 0) {
            return [feature]
         }
         if (acc.find((a) => a.properties.type === feature.properties.type 
            && a.properties.note === feature.properties.note
            && a.properties.farve === feature.properties.farve
         )) {
            return acc
         }
         return [...acc, feature]
      },[])

      return await Promise.all(reducedFeat.map(async (a) => {
         console.log(a)
         return await GenerateGeom.SVGToImage(LegendSVG(
            (a.properties.farve || colorByType(a.properties.type) || "lightblue"),
            (a.properties.note || textByType(a.properties.type) || "Ukendt type")
         ), 290, 29)
      }))
   }


     
   function FeaturesByModus(inFeatures, modus: ViewerModus) {
      let features = inFeatures.filter((a) => !(a.properties.type === BNBOOmrådeType.Inaktive))
      switch (modus) {
         case ViewerModus.ERidsUU:
            return features.filter((a) => !(a.properties.type === BNBOOmrådeType.UlempeAreal))
         case ViewerModus.ERids:
         case ViewerModus.Overblik:
         case ViewerModus.Kortbilag:
         case ViewerModus.Ingen:
         case ViewerModus.Ingen2:
            return features
      }
   }


   function reduceBySimilar(features: any[], feature: any) {
      
   }


   function getLayersByModus(modus: ViewerModus):  (string)[] {
      return {
         [ViewerModus.Overblik]: [],
         [ViewerModus.ERidsUU]: ["Ortofoto forår 12,5cm","Jordstykke med matrikelnummer i kortets retning", "Optaget Vej fra WFS", "Ejerlavgrænser med navn langs grænsen", "Vandboringer §21b, stk. 2-areal", "Vandboringer"],
         [ViewerModus.ERids]: ["Ortofoto forår 12,5cm","Jordstykke med matrikelnummer i kortets retning", "Optaget Vej fra WFS", "Ejerlavgrænser med navn langs grænsen", "Vandboringer §21b, stk. 2-areal", "Vandboringer"],
         [ViewerModus.Kortbilag]: ["BNBOLayerView", "BNBOLayerViewbnboGeom", "BNBOLayerViewmarkGeom", "BNBOLayerViewlodsGeom"],
         [ViewerModus.Ingen]: ["BNBOLayerView", "BNBOLayerViewbnboGeom", "BNBOLayerViewmarkGeom", "BNBOLayerViewlodsGeom"],
         [ViewerModus.Ingen2]: ["BNBOLayerView", "BNBOLayerViewbnboGeom", "BNBOLayerViewmarkGeom", "BNBOLayerViewlodsGeom"]
      }[modus] || [];
   }

   function getLegendURLbyModus(modus: ViewerModus):string[] {
      return {
         [ViewerModus.Overblik]: [new URL("/demoDataBNBO/kortBilagSignaturSimple.png", import.meta.url).href],
         [ViewerModus.ERids]: [new URL("/demoDataBNBO/eRidsSignatur.png", import.meta.url).href],
         [ViewerModus.ERidsUU]: [new URL("/demoDataBNBO/eRidsSignatur.png", import.meta.url).href],
         [ViewerModus.Kortbilag]: [new URL("/demoDataBNBO/kortBilagSignaturSimple.png", import.meta.url).href],
         [ViewerModus.Ingen]: [""],
         [ViewerModus.Ingen2]: [""]
      }[modus] || [""];
   }

function LegendSVG(color ,text) {
   return `<svg
   width="290.79822"
   height="29.836334"
   viewBox="0 -1 76.940362 7.8941968"
   version="1.1"
   id="svg1"
   xml:space="preserve"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><defs
     id="defs1" /><g
     id="layer1"
     transform="translate(-0.49937871,-23.431982)"><text
       xml:space="preserve"
       style="font-size:4.23333px;text-align:start;writing-mode:lr-tb;direction:ltr;text-anchor:start;fill:#000000;stroke-width:3.96875"
       x="15.599524"
       font-family="Arial, Helvetica, sans-serif"
       y="28.730408"
       id="text1-4-6">${text}
         </text>
         <rect
       style="fill:${color};fill-opacity:1;stroke-width:3.96875"
       id="rect1-5-1"
       width="10.986874"
       height="5.6706443"
       x="0.88603806"
       y="24.36945" /></g></svg>`
}