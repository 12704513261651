import { PropertyInformation } from "src/managers/PropertyInformation";
import { PropertyData, VurdData } from "./PropertyInfoCollectorTypes";
import { FormatUtils } from "src/managers/FormatUtils";
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import {
  EmptySpace,
  GlassFoldUdBox,
  KeyValueLine,
  KeyValueTable,
  KeyValuesBoxGrid,
  RenderLinkToCompanyInfo,
  RenderLinkToCondominium,
  RenderLinkToPersonInfo,
  TitleValueLine,
  ValueGroup,
  
} from "./PropertyComponents";
import { Fragment } from "react";
import {
  Feature,
  FeatureType,
} from "src/states/ApplicationState";
import { Col, FloatingLabel, Form } from "react-bootstrap";

import './PropertyInfoGroupFormatters.css';
import { plandataKeyParser, areaParser, plandataParser,
  titleValParser, vurdBenytParser } from "./PropertyInfoParser";
import { BBRTranslateByDataKey } from "src/managers/BBRTranslateByDataKey";
import { ExportData } from "src/managers/ExportData";
import { BBRKodeLister } from "./BBRKodeLister";
import { GlassButton } from "src/components/GlassButtons";

export class PropInfoGroupFormatters {

  static MatrikelKort(MatUdsnit:any): any {
    return (
      <>
      <img style={{width:"100%"}} src={("oversigtskort" in (MatUdsnit) && "data:image/svg+xml;base64,"+(MatUdsnit).oversigtskort) || ""} ></img>
      {MatUdsnit.matrikelklynger.map((a,idx) => {
        return (<GlassFoldUdBox key={a.matrikler[0].matrnr} title={"Område "+(idx+1)} foldetUd={false} >
          <img style={{width:"100%"}} src={("oversigtskort" in (MatUdsnit) && "data:image/svg+xml;base64,"+(a).b64_svgz) || ""} ></img>
        </GlassFoldUdBox>)
      })}
      </>
    )
    
  }
  static BBRKort(BBRUdsnit:any): any {
    return (
      <>
      {BBRUdsnit.matrikelklynger.map((a,idx) => {
        return (<GlassFoldUdBox key={idx} title={"Område "+(idx+1)} foldetUd={idx == 0} >
          <img style={{width:"100%"}} src={("data:image/svg+xml;base64,"+(a)) || ""} ></img>
        </GlassFoldUdBox>)
      })}
      </>
    )
    
  }
  static ViamapMarkedsStatistik(data: PropertyData): any {
    if (data.ViamapEjendomStats) {
      let shortenArray = (val: any, idx: number) => {
        return idx > 3;
      };
      return (
        <>
          <KeyValueTable>
              <tr>
                <th>Kvartal:</th>
                {data.ViamapEjendomStats?.perioder
                  ?.filter(shortenArray)
                  .map((per, idx) => {
                    return <th key={idx}>{per}</th>;
                  })}
              </tr>
              <tr>
                <td>
                  {data.ViamapEjendomData?.kommuneNavn +
                    " (" +
                    data.Mat2?.kommunekode +
                    ")"}
                </td>
                {data.ViamapEjendomStats?.vaerdierBM010
                  ?.filter(shortenArray)
                  .map((per, idx) => {
                    let val = isNaN(per as any)
                      ? "-"
                      : new Intl.NumberFormat("da-dk", {
                          maximumFractionDigits: 0,
                        }).format(parseFloat(per.replace(".", "")));
                    return <td key={idx}>{val}</td>;
                  })}
              </tr>
              <tr>
                <td>
                  {(data.EBRBFE?.husnummer?.[0]?.postnummer?.postnr || "") +
                    " " +
                    (data.EBRBFE?.husnummer?.[0]?.postnummer?.navn || "")}
                </td>
                {data.ViamapEjendomStats?.vaerdierBM011
                  ?.filter(shortenArray)
                  .map((per, idx) => {
                    let val = isNaN(per as any)
                      ? "-"
                      : new Intl.NumberFormat("da-dk", {
                          maximumFractionDigits: 0,
                        }).format(parseFloat(per.replace(".", "")));
                    return <td key={idx}>{val}</td>;
                  })}
              </tr>
          </KeyValueTable>
          <EmptySpace />
          <KeyValueLine
            valKey={`Kilde: ${data.ViamapEjendomStats?.kilde}`}
            value={""}
            keepEmpty
          />
          <KeyValueLine
            valKey={`Enhed: ${data.ViamapEjendomStats?.enhed}`}
            value={""}
            keepEmpty
          />
        </>
      );
    }
  }

  
  static PlandataDatoViser(plan: {}) {
    return Object.keys(plan).filter((a:string) => a.toLowerCase().includes("dato") && plan[a]).sort((a,b) => {
      let resA = parseInt(plan[a].toString().split("T")?.[0].replaceAll("-","")); 
      let resB = parseInt(plan[b].toString().split("T")?.[0].replaceAll("-",""));
      return resA - resB
    }).map((a) => {
      return (
        <KeyValueLine
        CParser={plandataParser}
        KeyEndParser={plandataKeyParser}
        key={a}
        valKey={a}
        value={plan[a]}
        />
        )
    })
  }

  static Delområde(data: PropertyData, foldetUd: boolean) {
    return data?.PlanDataDelområde?.map((plan, idx) => {
      return (
        <GlassFoldUdBox
          key={plan.lp_plannr+plan.delnr}
          boxKey={"Delområder"}
          group="Planer"
          title={`Delområde Plan - ${plan.lp_plannr} ${plan.delnr}`}
          foldetUd={foldetUd || false}

        >
        {Object.keys(plan).filter((a) => !a.includes("dato")).map((planKey) => 
          <KeyValueLine
          CParser={plandataParser}
          KeyEndParser={plandataKeyParser}
          key={planKey}
          valKey={planKey}
          value={plan[planKey]}
        />
        )}
        <EmptySpace />
        <ValueGroup>
        <TitleValueLine keepEmpty valKey={"Tidlinje"} value={""} />
        <KeyValuesBoxGrid minWidth="150px">
          {this.PlandataDatoViser(plan)}
        </KeyValuesBoxGrid>
        </ValueGroup>
      </GlassFoldUdBox>
      )
    })
  }

  static LokalPlan(data: PropertyData, foldetUd: boolean) {
    return data?.PlanDataLokalPlan?.map((plan, idx) => {
        return (
          <GlassFoldUdBox
            key={plan.id}
            boxKey={"Lokalplaner"}
            group="Planer"
            title={`Lokal Plan - ${plan.plannavn}`}
            foldetUd={foldetUd || false}

          >
          {Object.keys(plan).filter((a) => !a.includes("dato")).map((planKey) => 
            <KeyValueLine
            CParser={plandataParser}
            KeyEndParser={plandataKeyParser}
            key={planKey}
            valKey={planKey}
            value={plan[planKey]}
          />
          )}
          <EmptySpace />
          <ValueGroup>
          <TitleValueLine keepEmpty valKey={"Tidlinje"} value={""} />
          <KeyValuesBoxGrid minWidth="150px">
            {this.PlandataDatoViser(plan)}
          </KeyValuesBoxGrid>
          </ValueGroup>
        </GlassFoldUdBox>
        )
      })
  }

  static KommunePlan(data: PropertyData, foldetUd: boolean) {
    return data?.PlanDataKommunePlan?.map((plan,idx) => {
        return (
          <GlassFoldUdBox
            key={plan.id}
            group="Planer"
            title={`Kommune Plan - ${plan.plannavn}`}
            boxKey={"Kommuneplan"}
            foldetUd={foldetUd || false}
            >
            {Object.keys(plan).filter((a) => !a.includes("dato")).map((planKey) => 
              <KeyValueLine
              CParser={plandataParser}
              KeyEndParser={plandataKeyParser}
              key={planKey}
              valKey={planKey}
              value={plan[planKey]}
            />
            )}
            <EmptySpace />
            <ValueGroup>
            <TitleValueLine keepEmpty valKey={"Tidlinje"} value={""} />
            <KeyValuesBoxGrid minWidth="200px">
              {this.PlandataDatoViser(plan)}
            </KeyValuesBoxGrid>
            </ValueGroup>
          </GlassFoldUdBox>
          )
      })
  }

  static PlanDataKommuneAflyst(data: PropertyData, foldetUd:boolean ) {
    return (
      <GlassFoldUdBox group="Planer" title={"Aflyste Kommuneplaner"} boxKey={"Aflyste Kommuneplaner"}
        foldetUd={false} >
          {
            data.PlanDataKommunePlanAflyst?.toSorted((b,a) => a.datoaflyst - b.datoaflyst).map((a) => {
              return (
                <ValueGroup key={a.oid}>
                  <TitleValueLine valKey={[a.plannavn]} value={<a
                      type="application/pdf"
                      target="_blank"
                      href={a.doklink}
                    >Hent Dokument</a>} keepEmpty />
                  <KeyValueLine valKey={"Hændelse"}  value={a.aflystype} />
                  <KeyValueLine valKey={"Aflyst d."} value={a.datoaflyst} CParser={(a,b) => FormatUtils.formatNumberStringDate(b)} />
                </ValueGroup>
              )
            })
          }
        
      </GlassFoldUdBox>
    )
  }

  static PlanDataLokalPlanAflyst(data: PropertyData, foldetUd:boolean ) {
    return (
      <GlassFoldUdBox group="Planer" title={"Aflyste LokalPlaner"} boxKey={"Aflyste LokalPlaner"}
        foldetUd={false} >
          {
            data.PlanDataLokalPlanAflyst?.toSorted((b,a) => a.datoaflyst - b.datoaflyst).map((a) => {
              return (
                <ValueGroup key={a.oid}>
                  <TitleValueLine valKey={[a.plannavn]} value={<a
                      type="application/pdf"
                      target="_blank"
                      href={a.doklink}
                    >Hent Dokument</a>} keepEmpty />
                  <KeyValueLine valKey={"Hændelse"}  value={a.aflystype} />
                  <KeyValueLine valKey={"Aflyst d."} value={a.datoaflyst} CParser={(a,b) => FormatUtils.formatNumberStringDate(b)} />
                </ValueGroup>
              )
            })
          }
        
      </GlassFoldUdBox>
    )
  }

  static Vurderinger(data: PropertyData, foldetUd: boolean) {
    function VurdSort<T extends VurdData[number]>(a: T, b: T) {
      return a.år && b.år && a.år !== b.år
        ? a.år > b.år
          ? -1
          : 1
        : a.VurderetUnder || b.VurderetUnder
        ? a.VurderetUnder || false
          ? 1
          : -1
        : (a.ændringDato || 0) < (b.ændringDato || 0)
        ? 1
        : -1;
    }
    return data.Vurd?.sort(VurdSort).map((a, idx) => {
      return (
        <GlassFoldUdBox
          key={a.id}
          group="Vurderinger"
          boxKey={"Vurd " +a.år}
          title={
            <>
              {"Vurderingår " + a.år}{" "}
              <span style={{ fontSize: "0.7em" }}>
                (
                {PropertyInformation.VurdÆndringskode(
                  a.ændringKode || "",
                  a.år || 2000
                ) + (a.VurderetUnder ? " - Vurderet Under" : "")}
                )
              </span>
            </>
          }
          foldetUd={foldetUd && idx == 0}
        >
          {Object.keys(a)
            .filter(
              (key) =>
                ![
                  "id",
                  "dækningsafgiftForskelVærdi",
                  "dækningsafgiftPligtKode",
                  "dækningsafgiftPligtTypeKode",
                  "juridiskKategoriKode",
                  "juridiskKategoriTekst",
                  "juridiskUnderkategoriKode",
                  "juridiskUnderkategoriTekst",
                  "VURMark",
                  "Vurderingsejendom",
                ].includes(key)
            )
            .map((key) => {
              if (key === "benyttelseKode") {
                return <KeyValueLine key={key} valKey={key} value={a[key]} CParser={vurdBenytParser} />
              }
              return typeof a[key] !== "object" ? (
                <KeyValueLine key={key} valKey={key} value={a[key]} />
              ) : null;
            })}
          {(a.BFEnummerList?.length || 0) < 4 ? (
            a.BFEnummerList?.map((a, idx) => (
              <KeyValueLine
                key={a}
                valKey={idx != 0 ? "" : "Bfe Numre"}
                value={a}
              />
            ))
          ) : (
            <GlassFoldUdBox title={"Bfe Numre"} foldetUd={false}>
              <KeyValuesBoxGrid minWidth="150px">
                {a.BFEnummerList?.map((a) => (
                  <KeyValueLine
                    key={a}
                    valKey={a.toString()}
                    value={""}
                    keepEmpty={true}
                  />
                )) || <></>}
              </KeyValuesBoxGrid>
            </GlassFoldUdBox>
          )}
          {Object.keys(a)
            .filter(
              (key) => !["BFEnummerList", "Vurderingsejendom"].includes(key)
            )
            .map((key) => {
              return typeof a[key] == "object" ? (
                Array.isArray(a[key]) ? (
                  a[key].map((a, idx) => {
                    return (
                      <GlassFoldUdBox
                        key={a + idx}
                        title={key + " " + (a.tekst ?? idx)}
                        foldetUd={false}
                      >
                        {Object.keys(a).map((key) => (
                          <KeyValueLine key={key} valKey={key} value={a[key]} />
                        ))}
                      </GlassFoldUdBox>
                    );
                  })
                ) : (
                  <GlassFoldUdBox key={key} title={key} foldetUd={false}>
                    {Object.keys(a[key]).map((nKey) => (
                      <KeyValueLine
                        key={nKey}
                        valKey={nKey}
                        value={a[key][nKey]}
                      />
                    ))}
                  </GlassFoldUdBox>
                )
              ) : null;
            }) || <></>}
        </GlassFoldUdBox>
      );
    });
  }

  static extractProperties(obj) {
    obj?.features?.[0]?.properties;
  }

  static ArealerGroup(data: PropertyData) {
    let ejd = data.ViamapEjendomData;
    if (!ejd) {
      return [];
    }
    let grund =
      ((data?.Mat2?.registreretareal || 0) as number) -
      ((data?.Mat2?.vejareal || 0) as number);
    let type = PropertyInformation.beregnBebyggelsesType(data?.BBRByg);
    let standardRegel = PropertyInformation.beregnStandardRegel(type);
    let regler = PropertyInformation.beregnBebyggelsesRegler(
      standardRegel,
      data.PlanDataLokalPlan || {},
      data.PlanDataKommunePlan || {}
    );
    let pct = regler.bebygpct.val;

    let AreaFormatter = new Intl.NumberFormat("da-dk");
    let TAreal = ejd.totalAreal && Number(ejd.totalAreal?.split(".").join("")) || null
    let VAreal = ejd.herafVej && Number(ejd.herafVej?.split(".").join("")) || null
    let EAreal = (TAreal && TAreal && (TAreal - VAreal)) ?? null;
    let maxBygAreal = grund && pct && Math.floor((grund * pct) / 100.0);
    let nuvaerendeBygninger = PropertyInformation.beregnSamletBebyggetAreal(
      data.BBRByg
    );
    const samletBygningsareal = PropertyInformation.beregnSamletBygningsAreal(data.BBRByg)
    let restByggeareal = Math.max(0, maxBygAreal - nuvaerendeBygninger);
    function formatRule(rule: any): string {
      return "(" + rule.source + ") " + rule.val;
    }
    return [
      { key: "Bebyggelsestype", name: "Bebyggelsestype", val: type },
      {
        key: "Total-/grundareal",
        name: "Total-/grundareal",
        val: AreaFormatter.format(TAreal),
      },
      {
        key: "Heraf vej",
        name: "Heraf vej",
        val: AreaFormatter.format(VAreal),
      },
      {
        key: "Ekskl. vej",
        name: "Ekskl. vej",
        val: AreaFormatter.format(EAreal),
      },
      {
        key: "Samlet Bebygget areal",
        name: "Samlet Bebygget areal",
        val: AreaFormatter.format(nuvaerendeBygninger),
      },
      {
        key: "Samlet Bygningsareal",
        name: "Samlet Bygningsareal",
        val: AreaFormatter.format(samletBygningsareal),
        infoText:"Excl. kælder og tagetage"
      }
      // {
      //   key: "Max bebyggelsespct",
      //   name: "Max bebyggelsespct",
      //   val: formatRule(regler.bebygpct),
      // },
      // {
      //   key: "Max byggehøjde",
      //   name: "Max byggehøjde",
      //   val: formatRule(regler.maxbygnhjd),
      // },
      // {
      //   key: "Max etager",
      //   name: "Max etager",
      //   val: formatRule(regler.maxetager),
      // },
      // {
      //   key: "Restbyggeareal",
      //   name: "Restbyggeareal",
      //   val: AreaFormatter.format(restByggeareal),
      // },
    ];
  }

  static TingbogEjendom(data: PropertyData, access): any {
    let tingbogsPrefix =
      "https://www.tinglysning.dk/tinglysning/rest/indskannetakt/rd/";
    let record = data.ViamapTingBog?.stamOplysninger?.indskannetAktDokumentId;
    if (record)
      return (
        <>
          <KeyValueLine
            valKey="Indskannet Akt"
            value={
              (access(Feature.DownloadAktFraTinglysning) && (
                <a
                  type="application/pdf"
                  target="_blank"
                  href={tingbogsPrefix + record}
                >
                  {record}
                </a>
              )) ||
              record
            }
          />
          {data.ViamapTingBog?.stamOplysninger.tillaegsTekster.map(
            (tekst, idx) => {
              return (
                <KeyValueLine
                  key={idx}
                  valKey={"Tillæg " + tekst.overskrift}
                  value={
                    (access(Feature.DownloadAktFraTinglysning) && (
                      <a
                        type="application/pdf"
                        target="_blank"
                        href={tingbogsPrefix + tekst.dokumentId}
                      >
                        {tekst.dokumentId}
                      </a>
                    )) ||
                    tekst.dokumentId
                  }
                />
              );
            }
          )}
        </>
      );
    return Localization.getText("No data");
  }

  static EjerLejligheden(DarAdresse:any, MatEjerlejlighed:any): any {
    
    if (!MatEjerlejlighed) {
      return <></>
    }
    
    const Mat = MatEjerlejlighed

    return (
      <>
      <KeyValueLine valKey={"Adresse"} value={DarAdresse?.[0]?.adressebetegnelse} />
      <KeyValueLine valKey={"SFE Nummer "} value={Mat.samletFastEjendomBFEnummer} />
      <KeyValueLine valKey={"BFE Nummer "} value={Mat.BFEnummer} />
      <KeyValueLine valKey={"Ejerlejlighedsnummer "} value={Mat.ejerlejlighedsnummer} />
      <KeyValueLine valKey={"Type"} value={"Ejerlejlighed"} />
      </>
    )
  }

  static LejAreal(MatEjerlejlighed: any) {
    const Mat = MatEjerlejlighed
    return (
      <>
      {Mat ? <KeyValueLine valKey={"Samlet Areal"} value={Mat.samletAreal} /> : <></>}
      {Mat ? <KeyValueLine valKey={"Fordelingstal"} value={Number(Mat.fordelingstalTaeller/Mat.fordelingstalNaevner).toLocaleString(Localization.getLocale(),{style: 'percent', minimumFractionDigits:2})} /> : <></>}
      {Mat ? <KeyValueLine valKey={"Andele af Hovedejendom"} value={Mat.fordelingstalTaeller} /> : <></>}
      {Mat ? <KeyValueLine valKey={"Andele i Hovedejendom"} value={Mat.fordelingstalNaevner} /> : <></>}
      </>
    )
  }

  static LejHandel(ESEjerlejlighed: any) {
    const Es = ESEjerlejlighed;
    return (
      <>
       {Es.handel_tidspunkt ? <KeyValueLine valKey={"Virkning fra"} value={Es.handel_tidspunkt} /> : <></>}
       {Es.handel_forretningsh ? <KeyValueLine valKey={"Forretningshændelse"} value={Es.handel_forretningsh} /> : <></>}
       {Es.handel_maade ? <KeyValueLine valKey={"Overdragelsesmåde"} value={Es.handel_maade} /> : <></>}
       {Es.handel_samletKoebesum ? <KeyValueLine valKey={"SamletKoebesum"} value={Es.handel_samletKoebesum} /> : <></>}
       {Es.handel_status && Es.handel_status !== "gældende" ? <KeyValueLine valKey={"Status"} value={Es.handel_status} /> : <></>}
       {Es.handel_valutaKode ? <KeyValueLine valKey={"Valutakode"} value={Es.handel_valutaKode} /> : <></>}
      </>
    )
  }

  static EjerLejEjer(ESEjerlejlighed: any) {
    const Es = ESEjerlejlighed;

    return (
      <>
      {Es ? <KeyValueLine valKey={"Ejer"} value={Es.ejf_ejere} /> : <></>}
      {Es.ejf_cvrnr ? <KeyValueLine valKey={"CVRnummer"} KeyEndParser={(a) => a} value={<RenderLinkToCompanyInfo cvrNr={Es.ejf_cvrnr}>{Es.ejf_cvrnr}</RenderLinkToCompanyInfo> } /> : <></>}
      </>
    )
  }

  static EkstraEjerlejlighed(bfeNr: number|string, ESEjerlejlighed?:any[], Mat2Ejerlejlighed?:any[]) {
    if (! bfeNr) {
      return <></>
    }
    const Es = ESEjerlejlighed?.find((a) => a?.bfe_nr == bfeNr) 
    const Mat = Mat2Ejerlejlighed?.find((a) => a?.BFEnummer == bfeNr) 

    return (
      <>
      { Mat ? 
        <>
        <KeyValueLine valKey={"Samlet Areal"} value={Mat.samletAreal} />
        <KeyValueLine valKey={"Ejerlejlighedsnummer "} value={Mat.ejerlejlighedsnummer} />
        </>
         : <> </> }
      { Es ? (
          <>
            <KeyValueLine _className="compact" valKey={"Adresse"} value={(Es.bfe_adresse || "").replace(/,([^,]*)$/,",\n$1")} />
            <KeyValueLine valKey={"Ejer"} value={Es.ejf_ejere} />
            <KeyValueLine valKey={"Reklamebeskyttet"} value={Es.ejf_reklameBeskyttelse} />
            <KeyValueLine valKey={"Senest handelpris"} value={Es.handel_samletKoebesum} />
            <KeyValueLine valKey={"Handelstidspunkt"} value={Es.handel_tidspunkt} />
          </>
        ) : <></> }
      </>
    )

  }



  static SFE(data: PropertyData) {
    const filterGaeldende = (elm: any) => {
      return elm?.status === "Gældende" || elm?.properties?.status === "Gældende";
    }

    const prop = data.BBRSFE?.filter(filterGaeldende)?.[0]?.properties || null;
    
    if (!prop) {
      return <>No active SFE exist</>
    }


    return (
      <>
        <KeyValuesBoxGrid minWidth="200px" maxCols={2}>
          <>
            {Object.keys(prop)
              .filter(
                (keys) =>
                  ![
                    "sekundaerForretningshaendelse",
                    "forretningsomraade",
                    "forretningsproces",
                    "id_lokalId",
                    "id_namespace",
                    "jordstykke",
                    "paataenktHandling",
                    "registreringTil",
                    "senesteSagLokalId",
                    "virkningTil",
                  ].includes(keys)
              )
              .map((key) => {
                return typeof prop[key] !== "object" ? (
                  <KeyValueLine key={key} valKey={key} value={prop[key]} />
                ) : null;
              })}
            <KeyValueLine
              valKey={"Antal Bygninger på Fremmed Grund"}
              value={(prop && prop?.["bygningPaaFremmedGrund"]?.filter(filterGaeldende)?.length) || 0}
            />
            <KeyValueLine
              valKey={"Antal Ejerlejligheder"}
              value={(prop && prop?.["ejerlejlighed"]?.filter(filterGaeldende)?.length) || 0}
            />
            <KeyValueLine
              valKey={"Antal Jordstykker"}
              value={(prop && prop?.["jordstykke"]?.filter(filterGaeldende)?.length) || 0}
            />
          </>
        </KeyValuesBoxGrid>
        {prop && prop?.["bygningPaaFremmedGrund"]?.filter(filterGaeldende)?.length ? (
          <GlassFoldUdBox title={"Bygninger på Fremmed Grund"} foldetUd={false}>
            <KeyValuesBoxGrid minWidth="200px" maxCols={2}>
              {prop?.["bygningPaaFremmedGrund"]?.filter(filterGaeldende).map((a, idx) => {
                return (
                  <ValueGroup key={idx}>
                    {Object.keys(a).map((keys) => (
                      <KeyValueLine
                        key={keys}
                        valKey={keys}
                        value={a[keys]}
                      />
                    ))}
                  </ValueGroup>
                );
              })}
            </KeyValuesBoxGrid>
          </GlassFoldUdBox>
        ) : null}
        {prop && prop?.["ejerlejlighed"]?.filter(filterGaeldende)?.length ? (
          <GlassFoldUdBox title={"Ejerlejligheder"} foldetUd={false}>
            <KeyValuesBoxGrid minWidth="200px" maxCols={2}>
              {prop?.["ejerlejlighed"]?.filter(filterGaeldende).map((a, idx) => {
                return (
                  <ValueGroup key={idx}>
                    
                    {Object.keys(a).map((keys) => (
                      keys === "BFEnummer" ? 
                      <KeyValueLine
                        key={keys}
                        valKey={keys}
                        value={<RenderLinkToCondominium bfeNr={a[keys]}>{a[keys]}</RenderLinkToCondominium>}
                      />
                      :  <KeyValueLine
                          key={keys}
                          valKey={keys}
                          value={a[keys]}
                        />
                      
                    ))}
                    {this.EkstraEjerlejlighed(a.BFEnummer,data.ESearchEjerlejlighed,data.Mat2Ejerlejlighed)}
                  </ValueGroup>
                );
              })}
            </KeyValuesBoxGrid>
          </GlassFoldUdBox>
        ) : null}
        {prop && prop?.["jordstykker"]?.length ? (
          <GlassFoldUdBox title={"Jordstykker"} foldetUd={false}>
            <KeyValuesBoxGrid minWidth="200px" maxCols={2}>
              {prop?.["jordstykker"].map((a, idx) => {
                return (
                  <ValueGroup key={idx}>
                    {Object.keys(a).map((keys) => (
                      <KeyValueLine
                        key={keys}
                        valKey={keys}
                        value={prop[keys]}
                      />
                    ))}
                  </ValueGroup>
                );
              })}
            </KeyValuesBoxGrid>
          </GlassFoldUdBox>
        ) : null}
      </>
    );
  }

  static TingbogHæftelser(data: PropertyData) {
    return (
      data.ViamapTingBog?.hæftelser?.liste?.map((hæftelse) => {
        return (
          <Fragment key={hæftelse.dokumentId}>
            <KeyValueLine
              valKey={
                hæftelse.haeftelsesType + " - " + hæftelse.tinglysningsDato
              }
              value={
                <>
                  <div>{hæftelse.kreditorDisplayText}</div>
                  <div>{hæftelse.beloebVaerdi + " " + hæftelse.valutakode}</div>
                </>
              }
            />
          </Fragment>
        );
      }) || Localization.getText("No data")
    );
  }

  static TingbogServitutter(
    data: PropertyData,
    access: (a: FeatureType) => boolean
  ): any {
    let tingbogsPrefix =
      "https://www.tinglysning.dk/tinglysning/rest/indskannetakt/rd/";
    let tingbogsPrefixNyeAkter =
      "https://www.tinglysning.dk/tinglysning/rest/pdf/atd/";
    // let tingbogsPrefixNyeAkter="https://www.tinglysning.dk/tmv/foresporgsel/detail/dokumenter/";
    // let tingbogsPrefixNyePaategninger="https://www.tinglysning.dk/tmv/foresporgsel/paategninger/";
    function getTingbogsDokLink(ser: any): string | undefined {
      if (ser.dokumentFilnavn && ser.dokumentFilnavn.length > 0) {
        return tingbogsPrefix + ser.dokumentFilnavn;
      } else {
        if (ser.aktAliasId && ser.aktAliasId.length > 0) {
          return tingbogsPrefixNyeAkter + ser.dokumentId;
        } else {
          return undefined;
        }
      }
    }

    return (
      data.ViamapTingBog?.servitutter?.toSorted((a, b) => {
        const IA = (d) => Number(d.prioritetNummer) || 10000;
        if (IA(a) < IA(b))
          return -1
        if (IA(a) > IA(b)) {
          return 1
        }
        return 0
      }).map((servitut) => {
        let tingbogsLink = getTingbogsDokLink(servitut);
        let hasAccess = access(Feature.DownloadAktFraTinglysning);

        function TingBogRecordNumber(a : string) {
          return a.replace(/(.{4})(.{2})(.{2})-()/, "$3$2$1-$4")
        }

        let recordNo = TingBogRecordNumber(servitut.aktId || servitut.aktAliasId);

        return (
          <KeyValueLine
            keepEmpty
            key={servitut.aktId || servitut.aktAliasId}
            valKey={
              <>
                <span title="Prioritet Nummer" style={{color:"#aaa", fontFamily:"Consolas", marginRight:"0.5em"}}>{servitut.prioritetNummer?.toString().padStart(2,"0") || "??"}</span>
                {(tingbogsLink && hasAccess && (
                <a type="application/pdf" target="_blank" href={tingbogsLink}>
                  {recordNo}
                </a>
              )) || recordNo}
              </>
            }
            value={
              servitut.dokumentFilnavn +
              " " +
              (servitut.tekster.join(" ") || servitut.tekstSummarisk)
            }
          />
        );
      }) || Localization.getText("No data")
    );
  }

  static TingbogAdkomster(data: PropertyData): any {
    return (
      <ValueGroup>
        <KeyValueLine
          keepEmpty
          valKey="Adkomsthaver"
          value={data.ViamapTingBog?.SenesteAdkomst?.adkomsthaver
            ?.map((a) => {
            if ("birthDate" in a && a.birthDate) {
              return `${a.displayText} - ${(a.birthDate as string).replace(/(\d+)-(\d+)-(\d+)/gm,`$3.$2.$1`)}` 
            }
            return a.displayText
            }).join("\n ")
          }
        />
        <KeyValueLine
          keepEmpty
          valKey="Tinglysningsdato"
          value={data.ViamapTingBog?.SenesteAdkomst?.tinglysningsDato}
        />
        <KeyValueLine
          keepEmpty
          valKey="Kontantkøbesum"
          value={data.ViamapTingBog?.SenesteAdkomst?.kontantKoebesum}
        />
        <KeyValueLine
          keepEmpty
          valKey="I alt købesum"
          value={data.ViamapTingBog?.SenesteAdkomst?.iAltKoebesum}
        />
      </ValueGroup>
    );
  }

  static HandelsOplysninger(data: PropertyData) {
    if (!data.ViamapHandelsOplysning) {
      return [];
    }

    return data.ViamapHandelsOplysning?.handel.features
      .sort((a, b) => {
        // sort by date, descending
        return (
          new Date(b.properties.virkningFra).getTime() -
          new Date(a.properties.virkningFra).getTime()
        );
      })
      .map((feature, idx) => {
        let props = feature.properties;
        let tidspunkt = FormatUtils?.formatDate(new Date(props.virkningFra));
        let ejersk = data.ViamapHandelsOplysning?.ejerskifte?.features.find(
          (feat) => {
            return (
              feat.properties.handelsoplysningerId ===
              feature.properties.id_lokalId
            );
          }
        );
        let maade = ejersk && ejersk.properties.overdragelsesmaade;
        let forretningsh =
          props.forretningshaendelse !== "Ukendt - konverteret fra ESR"
            ? props.forretningshaendelse
            : null;
        let status = props.status !== "gældende" ? props.status : null;

        return [
          { name: "Virkning fra", key: "Virkning fra", val: tidspunkt },
          {
            name: "Forretningshændelse",
            key: "Forretningshændelse",
            val: forretningsh,
          },
          { name: "Overdragelsesmåde", key: "Overdragelsesmåde", val: maade },
          { name: "Status", key: "Status", val: status },
          {
            name: "Samlet købesum",
            key: "Samlet købesum",
            val: props.samletKoebesum,
          },
          { name: "Valutakode", key: "Valutakode", val: props.valutakode },
        ];
      });
  }

  static Ejendommen(data: PropertyData, mini?: boolean) {
    if (!data.ViamapEjendomData) {
      return [];
    }

    let SfeMatData = PropertyInformation.formatMatrikler(
      (data.BBRSFE?.[0]?.properties?.jordstykke || [])
        .map((a) => a.properties)
        .filter((a) => a.status === "Gældende"),
      false
    );
    let ejd = data.ViamapEjendomData;
    let darKommune:any|undefined = undefined;
    if (ejd.kommuneNr) {
      darKommune = { key: "Kommune", name: "Kommune", val: BBRKodeLister.Kommunekode(ejd.kommuneNr).replace(" Kommune","")}
    }

    return [
      { key: "Adresse", name: "Adresse", val: ejd.ejdAdresse },
      {
        key: "Antal Matrikler",
        name: "Antal Matrikler",
        val: ejd.antalMatrikler,
      },
      ejd.antalMatrikler > 5 || { key: "Matrikler", name: "Matrikler", val: SfeMatData },
      darKommune ?? { key: "Kommune", name: "Kommune", val: ejd.kommuneNavn },
      { key: "Kategori", name: "Kategori", val: ejd.ejendomsKategori },
      { key: "Type", name: "Type", val: ejd.ejdtype },
    ].filter((a) => a.key).filter((a,idx) => !mini || idx < 2);
  }

  static EjerforholdData(data: PropertyData) {
    return (data.ViamapEjendomData?.ejere || []).map((ejer, idx) => {
      let cvrLink =
        "https://datacvr.virk.dk/enhed/virksomhed/" + ejer.CVRNummer;
      // ToDo: remove hack by changing the backend web service.
      let isRoad=Boolean(ejer.displayText=="ukendt ejertype, ingen adresse, intet postnummer intet postdistrikt");
      let renderCVRInfo = ejer.CVRNummer ? (
        <a
          href={cvrLink}
          className="no-print"
          target="_blank"
          style={{ textAlign: "left", fontWeight: "600", color: "black" }}
        >
          (Link til cvr.dk)
        </a>
      ) : null;
      let renderReklamebeskyttelse = ejer.reklameBeskyttet
        ? `(${Localization.getText("Reklamebeskyttet")})`
        : "";
      return (
        <div
          key={ejer.displayText + idx}
          style={{ textAlign: "left", paddingLeft:"18px", fontWeight: "bold", fontSize: "15px" }}
          className="mit-geo-details-popup"
        >
          {isRoad ? Localization.getText("No data") :
          ejer.CVRNummer ? <RenderLinkToCompanyInfo cvrNr={ejer.CVRNummer}>{ejer.displayText}</RenderLinkToCompanyInfo>:
          <RenderLinkToPersonInfo {...(ejer as any)} dateOfBirth={ejer.foedselsdato && ejer.foedselsdato.length !== 0 ? new Date(ejer.foedselsdato): "undefined"} > {ejer.displayText}</RenderLinkToPersonInfo>
          }&nbsp;{renderReklamebeskyttelse}
          {renderCVRInfo}
        </div>
      );
    });
  }

  static Ejerforhold(data: PropertyData) {
    
    return ("ViamapEjerskab" in data && data.ViamapEjerskab as any)?.map((ejer, idx) => {
      let cvrLink =
        "https://datacvr.virk.dk/enhed/virksomhed/" + ejer.CVRNummer;
      // ToDo: remove hack by changing the backend web service.
      let isRoad=Boolean(ejer.displayText=="ukendt ejertype, ingen adresse, intet postnummer intet postdistrikt");
      let renderCVRInfo = ejer.CVRNummer ? (
        <a
          href={cvrLink}
          className="no-print"
          target="_blank"
          style={{ textAlign: "left", fontWeight: "600", color: "black" }}
        >
          (Link til cvr.dk)
        </a>
      ) : null;
      let renderReklamebeskyttelse = ejer.reklameBeskyttet
        ? `(${Localization.getText("Reklamebeskyttet")})`
        : "";
      return (
        <div
          key={ejer.displayText + idx}
          style={{ textAlign: "left", paddingLeft:"18px", fontWeight: "bold", fontSize: "15px" }}
          className="mit-geo-details-popup"
        >
          {isRoad ? Localization.getText("No data") :
          ejer.CVRNummer ? <RenderLinkToCompanyInfo cvrNr={ejer.CVRNummer}>{ejer.displayText}</RenderLinkToCompanyInfo>:
          <RenderLinkToPersonInfo {...(ejer as any)} dateOfBirth={ejer.foedselsdato && ejer.foedselsdato.length !== 0 ? new Date(ejer.foedselsdato): "undefined"} > {ejer.displayText}</RenderLinkToPersonInfo>
          }&nbsp;{renderReklamebeskyttelse}
          {renderCVRInfo}
        </div>
      );
    });
  }

  static MatriklerTable(data: PropertyData, hasAccessToFeature: (Feature) => boolean) {
    const matSort = (a, b) => {
      let parseEjerlav =
        parseInt(a.properties.ejerlavskode, 10) -
        parseInt(b.properties.ejerlavskode, 10);
      if (parseEjerlav !== 0) {
        return parseEjerlav;
      }
      return PropertyInformation.matrikelSortFunc(
        a.properties.matrikelnummer || "",
        b.properties.matrikelnummer || ""
      );
    };
    const filterGaeldende = (elm: any) => {
      return elm?.properties?.status === "Gældende";
    };
    let restMatData = data.BBRSFE?.[0]?.properties?.jordstykke
      ?.filter(filterGaeldende)
      .sort(matSort)
      .map((mat) => mat.properties);
    return (
      <KeyValueTable>
          <tr>
            <th>Nr.</th>
            <th>Ejerlav</th>
            <th style={{textAlign:"right"}}>Areal</th>
            <th style={{textAlign:"right"}}>Heraf Vej</th>
          </tr>
          {restMatData?.map((a) => {
            const id = a.matrikelnummer + a.ejerlavskode;
            return (
              <tr key={id}>

                <td>{hasAccessToFeature(Feature.LinkToMao) ? <a target="_blank" href={`https://mao.gst.dk/register.aspx?ejerlavskode=${a.ejerlavskode}&matrnr=${a.matrikelnummer}`}>{a.matrikelnummer}</a> : a.matrikelnummer}</td>
                <td>
                  {a.ejerlavsnavn} ({a.ejerlavskode})
                </td>
                <td style={{textAlign:"right"}}>
                  {new Intl.NumberFormat("da-dk").format(
                    Number(a.registreretAreal)
                  )}
                </td>
                <td style={{textAlign:"right"}}>
                  {new Intl.NumberFormat("da-dk").format(Number(a.vejareal))}
                </td>
              </tr>
            );
          })}
          <tr className="bold">
            <td></td>
            <td></td>
            <td style={{textAlign:"right"}}>
              {new Intl.NumberFormat("da-dk").format(
                restMatData?.reduce((a, b) => {
                  return b.registreretAreal + a;
                }, 0) || 0
              )}
            </td>
            <td style={{textAlign:"right"}}>
              {new Intl.NumberFormat("da-dk").format(
                restMatData?.reduce((a, b) => {
                  return (
                    (isNaN(Number(b.vejareal)) ? 0 : Number(b.vejareal)) + a
                  );
                }, 0) || 0
              )}
            </td>
          </tr>
      </KeyValueTable>
    );
  }

  static MatriklerIndv(data: PropertyData) {
    const matSort = (a, b) => {
      let parseEjerlav =
        parseInt(a.properties.ejerlavskode, 10) -
        parseInt(b.properties.ejerlavskode, 10);
      if (parseEjerlav !== 0) {
        return parseEjerlav;
      }
      return PropertyInformation.matrikelSortFunc(
        a.properties.matrikelnummer || "",
        b.properties.matrikelnummer || ""
      );
    };
    const filterGaeldende = (elm: any) => {
      return elm?.properties?.status === "Gældende";
    };
    const constructName = (jordstykke: any) => {
      return `${jordstykke.matrikelnummer} ${jordstykke.ejerlavsnavn} (${jordstykke.ejerlavskode})`;
    };
    return (
      data.BBRSFE?.[0]?.properties?.jordstykke
        ?.filter(filterGaeldende)
        .toSorted(matSort)
        .map((mat) => mat.properties)
        .map((a) => ({
          key: constructName(a),
          name: constructName(a),
          val: Object.keys(a)
            .filter(
              (fil) =>
                ![
                  "centroide",
                  "sekundaerForretningshaendelse",
                  "forretningsomraade",
                  "forretningsproces",
                  "id_lokalId",
                  "id_namespace",
                  "paataenktHandling",
                  "registreringTil",
                  "stammerFraJordstykkeLokalId",
                  "supplerendeMaalingSagLokalId",
                  "virkningTil",
                ].includes(fil)
            )
            .flatMap((b) => {
                if (a[b] && typeof a[b] == "object") {
                  return Object.keys(a[b]).map((c) => {
                    let name = c.includes(b) ? c : b + " " + c
                    return {key: name, name:name, val: a[b][c]}
                  })
                }
                return { key: b, name: b, val: a[b] }
            }),
        })) || []
    );
  }

  
  static MatriklerAndContent(data: PropertyData) {
    const matSort = (a, b) => {
      let parseEjerlav =
        parseInt(a.properties.ejerlavskode, 10) -
        parseInt(b.properties.ejerlavskode, 10);
      if (parseEjerlav !== 0) {
        return parseEjerlav;
      }
      return PropertyInformation.matrikelSortFunc(
        a.properties.matrikelnummer || "",
        b.properties.matrikelnummer || ""
      );
    };
    const filterGaeldende = (elm: any) => {
      return elm?.properties?.status === "Gældende";
    };
    let restMatData = data.BBRSFE?.[0]?.properties?.jordstykke
      ?.filter(filterGaeldende)
      .sort(matSort)
      .map((mat) => mat.properties);
    return (
      <KeyValueTable>
          <tr>
            <th>Nr.</th>
            <th>Ejerlav</th>
            <th>Areal</th>
            <th>Heraf Vej</th>
          </tr>
        <tbody>
          {restMatData?.map((a) => {
            const id = a.matrikelnummer + a.ejerlavskode;
            return (
              <tr key={id}>
                <td>{a.matrikelnummer}</td>
                <td>
                  {a.ejerlavsnavn} ({a.ejerlavskode})
                </td>
                <td>
                  {new Intl.NumberFormat("da-dk").format(
                    Number(a.registreretAreal)
                  )}
                </td>
                <td>
                  {new Intl.NumberFormat("da-dk").format(Number(a.vejareal))}
                </td>
              </tr>
            );
          })}
          <tr className="bold">
            <td></td>
            <td></td>
            <td>
              {new Intl.NumberFormat("da-dk").format(
                restMatData?.reduce((a, b) => {
                  return b.registreretAreal + a;
                }, 0) || 0
              )}
            </td>
            <td>
              {new Intl.NumberFormat("da-dk").format(
                restMatData?.reduce((a, b) => {
                  return (
                    (isNaN(Number(b.vejareal)) ? 0 : Number(b.vejareal)) + a
                  );
                }, 0) || 0
              )}
            </td>
          </tr>
        </tbody>
      </KeyValueTable>
    );
  }

  static PEnhederPaaEjendomFormatter(data: PropertyData) {
    let d = data?.PEnheder;

    function formatBeskaeftigelse(data:any) {
      return data ? (
        <KeyValueTable>
          {
            ["aar", "kvartal", "antalAnsatte", "antalAarsvaerk"].reduce<(Element | JSX.Element)[]>((result, item, idx) => {
              let val = data[item];
              if (val) {
                let itemFormatted = (
                <tr key={idx}>
                  <td>{titleValParser(item)}</td>
                  <td>{val}</td>
                </tr>
                )
                return [...result, itemFormatted];
              } else {
                return result;
              } 
            }, [])
          }
        </KeyValueTable>
      ) : null;
    }

    return (<KeyValueTable key={"Penheder"}>
      <tr>
        <th style={{textAlign:"left"}} >Virsomhed</th>
        <th style={{textAlign:"left"}} >Branche</th>
        <th>Ansatte</th>
        <th>Årsværk</th>
      </tr>
      {
        d?.flatMap((a) => a.adresser.flatMap((a) => {
          if (a.pEnheder.length === 0) {
            return (<></>)
          }
          return (
            <Fragment key={a.adresseData.id}>
            {/* <tr><td colSpan={5}>{a.adresseData.navn}</td></tr> */}
            {a.pEnheder.map((a) => {
                return (
                  <tr key={a.cvrNr}>
                    <td style={{textAlign:"left"}} >{a.navn}<br/><RenderLinkToCompanyInfo cvrNr={a.cvrNr} >{a.cvrNr}</RenderLinkToCompanyInfo></td>
                    <td style={{textAlign:"left"}} >{a.branchetekst}</td>
                    <td>{a.beskaeftigelseKvt?.antalAnsatte || a.beskaeftigelseAar?.antalAnsatte || ""}</td>
                    <td>{a.beskaeftigelseKvt?.antalAarsvaerk || a.beskaeftigelseAar?.antalAarsvaerk || ""}</td>
                  </tr>
                )
              })
            }
            </Fragment>
            )
        }))
      }
      </KeyValueTable>)
  }
    

  static BBREvery(dataToParse) {
    let data = dataToParse
    if (!Array.isArray(dataToParse)) {
      data = [dataToParse]
    }
    return data.flat().map((atom) => {
      if (typeof atom !== "object") {
        return null
      }
      return (
        <ValueGroup key={(atom.id_lokalId+atom.status)} >
            {
              Object.keys(atom).filter(ExportData.BBRKeyFilter).map((a) => {
                return (
                  <KeyValueLine 
                    key={a}
                    valKey={a}
                    value={atom[a]}
                    KeyEndParser={(a) => {return ExportData.BBRDefaultKeyTranslator(a).toString()}}
                    CParser={(c,b) => {
                      let x = BBRTranslateByDataKey(a,b)
                      if (Array.isArray(x)) {
                        return ""
                      }
                      if (x instanceof Date) {
                        return x.toLocaleString(Localization.getLocale())
                      }
                      return x.toString()
                    }} 
                  />
                )
              })
            }
        </ValueGroup>
      )
      

    })
  }

  static BBRTeknikFilter(key):boolean {
    const blackList = [
      "id_namespace",
      "id_lokalId",
      "datafordelerOpdateringstid",
      "bygning",
      "forretningsområde",
      "forretningsproces",
      "husnummer",
      "tek042Revisionsdato",
      "tek045Koordinatsystem",
      "tek076KildeTilKoordinatsæt",
      "tek077KvalitetAfKoordinatsæt",
      "tek078SupplerendeOplysningOmKoordinatsæt",
      "tek107PlaceringPåSøterritorie",
      "tek109Koordinat",
      "registreringFra",
      "registreringsaktør",
      "virkningFra",
      "virkningsaktør"
    ]
    if (blackList.includes(key))
      return false;
    return true;
  }

  static BBRTeknik(dataToParse) {
    let data = dataToParse
    if (!Array.isArray(dataToParse)) {
      data = [dataToParse]
    }
    return data.flat().map((atom) => {
      if (typeof atom !== "object") {
        return null
      }
      return (
        <ValueGroup key={(atom.id_lokalId+atom.status)} >
            {
              Object.keys(atom).filter(PropInfoGroupFormatters.BBRTeknikFilter).map((a) => {
                return (
                  <KeyValueLine 
                    key={a}
                    valKey={a}
                    value={atom[a]}
                    KeyEndParser={(a) => {return ExportData.BBRDefaultKeyTranslator(a).toString()}}
                    CParser={(c,b) => {
                      let x = BBRTranslateByDataKey(a,b)
                      if (Array.isArray(x)) {
                        return ""
                      }
                      if (x instanceof Date) {
                        return x.toLocaleString(Localization.getLocale())
                      }
                      return x.toString()
                    }} 
                  />
                )
              })
            }
        </ValueGroup>
      )
      

    })
  }


  static BBRBygninger(data: PropertyData) {

    let BygningerSorted =
      Array.isArray(data.BBRByg) &&
      data.BBRByg.flatMap((a) => a).filter(PropertyInformation.filterOpførtGældende).sort((ar, br) => {
        let a = ar.byg007Bygningsnummer;
        let b = br.byg007Bygningsnummer;
        return a > b ? 1 : a < b ? -1 : 0;
      });

    return (
      <>
        {" "}
        {data.EBRBFE && (
          <ValueGroup key={"links"}>
          <KeyValueLine
            key={"links"}
            valKey={""}
            value={
              <>
                <a
                  target="_blank"
                  href={
                    "https://kort.bbr.dk/?p=715776.81,6177239.359,12&bfe=" +
                    data.EBRBFE.bestemtFastEjendomBFENr
                  }
                >
                  Link til BBR-Kort
                </a>
                <a
                  target="_blank"
                  href={
                    "https://bbr.dk/se-bbr-oplysninger/0/1/0/bfe:" +
                    data.EBRBFE.bestemtFastEjendomBFENr
                  }
                >
                  Link til BBR-oplysninger
                </a>
                <a
                  target="_blank"
                  href={
                    "https://bbr.dk/pls/wwwdata/get_newois_pck.show_bbr_meddelelse_pdf?i_bfe=" +
                    data.EBRBFE.bestemtFastEjendomBFENr
                  }
                >
                  Link til BBR-Meddelelse
                </a>
              </>
            }
          />
          </ValueGroup>
        )}
        {BygningerSorted &&
          BygningerSorted.map((bygn) => {
            return (
              <ValueGroup key={bygn.byg007Bygningsnummer}>
                <TitleValueLine
                  valKey={"Bygning " + bygn.byg007Bygningsnummer}
                  value={""}
                  keepEmpty={true}
                />
                <KeyValueLine
                  valKey="Anvendelse"
                  value={PropertyInformation.bygningsAnvendelsesTekst(
                    bygn.byg021BygningensAnvendelse
                  )}
                />
                <KeyValueLine
                  valKey="Opførelsesår"
                  value={bygn.byg026Opførelsesår}
                />
                <KeyValueLine
                  valKey="Om/tilbygningsår"
                  value={bygn.byg027OmTilbygningsår}
                />
                <KeyValueLine
                  valKey="Samlet Bygningsareal"
                  CParser={areaParser}
                  infoText="Excl. kælder og tagetage"
                  value={bygn.byg038SamletBygningsareal}
                />
                <KeyValueLine
                  valKey="Bebygget Areal"
                  CParser={areaParser}
                  value={bygn.byg041BebyggetAreal}
                />
                <KeyValueLine
                  valKey="Samlet Boligareal"
                  CParser={areaParser}
                  value={bygn.byg039BygningensSamledeBoligAreal}
                />
                <KeyValueLine
                  valKey="Samlet Erhvervsareal"
                  CParser={areaParser}
                  value={bygn.byg040BygningensSamledeErhvervsAreal} 
                />
                <KeyValueLine
                  valKey="Antal Etager"
                  infoText="Excl. kælder og tagetage"
                  value={bygn.byg054AntalEtager}
                />
              </ValueGroup>
            );
          })}
        <ValueGroup>
          {data.BBREnhed?.flatMap((a) => a).flatMap((a) => a)
            .filter(PropertyInformation.filterOpførtGældende)
            .map((enhed, endhedIDX) => {
              return (
                <ValueGroup key={enhed.id_lokalId}>
                  <TitleValueLine
                    valKey={"Enhed " + (endhedIDX + 1)}
                    value={""}
                    keepEmpty={true}
                  />
                  <KeyValueLine
                    valKey="Anvendelse"
                    value={
                      enhed.enh020EnhedensAnvendelse &&
                      PropertyInformation.bygningsAnvendelsesTekst(
                        enhed.enh020EnhedensAnvendelse.toString()
                      )
                    }
                  />
                  <KeyValueLine
                    valKey="Antal værelser"
                    value={enhed.enh031AntalVærelser}
                  />
                  <KeyValueLine
                    valKey="Antal badeværelser"
                    value={enhed.enh066AntalBadeværelser}
                  />
                  <KeyValueLine
                    valKey="Antal toiletter"
                    value={enhed.enh065AntalVandskylledeToiletter}
                  />
                  <KeyValueLine
                    valKey="Terrasse, areal"
                    value={enhed.enh070ÅbenAltanTagterrasseAreal}
                  />
                  <KeyValueLine
                    valKey="Udestue, areal"
                    value={enhed.enh062ArealAfLukketOverdækningUdestue}
                  />
                  <KeyValueLine
                    valKey="Varmeinstallation"
                    value={enhed.enh051Varmeinstallation}
                  />
                  <KeyValueLine
                    valKey="Opvarmningsmiddel"
                    value={enhed.enh052Opvarmningsmiddel}
                  />
                  <KeyValueLine
                    valKey="SupplerendeVarme"
                    value={enhed.enh053SupplerendeVarme}
                  />
                </ValueGroup>
              );
            })}
        </ValueGroup>
        <ValueGroup>
          <KeyValueLine
            valKey="Samlet bebygget areal (i grundplan)"
            value={PropertyInformation.beregnSamletBebyggetAreal(data.BBRByg)}
          />
        </ValueGroup>
      </>
    );
  }

  static MarkedsDataNewsec(
    data: PropertyData & MarkedsData,
    foldetUd: boolean,
    access,
    select,
    setSelect
  ) {
    const minObservationer = 3;

    if (!access(Feature.ExplorerMarkedsData)) return [];

    const Mdata = data?.["NewSec" + select] as MarkedsDataIndv;
    if (!Mdata)
      return [
        <GlassFoldUdBox
          key={select + "Search"}
          group="Markedsdata"
          foldetUd={foldetUd}
          title={"Kriterier For Søgning"}
        >
          <GlassButton >{Localization.getText("Missing connection: Market data")}</GlassButton>
        </GlassFoldUdBox>,
      ];

    function formatNumberThousandsSep(value?: number) {
      return value
        ? value.toLocaleString("da-dk", { maximumFractionDigits: 0 })
        : "";
    }

    function SelectInput(
      label: string,
      prompt: string,
      options: string[],
      value: string,
      onChange: (value: string) => any
    ) {
      return (
        <Col>
          <FloatingLabel
            controlId={"floatingInput" + label}
            label={label}
            className="mb-1"
          >
            <Form.Select
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            >
              {options.map((opt) => {
                return (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                );
              })}
            </Form.Select>
          </FloatingLabel>
        </Col>
      );
    }

    return [
      <GlassFoldUdBox
        key={select + "Salg"}
        group="Markedsdata"
        title={"Salg"}
        foldetUd={foldetUd}
      >
        {Mdata.PrisKvmData?.observationCount >= minObservationer ? (
          <KeyValueLine
            valKey="Gns. pris pr. m2"
            value={
              Mdata?.PrisKvmData.data && Mdata?.PrisKvmData.data.length > 0
                ? formatNumberThousandsSep(
                    Math.round(Mdata?.PrisKvmData.data[0].value)
                  )
                : ""
            }
          />
        ) : (
          "For få observationer for området..."
        )}
        <GlassFoldUdBox title={"Detaljer"} foldetUd={false}>
          <KeyValueLine
            valKey="Observationer"
            value={Mdata?.PrisKvmData?.observationCount}
          />
          <KeyValueLine
            valKey="Tidligste år"
            value={new Date(Mdata?.PrisKvmData?.minDate).getFullYear()}
          />
          <KeyValueLine
            valKey="Seneste år"
            value={new Date(Mdata?.PrisKvmData?.maxDate).getFullYear()}
          />
          <KeyValueLine
            valKey="Nærmeste obs (m)"
            value={Mdata?.PrisKvmData?.minDistanceMeters.toFixed(0)}
          />
          <KeyValueLine
            valKey="Obs længst væk (m)"
            value={Mdata?.PrisKvmData?.maxDistanceMeters.toFixed(0)}
          />
        </GlassFoldUdBox>
      </GlassFoldUdBox>,
      <GlassFoldUdBox
        key={select + "Udlejning"}
        group="Markedsdata"
        title={"Udlejning"}
        foldetUd={foldetUd}
      >
        {Math.min(
          Mdata.NettoLejeData?.observationCount,
          Mdata.BruttoLejeData?.observationCount
        ) >= minObservationer ? (
          <>
            <KeyValueLine
              valKey="Gns. nettoleje"
              value={formatNumberThousandsSep(
                Math.round(Mdata?.NettoLejeData?.data[0]?.value || 0)
              )}
              infoText="Gennemsnitlig nettoleje i nye udlejninger, hvor lejemålets husleje er angivet som nettoleje"
            />
            <KeyValueLine
              valKey="Gns. bruttoleje"
              value={formatNumberThousandsSep(
                Math.round(Mdata?.BruttoLejeData?.data[0]?.value || 0)
              )}
              infoText="Gennemsnitlig bruttoleje i nye udlejninger, hvor lejemålets husleje er angivet som bruttoleje"
            />
          </>
        ) : (
          "For få observationer for området..."
        )}
        <GlassFoldUdBox title={"Detaljer"} foldetUd={false}>
          <KeyValueLine
            valKey="Observationer"
            value={Mdata?.NettoLejeData?.observationCount}
          />
          <KeyValueLine
            valKey="Tidligste år"
            value={new Date(Mdata?.NettoLejeData?.minDate).getFullYear()}
          />
          <KeyValueLine
            valKey="Seneste år"
            value={new Date(Mdata?.NettoLejeData?.maxDate).getFullYear()}
          />
          <KeyValueLine
            valKey="Nærmeste obs (m)"
            value={Mdata?.NettoLejeData?.minDistanceMeters.toFixed(0)}
          />
          <KeyValueLine
            valKey="Obs længst væk (m)"
            value={Mdata?.NettoLejeData?.maxDistanceMeters.toFixed(0)}
          />
        </GlassFoldUdBox>
      </GlassFoldUdBox>,
      <GlassFoldUdBox
        key={select + "Search"}
        group="Markedsdata"
        foldetUd={foldetUd}
        title={"Kriterier For Søgning"}
      >
        <KeyValueLine
          valKey="Target antal"
          value={SettingsManager.getSystemSetting("marketDataTargetCount")}
        />
        <KeyValueLine
          valKey="Max afstand (m)"
          value={SettingsManager.getSystemSetting("marketDataMaxDistance")}
        />
        <KeyValueLine
          valKey="Fra år"
          value={SettingsManager.getSystemSetting("marketDataStartYear")}
        />
        <KeyValueLine
          valKey="Til år"
          value={SettingsManager.getSystemSetting("marketDataEndYear")}
        />
        {SelectInput(
          "ejendomstype",
          "ejendomstype",
          ["Kontor", "Detail", "Industri", "Andet"],
          select,
          (val) => {
            setSelect(val);
          }
        )}
      </GlassFoldUdBox>,
    ];
  }
}

type MarkedsData =
  | {
      Kontor: MarkedsDataIndv;
      Detail: MarkedsDataIndv;
      Industri: MarkedsDataIndv;
      Andet: MarkedsDataIndv;
    }
  | undefined;

type MarkedsDataIndv = Record<
  "BruttoLejeData" | "NettoLejeData" | "VolumenData" | "PrisKvmData",
  {
    observationCount: number;
    data: {
      timeStamp: number;
      value: number;
    }[];
    minDate: string;
    maxDate: string;
    minDistanceMeters: number;
    maxDistanceMeters: number;
  }
>;

