import { SettingsManager, Utils } from "@viamap/viamap2-common";
import React from "react";
import { MapitUtils } from "src/managers/MapitUtils";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import * as Turf from '@turf/turf';
import { GenerateGeomUtils } from "src/managers/GenerateGeomUtils";
import { BNBOFunc } from "src/BNBOModule/BNBOFunc";
import { MitLatLng } from "src/managers/MapFacade";
import { BNBOProjectsPersistence, BNBOSFEPersistence, SFERecord } from "./BNBODataStore";
import { FeatureAllowDenyListMap } from "src/states/ApplicationStateFeatureMapping";
import { Feature } from "src/states/ApplicationState";

export enum MessageType {
    Error = "Error",
    Success = "Success"
}

export enum ProcesStatus {
    Start = "Start",
    ServitutArealValgt = "ServitutArealValgt",
    ErstatningsArealValgt = "ErstatningsArealValgt",
    ErstatningBeregnet = "ErstatningBeregnet"
}

export enum BNBOUserAccessRights {
    "Read" = "Read",
    "Write" = "Write",
    "Admin" = "Admin"
}

/**
* User Access Rights to Project Access Parameters
*/
export const FeaturesAccessibleBNBOUserAccessRights:FeatureAllowDenyListMap = {
    [BNBOUserAccessRights.Read]: {
        allow: [Feature.BNBOProjectRead],
        deny: [ Feature.BNBOProjectWrite, Feature.BNBOProjectAdmin]
    },
    [BNBOUserAccessRights.Write]: {
        allow: [Feature.BNBOProjectRead, Feature.BNBOProjectWrite],
        deny: [ Feature.BNBOProjectAdmin]
    },
    [BNBOUserAccessRights.Admin]: {
        allow: [Feature.BNBOProjectRead, Feature.BNBOProjectWrite, Feature.BNBOProjectAdmin],
        deny: [ ]
    },
};

export enum BNBOLodsejerStatus {
    "Ny" = "Ny",
    "Under udarbejdelse" = "Under udarbejdelse",
    "Klar" = "Klar",
    "Sendt til høring" = "Sendt til høring",
    "Høring udløbet" = "Høring udløbet",
    "Tinglyst" = "Tinglyst",
    "Afvist" = "Afvist",
    "Accepteret" = "Accepteret",
    "Godkendt" = "Godkendt",
}

export interface ProjectsPersistenceInterface {
    listProjects(filter: any): Promise<BNBOProject[]>;
    getProject(projectId: string): Promise<BNBOProject>;
    saveProject(project: BNBOProject): Promise<void>;
    deleteProject(projectId: string): Promise<void>;
    createProject(project: BNBOProject): Promise<void>
};

export interface SFEPersistenceInterface {
    listSFEs(): Promise<SFERecord[]>;
    getSFE(sfeId: string): Promise<SFERecord>;
    saveSFE(sfeId: string, sfeRecord: SFERecord): Promise<void>;
    deleteSFE(sfeId: string): Promise<void>;
    removeAllSFEs(): Promise<void>;
}

export type TurfFeature = any; // for documentation only.
export type geoJsonMultipolygon = any;
export enum BNBOOmrådeType {
    Vejareal = "Vejareal",
    ServitutAreal = "ServitutAreal", 
    MarkServitutareal = "MarkServitutAreal", 
    Restareal = "Restareal", 
    FradragsAreal = "FradragsAreal", 
    UlempeAreal = "UlempeAreal", 
    Par3Areal = "Par3Areal", 
    FredskovsAreal = "FredskovsAreal",
    Inaktive = "Inaktive",
}

export type BNBOOrganization = string;

export type BNBOLodsejerRegister = { [lodsejerId: string]: BNBOLodsejer }

export type BNBOLodsejer = {
    id: string,
    sfeNummer: number,
    geometry?: geoJsonMultipolygon,
    centroide?: { lat: number, lng: number }, // en centroide i en af matriklerne
    status: BNBOLodsejerStatus,
    sagsbehandler?: string,
    addr?: string
    note?: string,
    koordinater?: MitLatLng,
    ejere?: any[],
    ejereTekst?: string,
    ejdtype?: string,
    vurdejdtype?: string,
    tags?: string[],
    sats?: number,
    forsyningNavn?: string,
    forsyningCVR?: string,
    forsyningAdresse?: string,
    forsyningPostnummer?: string,
    // ToDo: Add properties for ejerinfo, adresse, matrikelnummer, etc.
    erSpecialSats?: boolean,


    // Ekstra fields brevFletning
    EjerNavn?: string
    Adresse?: string
    Adresse2?: string
    JournalNr?: string
    RefNr?: string
    MatrNr?: string
    Ejerlav?: string
    Kommune?: string

    _version?: number; // Version number of the document when read from the data store

}

export type BNBOLodsejerPåvirkedeMarker = { [lodsejerId: string]: { [markId: string]: BNBOMark } }

export type BNBOMark = {
    lodsejerId: string,
    id: string,
    geometry: geoJsonMultipolygon,
    geometryNetto?: geoJsonMultipolygon,
    afgrøde: string,
    markBlok: string,
    markNr: string,
    påvirketAfBnboIder: string[]
    arealBrutto?: number,
    arealNetto?: number,
    // ToDo: andre properties.
    note?: string,
}

export type BNBODel = {
    id: string,
    geometry?: geoJsonMultipolygon,
    type: BNBOOmrådeType
    arealBrutto: number,
    arealNetto: number,
    note?: string,
    farve?: string,
    faktor?: number,
    isManualTakst?: boolean,
    takst?: number,
    demoArealCaseId?: string,
    markId?: string,
}

export type BNBOLodsejerDele = {[lodsejerId:string]: BNBODel[]}
export type BNBOLodsejerPoints = {[lodsejerId:string]: {geomerty: ReturnType<typeof Turf.geometry>, type:"Feature", properties: Partial<{note: string}> }[]}
export type BNBOLodsejerMarkDele = {[lodsejerId:string]: {[markId:string]: BNBODel[]}};

export type BNBOLodsejerPåvirkedeMatrikler = { [lodsejerId: string]: BNBOMatrikel[] }

export type BNBOMatrikel = {
    lodsejerId: string
    id: string,
    geometry?: geoJsonMultipolygon,
    geometryNetto?: geoJsonMultipolygon,
    centroide?: { lat: number, lng: number },
    matrikelNummer: string,
    ejerlavsKode: number,
    ejerlavsNavn: string,
    arealBrutto: number,
    arealNetto: number,
    bnboId: string,
    duplikatMatrikelVedFlereBNBOerForSammeMatrikel?: boolean // Markerer nr 2 eller senere matrikel hvis der er 2 eller flere BNBOer på samme matrikel
}

export type BNBOLodsejerPåvirkedeBNBOer = { [lodsejerId: string]: { [bnboId: string]: BNBOBeskyttelsesOmråde } }

export type BNBOBeskyttelsesOmråde = {
    id: string,
    geometry?: geoJsonMultipolygon,
    dguNr: string,
    statusCode: number,
    statusTekst: string
    anlaegsNavn: string,
    kommuneNavn: string,
    cvr_navn: string,
    cvr_kode: number,
    inactive?: boolean,
}

export type BNBOMarkErstatningLinie = {
    areaType: BNBOOmrådeType,
    antal: number,
    takst: number,
    faktor: number,
    procent: number,
    erstatning: number,
}

export interface BNBOErstatningLinieNoTotal {
    linieErTotalForAntalDele?: number,
    areaType: BNBOOmrådeType,
    antal: number,
    takst: number,
    faktor: number,
    procent: number,
    matrikel?: { ejerlavsNavn: string, ejerlavsKode: number, matrikelNummer: string },
    mark?: { markBlok: string, markNr: string, afgrøde: string },
    isManualTakst?: boolean,
    mask?: string,
    note?: string,
    farve?: string,
    geometry?: geoJsonMultipolygon,
}

export interface BNBOErstatningLinie extends BNBOErstatningLinieNoTotal {
    erstatning: number
    
}


export type LoadedBNBOState = {
    lodsejerRegister: BNBOLodsejerRegister,
    lodsejerBNBOer: BNBOLodsejerPåvirkedeBNBOer,
    lodsejerMarker: BNBOLodsejerPåvirkedeMarker,
    lodsejerMatrikler: BNBOLodsejerPåvirkedeMatrikler,
    markDele: BNBOLodsejerMarkDele
    dele: BNBOLodsejerDele
}

export interface BNBOProject {
    id: string;
    name: string;
    description: string;
    active: boolean;
    ownerUser: string; // User id of the owner. E.g. kbe@viamap.net
    ownerOrganization: string; // Organization of the owner. E.g. viamap.net
    allowedUsersWithRoles: { [userId: string]: string[] }; // E.g. {"kbe@viamap.net":["BNBO","BNBOAdmin","BNBOWriteAcess"],"mbj@molbak.dk":["BNBO","BNBOWriteAcess"]}
    allowedOrganizationsWithRoles: { [organizationId: string]: string[] }; // E.g. {"viamap.net":["BNBO"],"molbak.dk":["BNBO", "BNBOWriteAcess"]}
    settings: any;
    esIndexName: string;
    _version?: number; // Version number of the document when read from the data store
    protected: boolean; // If true, the project is protected from deletion or bulk update
}

// ---------------------------------------------------- STATE --------------------------------------------------------------

export interface BNBOState {
    stateVersion: 1,
    activeTransactions: { [guid: string]: any },
    message: string,
    messageType: MessageType,

    isInitialized: boolean;

    procesStatus: ProcesStatus,
    organization: BNBOOrganization,
    lodsejer: BNBOLodsejerRegister,
    bnboer: BNBOLodsejerPåvirkedeBNBOer,
    matrikler: BNBOLodsejerPåvirkedeMatrikler,
    marker: BNBOLodsejerPåvirkedeMarker,
    markDele: BNBOLodsejerMarkDele,
    dele: BNBOLodsejerDele,
    points?: BNBOLodsejerPoints,

    bnbo_areas_geojson?: any[];
    servitutAreal?: any
    erstatningsAreal?: any

    bnbo_org_stats: { [key: string]: any }[];

    availableProjectsList: BNBOProject[];
    selectedProjectId?: string;
    selectedProject?: BNBOProject;

    loadedProjectId?: string; // Set when all sfe's are loaded for the selected project
}

// ---------------------------------------------------- INITIAL STATE --------------------------------------------------------------

export function initialBNBOState(): BNBOState {

    return {
        stateVersion: 1, //Increment, when requiredFields are added
        activeTransactions: {},
        message: "",
        messageType: MessageType.Success,
        procesStatus: ProcesStatus.Start,

        isInitialized: false,
        organization: "",
        lodsejer: {},
        bnboer: {},
        matrikler: {},
        marker: {},
        markDele: {},
        dele: {},

        bnbo_org_stats: [],

        availableProjectsList: [],
    };
}

export type SFEElem = { addr: string, sfeNummer: number, status: string }

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------
export enum BNBOActionType {
    TxStarted,
    TxCompleted,
    TxFailed,

    Initialize,
    ReplaceBNBOState,

    LoadSFE,
    LoadDataForGeoJson,

    NyMatrikel,
    NyMark,
    NyMarkMatrikel,
    NyMatrikelDel,
    AddLodsejerDel,

    SetProcesStatus,
    SetServitutAreal,
    SetErstatningsAreal,
    SetErstatningsArealForDel,

    SetLodsejerTags,
    SetLodsejerStatus,
    SetLodsejerNote,
    SetLodsejerSagsbehandler,
    SetLodsejerErSpecialSats,
    SetLodsejerSats,
    SetLodsejerForsyningsInfo,
    SetLodsejerDele,
    SetLodsejerPoints,

    SetMarkDele,
    SetMarkNote,
    SetMarkErSpecialsats,
    SetMarkSats,

    SetSFEFilter,
    ClearSFEFilter,

    SetLodsejerBNBOInactive,

    LoadSFEState,

    AddProject,
    UpdateProject,
    DeleteProject,
    SelectProject,
    LoadProjects
}

export interface TxStarted {
    type: BNBOActionType.TxStarted;
    payload: { guid: string, action: BNBOActions };
}

export interface TxCompleted {
    type: BNBOActionType.TxCompleted;
    payload: { guid: string, action: BNBOActions };
}

export interface TxFailed {
    type: BNBOActionType.TxFailed;
    payload: { guid: string, action: BNBOActions, error: any };
}


export interface NyMatrikel {
    type: BNBOActionType.NyMatrikel;
    payload: { matrikelId: string, item: any };
    results?: any;
}

export interface Initialize {
    type: BNBOActionType.Initialize;
    payload: {
        projectsElasticIndex: string,
        userId: string
    };
    results?: { 
        projects: BNBOProject[], 
        projectsPersistence: ProjectsPersistenceInterface 
    };
}
export interface LoadSFE {
    type: BNBOActionType.LoadSFE;
    payload: { sfeNummer: number };
    results?: any;
}

export interface LoadDataForGeoJson {
    type: BNBOActionType.LoadDataForGeoJson;
    payload: { multipolygon: any, replaceAll: boolean, addTags: string[], currentState?: LoadedBNBOState, callback?: (info: any) => void, progressCallback?: (progressPct: number) => void };
    errorCallback?: (error: any) => void,
    previousCount?: number;
    results?: any;
}

export interface NyMark {
    type: BNBOActionType.NyMark;
    payload: { item: any };
    results?: any;
}

export interface NyMarkMatrikel {
    type: BNBOActionType.NyMarkMatrikel;
    payload: { markId: string, item: any };
    results?: any;
}

export interface NyMatrikelDel {
    type: BNBOActionType.NyMatrikelDel;
    payload: { matrikelId: string, delId: string, delType: string, item: any };
    results?: any;
}

export interface AddLodsejerDel {
    type: BNBOActionType.AddLodsejerDel;
    payload: { item: BNBODel } & SetLodsejerIndexs
}



export interface SetProcesStatus {
    type: BNBOActionType.SetProcesStatus;
    payload: { procesStatus: ProcesStatus };
}
export interface SetServitutAreal {
    type: BNBOActionType.SetServitutAreal;
    payload: { item: TurfFeature };
}

export interface SetErstatningsAreal {
    type: BNBOActionType.SetErstatningsAreal;
    payload: { item: TurfFeature };
}

export interface SetErstatningsArealForDel {
    type: BNBOActionType.SetErstatningsArealForDel;
    payload: { delId: string, item: TurfFeature };
}

type SetLodsejerIndexs = { lodsejerId: string }

export interface SetLodsejerTags {
    type: BNBOActionType.SetLodsejerTags;
    payload: { tags: string[] } & SetLodsejerIndexs;
}
export interface SetLodsejerStatus {
    type: BNBOActionType.SetLodsejerStatus;
    payload: { status: BNBOLodsejerStatus } & SetLodsejerIndexs;
}
export interface SetLodsejerNote {
    type: BNBOActionType.SetLodsejerNote;
    payload: { note: string } & SetLodsejerIndexs;
}
export interface SetLodsejerSagsbehandler {
    type: BNBOActionType.SetLodsejerSagsbehandler;
    payload: { sagsbehandler: string } & SetLodsejerIndexs;
}
export interface SetLodsejerPoints {
    type: BNBOActionType.SetLodsejerPoints;
    payload: {points:BNBOLodsejerPoints[string]} & SetLodsejerIndexs;
}


export interface SetLodsejerErSpecialSats {
    type: BNBOActionType.SetLodsejerErSpecialSats;
    payload: { erSpecialSats: boolean } & SetLodsejerIndexs;
}
export interface SetLodsejerSats {
    type: BNBOActionType.SetLodsejerSats;
    payload: { sats: number } & SetLodsejerIndexs;
}
export interface SetLodsejerForsyningsInfo {
    type: BNBOActionType.SetLodsejerForsyningsInfo;
    payload: { lodsejerId: string, key: "Navn"|"CVR"|"Adresse"|"Postnummer", value: string }
}

export interface SetLodsejerDele {
    type: BNBOActionType.SetLodsejerDele;
    payload: { dele: BNBODel[] } & SetLodsejerIndexs
}


// SET Marker
type SetMarkIndexes = { lodsejerId: string, markId: string }
export interface SetMarkNote {
    type: BNBOActionType.SetMarkNote;
    payload: { note: string } & SetMarkIndexes
}
export interface SetMarkErSpecialsats {
    type: BNBOActionType.SetMarkErSpecialsats;
    payload: { erSpecialsats: boolean } & SetMarkIndexes
}
export interface SetMarkSats {
    type: BNBOActionType.SetMarkSats;
    payload: { sats: number } & SetMarkIndexes
}
export interface SetMarkDele {
    type: BNBOActionType.SetMarkDele;
    payload: { dele: BNBODel[] } & SetMarkIndexes
}

export interface SetLodsejerBNBOInactive {
    type: BNBOActionType.SetLodsejerBNBOInactive;
    payload: { lodsejerId:string, bnboId:string, inactive: boolean }
}

export interface ReplaceBNBOState {
    type: BNBOActionType.ReplaceBNBOState;
    payload: { state: BNBOState }
}

export interface LoadSFEState {
    type: BNBOActionType.LoadSFEState;
    payload: {
        indexName: string,
        callback?: (info: any) => void,
        progressCallback?: (progressPct: number) => void,
        userId?: string
    }
    results?: any; // loaded data
    errorCallback?: (error: any) => void,
}

export interface AddProject {
    type: BNBOActionType.AddProject;
    payload: { id: string, item: BNBOProject }
}
export interface UpdateProject {
    type: BNBOActionType.UpdateProject;
    payload: { id: string, item: BNBOProject, versionNumberWhenRead?: number }
}
export interface DeleteProject {
    type: BNBOActionType.DeleteProject;
    payload: { id: string, versionNumberWhenRead?: number }
}
export interface SelectProject {
    type: BNBOActionType.SelectProject;
    payload: { id: string }
}
export interface LoadProjects {
    type: BNBOActionType.LoadProjects;
    results?: any; // loaded data
    callback?: (availableProjectsList: BNBOProject[]) => void;
}

// -------------------- utility functions to create an Action object ---------------------------------

export const actionTxStarted = (guid: string, action: BNBOActions): TxStarted => ({
    type: BNBOActionType.TxStarted,
    payload: { guid, action }
});

export const actionTxCompleted = (guid: string, action: BNBOActions): TxCompleted => ({
    type: BNBOActionType.TxCompleted,
    payload: { guid, action }
});

export const actionTxFailed = (guid: string, action: BNBOActions, error: any): TxFailed => ({
    type: BNBOActionType.TxFailed,
    payload: { guid, action, error }
});

export const initialize = (projectsElasticIndex: string, userId:string): Initialize => ({
    type: BNBOActionType.Initialize,
    payload: { projectsElasticIndex, userId }
});

export const loadSFE = (sfeNummer: number): LoadSFE => ({
    type: BNBOActionType.LoadSFE,
    payload: { sfeNummer }
});

export const loadDataForGeoJson = (multipolygon: any, replaceAll: boolean, addTags: string[], currentState?: LoadedBNBOState, callback?: (info: any) => void, errorCallback?: (error: any) => void, progressCallback?: (progressPct: number) => void): LoadDataForGeoJson => ({
    type: BNBOActionType.LoadDataForGeoJson,
    payload: { multipolygon, replaceAll, addTags, currentState, callback, progressCallback },
    errorCallback: errorCallback
});

export const nyMatrikel = (matrikelId: string, item: any): NyMatrikel => ({
    type: BNBOActionType.NyMatrikel,
    payload: { matrikelId, item }
});

export const nyMark = (item: any): NyMark => ({
    type: BNBOActionType.NyMark,
    payload: { item }
});

export const nyMarkMatrikel = (markId: string, item: any): NyMarkMatrikel => ({
    type: BNBOActionType.NyMarkMatrikel,
    payload: { markId, item }
});

export const nyMatrikelDel = (matrikelId: string, delId: string, delType: any, item: any): NyMatrikelDel => ({
    type: BNBOActionType.NyMatrikelDel,
    payload: { matrikelId, delId, delType, item }
});

export const addLodsejerDel = (lodsejerId: string, item: BNBODel): AddLodsejerDel => ({
    type: BNBOActionType.AddLodsejerDel,
    payload: { lodsejerId, item }
});

export const setProcesStatus = (procesStatus: ProcesStatus): SetProcesStatus => ({
    type: BNBOActionType.SetProcesStatus,
    payload: { procesStatus }
});

export const setServitutAreal = (item: TurfFeature): SetServitutAreal => ({
    type: BNBOActionType.SetServitutAreal,
    payload: { item }
});

export const setErstatningsAreal = (item: TurfFeature): SetErstatningsAreal => ({
    type: BNBOActionType.SetErstatningsAreal,
    payload: { item }
});

export const setErstatningsArealForDel = (delId: string, item: TurfFeature): SetErstatningsArealForDel => ({
    type: BNBOActionType.SetErstatningsArealForDel,
    payload: { delId, item }
});

export const setLodsejerTags = (tags: string[], lodsejerId: string): SetLodsejerTags => ({
    type: BNBOActionType.SetLodsejerTags,
    payload: { tags, lodsejerId }
})

export const setLodsejerStatus = (status: BNBOLodsejerStatus, lodsejerId: string): SetLodsejerStatus => ({
    type: BNBOActionType.SetLodsejerStatus,
    payload: { status, lodsejerId }
})
export const setLodsejerNote = (note: string, lodsejerId: string): SetLodsejerNote => ({
    type: BNBOActionType.SetLodsejerNote,
    payload: { note, lodsejerId }
})
export const setLodsejerSagsbehandler = (sagsbehandler: string, lodsejerId: string): SetLodsejerSagsbehandler => ({
    type: BNBOActionType.SetLodsejerSagsbehandler,
    payload: { sagsbehandler, lodsejerId }
})

export const setLodsejerErSpecialSats = (erSpecialSats: boolean, lodsejerId: string): SetLodsejerErSpecialSats => ({
    type: BNBOActionType.SetLodsejerErSpecialSats,
    payload: { erSpecialSats, lodsejerId }
})
export const setLodsejerSats = (sats: number, lodsejerId: string): SetLodsejerSats => ({
    type: BNBOActionType.SetLodsejerSats,
    payload: { sats, lodsejerId }
})
export const setLodsejerForsyning = (lodsejerId: string, key: "Navn"|"CVR"|"Adresse"|"Postnummer", value: string): SetLodsejerForsyningsInfo => ({
    type: BNBOActionType.SetLodsejerForsyningsInfo,
    payload: { lodsejerId, key, value }
})


export const setLodsejerDele = (lodsejerId: string, dele: BNBODel[]): SetLodsejerDele => ({
    type: BNBOActionType.SetLodsejerDele,
    payload: { dele, lodsejerId }
});
export const setLodsejerPoints = (lodsejerId: string, points: BNBOLodsejerPoints[string] ): SetLodsejerPoints => ({
    type: BNBOActionType.SetLodsejerPoints,
    payload: {points, lodsejerId}
})

export const setLodsejerBNBOinactive = (lodsejerId: string, bnboId: string, inactive:boolean ): SetLodsejerBNBOInactive => ({
    type: BNBOActionType.SetLodsejerBNBOInactive,
    payload: {lodsejerId, bnboId, inactive}
})

export const setMarkNote = (lodsejerId: string, markId: string, note: string): SetMarkNote => ({
    type: BNBOActionType.SetMarkNote,
    payload: { note, lodsejerId, markId }
});
export const setMarkErSpecialsats = (lodsejerId: string, markId: string, erSpecialsats: boolean): SetMarkErSpecialsats => ({
    type: BNBOActionType.SetMarkErSpecialsats,
    payload: { erSpecialsats: erSpecialsats, lodsejerId, markId }
});
export const setMarkSats = (lodsejerId: string, markId: string, sats: number): SetMarkSats => ({
    type: BNBOActionType.SetMarkSats,
    payload: { sats, lodsejerId, markId }
});
export const setMarkDele = (lodsejerId: string, markId: string, dele: BNBODel[]): SetMarkDele => ({
    type: BNBOActionType.SetMarkDele,
    payload: { dele, lodsejerId, markId }
});



export const replaceBNBOState = (state: BNBOState): ReplaceBNBOState => ({
    type: BNBOActionType.ReplaceBNBOState,
    payload: { state }
})

export const loadSFEState = (indexName: string, userId?:string, callback?: (info: any) => void, errorCallback?: (error: any) => void, progressCallback?: (progressPct: number) => void): LoadSFEState => ({
    type: BNBOActionType.LoadSFEState,
    payload: { indexName, callback, progressCallback, userId },
    errorCallback: errorCallback
})

export const addProject = (id: string, item: BNBOProject): AddProject => ({
    type: BNBOActionType.AddProject,
    payload: { id, item }
})
export const updateProject = (id: string, item: BNBOProject, versionNumberWhenRead?: number): UpdateProject => ({
    type: BNBOActionType.UpdateProject,
    payload: { id, item, versionNumberWhenRead }
})
export const deleteProject = (id: string, versionNumberWhenRead?: number): DeleteProject => ({
    type: BNBOActionType.DeleteProject,
    payload: { id, versionNumberWhenRead }
})
export const selectProject = (id: string): SelectProject => ({
    type: BNBOActionType.SelectProject,
    payload: { id }
})
export const loadProjects = (callback?:(info)=>any): LoadProjects => ({
    type: BNBOActionType.LoadProjects,
    callback: callback
})

export type BNBOActionsTransActional = NyMark | NyMarkMatrikel | NyMatrikelDel | NyMatrikel | AddLodsejerDel |
    Initialize | LoadSFE | LoadDataForGeoJson | ReplaceBNBOState |
    SetProcesStatus | SetServitutAreal | SetErstatningsAreal | SetErstatningsArealForDel |
    SetMarkDele | SetMarkNote | SetMarkSats | SetMarkErSpecialsats | SetLodsejerStatus | SetLodsejerNote | SetLodsejerSagsbehandler | SetLodsejerTags | SetLodsejerErSpecialSats |
    SetLodsejerSats | LoadSFEState | SetLodsejerDele | AddProject | UpdateProject | DeleteProject | SelectProject | LoadProjects | SetLodsejerForsyningsInfo;
export type BNBOActionsNonTransActional = TxStarted | TxCompleted | TxFailed |
    SetMarkDele | SetMarkNote | SetMarkSats | SetMarkErSpecialsats  | SetLodsejerStatus | SetLodsejerNote | SetLodsejerSagsbehandler | SetLodsejerTags | SetLodsejerErSpecialSats | SetLodsejerPoints |
    SetLodsejerSats | SetLodsejerDele | SetLodsejerBNBOInactive | SetLodsejerForsyningsInfo;
export type BNBOActions = BNBOActionsTransActional | BNBOActionsNonTransActional;

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------



// ---------------------------------------------------- TRANSACTIONAL REDUCER --------------------------------------------------------------

export function transactionalBNBOReducer(action: BNBOActionsTransActional, dispatch: any) {

    switch (action.type) {
        //   case BNBOActionType.NyMark: {
        //      }
        //      break;
        case BNBOActionType.NyMatrikel: {
            let ft4 = action.payload.item;
            let ejerlavsKode = ft4.properties["mat:ejerlavskode"];
            let matrikelNr = ft4.properties["mat:matrikelnummer"];
            let ejere = ft4["bnbo_ejere"];
            if (ejerlavsKode && matrikelNr && !ejere) {
                let sfeNummer = ft4.properties["mat:samletFastEjendomLokalId"];
                action.payload.item.properties.sfeNummer = sfeNummer;
                let txId = MapitUtils.getNewUuid();
                dispatch(actionTxStarted(txId, action));

                PropertyInfoInterface.getEjendomData(ejerlavsKode, matrikelNr)
                    .then((ejdProps) => {
                        if (ejdProps) {
                            action.payload.item.properties.bnbo_ejere = ejdProps.ejere;
                            action.payload.item.properties.bnbo_ejereTekst = ejdProps.ejere.reduce((result, val, idx) => { return result + (idx > 0 ? ", " : "") + val.displayText }, "");
                            action.payload.item.properties.bnbo_ejdtype = ejdProps.ejdtype;
                            action.payload.item.properties.bnbo_ejdAdresse = ejdProps.ejdAdresse;
                            action.payload.item.properties.bnbo_vurdejdtype = ejdProps.vurdejdtype;
                            action.results = ejdProps;
                        }
                        dispatch(actionTxCompleted(txId, action));
                        dispatch(action);
                    })
                    .catch((error) => {
                        console.log("Got error " + error);
                        dispatch(actionTxFailed(txId, action, "Error get ejendom data: " + (error.message || error)));
                    })
            } else {
                dispatch(action);
            }
            break;
        }

        case BNBOActionType.NyMark: {
            let matMarkOverlap = action.payload.item;

            let txId = MapitUtils.getNewUuid();
            dispatch(actionTxStarted(txId, action));

            // Enrich with jordbundsdata
            let center = Turf.center(matMarkOverlap).geometry.coordinates;
            let utmLoc = GenerateGeomUtils.convertFromLatLngToUTM({ lat: center[1], lng: center[0] })
            PropertyInfoInterface.getJordbundsData(utmLoc)
                .then((jordBundsData) => {
                    if (jordBundsData && jordBundsData.features.length > 0) {
                        matMarkOverlap.properties.bnbo_JB_nr = jordBundsData.features[0].properties.JB_nr;
                        matMarkOverlap.properties.bnbo_Jordtype = jordBundsData.features[0].properties.Jordtype;
                        action.results = jordBundsData;
                    }
                    dispatch(actionTxCompleted(txId, action));
                    dispatch(action);
                })
                .catch((error) => {
                    console.log("Got error " + error);
                    dispatch(actionTxFailed(txId, action, "Error get ejendom data: " + (error.message || error)));
                })
            break;
        }

        case BNBOActionType.Initialize: {
            if (action.payload.projectsElasticIndex) {
                let projectsPersistence = new BNBOProjectsPersistence(action.payload.projectsElasticIndex, action.payload.userId);
                BNBOProjectsPersistence.setInstance(projectsPersistence);
                let txId = MapitUtils.getNewUuid();
                dispatch(actionTxStarted(txId, action));
                BNBOProjectsPersistence.getInstance().listProjects({})
                    .then((projects) => {
                        action.results = { projects: projects, projectsPersistence: projectsPersistence };
                        dispatch(actionTxCompleted(txId, action));
                        dispatch(action);
                    })
                    .catch((error) => {
                        console.log("Got error " + error);
                        dispatch(actionTxFailed(txId, action, "Error get projects: " + (error.message || error)));
                    })
            }
            break;
        }

        case BNBOActionType.LoadSFE: {
            let sfeNummer = action.payload.sfeNummer;

            let txId = MapitUtils.getNewUuid();
            dispatch(actionTxStarted(txId, action));

            BNBOSFEPersistence.getInstance().getSFE(""+sfeNummer)
                .then((data) => {
                    action.results = data;

                    dispatch(actionTxCompleted(txId, action));
                    dispatch(action);
                })
                .catch((error) => {
                    console.log("Got error get SFE data: " + error);
                    dispatch(actionTxFailed(txId, action, "Error get SFE data: " + (error.message || error)));
                })
            break;
        }

        case BNBOActionType.LoadDataForGeoJson: {
            let geoJson = action.payload.multipolygon;

            let txId = MapitUtils.getNewUuid();
            dispatch(actionTxStarted(txId, action));
            let previousCount = 0;
            if (action.payload.replaceAll) {
                previousCount = 0;
            } else {
                previousCount = action.payload.currentState?.lodsejerRegister ? Object.keys(action.payload.currentState?.lodsejerRegister).length : 0;
            }
            BNBOFunc.loadDataForGeoJsonVersion2(geoJson, action.payload.replaceAll, action.payload.addTags, action.payload.currentState, action.payload.progressCallback)
                .then((data) => {
                    action.results = data;
                    action.previousCount = previousCount;

                    dispatch(actionTxCompleted(txId, action));
                    dispatch(action);
                })
                .catch((error) => {
                    console.log("Got error LoadDataForGeoJson: " + error);
                    dispatch(actionTxFailed(txId, action, "Error LoadDataForGeoJson: " + (error.message || error)));
                })
            break;
        }

        case BNBOActionType.LoadSFEState: {
            let txId = MapitUtils.getNewUuid();
            dispatch(actionTxStarted(txId, action));

            // load data from elastic search
            new Promise<void>(async (resolve, reject) => {
                try {
                    let bs = new BNBOSFEPersistence(action.payload.indexName, action.payload.userId);
                    BNBOSFEPersistence.setInstance(bs);
                    bs.listSFEs()
                        .then((data) => {
                            action.results = data;
                            resolve();
                        })
                        .catch((error) => {
                            console.log("Got error " + error);
                            reject(error);
                        });
                } catch (error) {
                    reject(error);
                }
            })
                .then(() => {
                    dispatch(actionTxCompleted(txId, action));
                    dispatch(action);
                })
                .catch((error) => {
                    console.log("Got error " + error);
                    dispatch(actionTxFailed(txId, action, "Error persist: " + (error.message || error)));
                });

            break;
        }

        case BNBOActionType.LoadProjects: {
            let txId = MapitUtils.getNewUuid();
            dispatch(actionTxStarted(txId, action));
            let projectsPersistence = new BNBOProjectsPersistence(SettingsManager.getSystemSetting("bnbo.projectsElasticIndex", ""), "dummy, only for loading");
            BNBOProjectsPersistence.setInstance(projectsPersistence);
            BNBOProjectsPersistence.getInstance().listProjects({}).then((result) => {
                dispatch(actionTxCompleted(txId, action));
                action.results = result;
                dispatch(action);
            }).catch((error) => {
                console.log("Got error " + error);
                dispatch(actionTxFailed(txId, action, "Error persist: " + (error.message || error)));
            })
            break;
        }

        default:
            // proceed directly to non-transational reducer for other actions
            dispatch(action);
    }
}


// ---------------------------------------------------- REDUCER --------------------------------------------------------------

export function safeAddToDoubleDepthObject(topObject: { [key1: string]: { [key2: string]: any } }, key1: string, key2: string, value: any) {
    if (!topObject[key1]) {
        topObject[key1] = {};
    }
    if (!topObject[key1][key2]) {
        topObject[key1][key2] = {};
    }
    topObject[key1][key2] = value;
}

export function safeGetDoubleDepthObject(topObject: { [key1: string]: { [key2: string]: any } }, key1: string, key2: string): any {
    if (!topObject[key1]) {
        return null;
    }
    if (!topObject[key1][key2]) {
        return null;
    }
    return topObject[key1][key2];
}

export function BNBOReducer(state: BNBOState, action: BNBOActions): BNBOState {

    function enum2String(type: any, value: number): string {
        return Object.values<string>(type)[value];
    }

    switch (action.type) {

        case BNBOActionType.TxStarted:
            return {
                ...state,
                activeTransactions: { ...state.activeTransactions, [action.payload.guid]: action }
            };

        case BNBOActionType.TxFailed: {
            let tmp = { ...state.activeTransactions };
            delete tmp[action.payload.guid];
            console.log(action.payload.error);
            let msg = Utils.formatString(/*Localization.getTextSafeMode(*/"Error. Transaction {txname} returned with {error}"/*)*/, {
                txname: /*Localization.getTextSafeMode(*/BNBOActionType[action.payload.action.type]/*)*/,
                error: /*Localization.getTextSafeMode(*/action.payload.error/*)*/
            });
            // Logger.logError("viamapLiceningState", BNBOActionType[action.payload.action.type], msg);
            if ((action.payload.action as any).errorCallback) {
                (action.payload.action as any).errorCallback(action.payload.error);
            }
            return {
                ...state,
                message: msg,
                messageType: MessageType.Error,
                activeTransactions: tmp
            };
        }

        case BNBOActionType.TxCompleted: {
            let tmp = { ...state.activeTransactions };
            delete tmp[action.payload.guid];
            return {
                ...state,
                activeTransactions: tmp,
                message: ""
            };
        }

        case BNBOActionType.Initialize: {
            return {
                ...state,
                availableProjectsList: action.results ? action.results.projects : [],
                // projectsPersistence: action.results ? action.results.projectsPersistence : undefined,
                isInitialized: true
            };
            return state;
        }

        case BNBOActionType.LoadSFE: {
            if (action.results && action.results.lodsejerid) {
                let data = action.results;
                // Patch all registers with latest data for this lodsejer
                decodeAndSaveOneSFEToState(data.lodsejerid, state.lodsejer, data.lodsejer);
                decodeAndSaveOneSFEToState(data.lodsejerid, state.bnboer, data.bnboer);
                decodeAndSaveOneSFEToState(data.lodsejerid, state.matrikler, data.matrikler);
                decodeAndSaveOneSFEToState(data.lodsejerid, state.marker, data.marker);
                decodeAndSaveOneSFEToState(data.lodsejerid, state.markDele, data.markDele);
                decodeAndSaveOneSFEToState(data.lodsejerid, state.dele, data.dele);
            }
            return state;
        }

        case BNBOActionType.LoadDataForGeoJson: {
            if (action.payload.callback) {
                let deltaCount = Object.values(action.results.lodsejerRegister).length - (action.previousCount || 0);
                action.payload.callback({ count: deltaCount });
            }
            function getUnionOfZeroOneOrManyFeatures(obj: {[key:string]:BNBOBeskyttelsesOmråde|BNBOMark}): TurfFeature|undefined {
                try {
                    let union;
                    if (obj === undefined) {
                        return undefined;
                    }   
                    let count = Object.keys(obj).length;
                    if (count === 0) {
                        return undefined;
                    } else {
                        if (count == 1) {
                            union = Turf.feature(Object.values(obj)[0].geometry)
                        } else {
                            union = Turf.union(Turf.featureCollection(Object.values(obj).map((item:any) => Turf.feature(item.geometry))));                        
                        }
                    }
                    return union;
                } catch (error:any) {
                    console.log("Error in getUnionOfZeroOneOrManyFeatures: " + error);
                    return undefined;
                }
            }
            // beregn restareal (servitutareal som ikke er dækket af en mark) pr lodsejer
            for(let lodsejerId of Object.keys(action.results.lodsejerRegister)) {
                let unionBNBO = getUnionOfZeroOneOrManyFeatures(action.results.lodsejerBNBOer[lodsejerId]);
                let unionMarker = getUnionOfZeroOneOrManyFeatures(action.results.lodsejerMarker[lodsejerId]);
                if (unionBNBO) {
                    if (unionMarker) {
                        let restAreal = Turf.difference(Turf.featureCollection([unionBNBO, unionMarker]));
                        if (restAreal) {
                            let restDel:BNBODel = {
                                id: "dummy",
                                type: BNBOOmrådeType.Restareal,
                                arealBrutto: 0,
                                arealNetto: 0,
                                geometry: restAreal.geometry
                            }
                            action.results.lodsejerDele[lodsejerId].push(restDel);
                        }
                    } else {
                        // Hele BNBO er restareal
                        let restDel:BNBODel = {
                            id: "dummy",
                            type: BNBOOmrådeType.Restareal,
                            arealBrutto: 0,
                            arealNetto: 0,
                            geometry: unionBNBO.geometry
                        }
                        action.results.lodsejerDele[lodsejerId].push(restDel);
                    }
                }
            }
            return {
                ...state,
                lodsejer: action.results.lodsejerRegister,
                bnboer: action.results.lodsejerBNBOer,
                matrikler: action.results.lodsejerMatrikler,
                marker: action.results.lodsejerMarker,
                markDele: action.results.markDele,
                dele: action.results.lodsejerDele
            };
        }

        case BNBOActionType.NyMark: {
            // let markId = getMarkId(action.payload.item.properties);
            // state.marker[markId] = action.payload.item
            // return { ...state, marker: state.marker };
            return state;
        }

        case BNBOActionType.NyMatrikel: {
            // let matrikelId = getMatrikelId(action.payload.item.properties);
            // state.matrikler[matrikelId] = action.payload.item
            // let ejerId = JSON.stringify(action.results?.ejere);
            // if (ejerId) {
            //     safeAddToDoubleDepthObject(state.ejerToMatrikel, ejerId, matrikelId, action.payload.item);
            //     state.ejerToEjerInfo[ejerId] = action.results;
            // }
            // return { ...state, matrikler: state.matrikler, ejerToEjerInfo:state.ejerToEjerInfo };
            return state;
        }

        case BNBOActionType.NyMarkMatrikel: {

            // let matrikelId = getMatrikelId(action.payload.item.properties);
            // safeAddToDoubleDepthObject(state.markIdToMatrikel, action.payload.markId, matrikelId, action.payload.item);
            // let sfeNummer = action.payload.item.properties.sfeNummer;
            // if (sfeNummer) {
            //     safeAddToDoubleDepthObject(state.sfeToMatrikel, sfeNummer, matrikelId, action.payload.item);
            // }
            // let ejerId = JSON.stringify(action.results?.ejere);
            // if (ejerId) {
            //     safeAddToDoubleDepthObject(state.ejerToMatrikel, ejerId, matrikelId, action.payload.item);
            //     state.ejerToEjerInfo[ejerId] = action.results;

            //     let overlap = state.marker[action.payload.markId];
            //     safeAddToDoubleDepthObject(state.ejerToMark, ejerId, action.payload.markId, overlap);
            // }
            // return { ...state, markIdToMatrikel: state.markIdToMatrikel, ejerToMatrikel: state.ejerToMatrikel, ejerToEjerInfo:state.ejerToEjerInfo };
            return state;
        }

        case BNBOActionType.NyMatrikelDel: {
            // let props = action.payload.item.properties;
            // let erstatning = props.bnbo_faktor * props.bnbo_areal_m2 * SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.min");
            // action.payload.item.properties.bnbo_erstatning = erstatning;
            // safeAddToDoubleDepthObject(state.matrikelToDel, action.payload.matrikelId, action.payload.delId, action.payload.item);
            // state.delIdToDel[action.payload.delId] = action.payload.item;
            // return {...state, matrikelToDel: state.matrikelToDel, delIdToDel:state.delIdToDel}
            return state;
        }

        case BNBOActionType.AddLodsejerDel: {
            let delList = state.dele[action.payload.lodsejerId] || [];
            delList.push(action.payload.item);
            return { ...state, dele: { ...state.dele, [action.payload.lodsejerId]: delList } }
        }

        case BNBOActionType.SetProcesStatus: {
            return { ...state, procesStatus: action.payload.procesStatus }
        }

        case BNBOActionType.SetErstatningsAreal: {
            return { ...state, erstatningsAreal: action.payload.item, procesStatus: ProcesStatus.ErstatningsArealValgt }
        }

        case BNBOActionType.SetErstatningsArealForDel: {
            // state.delIdToDelErstatningsAreal[action.payload.delId] = action.payload.item;
            // return {...state, delIdToDelErstatningsAreal: state.delIdToDelErstatningsAreal}
            return state;
        }

        case BNBOActionType.SetServitutAreal: {
            // Nulstil erstatningsareal hvis servitutareal ændres.
            return {
                ...state,
                servitutAreal: action.payload.item,
                erstatningsAreal: action.payload.item,
                //     procesStatus: ProcesStatus.ServitutArealValgt,
                //     // NULSTIL AFLEDET INFORMATION
                //     marker: {},
                //     markIdToMatrikel: {},
                //     matrikler: {},
                //     sfeToMatrikel: {},
                //     ejerToMatrikel: {},
                //     ejerToMark: {},
                //     ejerToEjerInfo: {},
                //     matrikelToDel: {},
                //     delIdToDel: {},
                //     delIdToDelErstatningsAreal: {}
            }
            return state;
        }

        case BNBOActionType.SetLodsejerTags: {
            let lodsejerId = action.payload.lodsejerId;
            let nyLodsEjer = { ...state.lodsejer[lodsejerId], tags: action.payload.tags };

            return {
                ...state, lodsejer: {
                    ...state.lodsejer, [lodsejerId]: nyLodsEjer
                }
            }
        }

        case BNBOActionType.SetLodsejerStatus: {
            let lodsejerId = action.payload.lodsejerId;
            let nyLodsEjer = { ...state.lodsejer[lodsejerId], status: action.payload.status };

            return {
                ...state, lodsejer: {
                    ...state.lodsejer, [lodsejerId]: nyLodsEjer
                }
            }
        }

        case BNBOActionType.SetLodsejerNote: {
            let lodsejerId = action.payload.lodsejerId;

            return {
                ...state, lodsejer: {
                    ...state.lodsejer, [lodsejerId]: { ...state.lodsejer[lodsejerId], note: action.payload.note }
                }
            }
        }
        case BNBOActionType.SetLodsejerPoints: { 
            let lodsejerId = action.payload.lodsejerId;
            return {...state, points: {
                ...state.points, [lodsejerId]: action.payload.points
            }}
        }
        case BNBOActionType.SetLodsejerSagsbehandler: {
            let lodsejerId = action.payload.lodsejerId;

            return {
                ...state, lodsejer: {
                    ...state.lodsejer, [lodsejerId]: { ...state.lodsejer[lodsejerId], sagsbehandler: action.payload.sagsbehandler }
                }
            }
        }

        case BNBOActionType.SetLodsejerErSpecialSats: {
            let lodsejerId = action.payload.lodsejerId;

            return {
                ...state, lodsejer: {
                    ...state.lodsejer, [lodsejerId]: { ...state.lodsejer[lodsejerId], erSpecialSats: action.payload.erSpecialSats }
                }
            }
        }

        case BNBOActionType.SetLodsejerSats: {
            let lodsejerId = action.payload.lodsejerId;

            return {
                ...state, lodsejer: {
                    ...state.lodsejer, [lodsejerId]: { ...state.lodsejer[lodsejerId], sats: action.payload.sats }
                }
            }
        }
        case BNBOActionType.SetLodsejerForsyningsInfo: {
            let lodsejerId = action.payload.lodsejerId;
            let key = action.payload.key
            return {
                ...state, lodsejer: {
                    ...state.lodsejer, [lodsejerId]: { ...state.lodsejer[lodsejerId], ["forsyning"+key]: action.payload.value }
                }
            }

        }

        case BNBOActionType.SetMarkNote: {
            let lodsejerId = action.payload.lodsejerId;
            let markId = action.payload.markId;

            return {
                ...state, marker: {
                    ...state.marker, [lodsejerId]: {
                        ...state.marker[lodsejerId],
                        [action.payload.markId]: { ...state.marker[lodsejerId][action.payload.markId], note: action.payload.note }
                    }
                }
            };
        }

        // case BNBOActionType.SetMarkErSpecialsats: {
        //     let lodsejerId = action.payload.lodsejerId;
        //     let markId = action.payload.markId;
        //     return {...state, marker: {...state.marker, [lodsejerId]: {...state.marker[lodsejerId],
        //         [action.payload.markId]: {...state.marker[lodsejerId][action.payload.markId], erSpecialSats: action.payload.erSpecialsats}
        //     }}};
        // }

        // case BNBOActionType.SetMarkSats: {
        //     let lodsejerId = action.payload.lodsejerId;
        //     let markId = action.payload.markId;
        //     return {...state, marker: {...state.marker, [lodsejerId]: {...state.marker[lodsejerId],
        //         [action.payload.markId]: {...state.marker[lodsejerId][action.payload.markId], sats: action.payload.sats}
        //     }}};
        // }

        case BNBOActionType.SetLodsejerDele: {

            return {
                ...state, dele: {
                    ...state.dele,
                    [action.payload.lodsejerId]: action.payload.dele

                }
            };
        }

        case BNBOActionType.SetMarkDele: {

            return {
                ...state, markDele: {
                    ...state.markDele,
                    [action.payload.lodsejerId]: {
                        ...state.markDele[action.payload.lodsejerId],
                        [action.payload.markId]: action.payload.dele
                    }
                }
            };
        }

        case BNBOActionType.SetLodsejerBNBOInactive: {
            let value = safeGetDoubleDepthObject(state.bnboer,action.payload.lodsejerId,action.payload.bnboId);
            if (value) {
                return {
                    ...state, bnboer: {
                        ...state.bnboer,
                        [action.payload.lodsejerId]: {
                            ...state.bnboer[action.payload.lodsejerId],
                            [action.payload.bnboId]: {...value, inactive: action.payload.inactive}
                        }
                    }
                }
            }
            return state;
        }

        case BNBOActionType.ReplaceBNBOState: {
            let newState = action.payload.state
            return {...state, ...newState};
        }

        case BNBOActionType.LoadSFEState: {
            if (action.results && action.results.length >= 0) {
            let { lodsejer, bnboer, matrikler, marker, markDele, dele}: { lodsejer: BNBOLodsejerRegister; bnboer: BNBOLodsejerPåvirkedeBNBOer; matrikler: BNBOLodsejerPåvirkedeMatrikler; marker: BNBOLodsejerPåvirkedeMarker; markDele: BNBOLodsejerMarkDele; dele: BNBOLodsejerDele; } = decodeSFEStatesFromPersistenceFormat(action.results);
            return { ...state, lodsejer, bnboer, matrikler, marker, markDele, dele};
            } else {
                return state;
            }   
        }

        case BNBOActionType.SelectProject: {
            let project = (state.availableProjectsList || []).find((p) => p.id === action.payload.id);
            if (project) {
                return { ...state, selectedProject: project, selectedProjectId: action.payload.id }
            } else {
                return state;
            }
        }

        case BNBOActionType.LoadProjects: {
            if (action.callback) {
                action.callback(action.results);
            };
            return { ...state, availableProjectsList: action.results }
        }

        case BNBOActionType.UpdateProject: {
            return { ...state, 
                selectedProject: action.payload.id === state.selectedProjectId ? action.payload.item : state.selectedProject,   
                availableProjectsList: (state.availableProjectsList || []).map((p) => p.id === action.payload.id ? action.payload.item : p) }
        }

        case BNBOActionType.AddProject: {
            return { ...state, availableProjectsList: (state.availableProjectsList || []).concat([action.payload.item]) }
        }

        case BNBOActionType.DeleteProject: {
            return { ...state, availableProjectsList: (state.availableProjectsList || []).filter((p) => p.id !== action.payload.id) }
        }

        default:
            throw new Error("Unknown action " + (action as BNBOActions).type);
    }
}

// ---------------------------------------------------- generic stuff --------------------------------------------------------------

export const BNBOContext = React.createContext<{
    state: BNBOState;
    dispatch: React.Dispatch<BNBOActions>;
}>({
    state: initialBNBOState(),
    dispatch: () => undefined,
});

export class GenericTransactionManager {

    static dispatchMiddleware<ActionTypes>(dispatch: any, transactionalReducer: (action: any, dispatch: any) => any) {

        return (action: ActionTypes) => {
            transactionalReducer(action, dispatch);
        };

    }
}

// ---------------------------------------------------- Utility Functions --------------------------------------------------------------

// -------- ID generations ---------
export function getLodsejerId(lodsejerData: any): string {
    return ("" + lodsejerData.sfeNummer);
}

export function getBNBOId(bnboData: any): string {
    return ("" + (bnboData.Objekt_id || bnboData.Dgunr || bnboData.dgunr));
}

export function getMarkId(markData: any): string {
    return (markData.Markblok || markData.markBlok || markData.markblok) + "-" + (markData.Marknr || markData.markNr || markData.marknr);
}

export function getMatrikelId(matrikel: any): string {
    return `${matrikel["mat:ejerlavskode"]}-${matrikel["mat:matrikelnummer"]}`
}

export function getMarkDelId(matrikelDel: any): string {
    return `${matrikelDel["mat:ejerlavskode"]}-${matrikelDel["mat:matrikelnummer"]}-${matrikelDel["Marknr"]}-${matrikelDel["bnbo_areal_m2"]}`
}

// ------- Accessor Functions ---------
export function getMarkData(state: BNBOState, lodsejerId: string, markId: string): BNBOMark {
    return state.marker[lodsejerId][markId];
}

export function getMarkDele(state: BNBOState, lodsejerId: string, markId: string): BNBODel[] {
    return state.markDele[lodsejerId][markId];
}

export function getMatrikelData(state: BNBOState, lodsejerId: string, matrikelId: string): BNBOMatrikel {
    return state.matrikler[lodsejerId][matrikelId];
}

export function getBNBOData(state: BNBOState, lodsejerId: string, bnboId: string): BNBOBeskyttelsesOmråde {
    return state.bnboer[lodsejerId][bnboId];
}

export function getLodsejerData(state: BNBOState, lodsejerId: string): BNBOLodsejer {
    return state.lodsejer[lodsejerId];
}

// ------- Update Functions - returns updated component to be pushed to the state ---------
export function setMarkData(state: BNBOState, lodsejerId: string, markId: string, value: BNBOMark): BNBOLodsejerPåvirkedeMarker {
    safeAddToDoubleDepthObject(state.marker, lodsejerId, markId, value);
    return state.marker;
}

export function setMarkDelData(state: BNBOState, lodsejerId: string, markId: string, value: BNBODel[]): BNBOLodsejerMarkDele {
    safeAddToDoubleDepthObject(state.markDele, lodsejerId, markId, value);
    return state.markDele;
}


export function setMatrikelData(state: BNBOState, lodsejerId: string, matrikelId: string, value: BNBOMatrikel): BNBOLodsejerPåvirkedeMatrikler {
    safeAddToDoubleDepthObject(state.matrikler, lodsejerId, matrikelId, value);
    return state.matrikler;
}

export function setBNBOData(state: BNBOState, lodsejerId: string, bnboId: string, value: BNBOBeskyttelsesOmråde): BNBOLodsejerPåvirkedeBNBOer {
    safeAddToDoubleDepthObject(state.matrikler, lodsejerId, bnboId, value);
    return state.bnboer;
}

export function setLodsejerData(state: BNBOState, lodsejerId: string, value: BNBOLodsejer): BNBOLodsejerRegister {
    state.lodsejer[lodsejerId] = value;
    return state.lodsejer;
}

// ------- Database encode/decode ---------
export function encodeLodsejerData(lodsejerId: string, state: BNBOState) {
    let lodsejer = state.lodsejer[lodsejerId];
    return {
        lodsejerid: lodsejer.id,
        status: lodsejer.status,
        ejerNavn: lodsejer.EjerNavn,
        adresse: lodsejer.Adresse,
        lodsejer: JSON.stringify(state.lodsejer[lodsejer.id]),
        bnboer: JSON.stringify(state.bnboer[lodsejer.id]),
        matrikler: JSON.stringify(state.matrikler[lodsejer.id]),
        marker: JSON.stringify(state.marker[lodsejer.id]),
        markDele: JSON.stringify(state.markDele[lodsejer.id]),
        dele: JSON.stringify(state.dele[lodsejer.id])
    };
}

function decodeAndSaveOneSFEToState(lodsejerId: string, register: any, data: SFERecord) {
    let json = data && JSON.parse(data);
    if (json) {
        register[lodsejerId] = json;
    }
}

function decodeSFEStatesFromPersistenceFormat(sfes: SFERecord[]) {
    let lodsejer: BNBOLodsejerRegister = {};
    let bnboer: BNBOLodsejerPåvirkedeBNBOer = {};
    let marker: BNBOLodsejerPåvirkedeMarker = {};
    let matrikler: BNBOLodsejerPåvirkedeMatrikler = {};
    let markDele: BNBOLodsejerMarkDele = {};
    let dele: BNBOLodsejerDele = {};

    for (let data of sfes) {
        decodeAndSaveOneSFEToState(data.lodsejerid, lodsejer, data.lodsejer);
        decodeAndSaveOneSFEToState(data.lodsejerid, bnboer, data.bnboer);
        decodeAndSaveOneSFEToState(data.lodsejerid, matrikler, data.matrikler);
        decodeAndSaveOneSFEToState(data.lodsejerid, marker, data.marker);
        decodeAndSaveOneSFEToState(data.lodsejerid, markDele, data.markDele);
        decodeAndSaveOneSFEToState(data.lodsejerid, dele, data.dele);
    }
    return { lodsejer, bnboer, matrikler, marker, markDele, dele};
}


export function getMyAccessRights(project:BNBOProject, userRef:string, customerRef:string):string[]|undefined {
    let accessRights = project.allowedUsersWithRoles && project.allowedUsersWithRoles[userRef] || project.allowedOrganizationsWithRoles && project.allowedOrganizationsWithRoles[customerRef];
    return accessRights;
 }
 

