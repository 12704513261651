import { Fragment, useEffect, useRef, useState } from "react";
import { FaCog } from "react-icons/fa";
import { GlassButton } from "./GlassButtons";
import { Localization } from "@viamap/viamap2-common";
import { BsGearFill, BsPalette, BsPalette2, BsPaletteFill } from "react-icons/bs";
import { autoUpdate, FloatingPortal, useFloating } from "@floating-ui/react";
import chroma from "chroma-js";
import { ColorPicker } from "./ComponentUtils";




export function GlassInputGroupTitle(props:{children}) {
   return (
      <div className="GlassInputGroupTitle">{props.children}</div>
   )
}

export function GlassInputGroupEscape(props:{children}) {
   return (
      <div className="GlassInputGroupEscape">{props.children}</div>
   )
}

export function GlassInputGroup(props:{children, style?, autoFocus?: boolean | number, onSubmit?: (e:any) => void, onEscape?: (e:any) => void}) {
   const ref = useRef<HTMLFormElement>(null)

   useEffect(() => { 
      if (typeof props.autoFocus === "number") {
         ref.current?.parentElement?.querySelectorAll(':is(input, select, checkbox)').forEach((a, idx) => {
            if (idx === props.autoFocus) {
               a instanceof HTMLElement && a?.focus?.()
            }
         })
      } else if (props.autoFocus) {  
         ref.current?.parentElement?.querySelectorAll(':is(input, select, checkbox)').forEach((a, idx) => {
            if (idx === 0) {
               a instanceof HTMLElement && a?.focus?.()
            }
         })
      }
   },[])

   return (
      <form className="GlassInputGroup" ref={ref} style={props.style}
         onSubmit={(e) => {e.preventDefault(); props.onSubmit?.(e)}}
         onKeyDown={(e) => {
            if (e.key === "Escape") {props.onEscape?.(e)}
            if (e.key === "Enter") {
               if (document.activeElement instanceof HTMLTextAreaElement) return
               

               e.preventDefault();
               let focusInput = false
               let shouldSubmit = true
               ref.current?.parentElement?.querySelectorAll(':is(input, select, checkbox)').forEach((a) => {
                  if (focusInput && shouldSubmit) {
                     a instanceof HTMLElement && a?.focus?.()
                     shouldSubmit = false
                  }
                  if (a === document.activeElement) {
                     focusInput = true
                  }
               })
               shouldSubmit && props.onSubmit?.(e)
            }
      
         }}
      >
         {props.children}
      </form>
   )
}

type ColorPalleteProps = {
   colorSet:string[],
   defaultColor: string,
   getter:()=>string,
   cogClick:() => void,
   onChange:(color:string)=>void
}
export function GlassColorPalette({
   colorSet,
   defaultColor,
   getter,
   cogClick,
   onChange
}:ColorPalleteProps) {
   const [showColorPicker, setShowColorPicker] = useState<boolean>(false)
   const {refs, floatingStyles} = useFloating({
      whileElementsMounted: autoUpdate,
      placement:"left",
   });
   

   return (
      <>
      <div className="GlassColorPalette">
          {colorSet.includes(getter()) ? colorSet.map((color, idx) => 
          idx === 0 && colorSet.includes(color, 1) ? null :
          <Fragment key={color}><GlassColorBox selected={color === getter()} onClick={(e) => onChange(color)} color={color} /></Fragment>
          )
          :
          <>
          <GlassColorBox selected={true} color={getter()} />
          <GlassButton
          onClick={() => {onChange(defaultColor);}}
          >
              {Localization.getText("Restore default")}
          </GlassButton>
          </>
          }
          <div className="Cog" style={{color:getter()}} ref={refs.setReference}>
          <BsPalette2 style={{width:"16px",height:"16px", marginLeft:"auto", cursor:"pointer"}} onClick={() => setShowColorPicker(true)} title={Localization.getText("Click to edit color")} />
          </div>
      </div>
      {showColorPicker ? (
      <FloatingPortal >
      <div ref={refs.setFloating} style={{...floatingStyles, zIndex:"1001"}} >
            <ColorPicker
                color={getter() || "blue"}
                onCommit={(color) => {
                  onChange(color)
                  setShowColorPicker(false)
                }}
                onCancel={() => setShowColorPicker(false)}
            />
            </div>
      </FloatingPortal>
      ):null}
      </>
  )
}

export function GlassColorBox(props: {color: string, selected?:boolean, onClick?:(e:any) => void}) {
   return (
      <div onClick={props.onClick} className={"GlassColorBox" + (props.selected ? " selected":"")} style={({"--color":props.color} as any)} ></div>
   )
}

export function GlassTextInput(
   props:{label, input}
) {

   return (
      <>
      <div className="GlassInputLabel"></div>
      <div className="GlassInputField"></div>
      </>
   )
}

export function GlassTextInputTransformer(props:{label, children}) {

   return (
      <>
      <div className="GlassInputLabel">{props.label}</div>
      <div className="GlassInputField">{props.children}</div>
      </>
   )
}

export function GlassInputControl(props: {children}) {
   return (
      <div className="GlassInputControl">{props.children}</div>
   )
}