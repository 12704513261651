import { PropertyInformationDialog } from "src/components/PropertyInformationDialog"
import { closeWindow, ManagedWindow, WindowId } from "./WindowState"
import { ObliqueViewer } from "src/components/ObliqueViewer"
import { SearchWindow } from "src/compentsModus/SearchWindow"
import { PlotScreen } from "src/components/PlotScreen"
import OwnershipDiagram from "src/compentsModus/OwnershipDiagram"
import { BNBOAreaViewerDialog } from "src/BNBOModule/BNBOAreaViewerDialog"
import { BnboDialog} from "src/BNBOModule/BNBODialog"
import { BNBOOpgørelse } from "src/BNBOModule/BNBOOpgørelse"
import { BNBOSingleInfo, BNBOSFEStatus } from "src/BNBOModule/BNBOSFEStatus"
import { SettingsEditor } from "src/components/SettingsEditor"
import { useWindowContext } from "./useWindowContext"
import { CatchmentInterface } from "src/managers/CatchmentInterface"
import { LoadedSettingsWindow, PersistenceScope, SettingsManager } from "@viamap/viamap2-common"
import { UserCustomerSettingsEditor } from "src/components/UserCustomerSettingsEditor"
import { BNBOAAreaEditorDialog } from "src/BNBOModule/BNBOAAreadEditorDialog"
import { GenerateReportDialog, SpatialExportDialog } from "src/components/SpatialFunctionDialog"
import { StreetViewer, StreetViewerUP } from "src/components/StreetViewer"
import { SelectCircle } from "src/components/SelectCircle"
import { SimulateOtherUserDialog } from "src/components/SimulateOtherUserDialog"
import { UserAccessRightsWindow } from "src/components/UserAccessRightsWindow"
import { MapStyling } from "src/components/MapStyling"
import { GDPRNoticeApproval } from "src/components/GDPRNoticeApproval"
import { DownloadDialog } from "src/components/DownloadDialog"
import { NameOnCreation } from "src/components/NameOnCreation"
import { ReportResultDialog } from "src/components/ReportResultDialog"
import { InfoScreen } from "src/components/InfoScreen"
import { CondominiumDialog } from "src/propertyInfoTemplates/CondominiumDialog"
import { CompanyInformationDialog } from "src/components/CompanyInformationDialog"
import { PersonInformationDialog } from "src/components/PersonInformationDialog"
import { CloudLayerList } from "src/components/CloudLayerList"
import { SaveMapAsCloudLinkDialog, SaveMapAsLinkDialog, SaveMapAsMapitDialog, SaveMapAsPrivateCloudDialog } from "src/components/SaveDialogs"
import { MaplinkOverview } from "src/components/MapLinkOverview"
import { DataRapportDialog } from "src/components/Datarapport"
import { SavedMapDialog } from "src/components/ReadOnlyMapDialog"
import { MultiPointTravelDialog } from "src/components/MultiPointTravelDialog"
import { ChatWindow } from "src/components/ChatWindow"
import { MapDescription } from "src/components/MapDescription"

// <></> is handle elsewhere

export function WindowInstances({window}:{window : ManagedWindow}): JSX.Element | null {
   const {dispatch :windowDispath} = useWindowContext()

   if (typeof window.id !== 'number') {
      return <div style={{position:"absolute", left:"50%"}}>Window not found {window.id} </div>
   }
   
   switch (window.id) {
      case WindowId.ChatWindow:
      return <ChatWindow window={window} onClose={() => windowDispath(closeWindow(window.id))} />
      case WindowId.MapDescription:
      return <MapDescription window={window} onClose={() => windowDispath(closeWindow(window.id))} />
      case WindowId.ShowSearchField:
      return <div>Search Field</div>
      case WindowId.InformationWindow:
      return <InfoScreen
              showWindow={1}
              onFormClose={() => windowDispath(closeWindow(window.id))}
            />
      case WindowId.GettingStarted:
         return null
      return <div>Getting Started</div>
      case WindowId.ShowReleases:
         return null
      return <div>Show Releases</div>
      case WindowId.Legend:
      return <></>
      case WindowId.ShowLegendButton:
      return <></>
      case WindowId.EjendomskortDialog:
      return <DataRapportDialog 
              show={1}
              onClose={() => windowDispath(closeWindow(window.id))}
            />
      case WindowId.DemographyReportDialog:
      return <GenerateReportDialog
            showWindow={1}
            onClose={() => {windowDispath(closeWindow(window.id))}}
            callbackOnReport={(c, d, a) => {
            windowDispath(closeWindow(window.id));
            }}
         />
      case WindowId.StreetView:
      return <StreetViewerUP
               showWindow={1}
               onFormClose={()=> {windowDispath(closeWindow(window.id));}}
               latlng={window.windowParams}
               />
      case WindowId.SelectCircle:
      return <SelectCircle
         showWindow={1}
      />
      case WindowId.GenerateReportDialog:
      return <GenerateReportDialog
         showWindow={1}
         callbackOnCancel={() => { windowDispath(closeWindow(window.id)); }}
         callbackOnReport={(c, d, a) => {
         windowDispath(closeWindow(window.id));
         }}
      />
      case WindowId.GenerateReportResultDialog:
      return <ReportResultDialog
               showWindow={1}
               onClose={() => { windowDispath(closeWindow(window.id)); }}
             />
      case WindowId.PropertyInformationDialog:
      return <PropertyInformationDialog
      showWindow={1}
      labelLatLng={window.windowParams || { latlng: {lat:0, lng:0}}}
      callBackOnClose={() => {
         {}
      }}
      allowExtendedInformation={true}
      cols={2}
      />
      case WindowId.CompanyInformationDialog:
      return <CompanyInformationDialog
            showWindow={1}
            cvrNr={window.windowParams?.cvrNr || 0}
            callBackOnClose={() => {
               windowDispath(closeWindow(window.id));
            }}
         />
      case WindowId.ObliqueViewer:
      return <ObliqueViewer 
      showWindow={1}
      imageProps={window.windowParams}
      onFormClose={() => {}}
      />
      case WindowId.SaveMapDialog:
      return <SaveMapAsMapitDialog />
      case WindowId.SaveLinkDialog:
      return <SaveMapAsLinkDialog />
      case WindowId.SavedLinkDialog:
      return <SavedMapDialog
                showWindow={1}
                refLink={window.windowParams.ref || "test"}
                expireDate={window.windowParams.expireDate || new Date()}
                callbackOnCancel={() => {windowDispath(closeWindow(window.id))}} 
              />
      case WindowId.CatchmentSelector:
      return <></>
      case WindowId.SpatialSelection:
      return <SpatialExportDialog
                showWindow={1}
                onClose={() => {windowDispath(closeWindow(window.id))}}
              />
      case WindowId.CustomerSettingsEditor:
      return <UserCustomerSettingsEditor
      scope={PersistenceScope.Customer}
      mangedWindow={window}
      />
      case WindowId.UserSettingsEditor:
      return <UserCustomerSettingsEditor
      scope={PersistenceScope.User}
      mangedWindow={window}
      />
      case WindowId.MapSettingsEditor:
      return <MapStyling
         showWindow={1}
         onClose={() =>  windowDispath(closeWindow(window.id))}        
      />
      case WindowId.PointDistanceTable:
      return <MultiPointTravelDialog
         showWindow={1}
         options={window.windowParams}
         callbackOnClose={() => {
         //  catchmentState.layerHandle && mapitState.map.removeLayerByHandle(catchmentState.layerHandle);
            windowDispath(closeWindow(window.id));
         }}
      />
      case WindowId.ConfirmLayerList:
         return null
      return <div>Confirm Layer List</div>
      case WindowId.FileTooBig:
         return null
      return <div>File Too Big</div>
      case WindowId.NameOnCreation:
      return <NameOnCreation
      showWindow={1}
      layers={window.windowParams.layers}
      onClose = {() => windowDispath(closeWindow(window.id))}
    />
      case WindowId.DownloadLayerDialog:
      return <DownloadDialog
               showWindow={1}
               layers={window.windowParams.layers}
               callBack={(evt) => {windowDispath(closeWindow(window.id))}}
             />
      case WindowId.GDPRNoticeApproval:
      return <GDPRNoticeApproval
         showWindow={1}
         currentCookie={window.windowParams.cookie}
         callbackOnCancel={()=> {windowDispath(closeWindow(window.id))}}
         callbackOnCommit={()=> {window.windowParams.acceptCallback(); windowDispath(closeWindow(window.id))}}
      />
      case WindowId.Explore:
      return <SearchWindow 
      show={true}
      callbackOnClose={() =>  {}}
      />
      case WindowId.PlotScreen:
      return <PlotScreen callbackOnClose={() =>  {}}
      externalSettings={window.windowParams?.externalSettings as any} />
      case WindowId.LoadedSettings:
      return <LoadedSettingsWindow
            show={true}
            bucket={SettingsManager.getSystemSetting("viamapStoreS3Bucket")}
            onClose={() => windowDispath(closeWindow(window.id))}
         />
      case WindowId.UserAccessRights:
      return <UserAccessRightsWindow
         show={true}
         onClose={() => windowDispath(closeWindow(window.id))}
      />
      case WindowId.SimulateOtherUser:
      return <SimulateOtherUserDialog
                    show={true}
                    onClose={() => windowDispath(closeWindow(window.id))}
                />
      case WindowId.CloudSaveDialog:
      return <SaveMapAsPrivateCloudDialog />
      case WindowId.ViewCloudSaveDialog:
      return <CloudLayerList 
              show={true}
              close={() => windowDispath(closeWindow(window.id))}
            />
      case WindowId.AdminCloudSaveDialog:
      return <SaveMapAsCloudLinkDialog />
      case WindowId.PersonInformationDialog:
      return  <PersonInformationDialog
         showWindow={1}
         personInfo={window.windowParams}
         callBackOnClose={() => {
           windowDispath(closeWindow(window.id));
         }}
      />
      case WindowId.OwnerShipDiagram:
      return <OwnershipDiagram
      showWindow={1}
      search={window.windowParams}
      />
      case WindowId.CondominiumDialog:
      return <CondominiumDialog 
              showWindow={1}
              bfeNr={window.windowParams as {bfeNr:string}} 
              callBackOnClose={() => {
                windowDispath(closeWindow(window.id));
              }}
              allowExtendedInformation={true}
            />
      case WindowId.MapLinkOverview:
      return <MaplinkOverview 
              show={true}
              close={() => windowDispath(closeWindow(window.id))}
            />

      // ----------------- BNBOModule -----------------

      case WindowId.BNBODialog:
      return <BnboDialog
      showWindow={1}
      onFormClose={() => {}}
      />
      case WindowId.BNBOAreaEditor:
      return <BNBOAAreaEditorDialog 
      showWindow={1}
      managedWindow={window}
      lodsejerId={window.windowParams?.lodsejerId as string}
      // markId={window.windowParams?.markId as string}
      />
      case WindowId.BNBOMarkAreaEditor:
      return <BNBOAAreaEditorDialog 
      showWindow={1}
      managedWindow={window}
      lodsejerId={window.windowParams?.lodsejerId as string}
      // markId={window.windowParams?.markId as string}
      />
      case WindowId.BNBOSingleElement:
      return <BNBOSingleInfo 
      show={1}
      managedWindow={window}
      lodsejerId={window.windowParams?.lodsejerId as any}
      onClose={() => {}}
      />
      case WindowId.BNBOSFEStatus:
      return <BNBOSFEStatus 
      show={1}
      
      onClose={() => {}}
      managedWindow={window}
      filterBy={window.windowParams?.filterBy}
      />
      case WindowId.BNBOOpgørelse:
      return <BNBOOpgørelse
      show={1}
      onClose={() => {}}
      lodsejerId={window.windowParams?.lodsejerId}
      />
      case WindowId.BNBOAreaViewer:
      return <BNBOAreaViewerDialog 
      showWindow={1}
      lodsejerId={window.windowParams?.lodsejerId as string}
      defaultModus={window.windowParams?.defaultModus}
      />
      default:
      return <div>Window not found</div>
   }
}




