import { ADModalBody, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { ManagedWindow } from "src/WindowManager/WindowState";
import { GlassInputControl } from "./GlassInput";
import { actionUpdateMapInfo, MapitStateContext } from "src/states/MapitState";
import { useContext } from "react";
import { Localization } from "@viamap/viamap2-common";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";


export function MapDescription(props: {window:ManagedWindow, onClose:()=>void}) {
   const {state: mapitState, dispatch: mapitDispatch} = useContext(MapitStateContext)
   const {hasAccessToFeature} = useContext(ApplicationStateContext);

   return (
      <AdvancedDragModal variant={"Small"} window={props.window}  onClose={props.onClose} 
      PosDefault={{left: "97%", top: "40px", width:"300px", height:"400px", transform: "translate(-97%, 0)"}}
      title={Localization.getText("Map Description")}>
         <ADModalBody>
            <div style={{padding:"2px", display:"flex", flex:"auto"}} >
            <GlassInputControl>
               <textarea readOnly={!hasAccessToFeature(Feature.EditMapDescription)} value={mapitState.mapInfo.mapDescription || ""} style={{width:"100%", height:"100%", resize:"none"}}
                  onChange={(e)=>mapitDispatch(actionUpdateMapInfo({mapDescription:e.target.value}))}
               ></textarea>
            </GlassInputControl>
            </div>
         </ADModalBody>
      </AdvancedDragModal>
   )

}