import { BsCaretRightFill } from "react-icons/bs";
import { PropertyData } from "./PropertyInfoCollectorTypes";
import { defaultParser, titleValParser } from "./PropertyInfoParser";
import { PiCaretRightLight } from "react-icons/pi";
import { Spinner } from "react-bootstrap";
import { Localization } from "@viamap/viamap2-common";
import { MapitStateContext } from "src/states/MapitState";
import { MitLatLng } from "src/managers/MapFacade";
import { BiCheck } from "react-icons/bi";
import React, { isValidElement, useState, useEffect, Fragment, useRef, useContext } from "react";
import { useFloating, autoUpdate, offset, useInteractions, useHover, useDismiss, FloatingPortal } from "@floating-ui/react";
import { hasAccessToFeature, ApplicationStateContext } from "src/states/ApplicationState";
import { Feature } from "src/states/ApplicationStateFeatures";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { openWindow, WindowId } from "src/WindowManager/WindowState";

type KeyValueComponentProps<T> = {
  valKey: React.ReactNode;
  value: T;
  keepEmpty?: boolean;
  infoText?: string;
  variant?: string;
  KeyEndParser?: (key:string) => string;
  CParser?: (key: string, val: T) => string;
  _className?: string;
};

export function KeyValueTable({ children }) {
  return <table className="Table-MitProp">
      <tbody>{children}</tbody>
    </table>;
}

export function FloatingBox(props: {
  children: React.ReactNode;
  aspectRatio?: number;
}) {
  return (
    <div
      className="FloatingBox-MitProp"
      style={{ aspectRatio: props.aspectRatio || undefined }}
    >
      {props.children}
    </div>
  );
}

export function PropertyMapContainer(props: {
  children: React.ReactElement;
  aspectRatio?: number;
}) {
  return (
    <div
      className="MapContainer-MitProp"
      style={{ aspectRatio: props.aspectRatio || undefined }}
    >
      {props.children}
    </div>
  );
}

export function PropertyImageContainer(props: {
  children: React.ReactElement;
  aspectRatio?: number;
}) {
  return (
    <div
      className="ImageContainer-MitProp"
      style={{ aspectRatio: props.aspectRatio || undefined }}
    >
      {props.children}
    </div>
  );
}

export function TitleValueLine<T>(props: KeyValueComponentProps<T>) {
  return <KeyValueLine<T> {...props} _className="KeyValTitle" />;
}

export function KeyValueLine<T>(props: KeyValueComponentProps<T>) {
  const valKey =
    typeof props.valKey == "string"
      ? titleValParser(props.valKey)
      : props.valKey;
  

  if (isValidElement(props.value)) {
    return (
      <div className={"Line-MitProp " + (props._className || "")}>
        <div data-info={props.infoText}>{typeof valKey == "string" ? props.KeyEndParser?.(valKey)||valKey: valKey}</div>
        <div>{props.value}</div>
      </div>
    );
  }
  let resVal =
    props.CParser?.(typeof valKey == "string" ? valKey : "", props.value) ??
    defaultParser(typeof valKey == "string" ? valKey : "", props.value);
  let keepEmpty = props.keepEmpty ?? false;

  if (resVal === "" && !keepEmpty) {
    return null;
  }

  return (
    <div className={"Line-MitProp " + (props._className || "")}>
      <div data-info={props.infoText} >{typeof valKey == "string" ? props.KeyEndParser?.(valKey)||valKey: valKey}</div>
      <div>{resVal}</div>
    </div>
  );
}

export function KeyValuesBoxGrid(props: {
  minWidth: `${number}px`;
  maxCols?: number;
  children: React.ReactNode;
}) {
  const maxCols = (props.maxCols ?? 9) + 1;
  return (
    <div
      className="Grid-MitProp"
      style={
        {
          "--minBoxWidth": props.minWidth,
          "--maxBoxCols": maxCols,
        } as React.CSSProperties
      }
    >
      {props.children}
    </div>
  );
}

export function EmptySpace() {
  return <div style={{ height: "1.4em" }} />;
}

export function ErrorMessage(props: {
  errors: Partial<Record<keyof PropertyData, { error: string }>>;
}) {
  let errorsIdx = Object.keys(props.errors);

  if (errorsIdx.length === 0) {
    return null;
  }

  return (
    <div className="ErrCon-MitProp">
      {errorsIdx.map((a) => (
        <SingleError key={a} error={props.errors[a]} />
      ))}
    </div>
  );
}

function VariantHandler(name:string, variant?:string):string {
  return (variant ? name+" "+name+"-"+variant : name)+" "
}

export function GlassFoldUdBox(props: {
  title: any;
  group?: string;
  boxKey?: string;
  variant?: "Small";
  foldetUd: boolean;
  children: any;
}) {
  let [fold, setFold] = useState(props.foldetUd);

  useEffect(() => {
    setFold(props.foldetUd);
  }, [props.foldetUd]);

  return (
    <div className={VariantHandler("GlassFoldUdBox", props.variant) + (fold ? " open" : "")}>
      <div
        className="Head"
        onClick={() => setFold((a) => !a)}
      >
        {props.title}

        <div className="FoldCaret">
          <PiCaretRightLight />
        </div>
      </div>
      <div className="Body">{props.children}</div>
    </div>
  );
}

export function ValueGroup(props: { children: any }) {
  return <div className="ValGroup-MitProp">{props.children}</div>;
}

export function SingleError(props: { error: { error: string } }) {
  console.error(props);
  return <div className="Error-MitProp">{props.error.error}</div>;
}

type GroupingChild = JSX.Element[] | JSX.Element | undefined;
export function ShowSelectedGroup(props: {
  keepSelected: boolean;
  loading: boolean;
  columns: number;
  group: string;
  favName?: string;
  favBoxes?: {[favName:string]:string[]}
  groupCallBack?: (groups: string[]) => any;
  children: GroupingChild[];
}) {
  let [validGroups, setValidGroups] = useState([""]);
  

  useEffect(() => {
    let x = (
      props.children.filter((a) => a) as NonNullable<GroupingChild>[]
    ).flatMap((a) => {
      const arrEle = Array.isArray(a) ? a : [a];
      return arrEle;
    });

    let nextValidGroups = [
      ...new Set([
        ...x.map((a) =>
          React.isValidElement(a) ? a.props?.["group"] || "" : ""
        ),
      ]),
    ].filter((a) => a);

    if (nextValidGroups.every((a, idx) => a === validGroups[idx])) {
      return;
    }
    setValidGroups(nextValidGroups);
    props.groupCallBack?.(nextValidGroups);
  }, [props.children]);

  let x = (
    props.children.filter((a) => a) as NonNullable<GroupingChild>[]
  ).flatMap((a) => {
    const arrEle = Array.isArray(a) ? a : [a];
    return arrEle;
  });

  let activeGroup = validGroups.includes(props.group)
    ? props.group
    : (props.keepSelected ? props.group : validGroups[0]);

  let selectedElements =
    activeGroup == "Alle"
      ? x
      : x?.filter?.((a) => a.props?.["group"] == activeGroup) || [];


  
  if (props.group == "Favoritter") {
    if (props.favBoxes && props.favName && Object.keys(props.favBoxes).includes(props.favName)) {
      return (
        <ColumnSplitter columns={props.columns}>
          {props.favBoxes[props.favName].map((a) => {
            return (
              <Fragment key={a} >
                {x.filter((val) => val?.props?.["boxKey"] == a) || <></>}
              </Fragment>
              )
          })}
        </ColumnSplitter>
        ) 
    }
  }

  if (selectedElements.length == 0) {
    if (props.loading) {
      return (<PropertyLoading />)
    }
    return (
      <PropertyWarning
        title={Localization.getFormattedText("No information in {tab}", {tab: activeGroup})}
        subText={Localization.getText("Please select another group")}
      />
    )
  }


  return (
    <ColumnSplitter columns={props.columns}>
      {selectedElements}
    </ColumnSplitter>
  );
}

export function PropertyLoading() {
  return <div className="PropertyLoading">{Localization.getText("Loading")} <Spinner size="sm" /></div>
}

export function PropertyWarning(props: {title: string, subText:string}) {
  return <div className="PropertyWarning">
    <div>{props.title}</div>
    <div>{props.subText}</div>
  </div>
}

export function GroupSelector(props: {
  selected: string;
  group: string[];
  onSelect?: (group: string) => any;
}) {
  let thisRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (thisRef.current) {
      let element = thisRef.current as HTMLDivElement;
      const moveScrollEvent = (e: PointerEvent) => {
        let { left, right } = element.getBoundingClientRect();
        if (e.clientX < left || e.clientX > right) {
          return;
        }
        if (e.pointerType == "touch") {
          return;
        }
        element.scrollLeft =
          ((element.scrollWidth - element.clientWidth) * (e.clientX - left)) /
          element.clientWidth;
      };
      document.addEventListener("pointermove", moveScrollEvent);
      return () => document.removeEventListener("pointermove", moveScrollEvent);
    }
  }, []);

  function select(a: string) {
    props.onSelect?.(a);
  }

  return (
    <div
      ref={thisRef}
      className="GlassHorizontalScroll dont-print"
      style={{ scrollbarWidth: "none", maxHeight: "49px" }}
    >
      {props.group.map((a) => {
        return (
          <button
            key={a}
            className={(a === props.selected ? "selected" : "")}
            onClick={(evt) => select(a)}
          >
            {a}
          </button>
        );
      })}
    </div>
  );
}

export function KeyValuesHorizontal(props: { children: any }) {
  return <div className="KeyValuesHorizontal">{props.children}</div>;
}

export function CenterGrid(props: { minWidth: `${number}px`; children }) {
  return (
    <div
      className="CenterGrid-MitProp"
      style={{ "--minBoxWidth": props.minWidth } as React.CSSProperties}
    >
      {props.children}
    </div>
  );
}

function useSplitterCols(
  smallestCol: number,
  min?: number,
  max?: number,
  ):[React.RefObject<HTMLDivElement>,number] {
  const container = useRef<HTMLDivElement>(null);
  const [cols, setCols] = useState(1)
  useEffect(() => {
    if (container.current) {
      const resizeObserver = new ResizeObserver((target) => {
        target.map((a) => {
          const calCols = a.borderBoxSize[0].inlineSize / smallestCol;
          setCols(Math.floor(Math.max(min ?? 1, Math.min(max ?? 4, calCols))))
        })
      })
      resizeObserver.observe(container.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  },[container, container.current])

  return [container, cols]
}


/**
 *
 * @param props
 * @returns
 * @print Always only prints one collumn
 */
export function ColumnSplitter(props: {
  columns: number;
  children: any;
}) {
  

  const colsNr = props.columns || 1;
  
  const [container, cols] = useSplitterCols(600, 1, 2)
  let collumnsIDS = Array.from(Array(cols).keys());

  return (
    <div
      ref={container}
      className="Splitter-MitProp"
      style={{ "--forcedColumns": cols || 1 } as React.CSSProperties}
    >
      {collumnsIDS.map((colkey) => {
        return (
          <div key={colkey} className="SplitterSingle-MitProp">
            {props.children.filter((a, idx) => idx % cols == colkey)}
          </div>
        );
      })}
    </div>
  );
}

// ----------------------------- link components -------------------------

export function RenderLinkToPropertyInfo(text:string, props:{lat:number, lng:number}) {
  const {dispatch: windowDispatch} = useWindowContext();
  
  return (
    <a className="property-info-punit-link" onClick={()=> {
      windowDispatch(openWindow(WindowId.PropertyInformationDialog, {latlng:new MitLatLng(props.lat, props.lng)}));
    }}>{text}</a>
    );
}

export function RenderLinkToCompanyGraph(props:{cvrNr}) {
  const {dispatch: windowDispatch} = useWindowContext();
  return (
    <a className="property-info-punit-link" onClick={()=> {
      windowDispatch(openWindow(WindowId.OwnerShipDiagram, {cvrNr: props.cvrNr}));
    }}>{props.cvrNr}</a>
  )
}

export function RenderLinkToPersonGraph(props:{cvrPersonId:number}) {
  const {dispatch: windowDispatch} = useWindowContext();
  return (
    <a className="property-info-punit-link" onClick={()=> {
      windowDispatch(openWindow(WindowId.OwnerShipDiagram, {personInfo: {cvrPersonId: props.cvrPersonId}}))
    }}>{props.cvrPersonId}</a>
  )

  
}

// ---------------------------- Dialog Header Components ---------------

type ViewBTN = {
  children: React.ReactNode
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
export function ViewButtonIcon({
children,
title,
...props}: ViewBTN) {

  const { hasAccessToFeature } = useContext(ApplicationStateContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {refs, floatingStyles, context: tooltipContext} = useFloating({
      whileElementsMounted: autoUpdate,
      // placement: hasAccessToFeature(Feature.ThemeNewSec) ? "bottom-end" : "top",
      open: tooltipOpen,
      middleware: [offset(5)],
      onOpenChange: setTooltipOpen,
    });
    const {
      getReferenceProps: getTooltipReferenceProps,
      getFloatingProps: getTooltipFloatingProps
    } = useInteractions([useHover(tooltipContext), useDismiss(tooltipContext)]);
  



  return (
    <>
    <button ref={refs.setReference} {...props} className={"ViewButton ViewButtonIcon " +props.className} >
      {children}
    </button>
    {Boolean(title && tooltipOpen) &&
        <FloatingPortal>
        <div ref={refs.setFloating} className="ViewButtonToolTip" style={floatingStyles} {...getTooltipFloatingProps()} >
            {title || "ThisIsToolTIp"}
        </div>
        </ FloatingPortal>
        }
    </>
  )
}
export function ViewButtonText({
  children,
  ...props}: ViewBTN) {
    return (
      <button {...props} className={"ViewButton ViewButtonText " +props.className} >
        {children}
      </button>
    )
  }

  export function ViewButtonCheck({
    children,
    checked,
    onCheck,
    ...props
  }: ViewBTN & {checked:boolean, onCheck: (e, a) => void}) {

    function handleClick(e) {
      onCheck?.(e, checked)
    }

    return <>
    <button {...props} onClick={handleClick} className={"ViewButton ViewButtonCheck " +props.className}>
      <div className={"ViewButtonCheckBox " + (checked?"checked":"hidden")}>
        <BiCheck />
      </div>
      {children}
    </button>
    </>
  }

  export function ViewButtonCaret({
    children,
    isOpen,
    ...props}: ViewBTN & {isOpen:boolean}) {
      return (
        <button {...props} className={"ViewButton ViewButtonCaret " +props.className} >
          {children}
          <div className={"ViewButton-Carret " + (isOpen?"open":"closed")}>
            <BsCaretRightFill></BsCaretRightFill>
          </div>
        </button>
      )
    }

export function RenderLinkToCompanyInfo(
  props:{
    children: React.ReactNode
    cvrNr:number, 
  }
  ) {
    const {dispatch: windowDispatch} = useWindowContext();
  return (
    <a className="property-info-punit-link" onClick={()=> {
      windowDispatch(openWindow(WindowId.CompanyInformationDialog, props))
    }}>{props.children}</a>
    );
}

export function RenderLinkToCondominium(
  props: {
    children: React.ReactNode,
    bfeNr: number|string,
  }) {
    const {dispatch: windowDispatch} = useWindowContext();
  return (
    <a className="property-info-punit-link" onClick={() => {
      windowDispatch(openWindow(WindowId.CondominiumDialog, props))
    }}>{props.children}</a>
  )
}

export function RenderLinkToPersonInfo(
  props:{
    children:React.ReactNode, 
    name:string, 
    dateOfBirth?:Date, 
    adresse: string,
    andelProcent: number,
    displayText:string,
    postdistrikt: string,
    postnr: number,
    cvrPersonId?: number,
  }
  ) {
    const {dispatch: windowDispatch} = useWindowContext();
  return (
    <a className="property-info-punit-link" onClick={()=> {
      windowDispatch(openWindow(WindowId.PersonInformationDialog, {...props}))
    }}>{props.children}</a>
    );
}
