/**
* A Search field with pluggable autocomplete components.
* Allows searching for different type of data from the same field.
*/
import { AppMessagesContext, Localization, actionSetErrorMessage } from '@viamap/viamap2-common';

import { TiArrowBack } from "react-icons/ti";
import { ToBeLocalizedString } from '@viamap/viamap2-common/dist/managers/Types';
import { Feature } from 'src/states/ApplicationState';
import { ProtectedFeature } from './ProtectedFeature';
import { useContext, useState, useRef, useEffect } from 'react';
import { GlassCheckbox } from './MitGlassComponents';

export type SearchBarGeneralPluginSuggestion = {
  isContext?: boolean, // if this choice is a preselection. E.g. ejerlavskode
  contextType?: string,
  betegnelse: string,
  code: string | number,
  name: string,
  checked?: boolean,
  data: any,
  onSelection?: (data:any, searchText?:string) => void
}

/** Type of preselections/context for further searching */
export type SearchBarGeneralContextType = "Ejerlavskode"|"Navngivenvej";
export type SearchBarGeneralContext = Partial<Record<SearchBarGeneralContextType, any>>

/** Interface for autocomplete search plugins */
export interface SearchBarGeneralPlugin {
  getContextType(): SearchBarGeneralContextType | undefined,
  getKey(): string,
  getTitle(): ToBeLocalizedString,
  shouldBeActive(context: SearchBarGeneralContext, queryString: string): boolean
  getSuggestions: (
    context: SearchBarGeneralContext, 
    prefix: string,
    sequenceNumber: number, // The sequence number for this result. Used to ignore old responses ('race condition')
  ) => Promise<SuggestionsGroup | SuggestionError>
}

export type SuggestionError = {
  error: string,
}

export type SuggestionsGroup = {
  key: string,
  title: ToBeLocalizedString,
  sequenceNumber: number, // The sequence number for this result. Used to ignore old responses ('race condition')
  suggestions: SearchBarGeneralPluginSuggestion[]
}

type Props = {
  id?:string,
  autocompleters: SearchBarGeneralPlugin[];
  showWindow: boolean;
  callbackOnSelection: (type: string, props: any) => void;
};

export function SearchBarGeneral(props: Props) {
  const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
  const [generalSuggestions, setGeneralSuggestions] = useState<{[key:string]:SuggestionsGroup}>({});
  const [showGeneralSuggestions, setShowGeneralSuggestions] = useState<boolean>(false);
  const [listPointer, setListPointer] = useState<number>(0);
  const [context, setContext] = useState<SearchBarGeneralContext>({});
  const [searchValue, setSearchValue] = useState<string>("");
  const [expandedGroup, setExpandedGroup] = useState<string>("");
  let inputRef = useRef<HTMLInputElement>(null);
  let sequenceNumberRef = useRef<number>(0);
  
  useEffect(() => {
    sequenceNumberRef.current += 1;
    generalAutocomplete();
  }, [searchValue])

  useEffect(() => {

    function setPlaceholder() {
      if (inputRef.current) {
        let idx = Math.floor(Math.random() * props.autocompleters.length)
        inputRef.current.placeholder = Localization.getText("Search") + " " + Localization.getText(props.autocompleters[idx]?.getTitle().key || "")
      }
    }

    const k = setInterval(() => {
      setPlaceholder()
    }, 5000)
    setPlaceholder()
    return () => { 
      k && clearInterval(k)
    }
  }, [])
  
  function onSelectSuggestion(selectedItem: SearchBarGeneralPluginSuggestion) {
    if (selectedItem.isContext && selectedItem.contextType) {
      setContext((ctx) => {
        return { ...ctx, [selectedItem.contextType!]: selectedItem };
      });
      setListPointer(0)
      setSearchValue("");
    } else {
      setContext({})
      setShowGeneralSuggestions(false);
      selectedItem.onSelection?.(selectedItem.data, getSearchText());
    }
    setExpandedGroup(selectedItem.contextType || "")
  }
  
  async function generalAutocomplete() {
    let queryString = getSearchText();
    if (Object.keys(context).length) {
      let key = Object.keys(context)[0] // Assume only 1 context exist at a time
      queryString = context[key].contextParse.replace("$",queryString)
    }
    
    props.autocompleters.forEach((autocompl) => {
      autocompl.getSuggestions(context, queryString, sequenceNumberRef.current)
      .then((result:SuggestionsGroup | SuggestionError) => {
        if ("error" in result) {
          throw new Error(result.error)
        } else {
          updateSuggestion(result);
        }
      })
      .catch((error) => {
        setGeneralSuggestions((gs) => {
          return({...gs, [autocompl.getKey()]: {key: autocompl.getKey(),title:autocompl.getTitle(),sequenceNumber:0, suggestions: [{
            betegnelse: Localization.getText("No data"),
            code: "Errr:Kunne ikke ",
            name: "string",
            data: "",
            onSelection: () => {
              appMessageDispatch(actionSetErrorMessage(Localization.getText(`Please try again shortly or contact support@viamap.net if the problem persists`)));
            }
          }]}})
        })
        // TODO: Make a non obstructing Messages 
      })
    });
    
  }
  
  async function generalListHideOnBlur() {
    setShowGeneralSuggestions(false);
  }
  
  function resetListPointer() {
    setListPointer(0);
  }
  
  function getSearchText():string {
    return searchValue;
  }
  
  function move(keyEvt: React.KeyboardEvent) {
    
    function focusSelected() {
      let pa = (keyEvt.target as any).parentNode
      if (pa) {
        let selected = (pa.querySelector(".active") as HTMLElement)
        selected.scrollIntoView({block:"center"})
      }
    }
    
    function incrementPointer() {
      if (expandedGroup && generalSuggestions[expandedGroup].suggestions.length) {
        if ((listPointer + 1) < generalSuggestions[expandedGroup].suggestions.length) {
          setListPointer((old) => old + 1);
        }} else {
          let max = Object.keys(generalSuggestions).filter((a) => generalSuggestions[a].suggestions.length).length
          if ((listPointer + 1) < max) {
            setListPointer((old) => old + 1)
          }
        }
      }
      
      function decrementPointer() {
        if (listPointer > 0) {
          setListPointer((old) => old - 1);
        }
      }
      
      switch (keyEvt.key) {
        case "ArrowDown":
        keyEvt.preventDefault()
        incrementPointer();
        focusSelected();
        break;
        case "ArrowUp":
        keyEvt.preventDefault()
        decrementPointer();
        focusSelected();
        break;
        case "Enter":
        setListPointer(0)
        if (expandedGroup) {
          onSelectSuggestion(generalSuggestions[expandedGroup].suggestions[listPointer]);
        } else {
          let x = Object.keys(generalSuggestions).filter((a) => generalSuggestions[a].suggestions.length).find((a, idx) => idx == listPointer)
          if (x) {
            setExpandedGroup(x)
            setListPointer(0)
          }
        }
        break;
        case "Escape": {
          if (expandedGroup) {
            setExpandedGroup("")
            setListPointer(Math.max(Object.keys(generalSuggestions).filter((a) => generalSuggestions[a].suggestions.length).findIndex((a) => a == expandedGroup),0))
            keyEvt.preventDefault()
          }
          break;
        }
        case "Backspace": {
          if (keyEvt.target["value"] == "") {
            setContext({})
            setExpandedGroup("")
            setShowGeneralSuggestions(false);
          }
          break
        }
        default:
        break;
      }
    }
    
    /**
    * Conditionally adds new suggestions to list. Filters late responses. ('race condition')
    * @param result Result to potentially add.
    */
    const updateSuggestion = (result: SuggestionsGroup) => {
      // Discard any old responses from earlier searches ('race condition')
      if (result.sequenceNumber == sequenceNumberRef.current) {
        setGeneralSuggestions((gs) => {
          return({...gs, [result.key] : result});
        });
      }
    }
    
    if (!props.showWindow) {
      return <></>
    }
    
    return (
      <div className="SearchBarGeneral">
      
      {Object.values(context)[0] ?
        <button onClick={() => {
          setContext({})
          setSearchValue('');
          generalAutocomplete();
        }}>{context && Object.values(context)[0].betegnelse}</button>
        : <></> 
      }
      <input
      id={props.id}
      ref={inputRef}
      type="search"
      value={searchValue}
      onFocus={() => {
        setShowGeneralSuggestions(true);
      }}
      onBlur={() => {
        generalListHideOnBlur()
      }}
      onChange={(e) => {
        setShowGeneralSuggestions(true);
        let val = e.target.value;
        setSearchValue(val);
        if (val == "") {
          setExpandedGroup("")
        }
      }}
      autoComplete="off"
      placeholder={Localization.getText("Search")}
      onKeyDown={key => move(key)}
      />
      <SuggestionPopupList 
      showWindow={showGeneralSuggestions} 
      selectedElement={listPointer} 
      expandedGroup={expandedGroup} 
      generalSuggestions={generalSuggestions}
      onSuggestion={(a) => {
        if ("group" in a) {
          setExpandedGroup(a.group);
          if (a.group == "") {
            setListPointer(Math.max(Object.keys(generalSuggestions).filter((a) => generalSuggestions[a].suggestions.length).findIndex((a) => a == expandedGroup),0))
          }
        }
        if ("suggestion" in a) {
          setSearchValue("")
          onSelectSuggestion(a.suggestion);
        }
      }}  
      />
      </div>
    );
  }
  
  
  function ImgMatchesGroup(props: {iconKey:string}) {
    switch (props.iconKey) {
      case "Cadastral Region":
        return <img src="SearchIcons/Ejerlav.svg" ></img>
      case "Cadaster":
        return <img src="SearchIcons/Matrikel.svg" ></img>
      case "Street name":
        return <img src="SearchIcons/Vej.svg" ></img>
      case "Place name":
        return <img src="SearchIcons/stednavn.svg" ></img>
      case "Address":
        return <img src="SearchIcons/Adresse.svg" ></img>
      case "CVR no":
        return <img src="SearchIcons/cvr_nr.svg" ></img>
      case "Company":
        return <img src="SearchIcons/cvr_navn_(virksomhed).svg" ></img>
      case "CVR Person":
        return <img src="SearchIcons/cvr_person.svg" ></img>
      case "Owner name":
        return <img src="SearchIcons/Ejernavn_(personer_virksomheder).svg" ></img>
      case "BFE":
        return <></>
      case "Favorite layers":
        return <img src="SearchIcons/favoritlag.svg" ></img>
      case "Layers":
        return <img src="SearchIcons/featurelag.svg" ></img>
      case "Special layers":
        return <img src="SearchIcons/speciallag.svg" ></img>
    }
    return <></>
  }
  
  function SuggestionPopupList(props: { showWindow: boolean, expandedGroup:string, selectedElement: number | undefined, generalSuggestions: {[key:string]:SuggestionsGroup}, onSuggestion }) {
    return props.showWindow ? (
      <div className="general-autocomplete-suggestion-window" >
      <div className="general-autocomplete-suggestion-scrollpane">
      <GetGeneralListItems expandedGroup={props.expandedGroup} selectedElement={props.selectedElement} generalSuggestions={props.generalSuggestions} onSuggestion={props.onSuggestion} />
      </div>
      </div>
    ) : null;
  }
  
  
  function GetGeneralListItems(props:{expandedGroup:string, selectedElement: number | undefined, generalSuggestions: {[key:string]:SuggestionsGroup}, onSuggestion}) {
    let indexOffset = 0;
    let previousCount = 0;
    if (props.expandedGroup) {
      return <ShowSearchResultGroup generalSuggestions={props.generalSuggestions[props.expandedGroup]} indexOffset={0} selectedElement={props.selectedElement} onSuggestion={props.onSuggestion} />
    }
    
    return (
      <>
      {Object.keys(props.generalSuggestions).map((key, idx) => {
        let sg = props.generalSuggestions[key];
        if (idx > 0) {
          indexOffset += previousCount;
        }
        previousCount = sg.suggestions.length ? 1 : 0;
          return <GroupPreview key={sg.key} generalSuggestions={sg} indexOffset={indexOffset} selectedElement={props.selectedElement} onSuggestion={props.onSuggestion} />
      })}
      </>
    );
  }
  
  function GroupPreview(props: {generalSuggestions: SuggestionsGroup, indexOffset, selectedElement, onSuggestion}) {
    let groupName = props.generalSuggestions.title.key;
    return props.generalSuggestions.suggestions.length > 0 ? (
      <div className={"general-autocomplete-suggestion-group compact" + (props.indexOffset === props.selectedElement ? " active" : "")} key={groupName}
      onMouseDown={(ev) => {
        props.onSuggestion({group: props.generalSuggestions.key})
        ev.preventDefault();
      }}
      >
      <div className="general-autocomplete-suggestion-header">
      <ImgMatchesGroup iconKey={groupName} />
      
        <>
        <h4 style={{whiteSpace:"nowrap"}}>{Localization.getText(groupName)}</h4>
        <span className='queryCount'>{props.generalSuggestions.suggestions.length}</span>
        <div className="info"><b>{Localization.getText("See all")}</b></div>
        </>
      {/* } >
        <h4 style={{whiteSpace:"nowrap"}}>{Localization.getText(groupName)} ({props.generalSuggestions.suggestions.length})</h4>
      </ProtectedFeature> */}
      </div>
      <ul className="general-autocomplete-list">
      {props.generalSuggestions.suggestions.filter((a, idx) => idx < 3).map((suggestion, idx) => {
        let checkItem = false
        if (suggestion.checked !== undefined) {
          checkItem = true
        }

        return (
          <li className={"general-autocomplete-suggestion" + (checkItem ? " checked-"+suggestion.checked : "")}
          key={idx}
          onMouseDown={(ev) => {
            props.onSuggestion({suggestion: suggestion})
            ev.stopPropagation()
            ev.preventDefault()
          }} >
            {checkItem ? <GlassCheckbox checked={suggestion.checked} /> : <></>}
            {suggestion.betegnelse}
          </li>
        );
      }
    )
  }
  </ul>
  
  </div>
) : null;


}

function ShowSearchResultGroup (props:{generalSuggestions:SuggestionsGroup, indexOffset:number, selectedElement:number|undefined, onSuggestion}) {
  let groupName = props.generalSuggestions.title.key; // ToDo: Translate...
  return (
    <div className="general-autocomplete-suggestion-group">
    <div className="general-autocomplete-suggestion-header" onMouseDown={(ev) => {
      props.onSuggestion({group:""})
      ev.preventDefault();
    }} >
    <ImgMatchesGroup iconKey={groupName} />
    <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={
        <>
        <h4 style={{whiteSpace:"nowrap"}}>{Localization.getText(groupName)}</h4>
        <span className='queryCount'>{props.generalSuggestions.suggestions.length}</span>
        <div className="info"><b>{Localization.getText("Show less")}:</b> Esc <TiArrowBack className='info' /></div>
        </>
      } >
        <h4 style={{whiteSpace:"nowrap"}}>{Localization.getText(groupName)} ({props.generalSuggestions.suggestions.length})</h4>
      </ProtectedFeature>
    </div>
    <ul className="general-autocomplete-list">
    {props.generalSuggestions.suggestions.map((suggestion, idx) => {
      let checkItem = false
      if (suggestion.checked !== undefined) {
        checkItem = true
      }
      return  (
        <li 
        key={idx}
        className={"general-autocomplete-suggestion" + (checkItem ? " checked-"+suggestion.checked : "") + (idx === props.selectedElement ? " active":"")}
        onMouseDown={(ev) => {
          props.onSuggestion({suggestion: suggestion})
        }}
        >
        {checkItem ? <GlassCheckbox checked={suggestion.checked} /> : <></>}
        {suggestion.betegnelse}
        </li>
      )
    }
  )
}
</ul>
<ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<></>} >
  <div onMouseDown={(ev) => {
      props.onSuggestion({group:""})
      ev.preventDefault();
    }} className="info" style={{cursor:"pointer"}}><b>{Localization.getText("Show less")}</b></div>
</ProtectedFeature>
</div>
)

}