/**
 * Encaptulates functionality to store and retrieve BNBO data from the data store
 */
import { ESInterface } from "src/compentsModus/ESInterface";
import { BNBOProject, ProjectsPersistenceInterface, SFEPersistenceInterface } from "./BNBOState";


export type SFERecord = any;

class WorkQueue {
   private lastJob: Promise<void> | undefined
 
   async queueWork(work: () => Promise<void>): Promise<void> {
     const nextJob = this.lastJob = this.lastJob?.then(work, _ => {}) ?? work()
     try {
       return await nextJob
     } finally {
       // ensure previous results can be garbage collected
       if (nextJob === this.lastJob) { this.lastJob = undefined }
     }
   }
 }
class ObjectVersionRegister {
   objectVersions = new Map<string, number>();

   getObjectVersion(objectId:string):number {
      return this.objectVersions.get(objectId) || 0;
   }

   setObjectVersion(objectId:string, version:number) {
      if (version < (this.objectVersions.get(objectId) || 0)) {
         throw new Error(`Version number must not be lower than the current version number. ${objectId} ${version} ${this.objectVersions.get(objectId)}`);
      }
      this.objectVersions.set(objectId, version);
   }

   clearObjectVersion(objectId:string) {
      this.objectVersions.delete(objectId);
   }

   clearAllObjectVersions() {
      this.objectVersions.clear();
   }
}

class OptimisticLockingWrapper {
   es:ESInterface;
   versionRegister:ObjectVersionRegister;
   getIdColumn:(data:any)=>string
   workQueue = new WorkQueue();
   userId?:string;

   constructor(es:ESInterface, versionRegister:ObjectVersionRegister, getIdColumn:(data:any)=>string, userId?:string) {
      this.es = es;
      this.versionRegister = versionRegister;
      this.getIdColumn = getIdColumn;
      this.userId = userId;
   }

   async updateDocument(documentId:string, document:any) {
      try {
         await this.workQueue.queueWork(() => this._updateDocument(documentId, document));
         // this._updateDocument(documentId, document);
      }
      catch (error:any) {
         console.log("Error in updateDocument: " + error.message);
         throw error;
      }
   }

   async _updateDocument(documentId:string, document:any) {
      let versionNumberWhenRead = this.versionRegister.getObjectVersion(documentId);
      if (versionNumberWhenRead) {
         // Check if the version number is still the same
         // If not, return an error
         let currentDb = await this.es.getDocument(documentId);
         versionNumberWhenRead = this.versionRegister.getObjectVersion(documentId);
         if (currentDb && currentDb._version != versionNumberWhenRead) {
            let doc = currentDb._source;
            // Todo: refresh the version number and data
            throw new Error(`A newer version of this document exists, by user ${doc.viamapUserId}. Cannot update.`);
         }
      }
      document.viamapUserId = this.userId;
      let record = await this.es.updateDocument(documentId, document);
      record._version && this.versionRegister.setObjectVersion(documentId, record._version);                
   }

   async getDocument(documentId:string) { 
      let record = await this.es.getDocument(documentId, {});     
      record._version && this.versionRegister.setObjectVersion(documentId, record._version);              
      return record._source;
   }

   async deleteDocument(documentId:string) {
      await this.es.deleteDocument(documentId);  
      this.versionRegister.clearObjectVersion(documentId);              
   }

   async listDocuments() {
      let res = await this.es.doSearch({},{},5000);    
      if (res && res.length > 0) {
         res.forEach((record:any) => {
            record._version && this.versionRegister.setObjectVersion(this.getIdColumn(record), record._version);
         });
      }
      return res;
   }

   async emptyTheIndex(indexName:string) {
      await this.es.emptyTheIndex(indexName);
      this.versionRegister.clearAllObjectVersions();
   }  

}
   

   export class BNBOProjectsPersistence implements ProjectsPersistenceInterface {

      projectsESIndexName;
      es:ESInterface;
      versions
      dw; 

      static instance:BNBOProjectsPersistence;

      static setInstance(instance: BNBOProjectsPersistence) {  
         BNBOProjectsPersistence.instance = instance;
      }

      static getInstance():BNBOProjectsPersistence {
         if (!BNBOProjectsPersistence.instance) {
           throw new Error("BNBOProjectsPersistence not initialized");
         }
         return BNBOProjectsPersistence.instance;
      }
      
      constructor(projectsESIndexName:string, userId:string) {
         this.projectsESIndexName = projectsESIndexName; 
         this.es = new ESInterface(this.projectsESIndexName)
         this.versions = new ObjectVersionRegister();
         this.dw = new OptimisticLockingWrapper(this.es, this.versions, (data) => data.id, userId);
      }
   
      listProjects(filter:any): Promise<BNBOProject[]> {
         // Retrieve projects from the data store
         return new Promise<BNBOProject[]>(async (resolve, reject) => {
            try {
               let res = await this.dw.listDocuments(); 
               if (res && res.length > 0) {
                  resolve(res as BNBOProject[]);
               } else {
                  resolve([]);
               }            
            } catch (error) {
               reject(error);
            }
         })
      }
      
      async getProject(projectId: string): Promise<BNBOProject> {
         // Retrieve project from the data store
         return new Promise<BNBOProject>(async (resolve, reject) => {
            try {
               let record = await this.dw.getDocument(projectId, {});                     
               resolve(record as BNBOProject);
            } catch (error) {
               reject(error);
            }
         })
      }

      async saveProject(project: BNBOProject): Promise<void> {
         // Save project to the data store
         return new Promise<void>(async (resolve, reject) => {
            try {
               await this.dw.updateDocument(project.id, project);
               resolve();
            } catch (error) {
               reject(error);
            }
         })
      }

      async createProject(project: BNBOProject): Promise<void> {
         // Save project to the data store
         this.saveProject(project)
         .then(() => {
            // Create sfe/lodsejer database for project
            let indexMappings =
            {
               "properties": {
                  "lodsejer": {
                  "type": "text"
                  },
                  "matrikler": {
                  "type": "text"
                  },
                  "markeDele": {
                  "type": "text"
                  },
                  "marker": {
                  "type": "text"
                  },
                  "områder": {
                  "type": "text"
                  },
                  "bnboer": {
                  "type": "text"
                  },
                  "dele": {
                  "type": "text"
                  },
               }
            }
            this.es.createIndex(project.esIndexName, { mappings: { ...indexMappings, "_meta": { "CreatedBy": "klaus bech" } } })
               .then((res) => {
                  // alert("Got result " + JSON.stringify(res));
               })
               .catch((error) => {
                  alert("Got error " + (error.message || error));
               })
            })
      }

      async deleteProject(projectId: string): Promise<void> {
         // Save project to the data store
         return new Promise<void>(async (resolve, reject) => {
            try {
               await this.dw.deleteDocument(projectId);           
               resolve();
            } catch (error) {
               reject(error);
            }
         })
      }

   }

   export class BNBOSFEPersistence implements SFEPersistenceInterface {
      
      ESIndexName;
      es:ESInterface;
      versions;
      dw;

      static instance:BNBOSFEPersistence;

      static setInstance(instance: BNBOSFEPersistence) {
         BNBOSFEPersistence.instance = instance;
      }

      static getInstance():BNBOSFEPersistence {
         if (!BNBOSFEPersistence.instance) {
            throw new Error("BNBOSFEPersistence not initialized");
         }
         return BNBOSFEPersistence.instance;
      }

      constructor(ESIndexName:string, userId?:string) {
         this.ESIndexName = ESIndexName; 
         this.es = new ESInterface(this.ESIndexName)
         this.versions = new ObjectVersionRegister();
         this.dw = new OptimisticLockingWrapper(this.es, this.versions, (data) => data.lodsejerid, userId);
      }

      listSFEs(): Promise<SFERecord[]> {
         // Retrieve SFEs from the data store
         // Retrieve projects from the data store
         return new Promise<SFERecord[]>(async (resolve, reject) => {
            try {
               let res = await this.dw.listDocuments();    
               if (res && res.length > 0) {
                  resolve(res as SFERecord[]);
               } else {
                  resolve([]);
               }            
            } catch (error) {
               reject(error);
            }
         })
      }  

      removeAllSFEs(): Promise<void> {
         // Remove all sfes from mindex but do not delete index
         return new Promise<void>(async (resolve, reject) => {
            try {
               await this.dw.emptyTheIndex(this.ESIndexName);               
               resolve();
            } catch (error) {
               reject(error);
            }
         })
      }  

      getSFE(sfeId: string): Promise<SFERecord> { 
         // Retrieve SFE from the data store
         return new Promise<SFERecord>(async (resolve, reject) => {
            try {
               let record = await this.dw.getDocument(sfeId, {});               
               resolve(record as SFERecord);
            } catch (error) {
               reject(error);
            }
         })
      }

      saveSFE(sfeId: string, sfe: SFERecord): Promise<void> {
         // Save SFE to the data store
         return new Promise<void>(async (resolve, reject) => {
            try {
               await this.dw.updateDocument(sfeId, sfe);             
               resolve();
            } catch (error) {
               reject(error);
            }
         })
      }     

      deleteSFE(sfeId: string): Promise<void> {
         // Delete SFE from the data store
         return new Promise<void>(async (resolve, reject) => {
            try {
               await this.dw.deleteDocument(sfeId);             
               resolve();
            } catch (error) {
               reject(error);
            }
         })
      }
   }
