import { Spinner } from "react-bootstrap";
import {
  Localization,
  AppMessagesContext,
  actionSetInfoMessage,
  actionSetProgressMessage,
} from "@viamap/viamap2-common";
import "./PropertyInformationDialog.css";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import {
  ProjectContext,
  handleToBeAdded,
} from "src/states/ProjectState";
import { AddressInterface } from "src/managers/AddressInterface";
import { ProtectedFeature } from "./ProtectedFeature";
import { PropertyInfoView } from "src/propertyInfoTemplates/PropertyInfoView";
import { FiCopy, FiPrinter } from "react-icons/fi";
import { MitStorage } from "src/managers/MitStorage";
import { ViewButtonCheck, ViewButtonIcon, ViewButtonText } from "src/propertyInfoTemplates/PropertyComponents";
import { ExportData } from "src/managers/ExportData";
import { PropertyData } from "src/propertyInfoTemplates/PropertyInfoCollectorTypes";
import { SheetFunc } from "src/managers/SheetFunc";
import { ADModalBodyProp, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { useState, useContext, useEffect } from "react";
import { BsPip } from "react-icons/bs";
import { closeWindow, WindowId } from "src/WindowManager/WindowState";
import { useWindowContext } from "src/WindowManager/useWindowContext";

type Props = {
  showWindow: number;
  callBackOnClose: () => void;
  labelLatLng?: { latlng?: { lat: number; lng: number } };
  allowExtendedInformation: boolean;
  cols?: number;
};

export function PropertyInformationDialog(props: Props) {
  const [propertyDefaultCollapsed, setPropertyDefaultCollapsed] =
    useState(MitStorage.getValue<Boolean>("PropertyAutoColapsed"));
  const { hasAccessToFeature } = useContext(ApplicationStateContext);
  const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
  const { dispatch: windowDispatch } = useWindowContext();
  const { state: projState, dispatch: projDispatch } =
    useContext(ProjectContext);

  const [isLoading, setIsLoading] = useState(true);
  const [propertyData, setPropertyData] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
  }, [props.labelLatLng]);

  if (!props.showWindow) {
    return <></>;
  }

  function RemberCollapseSettings(e: any, val) {
    MitStorage.setValue<Boolean>("PropertyAutoColapsed", !val);
  }

  async function handleExportToExcel() {
    let wb = await (new ExportData()).createPropertyDataExcelExport(
      "Property Export",
      new Date(), 
      propertyData,         
      (progress:number) => {
        appMessageDispatch(actionSetProgressMessage("Export", Math.round(100*progress/1)));
     });
     let blob = SheetFunc.createBlob(wb);
     let fileName = "PropertyData"+(propertyData?.BBRRel?.bfeNummer || "");
     fileName += ".xlsx";
     ExportData.downloadBlob(fileName, blob);
  }

  let fold = (
      <ViewButtonCheck
        checked={!propertyDefaultCollapsed}
        className="no-print"
        onCheck={(e, val) => {
          RemberCollapseSettings(e, val);
          setPropertyDefaultCollapsed(val);
        }}
      >
        {Localization.getText("Expanded")}
      </ViewButtonCheck>
  );

  return (
    <AdvancedDragModal
      title={"Ejendom"}
      topUpdate={props.showWindow}  
      allowUserMiniMize={true}
      allowFullscreen={true}
      PosDefault={{width:"800px", height: "calc(100% - 50px)", top: "50px", left: "50%", transform:"translateX(-50%)"}}
      PosDefaultNS={{width:"calc( 100% - 30px )", height: "calc(100% - 50px)", top: "50px", left:"15px"}}
      saveKey="PropertyInfo"
      PositionToSave={{width:true, height:true, top:true, left:true}}
      variant="NSPadding"
      onClose={() => {
        windowDispatch(closeWindow(WindowId.PropertyInformationDialog))}}
      subHeaderContent={
        <>
          <h4 className="no-print"></h4>
          <ProtectedFeature
            feature={Feature.AddToProjectUnassignedList}
            contentsIfNoAccess={<></>}
          >
            <ViewButtonText
              className="no-print"
              onClick={async (e) => {
                if (projState.isEmpty) {
                  appMessageDispatch(
                    actionSetInfoMessage(
                      Localization.getText(
                        "Please visit the Project tabs, an action is needed!"
                      )
                    )
                  );
                  return;
                }
                if (
                  props.labelLatLng?.latlng?.lat &&
                  props.labelLatLng?.latlng?.lng
                ) {
                  let resp = await AddressInterface.reverseGeocodeFull(
                    props.labelLatLng?.latlng.lat,
                    props.labelLatLng?.latlng.lng
                  );
                  let addrData = await resp.json();
                  let jordstykke = await (
                    await fetch(addrData.jordstykke.href)
                  ).json();

                  let fullAddress = addrData.adressebetegnelse;
                  let tmp = fullAddress.split(",");
                  let streetAndNumber = tmp[0] || fullAddress;
                  let zipAndCity =
                    addrData.postnummer.nr + " " + addrData.postnummer.navn;

                  projDispatch(
                    handleToBeAdded([{bfeNr:jordstykke.bfenummer,name:streetAndNumber + ", " + zipAndCity ,latLng:[props.labelLatLng?.latlng.lat,props.labelLatLng?.latlng.lng]}])
                  );
                  return;
                }
              }}
            >
              {Localization.getText("Add to Project")}
            </ViewButtonText>
          </ProtectedFeature>
          <ProtectedFeature feature={Feature.ExplorerToExcel} contentsIfNoAccess={<></>} >
            {isLoading ? <></> 
            :
            <ViewButtonIcon 
            disabled={false}
            title={Localization.getText("Export to Excel")} className="no-print" onClick={(e) => handleExportToExcel()} ><img src="./images/NewSecSVG/excel.svg"></img></ViewButtonIcon>
            }
         </ProtectedFeature>
          
          <ProtectedFeature
            feature={Feature.OpenPropetyInfoDocument}
            contentsIfNoAccess={<></>}
          >
            
            <ViewButtonIcon 
              className="no-print"
              onClick={() =>
                window.open(
                  `/propertyinfo/?latLng=${props.labelLatLng?.latlng?.lat},${props.labelLatLng?.latlng?.lng}`,
                  "_blank"
                )
              }
              title={Localization.getText("Popout")}
            >
              <FiCopy />
            </ViewButtonIcon>
          </ProtectedFeature>
          <ProtectedFeature
            feature={Feature.PrintPropertyInfo}
            contentsIfNoAccess={<></>}
          >
            <>
              {!(isLoading) ? (
                <>
                <ViewButtonIcon
                  className="no-print"
                  onClick={() => {
                    setTimeout(() => {
                      print();
                    },100)
                  }}
                  title={Localization.getText("Print")}
                >
                <FiPrinter />
                </ViewButtonIcon>
                </>
              ) : (
                <Spinner size="sm" />
              )}
            </>
          </ProtectedFeature>
          {fold}
        </>
      }
    >
      {/* <div className="mit-printable-modal" > */}
      {/* <PrintHeader/> */}
      <ADModalBodyProp>
      {(props.labelLatLng?.latlng && (
          <PropertyInfoView
            key={props.labelLatLng.latlng.lat.toString()}
            foldetUd={!propertyDefaultCollapsed}
            cols={1}
            labelLatLng={props.labelLatLng}
            callBackOnLoaded={(data:PropertyData) => {
              setIsLoading(false);
              setPropertyData(data);
            }}
            allowExtendedInformation={true}
          />
      )) ||
        "Missing Dot"}
      </ADModalBodyProp>
    </AdvancedDragModal>
  );
}
