import { MapFacadeMaplibre } from "src/managers/MapFacadeMaplibre";
import { sendParams, WindowId, openWindow } from "src/WindowManager/WindowState";
import { EjerlavAutocomplete, VejAutocomplete, MatrikelAutocomplete, AddressAutocomplete, CVRNumberAutocomplete, CVRNavnAutocomplete, CVRPersonAutocomplete, EjerNavnAutocomplete, StednavnAutoComplete, FeatureLayerAutoComplete, AddressBoundingBoxAutocomplete } from "src/managers/SearchBarGeneralPlugin";
import { MapitState, MapitStateContext } from "src/states/MapitState";
import { SearchBarGeneral, SearchBarGeneralPlugin } from "./SearchBarGeneral";
import { useContext, useMemo } from "react";
import { point } from "@turf/helpers";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { AutocompleterKeys } from "src/common/managers/Types";
import { MapDescriptionButton } from "./NavigationBarMicro";
import { BsArrowLeftRight } from "react-icons/bs";

export function DeepLinkNavigationBar(props: {onCollapse: () => void}) {
   const {state: mapitState} = useContext(MapitStateContext);

   const xData = mapitState.mapInfo?.searchBarAutocompleters;
   const boundingBox = mapitState.mapInfo?.boundingBoxWhenSaved;
   const autoCompleters:SearchBarGeneralPlugin[] = useMemo(() => {
      return (xData || []).map((ac) => {
         return getAutocompleterByKey(ac, mapitState.map, mapitState.mapInfo?.boundingBoxWhenSaved || undefined);
      });
   },[xData, mapitState.map]);

   return (
      <>
   <div className="NavigationTopBar small" id="Mit-NavigationTopBar" style={{zIndex:1,pointerEvents:"none"}}>
      <div className={"OnMapButton Collapse"} onClick={() => props.onCollapse()} >
        <BsArrowLeftRight />
      </div>
      {xData?.length ?
      <SearchBarGeneral
          showWindow={true}
          autocompleters={autoCompleters}
          callbackOnSelection = {(type:string, props:any) => { alert (`got callback Type:${type} props: ${JSON.stringify(props)}`)} }
          />
         : null}
      
      </div>
      <div style={{zIndex:1, position:"absolute", right:"0px", padding:"10px", gap:"10px", display:"flex", flexDirection:"column", alignItems:"center"}}>
      <MapDescriptionButton />
</div>
</>
   )
}



function getAutocompleterByKey(key: AutocompleterKeys, map: MapitState["map"], boundingBox?:[[number, number],[number, number]]):SearchBarGeneralPlugin {
   switch (key) {
      case "matrikel": return new MatrikelAutocomplete((data:any)=> {
         zoomToCadaster(data.matrikelnummer, data.ejerlavskode, map);
      });
      case "adresse": return new AddressAutocomplete((data:any)=> {
         let wgsCoords = data.geometri.coordinates[0].toReversed();
         map.zoomToData(point([wgsCoords[1], wgsCoords[0]]), 150);
      });
      case "lokaladresse": return new AddressBoundingBoxAutocomplete((data:any)=> {
         let wgsCoords = data.geometri.coordinates[0].toReversed();
         map.zoomToData(point([wgsCoords[1], wgsCoords[0]]), 150);
      }, boundingBox);
      case "stednavn": return new StednavnAutoComplete((data:any) => {
         (map as MapFacadeMaplibre).zoomToData(data.bbox, 150)
      });
   }
}

async function zoomToCadaster(matrNr:string, ejerlavskode:string, map: MapitState["map"]) {
   let res = await PropertyInfoInterface.getGeojsonOfCadasters([{matrikelNr: matrNr, ejerlavKode:Number(ejerlavskode)}])
   map.zoomToData(res, 150);
}