
import { AutomaticUserHelpPopup, SessionContext } from "@viamap/viamap2-common";
import { useContext } from "react"
import { QuickOrthoPhoto } from "src/components/QuickOrthoPhoto";
import { WindowDisplayer } from "src/WindowManager/WindowDisplayer";

export const MapitButtonsAndOverlays = () => {
  const { state: sessionState } = useContext(SessionContext);
  return (
    <>
      <AutomaticUserHelpPopup
        sessionState={sessionState}
      />
      <WindowDisplayer />
      <QuickOrthoPhoto />
      {/* <ReturnTo17 /> */}
    </>
  )
}
