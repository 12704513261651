import { createContext } from "react";
import { Actions, ManagedWindow, WindowId, WindowState } from "./WindowState";

type Window = ManagedWindow;
export const initialWindowState: ManagedWindow[] = [
   {id: WindowId.ChatWindow, state: WindowState.Closed, updateCounter: 0, alwayMounted: true},
]



export const windowContext = createContext<{ state: ManagedWindow[], dispatch: (action: Actions) => void }>({ state: initialWindowState, dispatch: () => {} });

   