import { useContext, useEffect } from "react"
import { MitWebsocket } from "./websocket"
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";

export function WebsocketManager() {
   const { hasAccessToFeature } = useContext(ApplicationStateContext)
   
   if (!hasAccessToFeature(Feature.Websocket)) {
      return (
         null
      )
   }

   return (
      <WebsocketEnabler /> 
   )
}

function WebsocketEnabler() {

   useEffect(() => {
      MitWebsocket.getInstance()

      return () => {
         MitWebsocket.closeInstance()
      }
   }, []);

   return <></>
}