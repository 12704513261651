import { useState } from "react"
import { Actions, windowReducer, ManagedWindow } from "./WindowState"
import { initialWindowState, windowContext } from "./windowContext";

export function WindowManager(props: {children: any}) {
   let [state, _dispatch] = useState<ManagedWindow[]>(initialWindowState);

   function dispatch(action: Actions) {
      _dispatch((a) => windowReducer({windows: a}, action).windows)
   }

   return (
      <windowContext.Provider value={{state, dispatch}}>
         {props.children}
      </windowContext.Provider>
   )
}
