import { FeatureHelpFile, MainApplicationFrame, SessionContext } from "@viamap/viamap2-common"
import { useContext, useEffect, useState } from "react";
import { ApplicationStateContext } from "src/states/ApplicationState";

export type Props = {
   applicationName: string,
   productNameAndVersion: string,
   children: any,
   newUserInformation: FeatureHelpFile,
   releaseInformation: FeatureHelpFile,
   hideLicenseInformation?: boolean,
   hideNavbar?: boolean,
   disableAutomaticUserHelpPopup?: boolean
}

/**
 * Wrapper for MainApplicationFrame that filters the help file by access rights. 
 * Such that items marked to be shown only to users with access rights are removed from the help file.
 * @param props 
 * @returns 
 */
export const MainApplicationFrameFilterHelpByAccessRights = (props: Props) => {
   const { hasAccessToFeature } = useContext(ApplicationStateContext);
   const {state: sessionState} = useContext(SessionContext);
   const [releaseInformation, setReleaseInformation] = useState<any>(props.releaseInformation);
   const [newUserInformation, setNewUserInformation] = useState<any>(props.newUserInformation);
   
   useEffect(() => {

      setReleaseInformation(filterHelpFileByAccessRights(props.releaseInformation));
      setNewUserInformation(filterHelpFileByAccessRights(props.newUserInformation));
      
   },[sessionState.customerRef, sessionState.userRef])

   function filterHelpFileByAccessRights(helpFile: FeatureHelpFile): FeatureHelpFile {
      return helpFile.map((release) => {
         return {
            ...release,
            featureInfo: release.featureInfo.map((feature) => {
               if (feature.licenseFeature && (feature as any).onlyShowIfHasAccess) {
                  try {
                     if (hasAccessToFeature(feature.licenseFeature)) {
                        return feature;
                     }
                  } catch (e) {
                     console.error(e);
                  }
               } else {
                  return feature;
               }
            }).filter((f) => f !== undefined)
         }
      });
   }

   return (
      <>
         <MainApplicationFrame
            productNameAndVersion={props.productNameAndVersion}
            applicationName={props.applicationName}
            releaseInformation={releaseInformation}
            newUserInformation={newUserInformation}
            hideNavbar={props.hideNavbar}
            disableAutomaticUserHelpPopup={props.disableAutomaticUserHelpPopup}
         >
            {props.children}
         </MainApplicationFrame>
      </>
   );
}