import { Tabs, Tab, Stack } from "react-bootstrap";
import { Localization, SessionContext, SettingsManager } from "@viamap/viamap2-common";
import { MapitStateContext } from "src/states/MapitState";
import { LayerInfo, LayerType } from "src/common/managers/Types";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { ADModalBody, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassButton, GlassButtonRow } from "../components/GlassButtons";
import { GlassInputControl, GlassInputGroup, GlassTextInputTransformer } from "../components/GlassInput";
import { message } from "../components/Message";
import { createPortal } from "react-dom";
import { GlassInfo } from "src/components/MitGlassComponents";
import { ProtectedFeature } from "src/components/ProtectedFeature";
import { keyValueObject } from "src/components/SettingsEditor";
import { Feature } from "src/states/ApplicationStateFeatures";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, WindowId } from "src/WindowManager/WindowState";
import { BNBOContext, initialize, ProcesStatus, selectProject, BNBOProject, addProject, updateProject, loadProjects, deleteProject, LoadedBNBOState, loadDataForGeoJson, BNBOUserAccessRights, getMyAccessRights } from "./BNBOState";
import { BNBOProjectEditor } from "./BNBOProjectEditor";
import { KeyValueTable } from "src/propertyInfoTemplates/PropertyComponents";
import { ConfirmationDialog } from "src/components/ConfirmationDialog";
import { BNBOConst } from "./BNBOConst";
import { ApplicationStateContext } from "src/states/ApplicationState";

type Props = {
  showWindow: number;
  onFormClose: () => void;
};

export function BnboDialog(props: Props) {
  const { state: bnboState, dispatch: bnboDispatch } = useContext(BNBOContext);
  const { hasAccessToFeature } = useContext(ApplicationStateContext);
  const {state: sessionState} = useContext(SessionContext);
  // let {dispatch: appMessageDispatch} = useContext(AppMessagesContext);
  const { dispatch: windowDispatch } = useWindowContext();

  const { state: mapitState, dispatch: mapitStateDispatch} = React.useContext(MapitStateContext);
  const [availableLayers, setAvailableLayers] = useState<any>([]);
  const [selectedLayerId, setSelectedLayerId] = useState<number>();
  const [tagName, setTagName] = useState<string>();
  const [projectId, setProjectId] = useState<string>();
  const [projectName, setProjectName] = useState<string>();
  const [esIndexName, setEsIndexName] = useState<string>();
  const [showProjectEditor, setShowProjectEditor] = useState<BNBOProject | null>(null);
  const [newProject, setNewProject] = useState<boolean>(false);
  const [peer, setPeer] = useState<any>();
  const [confirmDelete, setConfirmDelete] = useState<string | undefined>();
  const [emptyProject, setEmptyProject] = useState<BNBOProject>({
    id: "",
    name: "",
    description: "",
    esIndexName: "",
    ownerUser: sessionState.userRef,
    ownerOrganization: sessionState.customerRef,
    settings: {},
    active: true,
    allowedUsersWithRoles: {},
    allowedOrganizationsWithRoles: {},
    protected: false
  });



  useEffect(() => {
    // Keep a list of available layers with Dgunr property
    let relevantLayers = Object.values(mapitState.layers).filter((layerInfo: LayerInfo) => {
      return (layerInfo.propertiesInGeoJson?.map((prop) => prop.toLowerCase()).includes("dgunr")) && (layerInfo.type === LayerType.GeoJSON_Polygon)
    }
    );
    let relevantLayerKeys = relevantLayers.map((lr) => { return lr.layerId }).sort();
    let availableLayerKeys = availableLayers.map((lr) => { return lr.layerId }).sort();
    // only update if there are new layers - not on any (e.g. data or styling change)
    if (!(relevantLayerKeys + "" === availableLayerKeys + "")) {
      setAvailableLayers(relevantLayers);
      if (relevantLayers && relevantLayers.length > 0) {
        let found = relevantLayers.find((lr) => lr.layerId === selectedLayerId);
        if (!found) {
          // default to first available layer
          setSelectedLayerId(relevantLayers[0].layerId);
        }
      } else {
        setSelectedLayerId(undefined);
      }
    }
  }, [mapitState.layers]);

  useEffect(() => {
    // Load Projects State
    if (!bnboState.isInitialized) {
       bnboDispatch(initialize(SettingsManager.getSystemSetting("bnbo.projectsElasticIndex", ""),sessionState.userName));
    }
 },[])

 useEffect(() => {
  
 setEmptyProject({
    id: "",
    name: "",
    description: "",
    esIndexName: "",
    ownerUser: sessionState.userRef,
    ownerOrganization: sessionState.customerRef,
    settings: {},
    active: true,
    allowedUsersWithRoles: {[sessionState.userRef]: [BNBOUserAccessRights.Admin]},
    allowedOrganizationsWithRoles: {},
    protected: false
  })

 },[sessionState.customerRef, sessionState.userRef])

  if (!props.showWindow) {
    return <></>
  }

  function isProcessActive(status: ProcesStatus) {
    return ProcesStatusToIndex(bnboState.procesStatus) < ProcesStatusToIndex(status)
  }


  const SelectLayer = (props: { title: string, onSelect: (value: number | undefined) => void }) => {
    return (
      <>
      <GlassInputControl>
        <select onChange={e => {
          let layerId: number | undefined = Number.parseInt(e.target.value);
          if (Number.isNaN(layerId)) {
            layerId = undefined;
          }
          props.onSelect(layerId);
        }}
          // className="form-control input-sm"
          style={{ width: "auto" }}
          value={selectedLayerId}>
          <option key={"default"} value={""} >{Localization.getText("PointSource:ChooseALayer")}</option>
          {availableLayers.map((layerInfo: LayerInfo, index) => {
            return (
              <option key={"" + layerInfo.layerId + index} value={layerInfo.layerId}>{layerInfo.datasetname}</option>
            );
          })}
        </select>
        </GlassInputControl>
      </>
    )
  }

  const FormControlElement = (props: { labelElement, controlElement }) => {
    return (
      <GlassTextInputTransformer label={props.labelElement} >
        {props.controlElement}
      </GlassTextInputTransformer>
    )
  }

  function filterProjectsByAccessRights(project: BNBOProject): boolean {
    if (hasAccessToFeature(Feature.BNBOToolGlobalAdmin)) {
      return true;
    };
    let accessRights = getMyAccessRights(project, sessionState.userRef, sessionState.customerRef);
    if (accessRights && accessRights.includes(BNBOUserAccessRights.Admin)) {
      return true;
    }
    return false;
  }

  const ProjectsList = () => {

    return (
      <>
        <Stack gap={3}>
          {/* <h3>Projects. Selected = {bnboState.selectedProject ? bnboState.selectedProject.name : ""}</h3> */}
          <KeyValueTable>
            <tbody>
              <tr className="bold">
                <td>Id</td>
                <td>Project</td>
                <td>Protected</td>
                {/* <td>Status</td> */}
                {/* <td>Description</td> */}
                <td>ESIndexName</td>
                {/* <td>AllowedUserRoles</td>
                <td>Settings</td> */}
                <td>Actions</td>
              </tr>
              {(bnboState.availableProjectsList || []).filter(filterProjectsByAccessRights).map((project, idx) => {
                return (
                  <tr key={project.id+idx}>
                    <td>{project.id}</td>
                    <td>{project.name}</td>
                    <td>{project.protected ? "yes" : ""}</td>
                    {/* <td>{project.active ? "active" : "inactive"}</td> */}
                    {/* <td>{project.description}</td> */}
                    <td>{project.esIndexName}</td>
                    {/* <td>{JSON.stringify(project.allowedUsersWithRoles)}</td>
                    <td>{JSON.stringify(project.settings)}</td> */}
                    <td>
                      <GlassButtonRow>
                      <GlassButton
                        onClick={() => {
                          windowDispatch(closeWindow(WindowId.BNBOSingleElement));
                          windowDispatch(closeWindow(WindowId.BNBOAreaEditor));
                          windowDispatch(closeWindow(WindowId.BNBOAreaViewer));
                          windowDispatch(closeWindow(WindowId.BNBOOpgørelse));
                          bnboDispatch(selectProject(project.id));
                          let p = bnboState.availableProjectsList?.find((a) => a.id === project.id);
                          if (p) {
                            setEsIndexName(p.esIndexName);
                            setProjectId(p.id);
                            setProjectName(p.name);
                          }
                        }}>Select</GlassButton>
                        <GlassButton
                        onClick={() => {
                          // Load projects in case any has changed by other user
                          bnboDispatch(loadProjects((availableProjectsList:BNBOProject[]) => {
                            setShowProjectEditor(availableProjectsList?.find((a) => a.id === project.id) || null);
                          }));
                        }}>Edit</GlassButton>
                              {!project.protected ? (
                                  <GlassButton
                  onClick={() => {
                    setConfirmDelete(project.id)
                  }}>Delete
                  </GlassButton>
                              ) : null}
                        </GlassButtonRow>
                    </td>
                  </tr>
                )
              })
              }
            </tbody>
          </KeyValueTable>
        </Stack>
      </>
    )
  }

  const handleAddProject = async (projectId, project: BNBOProject) => {
    let projectsElasticIndex = SettingsManager.getSystemSetting("bnbo.projectsElasticIndex", undefined);

    // Settings value for new project is default value from BNBOConst settings
    let defaultSettings = BNBOConst.settings.reduce((acc, setting) => {
      acc[setting.key] = setting.default;
      return acc;
    }, {} as keyValueObject);
    let defaultValues: BNBOProject = {
      ...project,
      esIndexName: projectsElasticIndex + "_" + projectId,
      ownerUser: "kbe@viamap.net",
      ownerOrganization: "viamap.net",
      settings: defaultSettings,
      active: true,
    }
    bnboDispatch(addProject(projectId, defaultValues));    
  }

  function nameOnBlur(evt: any) {
    evt.preventDefault();
    setTagName(evt.target.value);
  }

  return (
    <AdvancedDragModal
      PosDefault={{ width: "1000px", left: "50%", transform: "translateX(-50%)", top: "1rem" }}
      title={"BNBO Databaseadministration"}
      onClose={(e) => windowDispatch(closeWindow(WindowId.BNBODialog))}
    >
      <ADModalBody>
        <Tabs defaultActiveKey={"BnboProjects"}>
          <Tab eventKey={"BnboArea"} title={"Indlæsning"} >
            
            <br />
            <GlassInfo>
              <h5>Vælg det datalag med BNBO'er der skal indlæses data for (påvirkede ejendomme)</h5>
              <h6>NB: Lag skal indeholde property 'Dgunr' for at kunne bruges</h6>
            </GlassInfo>
            <GlassInputGroup>
              <GlassTextInputTransformer label={"Projekt Navn"} >
                <GlassInputControl>
                <input disabled={true} value={bnboState.selectedProject?.name || "Vælg projekt under Projekter"} />
                </GlassInputControl>
              </GlassTextInputTransformer>
              <FormControlElement
                labelElement={(
                  <>{Localization.getText("MultiPointDataLayer")}</>
                )}
                controlElement={(
                  <SelectLayer
                    title={Localization.getText("MultiPointDataLayer")}
                    onSelect={(layerId: any) => {
                      setSelectedLayerId(layerId);
                    }} />
                )}
              />
              <GlassTextInputTransformer label={"Tag som tilføjes importerde ejendomme (optionelt)"}>
                <GlassInputControl>
                  <input
                    placeholder={"tagnavn (selvvalgt)"}
                    type='text'
                    value={tagName || ""}
                    onChange={(evt) => nameOnBlur(evt)}
                    autoComplete="off"
                  />
                </GlassInputControl>
              </GlassTextInputTransformer>
            </GlassInputGroup>
            <br />
            <ProtectedFeature feature={Feature.BNBOToolAdmin}>
              <GlassButton
                disabled={bnboState.selectedProject?.protected}
                onClick={() => {
                  runImport("Total Loader BNBO data", true);
                }}>FullLoad BNBO DataForLayer (replace all)</GlassButton>
            </ProtectedFeature>
            {" "}
            <ProtectedFeature feature={Feature.BNBOToolAdmin}>
              <GlassButton
                onClick={() => {
                  runImport("Tilføjer BNBO data", false);
                }}>Add BNBO DataForLayer</GlassButton>
            </ProtectedFeature>
          </Tab>

          <Tab eventKey={"BnboProjects"} title={"Projekter"}>
            <ProtectedFeature feature={Feature.BNBOToolAdmin}>
              <Stack gap={3}>
                <ProjectsList />
                {/* <VLine />
                <GlassInputControl>
                  <input
                    placeholder={"projectId"}
                    type='text'
                    value={projectId || ""}
                    onChange={(evt) => {
                      evt.preventDefault();
                      setProjectId(evt.target.value);
                    }}
                    autoComplete="off"
                  />
                  <input
                    placeholder={"projectName"}
                    type='text'
                    value={projectName || ""}
                    onChange={(evt) => {
                      evt.preventDefault();
                      setProjectName(evt.target.value);
                    }}
                    autoComplete="off"
                  />
                  <input
                    placeholder={"esDbName"}
                    type='text'
                    value={esIndexName || ""}
                    onChange={(evt) => {
                      evt.preventDefault();
                      setEsIndexName(evt.target.value);
                    }}
                    autoComplete="off"
                  />
                </GlassInputControl> */}
                <GlassButtonRow >
                <GlassButton
                  onClick={() => {
                    bnboDispatch(loadProjects());
                  }}>Load projects</GlassButton>
                <GlassButton
                  onClick={() => {
                    setNewProject(true);
                  }}>New Project</GlassButton>
                {/* <GlassButton
                  onClick={() => {
                    if (projectId && projectName && esIndexName) {
                      let p = bnboState.availableProjectsList?.find((a) => a.id === projectId);
                      if (p) {
                        p.name = projectName;
                        p.esIndexName = esIndexName;
                        bnboDispatch(updateProject(projectId, p));
                      }
                    }
                  }}>Update Project</GlassButton> */}
                
              </GlassButtonRow>
              </Stack>
            </ProtectedFeature>
          </Tab>

          {/* <Tab eventKey={"BnboPersistence"} title={"Persistence"}>
            <ProtectedFeature feature={Feature.BNBOToolAdmin}>
              <GlassButton onClick={() => {
                handleCreateIndex("bnbo20241016");
              }}>
                Create index
              </GlassButton>
              <GlassButton
                onClick={() => {
                  bnboDispatch(persistState(bnboState, "bnbo20241016", () => { alert("done") }));
                }}>Gem State i Elasticsearch</GlassButton>
              <GlassButton
                onClick={() => {
                  bnboDispatch(loadSFEState("bnbo20241016", () => { alert("done") }));
                }}>Load State fra Elasticsearch</GlassButton>
              <GlassButton
                onClick={async () => {
                  let es = new ESInterface("bnbo20241016");
                  let x = await es.getDocument("1305395", {});
                  console.log(x);
                  alert(JSON.stringify(x));
                }}>Load One Property fra Elasticsearch</GlassButton>
            </ProtectedFeature>
          </Tab> */}
{/*
          <Tab eventKey={"BnboAreaViewer"} title={"P2P"}>
            <ProtectedFeature feature={Feature.Debugging}>

              <GlassButton
                onClick={() => {
                  const peer = new Peer("bnbo_guldborgsund");
                  setPeer(peer);
                  peer.on("error", (err) => {
                    console.error(err);
                    alert(err);
                  });
                  peer.on("connection", (conn) => {
                    conn.on("data", (data) => {
                      // Will print 'hi!'
                      console.log(data);
                      alert(data);
                    });
                    conn.on("open", () => {
                      conn.send("hello!");
                    });
                  });
                  let conn = peer.connect("bnbo_guldborgsund");
                }}>Create p2p</GlassButton>
              <GlassButton
                onClick={() => {
                  const peer2 = new Peer("bnbo_guldborgsund");
                  peer2.on("error", (err) => {
                    console.error(err);
                    alert(err);
                  });
                  peer2.on("connection", (conn) => {

                    conn.on("open", () => {
                      conn.send("hi!");
                    });
                  });
                  // const conn = peer.connect("bnbo_guldborgsund");

                  // conn.on("open", () => {
                  //   conn.send("hi!");
                  // });
                  let conn2 = peer2.connect("bnbo_guldborgsund");
                }}>Send p2p</GlassButton>

            </ProtectedFeature>
          </Tab>
*/}
        </Tabs>
      </ADModalBody>
      {showProjectEditor ? createPortal(
        <BNBOProjectEditor value={showProjectEditor} show={true} onClose={function (): void {
          setShowProjectEditor(null);
        } } onChange={function (project:BNBOProject): void {
          bnboDispatch(updateProject(project.id, project));
          setShowProjectEditor(null);
        } }/>
      , document.getElementById("Mit-NonBlockingOverlay")||document.body) : null}
      {newProject ? createPortal(
        <BNBOProjectEditor title="New Project" value={emptyProject} show={true} onClose={function (): void {
          setNewProject(false);
        } } onChange={function (project:BNBOProject): void {
          message.info("New project created");
          handleAddProject(project.id, project);
          setNewProject(false);
        } }/>
      , document.getElementById("Mit-NonBlockingOverlay")||document.body) : null}
      {confirmDelete ? createPortal(
        <ConfirmationDialog showWindow={1} header="Delete Project" 
        onSubmit={(e) => {
          bnboDispatch(deleteProject(confirmDelete));
          setConfirmDelete(undefined);
        }}
        onCancel={(e) => {
          setConfirmDelete(undefined);
        }} >
          <>
        Er du sikker på at du vil slette projektet {confirmDelete}?
          </>
        </ConfirmationDialog>      
      , document.getElementById("Mit-NonBlockingOverlay")||document.body) : null}

    </AdvancedDragModal>
  )

  function runImport(title: string, replaceAll: boolean) {
    if (bnboState.selectedProject === undefined) {
      message.error("Vælg et projekt først");
      return;
    }
    if (replaceAll && bnboState.selectedProject?.protected) {
      message.error("Project is protected. cannot replace all data");
      return;
    }
    let li: LayerInfo = mapitState.layers[selectedLayerId!];
    let geoJson = li.geoJson;
    let count = geoJson.features.length;
    let importTags = tagName ? [tagName] : [];
    let loadedState: LoadedBNBOState = {
      lodsejerRegister: bnboState.lodsejer,
      lodsejerBNBOer: bnboState.bnboer,
      lodsejerMarker: bnboState.marker,
      lodsejerMatrikler: bnboState.matrikler,
      markDele: bnboState.markDele,
      dele: bnboState.dele
    };
    message.progress(`${title} for ${count} ${count > 1 ? "områder" : "område"}`, 0);
    bnboDispatch(loadDataForGeoJson(geoJson, replaceAll, importTags, loadedState, (info) => {
      message.progress("");
      message.info(`${info.count} ejendomme indlæst`);
      // Hide this window
      windowDispatch(closeWindow(WindowId.BNBODialog));
    }, (error) => {
      message.progress("");
      message.info("");
      message.error(`Fejlede ${error}`);
    },
      (progressPct) => {
        message.progress(`${title} for ${count} ${count > 1 ? "områder" : "område"}`, progressPct);
      }));
  }
}

function ProcesStatusToIndex(status: ProcesStatus) {
  return [
    ProcesStatus.Start,
    ProcesStatus.ServitutArealValgt,
    ProcesStatus.ErstatningsArealValgt,
    ProcesStatus.ErstatningBeregnet,
  ].findIndex((a) => a == status)
}
