import { useContext } from "react";
import { windowContext } from "./windowContext";
import { closeWindow, ManagedWindow, openWindow, WindowId, WindowState } from "./WindowState";

export function useWindowContext() {
   return {...useContext(windowContext)};
}

export function toggleWindow(state:ManagedWindow[], id: WindowId, options?: {}) {
   if (state.find(w => w.id === id)?.state == WindowState.Open) {
      return closeWindow(id);
   }
   return openWindow(id, options);
}

export function isWindowOpen(state:ManagedWindow[], id: WindowId) {
   return state.find(w => w.id === id)?.state == WindowState.Open;
}

export function getWindowState(state:ManagedWindow[], id: WindowId) {
   return state.find(w => w.id === id)?.state;
}