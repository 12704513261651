import { AppMessagesContext, actionSetProgressMessage, Localization } from "@viamap/viamap2-common";
import { Spinner } from "react-bootstrap";
import { FiPrinter } from "react-icons/fi";
import { ProtectedFeature } from "src/components/ProtectedFeature";
import { ExportData } from "src/managers/ExportData";
import { MitStorage } from "src/managers/MitStorage";
import { SheetFunc } from "src/managers/SheetFunc";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { ProjectContext } from "src/states/ProjectState";
import { ViewButtonCheck, ViewButtonIcon } from "./PropertyComponents";
import { PropertyData } from "./PropertyInfoCollectorTypes";
import { Feature } from "src/states/ApplicationStateFeatures";
import { CondominiumView } from "./CondominiumView";
import { AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { useState, useContext, useEffect } from "react";

type Props = {
    showWindow: number;
    callBackOnClose: () => void;
    bfeNr?:{bfeNr: string | number};
    allowExtendedInformation: boolean;
  };
  
  export function CondominiumDialog(props: Props) {
    const [propertyDefaultCollapsed, setPropertyDefaultCollapsed] =
      useState(MitStorage.getValue<Boolean>("EjerlejlighedAutoColapsed"));
    const { hasAccessToFeature } = useContext(ApplicationStateContext);
    const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
    const { state: projState, dispatch: projDispatch } =
      useContext(ProjectContext);
  
    const [isLoading, setIsLoading] = useState(true);
    const [propertyData, setPropertyData] = useState<any>();
  
    useEffect(() => {
      setIsLoading(true);
    }, [props.bfeNr]);
  
    if (!props.showWindow) {
      return;
    }
  
    function RemberCollapseSettings(e: any, val) {
      MitStorage.setValue<Boolean>("EjerlejlighedAutoColapsed", !val);
    }
  
    // MARK: Fix
    async function handleExportToExcel() {
      let wb = await (new ExportData()).createPropertyDataExcelExport(
        "Property Export",
        new Date(), 
        propertyData,         
        (progress:number) => {
          appMessageDispatch(actionSetProgressMessage("Export", Math.round(100*progress/1)));
       });
       let blob = SheetFunc.createBlob(wb);
       let fileName = "PropertyData"+(propertyData?.BBRRel?.bfeNummer || "");
       fileName += ".xlsx";
       ExportData.downloadBlob(fileName, blob);
    }
  
    let fold = (
        <ViewButtonCheck
          checked={!propertyDefaultCollapsed}
          className="no-print"
          onCheck={(e, val) => {
            RemberCollapseSettings(e, val);
            setPropertyDefaultCollapsed(val);
          }}
        >
          {Localization.getText("Expanded")}
        </ViewButtonCheck>
    );

    if (!props.showWindow) {
      return <></>
    }
  
    return (
      <AdvancedDragModal
        onClose={props.callBackOnClose}
        title={Localization.getText("Condominium")}
        PosDefault={{width:"800px", height: "calc(100% - 50px)", top: "50px", left: "50%", transform:"translateX(-50%)"}}
        subHeaderContent={

          <>
            <h4 className="no-print">{}</h4>
            <ProtectedFeature
              feature={Feature.NotActive}
              contentsIfNoAccess={<></>}
            >
                Tilføj til Projekt
            </ProtectedFeature>
            <ProtectedFeature
              feature={Feature.PrintPropertyInfo}
              contentsIfNoAccess={<></>}
            >
              <>
                {!isLoading ? (
                  <>
                  <ViewButtonIcon
                    className="no-print"
                    onClick={() => {
                      print();
                    }}
                    title={Localization.getText("Print")}
                  >
                  <FiPrinter />
                  </ViewButtonIcon>
                  </>
                ) : (
                  <Spinner size="sm" />
                )}
              </>
            </ProtectedFeature>
            {fold}
          </>
        }
      >
        
  
        {(props.bfeNr && (
            <CondominiumView
              key={props.bfeNr.bfeNr}
              foldetUd={!propertyDefaultCollapsed}
              cols={1}
              bfeNr={props.bfeNr.bfeNr} 
              callBackOnLoaded={(data:PropertyData) => {
                setIsLoading(false);
                setPropertyData(data);
              }}
              allowExtendedInformation={true}
              />
        )) ||
        "Missing Dot"}
      </AdvancedDragModal>
    );
    // 706899 Bygning på fremmedgrund
  }
  