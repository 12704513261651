import { useEffect, useState } from "react";
import { MitWebsocket } from "./websocket";
import { basicErrorHandler, message } from "./Message";
import { ADModalBody, ADModalBodyNoScroll, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassInputControl } from "./GlassInput";
import { ManagedWindow, WindowState } from "src/WindowManager/WindowState";

export function ChatWindow(props: {window:ManagedWindow, onClose:() => void}) {
   const [msg, setMsg] = useState<{sender:string, msg:string}[]>([]) 

   useEffect(() => {
      const onMessage = (evt : { action: "broadcast"; data: string}) => {
         try {
            const jData = evt
            setMsg((oldMsg) => {
               if ("data" in jData && typeof jData.data == "string") {
               return [...oldMsg, {sender:"unknown", msg:jData.data}]
               }
               return oldMsg
            });
         } catch (e) {
            basicErrorHandler(e as any)
         }
      }

      MitWebsocket.subscribe("broadcast", onMessage)
      return () => {
         MitWebsocket.unsubscribe("broadcast", onMessage)
      }  
   },[])

   if (props.window.state !== WindowState.Open) {
      return null
   }


   return (
      <AdvancedDragModal title="Chat" window={props.window} onClose={props.onClose}
         PosDefault={{left: "97%", top: "40px", height: "50%", transform: "translate(-97%, 0)"}}
      >
         <ADModalBody>
            <div >
               {msg.map((m,i) => {
                  return <div key={i}>{m.sender}: {m.msg}</div>
               })}
            </div>
         </ADModalBody>
         <ADModalFooter>
            <GlassInputControl>
               <input type="text" onKeyDown={(e) => {
                  if (e.key == "Enter" && e.currentTarget.value && e.currentTarget.value.trim() != "") {
                     let msg = e.currentTarget.value;
                     MitWebsocket.send({action:"broadcast", data:msg})
                     setMsg((oldMsg) => [...oldMsg, {sender:"me", msg:msg}])
                     e.currentTarget.value = ""
                  }
               }} ></input>
            </GlassInputControl>
         </ADModalFooter>

      </AdvancedDragModal>



   )

}