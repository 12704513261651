import { FormGroup, Row, Form, Col } from 'react-bootstrap';
import {Localization, Logger, SettingsManager} from "@viamap/viamap2-common";
import {Utils} from '@viamap/viamap2-common';
import { GenerateGeom, SpatialReturnType } from '../managers/GenerateGeom';
import { useContext, useState } from 'react';
import { MitLatLng } from 'src/managers/MapFacade';
import { SelectCircle } from './SelectCircle';
import { actionGenerateDemographyReportSuccess, MapitStateContext } from 'src/states/MapitState';
import { ADModalBody, ADModalFooter, ADModalInfo, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassButton } from './GlassButtons';
import { message } from './Message';
import { AWSAPIGatewayInterface } from 'src/managers/AWSAPIGatewayInterface';
import { DemographyReportMetaData } from './DemographyReport';
import { LayerInfo } from 'src/common/managers/Types';
import { SheetFunc } from 'src/managers/SheetFunc';
import { point } from '@turf/helpers';
import { LayerFunc } from 'src/managers/LayerFunc';
import { closeWindow, openWindow, WindowId } from 'src/WindowManager/WindowState';
import { useWindowContext } from 'src/WindowManager/useWindowContext';

enum SelectionState { Initial, CenterSelected, RimSelected, WaitingForServer, EmptyArea}
enum SpatialFunctionMode { Report, Export }

type Props = {
    showWindow: number;
    callbackOnCancel: () => void;
    callbackOnReport?: (center:MitLatLng, distance:number, address?:string) => void;
    callbackOnExport?: (spatialReturn:SpatialReturnType, center:MitLatLng, distance:number) => void;
    mode:SpatialFunctionMode,
    maxDistanceMeters:number,
    minDistanceMeters: number
};

export function SpatialFunctionDialog(props:Props) {
   let DefaultDistance=50;
   let MinimumDistance=50; 

    let [distance, setDistance] = useState<number>(DefaultDistance);
    let [selectionState, setSelectionState] = useState<SelectionState>(SelectionState.Initial);
    let [coords, setCoords] = useState<MitLatLng>();
    const { state: mapitState } = useContext(MapitStateContext);

   function cleanup() {
    //   map && marker && marker.removeFrom(map);
    //   map && map.off("click", mapClickHandler, this);
    //   map && map.off("mousemove", mapMoveHandler, this);
    //   map && map.closePopup();
    //   setState({marker: undefined});
   }
   
   function onClickExport() {
      cleanup();
      if (coords) {
        let spatialReturn = GenerateGeom.spatialSelection(mapitState, coords, distance!);
        if (Object.keys(spatialReturn).length == 0) {
            setSelectionState(SelectionState.EmptyArea)
          } else  {
            props.callbackOnExport?.(spatialReturn, coords, distance!);
          }
      }
   }

   function onClickApply() {
      cleanup();
      setSelectionState(SelectionState.WaitingForServer);
      coords && props.callbackOnReport && props.callbackOnReport(coords, distance, "");
   }

   function onClickCancel() {
      cleanup();
      (props as any).onClose?.();
   }

   function validateForm():boolean {
    return Boolean(coords && distance);
   }

    if (props.showWindow) {
      
        let instruction;
        let infoBsStyle;
        switch(selectionState) {
            case SelectionState.Initial:
                instruction = Localization.getText("Click to mark center of area to report on");
                infoBsStyle="warning";
                break;
            case SelectionState.EmptyArea:
                instruction = Localization.getText("No points in the selected area");
                infoBsStyle="warning";
                break;
            case SelectionState.CenterSelected:
                instruction = Localization.getText("Stretch to size and click when done");
                infoBsStyle="info";
                break;
            case SelectionState.RimSelected:
                infoBsStyle="success";
                if (props.mode === SpatialFunctionMode.Export) {
                    instruction = Localization.getText("Press 'Spatial Export' or click on map to start over");
                    break;
                }
                instruction = Localization.getText("Press 'Report' button to generate report or click to start over");
                break;
            case SelectionState.WaitingForServer:
                instruction = (
                    <div className="mit-text-appear-95">
                    {Localization.getText("Wating for server to produce the results...")}
                    </div>);
                infoBsStyle="success";
                break;            
            default:
                throw Utils.createErrorEventObject("Unknown or unexpected state:"+selectionState);
        }

        let RenderCommitButton = props.mode === SpatialFunctionMode.Export ? (
            <GlassButton 
                onClick={()=> onClickExport()}
                disabled={!validateForm()}
            >
            {Localization.getText("Spatial Export")}
            </GlassButton>
        ) : (
            <GlassButton 
                onClick={()=> onClickApply()}
                disabled={!validateForm()}
            >
            {Localization.getText("Generate")}
            </GlassButton>
        );

        let HeadingText = props.mode === SpatialFunctionMode.Export ?
            Localization.getText("Spatial Export") : Localization.getText("Demography Report");

        return (

        <AdvancedDragModal title={HeadingText} onClose={()=> onClickCancel()}
            PosDefault={{left:"100px", top:"150px", width:"480px"}}
            variant={"NSDark"}
        >
           
            <ADModalInfo>{instruction} </ADModalInfo>
            <ADModalBody>
            <Form>

                    <FormGroup as={Row}
                        style={{marginBottom:"10px"}}
                        controlId="formBasicText"
                        >
                        {/* <Form.Label column sm={3}>{""}
                        </Form.Label> */}
                        <Col sm={9} style={{textAlign:"left"}}>
                       {coords ? (distance > MinimumDistance ? "Radius "+distance.toLocaleString(undefined, {maximumSignificantDigits:4})+" m" : "") : Localization.getText("(Click on map to mark center)")}
                        </Col>
                    </FormGroup>

            </Form>
            </ADModalBody>
            <ADModalFooter>
            {RenderCommitButton}
            </ADModalFooter>
            {selectionState !== SelectionState.WaitingForServer ? (
            <SelectCircle
            active={true}
            showWindow = {props.showWindow}
            minDistanceMeters = {props.minDistanceMeters}
            maxDistanceMeters = {props.maxDistanceMeters}
            callbackOnStatus = { (centerSelected, rimSelected, center, distanceMeters) => {
                setDistance(distanceMeters);
                setCoords(center);
                if (centerSelected) {
                    if (rimSelected)
                        setSelectionState(SelectionState.RimSelected);
                    else 
                        setSelectionState(SelectionState.CenterSelected);
                }
                else 
                    setSelectionState(SelectionState.Initial);
            }}
            callbackOnCircle = { (center, distanceMeters) => {
                setDistance(distanceMeters);
                setCoords(center);
            }}
            />) : null }
        </AdvancedDragModal>
        );
    } else {
        return(null);
    }
  }


export function GenerateReportDialog(props:any) {
    const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
    const { dispatch: windowDispatch } = useWindowContext();
    let map = mapitState.map

    
function callbackOnGenerateReport(center: MitLatLng, radiusMeters: number, address?: string) {
    Logger.logAction("MapScreen", "Demography Report", Utils.formatString("center {center} radius {radius} address {address}", { center: center.toString(), radius: radiusMeters, address: address || "" }));
    let utmCoords = GenerateGeom.convertFromLatLngToUTM({ lat: center.lat, lng: center.lng });

    let authHost = SettingsManager.getSystemSetting("ambitionAuthHost");
    let cloudHost = SettingsManager.getSystemSetting("ambitionApiHost");
    let clientId = SettingsManager.getSystemSetting("ambitionClientId");
    let clientSecret = SettingsManager.getSystemSetting("ambitionClientSecret");
    let dataGroup = SettingsManager.getSystemSetting("ambitionDataGroup");
    // ToDo: remove hardcoding
    dataGroup = 9;
    if (!(authHost && cloudHost && clientId && clientSecret && dataGroup)) {
      message.error(Localization.getText("Missing configuration data. Cannot run report"));
    } else {
      message.info(Localization.getText("Generating Report..."));

      let apiVersion = SettingsManager.getSystemSetting("mapitServicesVersion", "1.0");

      switch (apiVersion) {
        case "1.0":
          throw new Error("Not supported any more");
          break;
        case "2.0":
          let result3 = AWSAPIGatewayInterface.ambitionGetReportDataV2(authHost, clientId, clientSecret, cloudHost, utmCoords.x, utmCoords.y, radiusMeters, dataGroup).then(
            (success2) => {
              Logger.logInfo("MapScreen", "Demography Report", "ReportData:" + JSON.stringify(success2));

              /**
               * Customer Specific Report Logo for 360North
               */
              let demographyReportLogo = SettingsManager.getSystemSetting("demographyReportLogo");
              let demographyReportLanguageCode = Localization.getLanguage();

              let reportName = Localization.getTextSpecificLanguage(demographyReportLanguageCode, "Demography Report");
              let reportDate = new Date();

              let metaData: DemographyReportMetaData = {
                center: center,
                radiusMeters: radiusMeters,
                reportName: reportName,
                reportDate: reportDate,
                logo: demographyReportLogo,
                address: address,
                languageCode: demographyReportLanguageCode
              };

              mapitStateDispatch(actionGenerateDemographyReportSuccess(metaData, success2))
              windowDispatch(openWindow(WindowId.GenerateReportResultDialog));
              windowDispatch(closeWindow(WindowId.GenerateReportDialog));
              message.info()
            },
            (error2) => {
              message.error(Localization.getFormattedText("Report generation failed: {error}", { error: error2 }));
              //  setState({showGenerateReportDialog:false});
              windowDispatch(closeWindow(WindowId.GenerateReportDialog));
              message.info()
            }
          );
          break;
        default:
          throw Utils.createErrorEventObject("Unknown or unexpected mapitSystemServicesVersion: " + apiVersion);
      }

    }
  }



    return SpatialFunctionDialog({...props, callbackOnReport:callbackOnGenerateReport , mode:SpatialFunctionMode.Report, minDistanceMeters: 50, maxDistanceMeters: 10000});
}

export const SpatialExportDialog = (props:any) => {
        const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
        const { dispatch: windowDispatch } = useWindowContext();
    
      function SpatialSelectionToNewLayers(spatialSelection: SpatialReturnType) {
        let layersToAdd = Object.keys(spatialSelection).map((layerKey) => {
          let layer: LayerInfo = mapitState.layers[layerKey]
          if (!layer) {
            return
          }
          
          let IdsToInclude = spatialSelection[layerKey]
          if (layer.datasheet) {
            let linesToInclude = IdsToInclude.map((obj) => obj + 1);  // correct for 1 header row.
            let sheet = SheetFunc.filterWorkSheet(layer.datasheet!, linesToInclude);
            let data = SheetFunc.sheetToJson(sheet)
            
            const featuresToInclude = IdsToInclude.map((id) => layer.handle.source.data.features[id])
            const features = data.map((a,idx) => {
              const [lng, lat, ..._] = (featuresToInclude[idx].geometry.coordinates as number[])
              return point([lng,lat], a)
            })
            const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(features, layer.datasetname)
            return (newLayerInfo)
            
          }
          const featuresToInclude = IdsToInclude.map((id) => layer.geoJson.features[id])
          const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(featuresToInclude, layer.datasetname)
          return (newLayerInfo)
        })
        let layersToRename = layersToAdd.filter(a => a)
        if (layersToRename.length) {
          windowDispatch(openWindow(WindowId.NameOnCreation, {layers: layersToAdd}))
        }
        props.onClose()
      }
    


    return SpatialFunctionDialog({...props, callbackOnExport:SpatialSelectionToNewLayers, mode:SpatialFunctionMode.Export, minDistanceMeters: 50, maxDistanceMeters: 200000});
}




