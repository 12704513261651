import { LicensingContext, SettingsFixedPriorities, SettingsManager } from "@viamap/viamap2-common"
import { useContext, useEffect } from "react";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { MapitStateActionType, MapitStateContext } from "src/states/MapitState";
import variant from "../mit-product-variant-settings.json"

/** This componennt is added to application three at places where the license may change. This component forces a refresh of systems settings from the license
 * ToDo: Move to viamap2-common
*/
export const SystemSettingsUpdater = (props:{children:any}) => {
   const {state:licensingState} = useContext(LicensingContext);
   const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
   const { state: applicationState, dispatch: applicationDispatch, hasAccessToFeature } = useContext(ApplicationStateContext);
 
   
   
   // ToDo: problem with multi update. Runs before ordinary license.json settings gets updated from LicensingBoundary.tsx
   useEffect(() => {
      function doThing() {   
         if (licensingState.currentlicense) {
            let productVariantSettings = variant as any;
            let license = licensingState.currentlicense;
            SettingsManager.clearByPriority(SettingsFixedPriorities.LicenseSettings);
            SettingsManager.clearByPriority(SettingsFixedPriorities.ProductVariantSettings);
            if (license.productVariant && productVariantSettings && productVariantSettings[license.productVariant]) {
               SettingsManager.addSettings(productVariantSettings[license.productVariant], {filename:"productvariant settings updated:"+license.productVariant, priority:SettingsFixedPriorities.ProductVariantSettings});
            }
            if (license.settings && Object.keys(license.settings).length > 0) {
               SettingsManager.addSettings(license.settings, {filename:"licencesettings updated", priority:SettingsFixedPriorities.LicenseSettings});
            }
            // Update feature layer list as it reads from license/customer/user settings.
            mapitStateDispatch({type:MapitStateActionType.InitializeStandardLayers, payload:{hasAccessToFeatureFunction:hasAccessToFeature}});
         }
      }
      doThing()
   }, [licensingState.currentlicense]);

   return (
      <>
      {props.children}
      </>
   );
}