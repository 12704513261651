import { actionSetErrorMessage, AppMessagesContext, SessionContext } from '@viamap/viamap2-common';
import { ReactElement, useCallback, useContext, useEffect, useReducer } from 'react';
import { BNBOActions, BNBOContext, BNBOReducer, initialBNBOState, transactionalBNBOReducer, GenericTransactionManager, BNBOState, encodeLodsejerData, BNBOActionType, loadSFEState } from 'src/BNBOModule/BNBOState';
import { BNBOProjectsPersistence, BNBOSFEPersistence } from './BNBODataStore';

export function BNBOManager(props: { children: ReactElement }) {
   let { state: sessionState } = useContext(SessionContext);
   let { dispatch: appMessageDispatch } = useContext(AppMessagesContext);

   // Save State
   let PersistenceReducer = useCallback((state: BNBOState, action: BNBOActions) => {
      let nextState = BNBOReducer(state, action);
      asyncPersistenceReducer(state, nextState, action);
      return nextState
   }, [sessionState.customerRef, sessionState.userRef])

   const [bnboState, bnboDispatch] = useReducer(PersistenceReducer, initialBNBOState());

   useEffect(() => {
      // Load sfe's for the selected project
      if (bnboState.selectedProject && bnboState.selectedProject.id != bnboState.loadedProjectId) {
         bnboDispatch(loadSFEState(bnboState.selectedProject.esIndexName));
      }
   }, [bnboState.selectedProject]);

   function asyncPersistenceReducer(oldState: BNBOState, nextState: BNBOState, action: BNBOActions) {
      switch (action.type) {

         case BNBOActionType.AddProject: {
            BNBOProjectsPersistence.getInstance().createProject(action.payload.item)
               .catch((reason) => {
                  appMessageDispatch(actionSetErrorMessage("Failed to add project: " + reason.message))
               })
            break;
         }

         case BNBOActionType.UpdateProject:
            {
               BNBOProjectsPersistence.getInstance().saveProject(action.payload.item)
                  .catch((reason) => {
                     appMessageDispatch(actionSetErrorMessage("Failed to update project: " + reason.message))
                  })
               break;
            }

         case BNBOActionType.DeleteProject: {
            BNBOProjectsPersistence.getInstance().deleteProject(action.payload.id)
               .catch((reason) => {
                  appMessageDispatch(actionSetErrorMessage("Failed to delete project: " + reason.message))
               })
            break;
         }

         case BNBOActionType.AddLodsejerDel:
         case BNBOActionType.SetLodsejerTags:
         case BNBOActionType.SetLodsejerStatus:
         case BNBOActionType.SetLodsejerNote:
         case BNBOActionType.SetLodsejerSagsbehandler:
         case BNBOActionType.SetLodsejerErSpecialSats:
         case BNBOActionType.SetLodsejerSats:
         case BNBOActionType.SetMarkNote:
         case BNBOActionType.SetLodsejerForsyningsInfo:
         case BNBOActionType.SetLodsejerDele:
         case BNBOActionType.SetLodsejerBNBOInactive:
         case BNBOActionType.SetMarkDele: {
            if (nextState.selectedProject && nextState.selectedProject.esIndexName) {
               BNBOSFEPersistence.getInstance().saveSFE(action.payload.lodsejerId, encodeLodsejerData(action.payload.lodsejerId, nextState))
                  .catch((reason) => {
                     appMessageDispatch(actionSetErrorMessage("Failed to save SFE: " + reason.message))
                  })
            }
            break;
         }

         case BNBOActionType.ReplaceBNBOState: 
         case BNBOActionType.LoadDataForGeoJson:         
         {
            // full replace of all lodsejere. Persist all lodsejere
            if (nextState.selectedProject && nextState.selectedProject.esIndexName) {
               let sfePersistence = new BNBOSFEPersistence(nextState.selectedProject.esIndexName);
               sfePersistence.removeAllSFEs()
                  .then(async () => {
                     for (let lodsejerId of Object.keys(nextState.lodsejer)) {
                        await sfePersistence.saveSFE(lodsejerId, encodeLodsejerData(lodsejerId, nextState))
                           .catch((reason) => {
                              appMessageDispatch(actionSetErrorMessage("Failed to save SFE: " + reason.message))
                           })
                     }
                  })
                  .catch((reason) => {
                     appMessageDispatch(actionSetErrorMessage("Failed to empty SFE index: " + reason.message))
                  })
            }
         }

         default: {
            // No persistence needed
         }
      }
   }

   return (
      <BNBOContext.Provider
         value={{
            state: bnboState,
            dispatch: GenericTransactionManager.dispatchMiddleware<BNBOActions>(bnboDispatch, transactionalBNBOReducer),
         }}
      >
         {props.children}
      </BNBOContext.Provider>
   )
}





