import { PropertyInfoCollector } from "./PropertyInfoCollector";
import { PropertyData, ReadyData } from "./PropertyInfoCollectorTypes";
import "../css/propertyInfoStyle.css";
import {
  FloatingBox,
  GlassFoldUdBox,
  GroupSelector,
  KeyValueLine,
  KeyValuesBoxGrid,
  KeyValuesHorizontal,
  PropertyImageContainer,
  PropertyMapContainer,
  PropertyWarning,
  ShowSelectedGroup,
  ValueGroup,
  ViewButtonIcon,
} from "./PropertyComponents";
import { PropInfoGroupFormatters } from "./PropertyInfoGroupFormatters";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { PropertyInfoTextOnMap } from "src/components/PropertyInfoTextOnMap";
import { ObliqueTileImage } from "src/components/ObliqueViewer";
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import { MarketDataInterface } from "src/managers/MarketDataInterface";
import { Cookies } from "@viamap/viamap2-common";
import { ViamapKortUdsnitApi } from "src/managers/ViamapKortUdsnitApi";
import { MitStorage } from "src/managers/MitStorage";
import { BsGearFill } from "react-icons/bs";
import { FavEditor } from "./FavEditor";
import { GlassButton } from "src/components/GlassButtons";
import { Fragment, useContext, useEffect, useState } from "react";
import { feature } from "@turf/helpers";
import App from "src/HLED/App";
import { message } from "src/components/Message";
import { FiCopy } from "react-icons/fi";
import { ProtectedFeature } from "src/components/ProtectedFeature";

type Props = {
  callBackOnLoaded?: (data:PropertyData) => void;
  labelLatLng?: { latlng?: { lat: number; lng: number } };
  allowExtendedInformation: boolean;
  foldetUd?: boolean;
  cols?: number;
};

export function PropertyInfoView(props: Props) {
  const { hasAccessToFeature } = useContext(ApplicationStateContext);
  const [collector] = useState(
    new PropertyInfoCollector({
      lat: props.labelLatLng?.latlng?.lat,
      lng: props.labelLatLng?.latlng?.lng,
    })
  );

  function setSelectedGroup(string) {
    Cookies.setCookie("PropertyInfoSelectedTab",string)
    _setSelectedGroup(string)
  }

  const [selectedGroup, _setSelectedGroup] = useState(
    Cookies.getCookie("PropertyInfoSelectedTab") ||  (hasAccessToFeature(Feature.PropertyInfoFavorite) && "Favoritter") || "Ejendom"
  );
  const [availableFavorites, _setAvailableFavorites] = useState<{[fav:string]:string[]}>(
    MitStorage.getValue("PropertyFavorites") as {[fav:string]:string[]} || {"minFavorit":[]}
  )

  function setAvailableFavorites(res) {
    MitStorage.setValue("PropertyFavorites",res)
    _setAvailableFavorites(res)
  }
  

  const [selectFav, setSelectedFav] = useState<string>(
    MitStorage.getValue("PropertySelectedFav") as string || "minFavorit"
  )

  const [showFavEditor, setShowFavEditor] = useState(false);

  function setFavorite(key:string, Folds: string[]) {
    const aFav = availableFavorites
    let res = {...aFav}
    if (Folds.length == 0) {
      if (Object.keys(aFav).includes(key)) {
          delete res[key]
      } else {
          res = {...res, [key]:[]}
      }
    } else {
      res = {...res, [key]:Folds}
    }
    MitStorage.setValue("PropertyFavorites",res)
    _setAvailableFavorites(res)
  }
  

  const [availableGroup, setAvailableGroup] = useState(hasAccessToFeature(Feature.PropertyInfoFavorite) ? ["Favoritter"] : []);
  const [data, setData] = useState<Partial<PropertyData>>({});
  const [loading, setLoading] = useState(true)
  let [select, setSelect] = useState("Kontor");


  useEffect(() => {
    // getDataCache()
    getDataAsync();
  }, [hasAccessToFeature]);

  function getDataCache() {
    import("./TestData.json").then((a) => {
      setData(a.default as any as PropertyData);
    });
  }

  function getDataAsync() {
    function setOnCallback<T extends keyof PropertyData>(
      val: ReadyData<PropertyData[T]>,
      key: T
    ) {
      if (val.status == "Ready" && val.data) {
        setData((a) => {
          return { ...a, [key]: val.data };
        });
      }
    }

    collector.requestData("Mat2", setOnCallback);
    if (props.allowExtendedInformation) {
      collector.requestData("BBRRel", setOnCallback);
      collector.requestData("ViamapTingBog", setOnCallback);
      collector.requestData("BBRSFE", setOnCallback);
    }
    collector.requestData("Mat2Ejerlejlighed", setOnCallback);
    collector.requestData("ViamapEjendomData", setOnCallback);
    collector.requestData("Ejerlav", setOnCallback);
    collector.requestData("ViamapHandelsOplysning", setOnCallback);
    collector.requestData("EBRBFE", setOnCallback);
    collector.requestData("BBRGrund", setOnCallback);
    collector.requestData("BBRGrundTeknik", setOnCallback);
    collector.requestData("BBRBygTeknik", setOnCallback);
    collector.requestData("BBREnhedTeknik", setOnCallback);
    collector.requestData("Vurd", setOnCallback);
    collector.requestData("DHM_Overflade", setOnCallback);  
    collector.requestData("DHM_Terraen", setOnCallback);
    collector.requestData("PlanDataDelområde", setOnCallback);
    collector.requestData("PlanDataLokalPlan", setOnCallback);
    collector.requestData("PlanDataKommunePlan", setOnCallback);
    collector.requestData("DARAdresser", setOnCallback);
    collector.requestData("ViamapEjendomStats", setOnCallback);
    collector.requestData("BBRByg", setOnCallback);
    // collector.requestData("ViamapObliqueFoto", setOnCallback);
    collector.requestData("BBREnhed", setOnCallback);
    collector.requestData("PEnheder", setOnCallback);
    collector.requestData("ESearchEjerlejlighed", setOnCallback);

    // collector.requestData("PlanDataLokalPlanGeom", setOnCallback);

    if (hasAccessToFeature(Feature.PlandataAflyst)) {
      // collector.requestData("PlanDataKommunePlanAflyst", setOnCallback)
      collector.requestData("PlanDataLokalPlanAflyst", setOnCallback)
    }

    if (hasAccessToFeature(Feature.PlandataForslag)) {
      collector.requestData("PlanDataKommunePlanForslag", setOnCallback)
      collector.requestData("PlanDataLokalPlanForslag", setOnCallback)
    }

    if (hasAccessToFeature(Feature.KortUdsnitEjendom))
    collector.addRequest("MatUdsnit", {
      identDependency:"Matnr",
      paramTransformer(ident, data) {
        if ("matrikelNr" in ident ) {
          return [ident.ejerlavNr, ident.matrikelNr]
        }
        return []
      },
      func:ViamapKortUdsnitApi.mtrkInfo,
      hasThis: ViamapKortUdsnitApi,
      returnTransformer(value) {
        return value
      },
    },setOnCallback as any);
    
    if (hasAccessToFeature(Feature.KortUdsnitEjendom))
    collector.addRequest("BBRUdsnit", {
      identDependency:"Matnr",
      paramTransformer(ident, data) {
        if ("matrikelNr" in ident ) {
          return [ident.ejerlavNr, ident.matrikelNr]
        }
        return []
      },
      func:ViamapKortUdsnitApi.bbrMap,
      hasThis: ViamapKortUdsnitApi,
      returnTransformer(value) {
        return value
      },
    },setOnCallback as any);
    // collector.addRequest("BBrUdsnit", {
    //   paramTransformer(ident, data) {
    //     "mat" ident
    //   },
    //   hasThis: {},
    //   returnTransformer(value) {
    //     return value
    //   },
    // },setOnCallback)

    // POI'er
    // (SettingsManager.getSystemSetting("POIsInUse") as string[]).forEach((a) => {
    //   collector.AddRequest(`${a}-data`,{
    //     paramTransformer(ident, data) {
    //       if ("lat" in ident)
    //       return [a, ident.lat, ident.lng]
    //       return []
    //     },
    //     func: ViamapApi.callNearestPoi,
    //     hasThis: ViamapApi,
    //     returnTransformer(result) {
    //       return result
    //     }
    //   },
    //   SetCallback as any
    //   )
    // })

    if (
      hasAccessToFeature(Feature.ExplorerMarkedsData) &&
      props.allowExtendedInformation
    ) {
      ["Kontor", "Detail", "Industri", "Andet"].forEach((ejendomsType) => {
        collector.addRequest(
          "NewSec" + ejendomsType,
          {
            paramTransformer(ident, data) {
              const indexNameTxAct =
                SettingsManager.getSystemSetting("indexNameTxAct");
              const indexNameRent =
                SettingsManager.getSystemSetting("indexNameRent");
              const marketDataStartYear = SettingsManager.getSystemSetting(
                "marketDataStartYear"
              );
              const marketDataEndYear =
                SettingsManager.getSystemSetting("marketDataEndYear");
              const marketDataTargetCount = SettingsManager.getSystemSetting(
                "marketDataTargetCount"
              );
              const marketDataMaxDistance = SettingsManager.getSystemSetting(
                "marketDataMaxDistance"
              );
              if ("lat" in ident)
                return [
                  indexNameTxAct,
                  indexNameRent,
                  ident.lat,
                  ident.lng,
                  ejendomsType,
                  marketDataTargetCount,
                  marketDataMaxDistance,
                  marketDataStartYear,
                  marketDataEndYear,
                  true,
                ];
              return [];
            },
            func: MarketDataInterface.getMarketData,
            hasThis: MarketDataInterface,
            returnTransformer(value) {
              return value;
            },
          },
          setOnCallback as any
        );
      });
    }
    collector.callBackOnFinished((data:PropertyData) => {
      props.callBackOnLoaded?.(data);
      setLoading(false)
    });
  }

  if (Object.keys(data).length === 0) {
    return <>Loading...</>;
  }

  function PrintHeader() {
    let printDate = new Date().toLocaleDateString();

    return (
      <div className="only-print print-header" style={{}}>
        <div style={{ display: "inline-block" }}>
          <h4>Ejendomsinformation</h4>
          {/* <h5>Adresse: {data?.ViamapEjendomData?.ejdAdresse}</h5> */}
          <h5>Printed: {printDate}</h5>
        </div>
        {/* <div style={{float:"right", marginRight:"25px"}}> <div className={"mit-viamap-icon"} id={"mit-viamap-icon"}/></div> */}
      </div>
    );
  }

  if ((data.ViamapEjendomData?.antalMatrikler || 0) == 0 && !data.ViamapEjendomData?.ejdAdresse && !loading) {
    return (
      <PropertyWarning
      title={Localization.getText("No information found for this location")}
      subText={Localization.getText("Please try another location")}
      />
    )
  }


  return (
    <>
      {/* {loading} */}
      <PrintHeader />

      {/* <NavigationBarSimple title='Ejendoms Info' > */}
      {/* <div className='Paper-MitProp'> */}
      {/* <div className='Container-MitProp' style={{padding:"0.5cm"}}> */}
      {/* <ErrorMessage errors={collector.errors} /> */}
      {/* ----------- AREAL ------------ */}
      {SettingsManager.getSystemSetting("debuggingShowExtraDebugging") ?
        <GlassButton onClick={() => console.info(data)} >Log to console</GlassButton>
      : null}
      
      <KeyValuesHorizontal>
        {PropInfoGroupFormatters.Ejendommen(data,hasAccessToFeature(Feature.DeviceScreenSizePhone)).map((a) => {
          return (
            <Fragment key={a.key}>
            <KeyValueLine
              key={a.key}
              valKey={a.key}
              value={a.val}
              keepEmpty={true}
            />
            </Fragment>
          );
        })}
      </KeyValuesHorizontal>
      <GroupSelector
        selected={selectedGroup}
        group={availableGroup}
        onSelect={setSelectedGroup}
      />
      {selectedGroup == "Favoritter" ?
        <>
          <div style={{marginLeft:"auto", marginRight:"20px"}}>
          <GlassButton onClick={() => setShowFavEditor(true)} ><BsGearFill /> {Localization.getText("Edit")}</GlassButton>
          </div>
          <FavEditor show={showFavEditor} favBoxes={availableFavorites} setFavBoxes={setAvailableFavorites} onClose={() => setShowFavEditor(false)} />
        </>
      : null}
      <div className="Scroll-content">
        <ShowSelectedGroup
          keepSelected={true}
          loading={loading}
          columns={props.cols!}
          favBoxes={availableFavorites}
          favName={selectFav}
          group={selectedGroup}
          groupCallBack={(groups) =>
            setAvailableGroup([...new Set([...(hasAccessToFeature(Feature.PropertyInfoFavorite) ? ["Favoritter"] : []),"Ejendom", ...groups,"Alle"])])
          }
        >
          {(data.ViamapObliqueFoto) ? (
            <GlassFoldUdBox
              boxKey="Skråfoto"
              key="Skråfoto"
              title="Skråfoto"
              group="Overblik"
              foldetUd={props.foldetUd || false}
            >
              <PropertyImageContainer>
                {(data.ViamapObliqueFoto && (
                  <>
                    <ObliqueTileImage
                      point={data.ViamapObliqueFoto.point || [0, 0]}
                      image={data.ViamapObliqueFoto.image}
                      width={data.ViamapObliqueFoto.width || 200}
                      height={data.ViamapObliqueFoto.height || 200}
                      isLoading={false}
                      markerType={"Dot"}
                      alternativePoint={data.ViamapObliqueFoto.alternativePoint}
                      pictureDate={data.ViamapObliqueFoto.pictureDate}
                    />
                    <FloatingBox>
                      {new Intl.DateTimeFormat("da-dk").format(
                        data.ViamapObliqueFoto.pictureDate
                      )}
                    </FloatingBox>
                  </>
                )) || <></>}
              </PropertyImageContainer>
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {props.labelLatLng?.latlng?.lat ? (
            <GlassFoldUdBox
              key={"map"}
              title="Område"
              boxKey="Område"
              group="Overblik"
              foldetUd={props.foldetUd || false}
            >
              {props.labelLatLng?.latlng && (
                <PropertyMapContainer>
                  <PropertyInfoTextOnMap
                    lat={props.labelLatLng?.latlng?.lat}
                    lng={props.labelLatLng?.latlng?.lng}
                  />
                </PropertyMapContainer>
              )}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
                    {props.labelLatLng?.latlng?.lat ? (
            <GlassFoldUdBox
              key={"mapOrtofoto"}
              title="Ortofoto"
              boxKey="Ortofoto"
              group="Overblik"
              foldetUd={props.foldetUd || false}
            >
              {props.labelLatLng?.latlng && (
                <PropertyMapContainer>
                  <PropertyInfoTextOnMap
                    orthoPhoto={true}
                    lat={props.labelLatLng?.latlng?.lat}
                    lng={props.labelLatLng?.latlng?.lng}
                  />
                </PropertyMapContainer>
              )}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {data.ViamapEjendomData ? (
            <GlassFoldUdBox
              title="Arealer"
              boxKey="Arealer"
              group="Ejendom"
              foldetUd={props.foldetUd || false}
            >
              <KeyValuesBoxGrid minWidth="200px" maxCols={3}>
                {PropInfoGroupFormatters.ArealerGroup(data).map((a) => {
                  return (
                    <Fragment key={a.key}>
                    <KeyValueLine
                      key={a.key}
                      valKey={a.key}
                      value={a.val}
                      infoText={a.infoText}
                      keepEmpty={true}
                    />
                    </Fragment>
                  );
                })}
              </KeyValuesBoxGrid>
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {data.DHM_Terraen && data.DHM_Overflade ? (
            <GlassFoldUdBox
              title="Højder"
              boxKey="Højder"
              group="Ejendom"
              foldetUd={props.foldetUd || false}
            >
              <KeyValuesBoxGrid minWidth="150px">
                <KeyValueLine valKey="Højde, grund" value={data.DHM_Terraen} />
                <KeyValueLine
                  valKey="Højde, hus (omtrentligt)"
                  value={
                    data.DHM_Overflade &&
                    data.DHM_Terraen &&
                    data.DHM_Overflade - data.DHM_Terraen
                  }
                />
              </KeyValuesBoxGrid>
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {data.ViamapHandelsOplysning ? (
            <GlassFoldUdBox
              title="Handelsoplysninger"
              group="Ejendom"
              boxKey="Handelsoplysninger"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.HandelsOplysninger(data).map(
                (a, idx) => {
                  return (
                    <ValueGroup key={a[0].val + idx}>
                      {a.map((b) => (
                        <Fragment key={b.key}>
                        <KeyValueLine
                          key={b.key}
                          valKey={b.key}
                          value={b.val}
                        />
                        </Fragment>
                      ))}
                    </ValueGroup>
                  );
                }
              )}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {/* <FoldUdBox group='Ejendom' title='Ejendommen' foldetUd={props.foldetUd || false} >

  </FoldUdBox> */}
          {data.ViamapEjendomData ? (
            <GlassFoldUdBox
              title="Ejerforhold"
              group="Ejendom"
              boxKey="Ejerforhold"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.EjerforholdData(data).map((a, idx) => {
                return <Fragment key={idx}>{a}</Fragment>;
              })}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {data.BBRSFE ? (
            <GlassFoldUdBox
              title="Samlet Fast Ejendom"
              group="Ejendom"
              boxKey="Samlet Fast Ejendom"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.SFE(data)}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {true ? (
            <GlassFoldUdBox
              title="POI"
              group="POI"
              foldetUd={props.foldetUd || false}
            >
              {(props.labelLatLng?.latlng && (
                <App
                  className="no-print"
                  group="POI"
                  lngLat={props.labelLatLng.latlng}
                />
              )) || <></>}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}

          {"MatUdsnit" in data && data.MatUdsnit ? (
            <GlassFoldUdBox
              title="Mat Oversigt"
              group="Matrikler"
              boxKey="Mat Oversigt"
              foldetUd={false}
            >
              {PropInfoGroupFormatters.MatrikelKort(data.MatUdsnit)}
            </GlassFoldUdBox>
          ):<></>}
          {data.BBRSFE ? (
            <GlassFoldUdBox
              title="Matrikler"
              group="Matrikler"
              boxKey="Matrikler"
              foldetUd={props.foldetUd || false}
            >

              {PropInfoGroupFormatters.MatriklerTable(data, hasAccessToFeature)}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {(data.BBRSFE &&
            PropInfoGroupFormatters.MatriklerIndv(data).map((a) => (
              <GlassFoldUdBox
                key={a.key}
                title={"Matrikel " + a.name}
                group="Matrikler"
                boxKey="Matrikler Seperat"
                foldetUd={props.foldetUd || false}
              >
                {a.val.map((b) => (
                  <KeyValueLine
                    key={b.key}
                    valKey={b.key}
                    keepEmpty
                    value={b.val}
                  />
                ))}
              </GlassFoldUdBox>
            ))) || <></>}

            {"BBRUdsnit" in data && data.BBRUdsnit ? (
            <GlassFoldUdBox
              title="BBR Oversigt"
              group="BBR"
              boxKey="BBR Oversigt"
              foldetUd={false}
            >
              {PropInfoGroupFormatters.BBRKort(data.BBRUdsnit)}
            </GlassFoldUdBox>
          ):<></>}

          {data.EBRBFE ? (
            <GlassFoldUdBox
              group="BBR"
              title="BBR"
              boxKey="BBR"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.BBRBygninger(data)}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}

        {"BBRGrundTeknik" in data && (data.BBRGrundTeknik as any)?.flat()?.length ? (
         <GlassFoldUdBox 
            title={"BBR Teknik på Grund"}
            group="BBR"
            boxKey="BBR Teknik"
            foldetUd={props.foldetUd || false}
         >
            {PropInfoGroupFormatters.BBRTeknik(data.BBRGrundTeknik)}
         </GlassFoldUdBox>) : <></>} 
      

         {"BBRBygTeknik" in data && (data.BBRBygTeknik as any)?.flat()?.length ? (
         <GlassFoldUdBox 
            title={"BBR Teknik på Bygning"}
            group="BBR"
            boxKey="BBR Teknik"
            foldetUd={props.foldetUd || false}
         >
            {PropInfoGroupFormatters.BBRTeknik(data.BBRBygTeknik)}
         </GlassFoldUdBox>) : <></>} 
      

         {"BBREnhedTeknik" in data && (data.BBREnhedTeknik as any)?.flat()?.length ? (
         <GlassFoldUdBox 
            title={"BBR Teknik på Enheder"}
            group="BBR"
            boxKey="BBR Teknik"
            foldetUd={props.foldetUd || false}
         >
            {PropInfoGroupFormatters.BBRTeknik(data.BBREnhedTeknik)}
         </GlassFoldUdBox>) : <></>} 
      

          {data.ViamapTingBog ? (
            <GlassFoldUdBox
              group="Tingbog"
              title="Tingbog - Adkomster"
              boxKey="Tingbog - Adkomster"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.TingbogAdkomster(data)}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {data.ViamapTingBog ? (
            <GlassFoldUdBox
              group="Tingbog"
              title="Tingbog - Hæftelser"
              boxKey="Tingbog - Hæftelser"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.TingbogHæftelser(data)}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}
          {data.ViamapTingBog ? (
            <GlassFoldUdBox
              group="Tingbog"
              title={<><span>Tingbog - Servitutter</span>
              <ProtectedFeature feature={Feature.ServitutTilExcel} contentsIfNoAccess={<></>}>
              <ViewButtonIcon
                title={Localization.getText("Copy to Excel")}
                style={{marginLeft:"auto"}} onClick={(e) => {
                e.stopPropagation();
                servitutTable(data, hasAccessToFeature, e.ctrlKey)
              }}><FiCopy />
              </ViewButtonIcon>
              </ProtectedFeature></>}
              boxKey="Tingbog - Servitutter"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.TingbogServitutter(
                data,
                hasAccessToFeature
              )}
            </GlassFoldUdBox>
            
          ) : (
            <></>
          )}
          {data.ViamapTingBog ? (
            <GlassFoldUdBox
              group="Tingbog"
              title="Tingbog - Øvrigt"
              boxKey="Tingbog - Øvrigt"
              foldetUd={props.foldetUd || false}
            >
              {PropInfoGroupFormatters.TingbogEjendom(data, hasAccessToFeature)}
            </GlassFoldUdBox>
          ) : (
            <></>
          )}


          {(hasAccessToFeature(Feature.BoligmarkedsStatistik) &&
            data.ViamapEjendomStats && (
              <GlassFoldUdBox
                group="Boligmarkedsstatistik"
                title="Realiceret handelspris, Parcel-/rækkehus"
                foldetUd={props.foldetUd || false}
              >
                {PropInfoGroupFormatters.ViamapMarkedsStatistik(data)}
              </GlassFoldUdBox>
            )) || <></>}

          {data.PlanDataDelområde?.[0] && PropInfoGroupFormatters.Delområde(data, props.foldetUd || false)}
          {data.PlanDataLokalPlan?.[0] && PropInfoGroupFormatters.LokalPlan(data, props.foldetUd || false)}
          {data.PlanDataKommunePlan?.[0] && PropInfoGroupFormatters.KommunePlan(data, props.foldetUd || false)}
          {data.PlanDataLokalPlanAflyst?.length && PropInfoGroupFormatters.PlanDataLokalPlanAflyst(data, props.foldetUd || false) }
          {data.PlanDataKommunePlanAflyst?.length && PropInfoGroupFormatters.PlanDataKommuneAflyst(data, props.foldetUd || false)}


          {data.PEnheder ? (
                       <GlassFoldUdBox
                       group="Erhverv"
                       title="Virksomheder på Ejendommen"
                       boxKey="Virksomheder på Ejendom"
                       foldetUd={props.foldetUd || false}
                     >
              {PropInfoGroupFormatters.PEnhederPaaEjendomFormatter(data)}
                         </GlassFoldUdBox>
          ) : (
            <></>
          )}

          {(hasAccessToFeature(Feature.ExplorerMarkedsData) &&
            PropInfoGroupFormatters.MarkedsDataNewsec(
              data as any,
              props.foldetUd || false,
              hasAccessToFeature,
              select,
              setSelect
            )) || <></>}
          {PropInfoGroupFormatters.Vurderinger(data, props.foldetUd || false)}
        </ShowSelectedGroup>
      </div>
    </>
  );
}



function servitutTable(data, access, ctrl=false) {
    let tingbogsPrefix =
      "https://www.tinglysning.dk/tinglysning/rest/indskannetakt/rd/";
    let tingbogsPrefixNyeAkter =
      "https://www.tinglysning.dk/tinglysning/rest/pdf/atd/";
    // let tingbogsPrefixNyeAkter="https://www.tinglysning.dk/tmv/foresporgsel/detail/dokumenter/";
    // let tingbogsPrefixNyePaategninger="https://www.tinglysning.dk/tmv/foresporgsel/paategninger/";
    function getTingbogsDokLink(ser: any): string | undefined {
      if (ser.dokumentFilnavn && ser.dokumentFilnavn.length > 0) {
        return tingbogsPrefix + ser.dokumentFilnavn;
      } else {
        if (ser.aktAliasId && ser.aktAliasId.length > 0) {
          return tingbogsPrefixNyeAkter + ser.dokumentId;
        } else {
          return undefined;
        }
      }
    }

    let Text = data.ViamapTingBog?.servitutter?.toSorted((a, b) => {
        const IA = (d) => Number(d.prioritetNummer) || 10000;
        if (IA(a) < IA(b))
          return -1
        if (IA(a) > IA(b)) {
          return 1
        }
        return 0
      }).map((servitut) => {
        let tingbogsLink = getTingbogsDokLink(servitut);
        let hasAccess = access(Feature.ServitutTilExcel);
        
        function TingBogRecordNumber(a : string) {
          return a.replace(/(.{4})(.{2})(.{2})-()/, "$3$2$1-$4")
        }

        let recordNo = TingBogRecordNumber(servitut.aktId || servitut.aktAliasId);
        if (ctrl) {
          return `${servitut.prioritetNummer?.toString()}\t${recordNo}\t${(servitut.tekster.join(" ") || servitut.tekstSummarisk)}`;
        }
        return `${servitut.prioritetNummer?.toString()}\t"${recordNo}\n${(servitut.tekster.join(" ") || servitut.tekstSummarisk)}"`;
      }) || ["No data"];

    navigator.clipboard.writeText(Text.join("\n")).then(() => {
      message.info("Copied to clipboard (Excel format)")
    });
  }