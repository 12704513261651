import { Utils, Logger, SettingsManager, Localization, ViamapPersistenceLayer, PersistenceScope, PersistenceObjectType, SessionContext } from "@viamap/viamap2-common";
import { FeatureType } from "aws-sdk/clients/sagemaker";
import { useContext } from "react";
import { MapInfo, LayerVariabilityType, ViewerState } from "src/common/managers/Types";
import { ExportData } from "src/managers/ExportData";
import { Persistence } from "src/managers/Persistence";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { Feature } from "src/states/ApplicationStateFeatures";
import { actionAddDataLayer, actionRemoveDataLayer, MapitStateContext, MapitState } from "src/states/MapitState";
import { SaveMapDialog } from "./SaveMapDialog";
import { SaveMapLinkDialog } from "./SaveMapLinkDialog";
import { message } from "./Message";
import { windowContext } from "src/WindowManager/windowContext";
import { closeWindow, openWindow, WindowId } from "src/WindowManager/WindowState";


 export function SaveMapAsPrivateCloudDialog() {
   const { state: WindowState, dispatch: WindowStateDispatch } = useContext(windowContext);
   const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
   const { state: sessionState } = useContext(SessionContext);

 async function PrivateCloudSave(mapInfo: MapInfo, readOnly: boolean, securityKey?: string, endDate?: Date) {
   let domainName = sessionState.customerRef;
   WindowStateDispatch(closeWindow(WindowId.CloudSaveDialog))
   let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
   Logger.logAction("MapScreen", "Save map", "Layers= " + layerArray.length);
   let fileName = (mapInfo.mapTitle ? mapInfo.mapTitle : Localization.getText("Savemap:defaultFileName")) + ".mapit";
   let userName = sessionState.userName;
   let userId = sessionState.userRef;
   let currentDate = new Date();
   // todo: only a subset of the features should be available when in readonlyanonymous mode
   // tslint:disable-next-line: max-line-length
   let saveState = await Persistence.extractMapState(mapitState ,false, mapInfo, layerArray, userName, currentDate, false, userId, getViewerState(mapitState.map), endDate, securityKey, mapitState.layerHierarchy);
   const ref = Persistence.chance.guid();
   let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
   let prefixPath = persit.createPrefix(PersistenceScope.User, PersistenceObjectType.MapitMaps, domainName, userId)
   const metadata = {
     mapTitle: encodeURI(mapInfo.mapTitle || ""),
     userId: encodeURI(userId),
     userName: encodeURI(userName),
     expirationDate: "null",
     createdDate: encodeURI(new Date().toISOString()),
     layers: Object.keys(mapitState.layers).length.toString(),
     size: JSON.stringify(saveState).length.toString(), // TODO: Find something better
     version: saveState.interfaceVersion?.toString()
   };
   let saveSuccessful = await persit.uploadObjectS3(prefixPath +"/"+ ref, JSON.stringify(saveState), metadata)

   if (saveSuccessful) {
     layerArray.forEach((layer) => {
       mapitStateDispatch(actionRemoveDataLayer(layer.layerId, false))
       layer.awsFilename = ref; // Store the saved filename in the layer for future paths.
       layer.group = ["TilføjedeLag"]
       layer.layerVariability = LayerVariabilityType.FixedFeatureLayer;
       mapitStateDispatch(actionAddDataLayer(layer, false))
     })

     Logger.logAction("MapScreen", "Save cloud layer", "Layers= " + layerArray.length);
     message.info((Localization.getText("Layer saved as cloud layer")));
   } else {
     message.error((Localization.getText("Failed to save as cloud layer")));
   }
 }


 return <SaveMapDialog
 showWindow={1}
 mapInfo={mapitState.mapInfo}
 title="Save Map on Cloud"
 callbackOnCancel={() => WindowStateDispatch(closeWindow(WindowId.CloudSaveDialog))}
 callbackOnCommit={(mapInfo, readOnly) => { PrivateCloudSave(mapInfo, true)}}
/>
}


export function SaveMapAsCloudLinkDialog() {
   const { state: WindowState, dispatch: WindowStateDispatch } = useContext(windowContext);
   const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
   const { state: sessionState } = useContext(SessionContext);
   
 async function CloudSave(mapInfo: MapInfo, readOnly: boolean, securityKey?: string, endDate?: Date) {
   let domainName = sessionState.customerRef;
   WindowStateDispatch(closeWindow(WindowId.AdminCloudSaveDialog))
   let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
   Logger.logAction("MapScreen", "Save map", "Layers= " + layerArray.length);
   let fileName = (mapInfo.mapTitle ? mapInfo.mapTitle : Localization.getText("Savemap:defaultFileName")) + ".mapit";
   let userName = sessionState.userName;
   let userId = sessionState.userRef;
   let currentDate = new Date();
   // todo: only a subset of the features should be available when in readonlyanonymous mode
   // tslint:disable-next-line: max-line-length
   let saveState = await Persistence.extractMapState(mapitState ,false, mapInfo, layerArray, userName, currentDate, false, userId, getViewerState(mapitState.map), endDate, securityKey, mapitState.layerHierarchy);
   const ref = Persistence.chance.guid();
   let persit = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"))
   let prefixPath = persit.createPrefix(PersistenceScope.Customer, PersistenceObjectType.SavedMaps, domainName)
   const metadata = {
     mapTitle: encodeURI(mapInfo.mapTitle || ""),
     expirationDate: endDate ? endDate.toISOString() : "",
     userId: userId,
     userName: userName
   };
   let saveSuccessful = await persit.uploadObjectS3(prefixPath +"/"+ ref, JSON.stringify(saveState), metadata)

   if (saveSuccessful) {
     layerArray.forEach((layer) => {
       mapitStateDispatch(actionRemoveDataLayer(layer.layerId, false))
       layer.awsFilename = ref; // Store the saved filename in the layer for future paths.
       layer.group = ["TilføjedeLag"]
       layer.layerVariability = LayerVariabilityType.FixedFeatureLayer;
       mapitStateDispatch(actionAddDataLayer(layer, false))
     })

     Logger.logAction("MapScreen", "Save cloud layer", "Layers= " + layerArray.length);
     message.info((Localization.getText("Layer saved as cloud layer")));
   } else {
     message.error((Localization.getText("Failed to save as cloud layer")));
   }
 }

   return <SaveMapDialog
      showWindow={1}
      mapInfo={mapitState.mapInfo}
      title="(ADMIN do not use) Save Map on Cloud"
      callbackOnCancel={() => {WindowStateDispatch(closeWindow(WindowId.AdminCloudSaveDialog))}}
      callbackOnCommit={(mapInfo, readOnly) => { CloudSave(mapInfo, true)}}
   />
}

export function SaveMapAsMapitDialog() {
   const { state: WindowState, dispatch: WindowStateDispatch } = useContext(windowContext);
   const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
   const { state: sessionState } = useContext(SessionContext);
   const { state: applicationState, hasAccessToFeature } = useContext(ApplicationStateContext);

   
 async function doSaveMap(mapInfo: MapInfo, readOnly: boolean, securityKey?: string, endDate?: Date) {
   WindowStateDispatch(closeWindow(WindowId.SaveMapDialog))
   let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
   Logger.logAction("MapScreen", "Save map", "Layers= " + layerArray.length);
   let fileExtension = hasAccessToFeature(Feature.ThemeNewSec) ? ".estateExplorer" : ".mapit"
   let fileName = (mapInfo.mapTitle ? mapInfo.mapTitle : Localization.getText("Savemap:defaultFileName")) + fileExtension;
   let userName = sessionState.userName;
   let userId = sessionState.userRef;
   let currentDate = new Date();
   let enabledFeatures: FeatureType[] = applicationState.availableFeatures;
   
   let saveState = await Persistence.extractMapState(mapitState ,readOnly, mapInfo, layerArray, userName, currentDate, false, userId, getViewerState(mapitState.map), endDate, securityKey, mapitState.layerHierarchy, enabledFeatures);
   let json = JSON.stringify(saveState);
   let blob = new Blob([json], { type: "application/json" });

   ExportData.downloadBlob(fileName, blob);
 }
   return <SaveMapDialog
    showWindow={1}
    mapInfo={mapitState.mapInfo}
    callbackOnCancel={() => WindowStateDispatch(closeWindow(WindowId.SaveMapDialog))}
    callbackOnCommit={(mapInfo, readOnly) => { doSaveMap(mapInfo, readOnly) }}
   />
}



 export function SaveMapAsLinkDialog() {
   const { state: WindowState, dispatch: WindowStateDispatch } = useContext(windowContext);
   const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
   const { state: applicationState, dispatch: applicationDispatch, hasAccessToFeature } = useContext(ApplicationStateContext);
   const { state: sessionState } = useContext(SessionContext);

   
function doSaveMapLink(mapInfo?: MapInfo, daysValidity?: number, minZoom?: number, maxZoom?: number, sharedFeatureLayers?: any[], overWriteExstingLinkFile?: boolean, oldLinkName?: string) {
   WindowStateDispatch(closeWindow(WindowId.SaveLinkDialog));
   let expireDate = Utils.addDays(new Date(), (daysValidity || 30));
   doMapLink(
     (ref) => WindowStateDispatch(openWindow(WindowId.SavedLinkDialog,{ref:ref, expireDate:expireDate})),
     "abc",
     expireDate,
     minZoom,
     maxZoom,
     sharedFeatureLayers,
     mapInfo,
     overWriteExstingLinkFile,
     oldLinkName
   );
 }

 async function doMapLink(
   callback: (ref?: string) => void,
   securityKey?: string,
   endDate?: Date,
   minZoom?: number,
   maxZoom?: number,
   sharedFeatureLayers?: any[],
   mapInfo?: MapInfo,
   overWriteExstingLinkFile?: boolean,
   oldLinkName?: string
 ) {
   let layerArray = Object.keys(mapitState.layers).map((li) => { return mapitState.layers[li]; });
   Logger.logAction("MapScreen", "Save map link", "Layers= " + layerArray.length);
   let userName = sessionState.userName;
   let userId = sessionState.userRef;
   let currentDate = new Date();
   // todo: only a subset of the features should be available when in readonlyanonymous mode
   let enabledFeatures: FeatureType[] = applicationState.availableFeatures;
   let activeFeatureLayers = mapitState.selectedFeatureLayerKeys
   // tslint:disable-next-line: max-line-length
   let mapState = await Persistence.extractMapState(mapitState ,true, (mapInfo ? mapInfo : mapitState.mapInfo), layerArray, userName, currentDate, true, userId, getViewerState(mapitState.map), endDate, securityKey, mapitState.layerHierarchy, enabledFeatures, minZoom, maxZoom, sharedFeatureLayers, activeFeatureLayers);

   let maxStateSize = SettingsManager.getSystemSetting("maxSavedMapLinkSize", 5900000); // Amazon AWS Lambda has a payload limit of 6MB (and API Gateway has one of 10MB)
   let mapSize = JSON.stringify(mapState).length;
   if (mapSize > maxStateSize) {
     message.error("Cannot save link. Map exceeds the maximum size of 6MB for linked maps");
   } else {
     let path = SettingsManager.getSystemSetting("mapLinkOnS3Path", "savedmaps/");
     Persistence.createStateReference(mapState, path, overWriteExstingLinkFile ? oldLinkName : undefined)
       .then((result) => { callback(result); })
       .catch((error) => { message.error("Persist failed: " + error)});
   }

 }


   return (<SaveMapLinkDialog
      showWindow={1}
      mapInfo={mapitState.mapInfo}
      linkDuration={SettingsManager.getSystemSetting("DefaultLinkDurationDays", 30)}
      currentZoomLevel={mapitState.map?.getZoom?.() || 16}
      backgroundLayers={mapitState.backgroundLayers || {}}
      callbackOnCancel={() => {WindowStateDispatch(closeWindow(WindowId.SaveLinkDialog))}}
      callbackOnCommit={doSaveMapLink}
    />)

}


function getViewerState(map: MapitState["map"]): ViewerState | undefined {
   if (map) {
     return {
       bearing: map.getBearing(),
       pitch:  map.getPitch(),
       zoomFactor: map.getZoom(),
       center: map.getCenter()
     };
   } else {
     return undefined;
   }
 }