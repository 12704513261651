import { useContext, useEffect, useState } from "react";
import { AdvancedDragModal, ADModalBody, ADModalFooter } from "src/componentsUtils/AdvancedDragModal";
import { GlassButton } from "../components/GlassButtons";
import { KeyValueTable } from "src/propertyInfoTemplates/PropertyComponents";
import { actionClearProgressMessage, actionSetErrorMessage, actionSetInfoMessage, actionSetProgressMessage, AppMessagesContext } from "@viamap/viamap2-common";
import { Spinner } from "react-bootstrap";
import { ExportData } from "src/managers/ExportData";
import { SheetFunc } from "src/managers/SheetFunc";
import { Persistence } from "src/managers/Persistence";
import * as Turf from "@turf/turf";
import { LayerFunc } from "src/managers/LayerFunc";
import { LayerInfo } from "src/common/managers/Types";
import { actionAddDataLayer, MapitStateContext } from "src/states/MapitState";
import { BNBOBeregninger } from "./BNBOBeregninger";
import { BNBOContext, BNBOErstatningLinie, BNBOMatrikel, BNBOOmrådeType } from "./BNBOState";
import { ProtectedFeature } from "src/components/ProtectedFeature";
import { Feature } from "src/states/ApplicationState";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, WindowId } from "src/WindowManager/WindowState";
import { colorByType } from "./BNBOAreaEditorExtra";

export function BNBOOpgørelse(props: { show: number, lodsejerId: string, onClose?: any }) {
  const { state: bnboState, dispatch: bnboDispatch } = useContext(BNBOContext);
  const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
  const { dispatch: mapitStateDispatch } = useContext(MapitStateContext)
  const { dispatch: windowDispatch } = useWindowContext();

  // const [erst, setErst] = useState<BNBOErstatningLinie[]>();
  const erst = props.lodsejerId ? BNBOBeregninger.udregnErstatningEjendom(bnboState, props.lodsejerId) : undefined

  let erstFilter = (ft: BNBOErstatningLinie) => {
    return Boolean(ft.areaType);
  }

  function erstListSorter(a: BNBOErstatningLinie, b: BNBOErstatningLinie) {
    // let res = matrikelSorter(a,b) === 0 ? markSorter(a,b) === 0 ? erstTypeSorter(a,b) : markSorter(a,b) : matrikelSorter(a,b);
    let res = matrikelSorter(a, b) || markSorter(a, b) || erstTypeSorter(a, b) * -1;
    return (res);
  }
  function markSorter(a: BNBOErstatningLinie, b: BNBOErstatningLinie) {
    if (a.mark && b.mark) {
      const markBlokComparison = a.mark.markBlok.localeCompare(b.mark.markBlok);
      if (markBlokComparison !== 0) {
        return markBlokComparison;
      }
      return a.mark.markNr.localeCompare(b.mark.markNr);
    }
    return a.mark ? 1 : b.mark ? -1 : 0;
  }
  function matrikelSorter(a: BNBOErstatningLinie, b: BNBOErstatningLinie) {
    if (a.matrikel && b.matrikel) {
      const ejerlavsNavnComparison = a.matrikel.ejerlavsNavn.localeCompare(b.matrikel.ejerlavsNavn);
      if (ejerlavsNavnComparison !== 0) {
        return ejerlavsNavnComparison;
      }
      return a.matrikel.matrikelNummer.localeCompare(b.matrikel.matrikelNummer);
    }
    return a.matrikel ? 1 : b.matrikel ? -1 : 0;
  }
  function erstTypeSorter(a: BNBOErstatningLinie, b: BNBOErstatningLinie) {
    if (a.areaType && b.areaType) {
      return a.areaType.localeCompare(b.areaType);
    }
    return a.areaType ? 1 : b.areaType ? -1 : 0;
  }

  function summarizeSomeTypes(result: BNBOErstatningLinie[], erst: BNBOErstatningLinie) {
    if (result.length > 0) {
      if (
        !(erst.areaType === BNBOOmrådeType.Vejareal && result[result.length - 1].note != erst.note)
        && erst.areaType != BNBOOmrådeType.ServitutAreal
        && result[result.length - 1].areaType === erst.areaType
        && result[result.length - 1].takst === erst.takst
        && result[result.length - 1].faktor === erst.faktor
        && result[result.length - 1].mask === erst.mask
        && result[result.length - 1].farve === erst.farve
      ) {
        result[result.length - 1] = {
          ...result[result.length - 1],
          antal: erst.antal + result[result.length - 1].antal,
          erstatning: erst.erstatning + result[result.length - 1].erstatning,
          linieErTotalForAntalDele: (result[result.length - 1].linieErTotalForAntalDele||1)+1,
          note: BNBOBeregninger.faktorFormat(erst.areaType)+ "(flere)",
        }
        return result;
      } else {
        return [...result, erst];
      }
    } else {
      return [erst];
    }
  }

  let erstFilteredAndSummarized = erst && erst.filter(erstFilter).sort(erstListSorter).reduce(summarizeSomeTypes, []) || [];
  let erstListFormat = BNBOBeregninger.formatErstatningsBeregningEjendom(erstFilteredAndSummarized);
  let lastEjerlavMatrikel = "";
  let totalErstatning = BNBOBeregninger.sumErstatninger(erstFilteredAndSummarized);

  if (!props.show) {
    return <></>
  }

  let transformNestedPropertyObject = (key: string, value: any) => {
    switch (key) {
      case "mark":
        if (value && value.markBlok) {
          value = `Mark ${value.markBlok} Nr ${value.markNr} ${value.afgrøde}`
        } else {
          value = "";
        }
        break;
      case "matrikel":
        if (value && value.ejerlavsNavn) {
          value = `Matrikel ${value.ejerlavsNavn} ${value.matrikelNummer}`
        } else {
          value = "";
        }
        break;
    }
    return { [key]: value };
  }

  function createSheet(erst: BNBOErstatningLinie[], lodsejerId: string, createdDate: Date, progressCallback: (progress: number) => void) {
    let props = {
      Title: "Opgørelse for SFE " + lodsejerId,
      Author: "Mapit BNBO edition",
      CreatedDate: createdDate
    }
    let sheets: any[] = [];

    sheets.push({
      sheetName: "Erstatningsopgørelse",
      rows: erst!.map((linie) => {
        return ExportData.ObjectToRows(
          linie,
          (key) => { return key != "geometry"; },
          transformNestedPropertyObject)
      })
    })

    let wb = SheetFunc.createWorkBookFromJSON(props, sheets);
    return wb;
  }

  async function handleExport() {
    if (!erst) {
      return
    }

    try {

      let createdDate = new Date();
      let count = erst.length;
      let wb = createSheet(erstFilteredAndSummarized, props.lodsejerId, createdDate,
        (progress: number) => {
          appMessageDispatch(actionSetProgressMessage("Export", Math.round(100 * progress / count)));
        });
      appMessageDispatch(actionClearProgressMessage());
      let blob = SheetFunc.createBlob(wb);
      let timeStamp = new Date
      let fileName = "Opgørelse_" + props.lodsejerId + "_" + createdDate.toLocaleDateString() + "_" + createdDate.toLocaleTimeString();
      fileName += ".xlsx";
      ExportData.downloadBlob(fileName, blob);
      appMessageDispatch(actionSetInfoMessage(`Opgørelse for SFE ${props.lodsejerId} has been exported to excel`));
    }
    catch (error: any) {
      appMessageDispatch(actionSetErrorMessage(error));
    }
  }

  function handleCreateLayers() {
    if (!erst) {
      alert("Erstatningsopgørelse er ikke klar endnu")
      return;
    }

    function onCreateLayer(title: string, visible: boolean, features: any[], propertiesToShow?: string[]) {
      Persistence.createLayersFromGeoJSON(title, Turf.featureCollection(features), new Date(), (li: LayerInfo) => {

        li.visible = visible;
        // Fallback: show all properties if not specified
        li.propertiesToDisplay = (propertiesToShow || LayerFunc.extractPropertiesFromGeoJSON(Turf.featureCollection(features))).reduce<any[]>((result, prop) => {
          return [...result, { name: prop }];
        }, []);
        li.propertiesInGeoJson = LayerFunc.extractPropertiesFromGeoJSON(Turf.featureCollection(features));
        li.styling = {...li.styling, ...OpgørelsesStyle()} as any;
        mapitStateDispatch(actionAddDataLayer(li));
      })
    }

    function createLayerIfAnyFeatures(title, visible, features: any[], propertiesToShow?: string[]) {
      if (features.length > 0) {
        onCreateLayer(title, visible, features, propertiesToShow);
      } else {
        console.log(`No '${title}' layer created as it is empty!`);
      }
    }

    function OpgørelsesStyle() {
      return {
      "color": "#4b7ccb",
      "lineOpacity": 1,
      "colorByProperty": "område",
      "colorByValue": {
         "useColorRange": 3,
         "divisions": {
            "type": 1,
            "list": Object.keys(områdeToColor).map((a) => 
               ({
                from: 0,
                to: 0,
                value: a,
                color: områdeToColor[a] || "lightblue"
               }),
            ),
            "otherValuesCount": 0
         },
         "divisionStylingType": 1
      },
      "outsideColorRange": {
         "show": true,
         "color": "lightgreen"
      }
   }};

    // Collect features for new layers
    let ftLinier: any[] = [];
    let områdeToColor:{[key:string]:string} = {};
    let erstListFormat = BNBOBeregninger.formatErstatningsBeregningEjendom(erst);
    erstListFormat.forEach((m) => {
      if (m.note || m.type_formatted) {
        områdeToColor[(m.note || m.type_formatted)+""] ??= m.farve || colorByType(m.type) || "lightblue";
      }
      if (m.geometry) {
        let properties = {
          "note": m.note,
          "areaType": m.type_formatted,
          "matrikel": m.matrikel,
          "mark": m.mark,
          "antal": m.antal_formatted,
          "område": m.note || m.type_formatted,
          "takst": m.takst_formatted,
          "faktor": m.faktor_formatted,
          "procent": m.procent_formatted,
          "erstatning": m.erstatning_formatted,
        };
        let pMapped = Object.keys(properties).reduce<{ [key: string]: string }>((result, key) => {
          return { ...result, ...transformNestedPropertyObject(key, properties[key]) };
        }, {});
        ftLinier.push(Turf.feature(m.geometry, pMapped));
      }
    })

    createLayerIfAnyFeatures("Erstatningsområder, sfe "+props.lodsejerId, true, ftLinier, ["areaType", "antal", "note"]);

  }

  let markDelUdenMark = bnboState.markDele[props.lodsejerId]
  // let totalErstatning = 0;

  let BnboMatrikelErstatning = (props: { lodsejerId: string, matrikel: BNBOMatrikel }) => {

    return (
      <>
        {`${props.matrikel.ejerlavsNavn} (${props.matrikel.ejerlavsKode}) mat ${props.matrikel.matrikelNummer}`}
        <br />
      </>
    )
  }

  let BnboMatrikelErstatningList = (props: { lodsejerId: string }) => {

    return (
      <>
        <BnboEjendomsErstatning lodsejerId={props.lodsejerId} />
      </>
    )
  }

  let BnboEjendomsErstatning = (props: { lodsejerId: string }) => {
    if (erst && erst.length > 0) {

      
      return (<KeyValueTable>
        <thead>
          <tr key={"ddx" }>
            <th>Hvor</th>
            <th>Note</th>
            <th>Type</th>
            <th>Antal</th>
            <th>Takst</th>
            <th>Faktor</th>
            <th>Pct</th>
            <th>Total</th>
            <th>Signatur</th>
          </tr>
        </thead>
        <tbody>
          <>
            {
              erstListFormat.map((ft, idx) => {
                let mat = ft.matrikel ? `Matrikel ${ft.matrikel.ejerlavsNavn} ${ft.matrikel.matrikelNummer}` : "";
                let hvor = ft.mark ? `Mark ${ft.mark.markBlok} Nr ${ft.mark.markNr} ${ft.mark.afgrøde}` : ""; // ft.matrikel ? `Matrikel ${ft.matrikel.ejerlavsNavn} ${ft.matrikel.matrikelNummer}` : "";
                hvor = ft.linieErTotalForAntalDele > 0 ? " ("+ft.linieErTotalForAntalDele+" dele)" : "";
                mat = ft.mask ? ft.mask : "";
                let rows = (
                  <>
                    {!(lastEjerlavMatrikel === mat) ? <tr key={"ddx" + idx}>
                      <td colSpan={9}><b>{mat}</b></td>
                    </tr> : null}
                    <tr key={"dd" + idx}>
                      <td>{hvor}</td>
                      <td>{ft.note}</td>
                      <td>{ft.type_formatted}</td>
                      <td style={{textAlign:"right"}} >{ft.antal_formatted}</td>
                      <td style={{textAlign:"right"}} >{ft.takst_formatted}</td>
                      <td style={{textAlign:"right"}} >{ft.faktor_formatted}</td>
                      <td style={{textAlign:"right"}} >{ft.procent_formatted}</td>
                      <td style={{ textAlign: "right" }} >{ft.erstatning_formatted}</td>
                      <td style={{ backgroundColor: ft.farve || colorByType(ft.type) || "lightblue" }} ></td>
                    </tr>
                  </>
                );
                lastEjerlavMatrikel = mat;
                return rows;
              })
            }
            <tr key={"ddy"}>
              <td colSpan={7}><strong>{"Total"}</strong></td>
              <td style={{ textAlign: "right" }} ><strong>{BNBOBeregninger.formatTotalErstatning(totalErstatning)}</strong></td>
              <td></td>
            </tr>
          </>
        </tbody>
      </KeyValueTable>);
    } else {
      return (
        <>
          <center>
            <Spinner animation={"border"} />
          </center>
        </>
      )
    }
  }

  return (
    <>
      <AdvancedDragModal
        title={"BNBO Opgørelse"}
        topUpdate={props.show}
        PosDefault={{ width: "1000px", height: "100%", top: "0px", left: "50%", transform: "translateX(-50%)" }}
        onClose={() => windowDispatch(closeWindow(WindowId.BNBOOpgørelse))}
      >
        <ADModalBody>
          <>
            <BnboEjendomsErstatning lodsejerId={props.lodsejerId} />
          </>
        </ADModalBody>
        <ADModalFooter>
          <GlassButton onClick={() => {
            handleExport()
          }}>
            Exportér til Excel
          </GlassButton>
          <ProtectedFeature feature={Feature.BNBOTool} contentsIfNoAccess={<></>}>
            <GlassButton onClick={() => {
              handleCreateLayers()
            }}>
              Generér resultat som lag
            </GlassButton>
          </ProtectedFeature>
        </ADModalFooter>
      </AdvancedDragModal>
    </>
  )
} 
