import { Logger } from "@viamap/viamap2-common";
import { DawaQuality, RowDataQuality } from "src/common/managers/Types";
import { GenerateGeom } from "./GenerateGeom";
import { Localization } from "@viamap/viamap2-common";
import { PropertyInfoInterface } from "./PropertyInfoInterface";
import { feature, featureCollection } from "@turf/helpers";

export type CadasterGeoCodeResult = any;

export class CadasterInterface {

    static async searchMatrikel(ejerlavkode: string, matrikelnr: string): Promise<any> {
        let serviceUrl = "https://dawa.aws.dk/jordstykker";
        let query = serviceUrl + "?ejerlavkode=" + ejerlavkode + "&matrikelnr=" + matrikelnr;
        try {
            const response = await fetch(query);
            if (response.status !== 200) {
                throw new Error("Http Error status code received: " + response.status);
            }
            const data = await response.json();
            return { data: data };
        } catch (err:any) {
            return { error: err.toString() };
        }
    }

    static async geocode(
        elavskode: string[],
        matrikelnr: string[],
        withPolygon: boolean,
        polygonForFullSFE: boolean,
        callback: (result: CadasterGeoCodeResult[]) => void,
        statusStarted: (noGeoCodeStarted: number) => void,
        statusCompleted: (noGeoCodeCompleted: number, stats: any) => void) {
        let completed = 0;
        let stats = {
            [DawaQuality.dawa_matched]: 0,
            [DawaQuality.dawa_washed]: 0,
            [DawaQuality.fuzzy_washed]: 0,
            [DawaQuality.unwashable]: 0,
            [DawaQuality.dawa_error]: 0,
        };
        let results: CadasterGeoCodeResult[] = [];
        let queryToIndexMap = {};


        function startIterationWLimitedConcurrency(i: number, activeRequestsLimit:number) {
            let elav = elavskode[i];
            let matrnr = matrikelnr[i];

            let activeRequests = (i + 1) - completed;
            if (activeRequests > activeRequestsLimit) {
                // Delay starting new requests before the queue is shorter. Test again in 1 sek.
                let delay = 1000 * 1; 
                setTimeout(
                    () => {
                        startIterationWLimitedConcurrency(i, activeRequestsLimit);
                    },
                    delay);
            } else {
                statusStarted(i + 1);
                CadasterInterface.washFunctionGsearch(elav, matrnr, withPolygon, polygonForFullSFE, i).then((response) => {
                // CadasterInterface.washFunction(elav, matrnr, withPolygon, polygonForFullSFE, i).then((response) => {

                    completed += 1;
                    const q = response.dataquality;
                    stats = { ...stats, [q]: stats[q] + 1 };
                    statusCompleted(completed, stats);

                    let index = response.idx;
                    // Store result in the right index
                    results[index] = response;

                    // Done?
                    if (completed === elavskode.length) {
                        Logger.logInfo("CadasterInterface", "geocode", "Completed. Stats: " + JSON.stringify(stats));
                        callback(results);
                    }
                });
                // Start next request, if any
                if (i + 1 < elavskode.length) {
                    startIterationWLimitedConcurrency(i + 1, activeRequestsLimit);
                }
            }
        }
        startIterationWLimitedConcurrency(0, 50);
    }

    private static async washFunctionGsearch(elav: string | number, matr: string, withPolygon: boolean, polygonForFullSFE:boolean, idx: number): Promise<CadasterGeoCodeResult> {
        if (matr == "") {
            return {
                "input": `${elav} - ${matr}`,
                "idx": idx,
                "dataquality": RowDataQuality.Error,
                "dataqualityNote": "No Matrikelnummer",
            }
        }
        if (typeof elav === "string" && (parseInt(elav).toString() == elav.trim())) {
            return {
                "input": `${elav} - ${matr}`,
                "idx": idx,
                "dataquality": RowDataQuality.Error,
                "dataqualityNote": "Invalid Ejerlavskode",
            }
        }

        type gSearchReturnMatrikel = {
            ejerlavskode: number;
            kommunenavn: string;
            ejerlavsnavn: string;
            jordstykke_id: number;
            centroid_x: number;
            matrikelnummer: string;
            centroid_y: number;
            geometri: any;
            kommunekode: string;
            visningstekst: string;
            bfenummer: number;
        }

        const search: void | gSearchReturnMatrikel[] = await PropertyInfoInterface.gSearch("matrikel", String(matr), 10, `ejerlavskode='${elav}'`).catch((err) => {
            console.error("(WashFunctionGsearch: gSearch): " + err);
        });
        if (!search || !search.length) {
            return {
                "input": `${elav} - ${matr}`,
                "idx": idx,
                "dataquality": RowDataQuality.Error,
                "dataqualityNote": "No search result",
            };
        }
        let searchFilter = search.filter((j) => j.matrikelnummer == String(matr));
        if (searchFilter.length == 0) {
            return {
                "input": `${elav} - ${matr}`,
                "idx": idx,
                "dataquality": RowDataQuality.Error,
                "dataqualityNote": "No match in search result",
            }
        }
        if ( searchFilter.length > 1) {
            return {
                "input": `${elav} - ${matr}`,
                "idx": idx,
                "dataquality": RowDataQuality.Error,
                "dataqualityNote": "Multiple matches in search result",
            }
        }
        let jord = searchFilter[0];
        let geojson = {}
        if (withPolygon) {
            if (polygonForFullSFE) {
                geojson = await PropertyInfoInterface.getGeojsonOfSFE(jord.bfenummer).catch((err) => {
                    console.error("(WashFunctionGsearch: getSFEFromMatrikel): " + err);
                    return { features: [] };
                });
            } else {
                geojson = featureCollection([feature(jord.geometri, { 
                    "Matrikelnummer": jord.matrikelnummer,
                    "Ejerlavskode": jord.ejerlavskode,
                    "Ejerlavsnavn": jord.ejerlavsnavn,
                 })]);
            }
        }
        let {x:easting, y:northing} = GenerateGeom.convertFromLatLngToUTM({ lat: jord.centroid_y, lng: jord.centroid_x });

        return {
         "input": `${elav} - ${matr}`,
         "idx": idx,
         "dataquality": RowDataQuality.Good,
         "sfeejendomsnr": jord.bfenummer ,
         "lat": jord.centroid_y,
         "lon": jord.centroid_x,
         "easting": easting,
         "northing": northing,
         "dataqualityNote": "Ok",
         ...(geojson && {geoJson: geojson} || {})
        }
    }
}