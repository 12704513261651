import {
  PropertyIdent,
  PropertyData,
  ReadyData,
  PropertyDataRequests,
  PropertyDataRequestsObj,
  IdentDepend,
} from "./PropertyInfoCollectorTypes";
import { IdentTransformers, RequestObject } from "./PropertyInfoCollectorHelpers";

export class PropertyInfoCollector {
  isEmpty: boolean = true;
  identification: PropertyIdent = {};
  data: PropertyData = {};
  loading: Partial<Record<keyof PropertyData, Promise<any>>> = {};
  loadingIdent: Partial<Record<IdentDepend, Promise<PropertyIdent>>> = {};
  errors: Partial<Record<IdentDepend | keyof PropertyData, { error: string }>> =
    {};

  request: typeof RequestObject = {};

  constructor(ident: PropertyIdent) {
    this.request = { ...RequestObject };
    this.identification = { ...ident };
  }

  async callBackOnFinished(callBack: (data: PropertyData) => void) {
    while (!!Object.keys(this.loading).length) {
      await Promise.allSettled(Object.values(this.loading));
    }
    callBack(this.data);
  }

  getIdent(key: IdentDepend): ReadyData<PropertyIdent> {
    if (this.errors[key]?.error) {
      return { status: "Error", error: this.errors[key]!.error };
    }
    if (this.getCurrentIdent().includes(key)) {
      if (key in this.loadingIdent) {
        return { status: "PartialReady", data: this.identification };
      }
      return { status: "Ready", data: this.identification };
    }
    if (key in this.loadingIdent) {
      return { status: "IsLoading" };
    }
    return { status: "NotRequested" };
  }

  getData<T extends keyof PropertyData>(key: T): ReadyData<PropertyData[T]> {
    if (this.errors[key]?.error) {
      return { status: "Error", error: this.errors[key]!.error };
    }
    if (key in this.data) {
      if (this.data[key] !== undefined) {
        if (key in this.loading) {
          return { status: "PartialReady", data: this.data[key] };
        }
        return { status: "Ready", data: this.data[key] };
      }
    }
    if (key in this.loading) {
      return { status: "IsLoading" };
    }
    return { status: "NotRequested" };
  }

  async addRequest<X extends string>(
    key: X,
    request: PropertyDataRequests<any>,
    callBack?: (val: ReadyData<PropertyData[keyof PropertyData]>, key: X) => any
  ) {
    if (!(key in this.request)) {
      this.request = { ...this.request, [key]: request };
    }
    return this.requestData(
      key as keyof PropertyData,
      callBack as <T extends keyof PropertyData>(
        val: ReadyData<PropertyData[T]>,
        key: T
      ) => any
    );
  }

  getCurrentIdent(): IdentDepend[] {
    return [
      "lat" in this.identification ? "latLng" : null,
      "BFEnr" in this.identification ? "BFEnr" : null,
      "matrikelNr" in this.identification ? "Matnr" : null,
      "geom" in this.identification ? "geom" : null,
    ].filter((a) => a !== null) as IdentDepend[];
  }

  async requestIdent(idenKey: IdentDepend) {
    if (this.getIdent(idenKey).status === "NotRequested") {
      if (!IdentTransformers.flatMap((a) => a.to).includes(idenKey)) {
        throw new Error("Ident transformer not found");
      }
      const currentIdent = this.getCurrentIdent();
      let useFullTransformers = IdentTransformers.filter((transformer) => {
        return (
          currentIdent.includes(transformer.from) &&
          transformer.to.includes(idenKey)
        );
      });
      if (useFullTransformers.length === 0) {
        throw new Error("Recursive dependency not found for IdentDependency");
      }
      let asyncFunc = async () => {
        let transformer = useFullTransformers[0];
        let input = transformer.paramTransformer(this.identification);
        let res = await transformer.func.apply(
          transformer.hasThis ?? null,
          input
        );
        this.identification = {
          ...this.identification,
          ...transformer.resultTransform(res),
        };
        return this.identification;
      };
      let value = asyncFunc();
      useFullTransformers[0].to.forEach((toKey) => {
        this.loadingIdent[toKey] = value;
      });
      await value.then((a) => {
        let x = a;
        delete this.loadingIdent[idenKey];
        return x;
      });
      return await value;
    } else {
      if (this.loadingIdent[idenKey]) {
        let value = this.loadingIdent[idenKey];
        await value;
        return value;
      }
    }
  }

  async requestData<T extends keyof PropertyData>(
    key: T,
    callBack?: (val: ReadyData<PropertyData[T]>, key: T) => any
  ): Promise<PropertyData[T]> {
    if (this.getData(key).status === "NotRequested") {
      const asyncFunc = async () => {
        let request: PropertyDataRequestsObj[T] = this.request[key];
        try {
          if (!request) {
            throw new Error("Missing Request");
          }
          if ("identDependency" in request && request.identDependency) {
            await this.requestIdent(request.identDependency);
          }
          if ("dependency" in request && request.dependency) {
            let depend =
              typeof request.dependency === "string"
                ? [request.dependency]
                : request.dependency;
            let dependRes = await Promise.all(
              depend.map(async (a) => {
                return { [a]: await this.requestData(a, (c,d) => callBack?.(c,d as any))};
              })
            );
            if (!dependRes) {
              throw new Error("Dependency failed");
            }
            const dependRequest = dependRes.reduce((a, b) => {
              return { ...a, ...b };
            }, {}) as PropertyData;

            const input = request.paramTransformer(
              this.identification,
              this.data
            );
            const inputArr = input.every((a) => Array.isArray(a))
              ? input
              : [input];
            if (inputArr[0]?.length === 0) {
              throw new Error("Missing value from dependencies");
            }
            const resArr = await Promise.all(
              inputArr.map(
                (input) =>
                  request.func.apply(request.hasThis ?? null, input).catch((a) => {console.error(key, ": Error on fetch"); return undefined}) as Promise<
                    PropertyData[T]
                  >
              )
            ) || []
            const result = resArr.filter((a) => a).map((res) => request.returnTransformer(res));
            return result.length === 1 ? result[0] : result;
          }
          const input = request.paramTransformer(this.identification);
          const inputArr = input.every((a) => Array.isArray(a))
            ? input
            : [input];
          const resArr = await Promise.all(
            inputArr.map(
              (input) =>
                request.func.apply(request.hasThis ?? null, input).catch((a) => {console.error(key, ": Error on fetch"); return undefined}) as Promise<
                  PropertyData[T]
                >
            )
          );
          const result = resArr.filter((a) => a).map((res) => request.returnTransformer(res));
          return result.length === 1 ? result[0] : result;
        } catch (err: any) {
          // if (err.message !== undefined) {
          //   throw { message: err.message}
          // }
          if (err) {
            throw err;
          }
          throw new Error("Failed to make connection");
        }
      };
      const value = asyncFunc();
      this.loading[key] = value;
      await value
        .then((a: PropertyData[keyof PropertyData]) => {
          this.data[key] = a;
          delete this.loading[key];
          callBack?.({status:"Ready",data:a}, key);
          return a;
        })
        .catch((b: any) => {
          callBack?.({ status: "Error", error: b?.msg || b }, key);
          console.error(b);
          if (this.loading[key]) {
            delete this.loading[key];
          }
          this.errors[key] = { error: b?.msg || b?.message || b };
        });
      callBack?.(this.getData(key), key);
      return await value;
    } else {
      callBack?.(this.getData(key), key);
      if (this.loading[key]) {
        let value = this.loading[key];
        await value;
        return value;
        callBack?.(this.getData(key), key);
      }
    }
  }
}
