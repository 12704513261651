
import {DemographyReport, DemographyReportMetaData} from './DemographyReport';
import { MitBody, MitDialog, MitHeader } from './ComponentUtils';

import {Localization, SettingsManager} from "@viamap/viamap2-common";
import { SheetFunc } from '../managers/SheetFunc';
import { ProtectedFeature } from './ProtectedFeature';
import { Feature } from 'src/states/ApplicationState';
import { ExportData } from 'src/managers/ExportData';
import { GlassButton } from './GlassButtons';
import { ADModalBody, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { ViewButtonIcon } from 'src/propertyInfoTemplates/PropertyComponents';
import { BsDownload } from 'react-icons/bs';
import { FiPrinter } from 'react-icons/fi';
import { useContext } from 'react';
import { MapitStateContext } from 'src/states/MapitState';
// import '../css/mit-controls.css';

type Props = {
    showWindow?:number;
    onClose: () => void;
};

export function ReportResultDialog(props:Props) {
  const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
  const languageCode= SettingsManager.getSystemSetting("demographyReportLanguageCode", "da");

  const data = mapitState.demographyReport?.result;
  const metaData = mapitState.demographyReport?.metadata;

  if (!metaData || !data) {
    return null;
  }

  function onClickDownload() {
    if (!data || !metaData) {
      return;
    }

    let ws = SheetFunc.create360NDataReport(metaData, data);
    let blob = SheetFunc.createBlob(ws);              
    let fileName = Localization.getText("FileName:Report") +".xlsx";
    ExportData.downloadBlob(fileName, blob);
  }

      let downloadButton = (
        <ViewButtonIcon
            onClick={()=> onClickDownload()}
            title={Localization.getText("Download")}
        >
          <BsDownload />
        </ViewButtonIcon>
      );
      let printButton = (
        <ViewButtonIcon
          onClick={()=>  {   print()}}
          title={Localization.getText("Print")}
        >
          <FiPrinter />
        </ViewButtonIcon>
      );

      // When this window is shown printing should not contain background map too
      let element:HTMLElement|null;
      element = document.getElementById("mit-mainwindow-map");
      if (props.showWindow) {
        element && element.classList.add("no-print");
      } else {
        element && element.classList.remove("no-print");
      }

      let contents = props.showWindow ? (
        <AdvancedDragModal onClose={props.onClose} 
          title={Localization.getTextSpecificLanguage(languageCode,"Demography Report")}
          PosDefault={{width:"1200px", height: "calc(100% - 50px)", top: "50px", left:"20px"}}
          saveKey="DemoGraphy"
          variant={"NSWhite"}
          subHeaderContent={
            <>
            {downloadButton}
            <ProtectedFeature feature={Feature.PrintPropertyInfo} contentsIfNoAccess={<></>}>
              {printButton}
            </ProtectedFeature>
            </>
          }
        >
          <ADModalBody>
              <DemographyReport
                showWindow={true}
                data={data}
                metaData={metaData}
                callback={() => handleDismiss()}
              />
          </ADModalBody>
        </AdvancedDragModal>
      ) : null;

      return contents;
  }
