import { retry } from "src/states/ProjectState";
import { message } from "./Message";


export class MitWebsocket {
   static instance: null | MitWebsocket = null;
   static initiated = false;
   static subscriptions:{[key: string] : ((data) => void)[]} = {};
   websocket: null | WebSocket;
   
   static getInitiated(): MitWebsocket | undefined {
      if (MitWebsocket.initiated) {
         return MitWebsocket.getInstance()
      }
      return undefined;
   }
   
   static closeInstance() {
      if (MitWebsocket.instance) {
         MitWebsocket.instance?.websocket?.close?.();
         MitWebsocket.instance = null;
         MitWebsocket.initiated = false;
      }
   }
   
   static getInstance() {
      if (MitWebsocket.instance == null) {
         MitWebsocket.instance = new MitWebsocket();
         MitWebsocket.initiated = true;
      }
      return MitWebsocket.instance;
   }
   
   
   static subscribe<T extends keyof WebSocketActions>(channel:T, callback: (data: WebSocketActions[T]) => void) { 
      if (!(channel in MitWebsocket.subscriptions)) {
         MitWebsocket.subscriptions[channel] = [];
      }
      MitWebsocket.subscriptions[channel].push(callback);
   }
   
   static unsubscribe(channel, callback) {
      MitWebsocket.subscriptions[channel] = MitWebsocket.subscriptions[channel].filter((cb) => cb !== callback);
   }

   static callSubscriptions(data:WebSocketActions[keyof WebSocketActions]) {
      if (data.action in MitWebsocket.subscriptions) {
         MitWebsocket.subscriptions[data.action].forEach((cb: any) => cb(data));
      }
   }
   
   constructor() {
      const onmessage = (event) => {
         const data = JSON.parse(event.data);
         if (data.action in MitWebsocket.subscriptions) {
            MitWebsocket.subscriptions[data.action].forEach((cb: any) => cb(data));
         }
      }
      this.websocket = null;
      try {
         this.websocket = new WebSocket('wss://ws.viamap.net');
      

         this.websocket.onmessage = onmessage;
         this.websocket.onclose = (ev) => {
            MitWebsocket.closeInstance();
            console.log("Websocket closed", ev);
         if (!ev.wasClean) {
            MitWebsocket.callSubscriptions({action:"ping", data:{connections:0}});
            setTimeout(() => {
               return MitWebsocket.getInstance()
            }, 5000);
         }
         // MitWebsocket.closeInstance();
         }
         return this
      } catch (e) {
         setTimeout(() => {
            return MitWebsocket.getInstance();
         }, 5000);
      }
   }
   
   static send<t extends keyof WebSocketActions>(data: WebSocketActions[t]) {
      let x = MitWebsocket.getInitiated()
      try {
         if (x && x.websocket?.readyState == 1) {
            x.websocket?.send(JSON.stringify(data));
         } else {
            console.log("Websocket not connected");
            message.error("Websocket not connected");
         }
      } catch (e) {
         console.log("Websocket not connected", e);
         message.error("Websocket not connected");
      }
   }
}

export type WebSocketActions = {
   "broadcast": {action:"broadcast", data:string},
   "unknown": {action:"unknown", msg:string},
   "joinProject": {action:"joinProject", data: {project: string}},
   "editProject": {action:"editProject", data: {project: string, sfeNr: string}},
   "ping": {action: "ping", data: {connections: number}}, // called periodically to keep connection alive, data.connections is the number of connections
   "recieved": {action: "received", id?: string | number},
}
