import { ProtectedFeature } from "./ProtectedFeature";

import ViamapLogo from '../svg/ViamapIcon.svg';
import EstateExLogo from '../svg/Estate_Explorer_logo.svg';

import { Feature } from "src/states/ApplicationStateFeatures";
// import { UserPopDown } from "./NavigationBar";


export function NavigationBarSimple(props: {title: string, children: any}) {
  return     <>
  <div className="NavigationTopBar" id="Mit-NavigationTopBar">
  <div className="TopMenu TopMenuGrid">
    <div className="group">
      <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={
        <img className="" src={ViamapLogo} alt="viamap" width={"48px"} height={"48px"} style={{zIndex:-1}} />
      }>
        <img className="" src={EstateExLogo} alt="Estate Explorer" height={"48px"} onClick={() => location.reload()} style={{zIndex:1, height:"48px", cursor:"pointer" ,width:"unset", marginLeft: "16px", marginRight: "32px",}} />
      </ProtectedFeature>
    </div>
    <div className="group">
      <h2>{props.title}</h2>
    </div>
    <div className="group" style={{justifyContent:"flex-end"}}>
      {/* <UserPopDown /> */}
    </div>
    </div>
    </div>
    {props.children}
    </>
}