import polylabel from "polylabel"

import { useContext, useEffect, useState } from "react";
import { actionSetErrorMessage, AppMessagesContext, Localization } from "@viamap/viamap2-common";

import ViamapLogo from '../svg/ViamapIcon.svg'
import EstateExLogo from '../svg/Estate_Explorer_logo.svg'

import { actionAddDataLayer, actionSetFeatureLayerVisibility, actionSetMapInteractionState, actionSetMapInteractionStateDefault, actionSetShowUserLocation, MapInteractionState, MapitStateContext } from "src/states/MapitState";
import { LayerFunc } from "src/managers/LayerFunc";
import { ProtectedFeature } from "./ProtectedFeature";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { MitLatLng } from "src/managers/MapFacade";
import { SearchBarGeneral } from "./SearchBarGeneral";
import { AddressAutocomplete, CVRNumberAutocomplete, CVRNavnAutocomplete, CVRPersonAutocomplete, EjerlavAutocomplete, EjerNavnAutocomplete, MatrikelAutocomplete, VejAutocomplete, FeatureLayerAutoComplete, StednavnAutoComplete } from "src/managers/SearchBarGeneralPlugin";
import { PropertyInformation } from "src/managers/PropertyInformation";
import { MapFacadeMaplibre } from "src/managers/MapFacadeMaplibre";
import { BsArrowLeftRight, BsCrosshair } from "react-icons/bs";
import { Gps } from "iconsax-react";
import { PiMapPinFill } from "react-icons/pi";
import { MapInteractionStateInfoBox } from "./MapInteractionStateInfoBox";
import { BacktoMapit3Banner } from "./BacktoMapit3Banner";
import { toggleWindow, useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, openWindow, sendParams, WindowId, WindowState } from "src/WindowManager/WindowState";
import { FiAlignRight, FiBell } from "react-icons/fi";
import { MitWebsocket, WebSocketActions } from "./websocket";

export function NavigationBarMicro(
  props: {onCollapse: () => void}
) {
  
  const {dispatch:appMessageDispatch} = useContext(AppMessagesContext);
  const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
  const { hasAccessToFeature } = useContext(ApplicationStateContext);
  const {state: windowState, dispatch: windowDispatch} = useWindowContext();

  

  // ------------------------------- HANDLERS --------------------------------------


  async function drawMatrikelLocation(matrNr: string, ejerlavskode: number, betegnelse: string): Promise<void> {
    let res = await PropertyInfoInterface.getGeojsonOfCadasters([{matrikelNr: matrNr, ejerlavKode:ejerlavskode}])

    if (res.features[0]) {
      let [x,y] = polylabel(res.features[0].geometry.coordinates[0], 0.00001)
      let layerInfo = LayerFunc.createLayerInfoForOnePoint(new MitLatLng(y, x), betegnelse + " " + matrNr, {...res.features[0].properties});
     
      windowDispatch(openWindow(WindowId.ShowSearchField));
      
      if (hasAccessToFeature(Feature.ThemeNewSec)) {
        layerInfo = LayerFunc.createLayerInfoForOnePoint(new MitLatLng(y, x), betegnelse + " " + matrNr, {});
      }
      mapitStateDispatch(actionAddDataLayer(layerInfo, true, true));
      windowDispatch(sendParams(WindowId.PropertyInformationDialog, {latlng:new MitLatLng(y, x)}));
    } else {
        appMessageDispatch(actionSetErrorMessage(Localization.getText("No data for given cadaster")));
    }
  }

  /* ------------------------------------------------------- */
  return (
    <>
    <div className="NavigationTopBar small" id="Mit-NavigationTopBar" style={{zIndex:1,pointerEvents:"none"}}>
        <div className={"OnMapButton Collapse"} onClick={() => props.onCollapse()} >
          <BsArrowLeftRight />
        </div>
        
        <ProtectedFeature feature={Feature.DeviceLocation} contentsIfNoAccess={<></>} >
        <div className={"OnMapButton " + (mapitState.showUserLocation ? "ActiveImg":"")} 
              onClick={(e)=>{
                mapitStateDispatch(actionSetShowUserLocation(!mapitState.showUserLocation));
              }}
               >
          <Gps variant={mapitState.showUserLocation ? "Bold" : undefined} />
            </div>{/*<MdLocationSearching />*/}
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.BasicMap} contentsIfNoAccess={<></>} >
        <div className={"OnMapButton " + (mapitState.mapInteractionState == MapInteractionState.ClickToSetMarker ? "ActiveImg":"")} 
              onClick={(e)=>{
                if (MapInteractionState.ClickToSetMarker !== mapitState.mapInteractionState) {
                  mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.ClickToSetMarker)) 
                } else {
                  mapitStateDispatch(actionSetMapInteractionStateDefault())
                }
              }}
               >
          <PiMapPinFill />
            </div>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.SearchBar} contentsIfNoAccess={<></>}>
          <SearchBarGeneral
          showWindow={true}
          autocompleters={[
            new EjerlavAutocomplete(), // If selected sets context limiting other searches
            new VejAutocomplete(), // If selected sets context limiting other searches
            new MatrikelAutocomplete((data:any)=> {
              drawMatrikelLocation(data.matrikelnummer, data.ejerlavskode, data.visningstekst); // NB Async
              
            }),

            new AddressAutocomplete((data:any)=> {
              let createPoint=true;
              // convert coordinates from UTM32 to WGS84
              let wgsCoords = data.geometri.coordinates[0].toReversed();
              if (createPoint) {
                let layerInfo = LayerFunc.createLayerInfoForOneAddressV2(data.vejnavn, data.husnummer, wgsCoords.reverse());
                mapitStateDispatch(actionAddDataLayer(layerInfo, true));

                (sendParams(WindowId.PropertyInformationDialog, {latlng:new MitLatLng(wgsCoords[1], wgsCoords[0])}));
              } else {
                windowDispatch(sendParams(WindowId.PropertyInformationDialog, {latlng:new MitLatLng(wgsCoords[1], wgsCoords[0])}));
              }


            }),
            new CVRNumberAutocomplete((data:any)=> {
              // Open CompanyInfo for cvr = data.cvrNummer
              windowDispatch(openWindow(WindowId.CompanyInformationDialog, {cvrNr:data.cvrNummer}));
            }),
            new CVRNavnAutocomplete((data:any)=> {
              // Open CompanyInfo for cvr = data.cvrNummer
              windowDispatch(openWindow(WindowId.CompanyInformationDialog, {cvrNr:data.cvrNummer}));
            }),
            new CVRPersonAutocomplete((data:any)=> {
              // Open PersonInfo for navn og cvr person enhedsnummer
              let gaeldendeNavn = data.navne.find((n:any) => { return n.periode.gyldigTil === null});
              let adr = data.deltagerpersonMetadata?.nyesteBeliggenhedsadresse;
              let postnr = adr?.postnummer;
              let adresse = adr?.vejnavn+ " "+adr?.husnummerFra; // Todo: bogstav og side...
              windowDispatch(openWindow(WindowId.PersonInformationDialog, {
                navn:gaeldendeNavn.navn, 
                postnr: postnr, 
                adresse: adresse, 
                vejnavn: adr?.vejnavn, 
                husnr: adr?.husnummerFra, 
                foedselsdato: undefined, 
                cvrPersonId:data.enhedsNummer}));
            }),
            new EjerNavnAutocomplete((data:any, searchText?:string)=> {
              // If the property is owned by more than one person. Make sure we show information for the person we searched for.
              let owners = data?.property_record?.hits?.hits?.[0]?._source?.ejf_ejere_liste
                || data?.property_record?.hits?.hits?.[0]?._source.ejf_ejere.split(",").filter((a) => a).map((a) => ({navn:a.trim()})) || []

              let selectedOwnerData = PropertyInformation.propertyOwnerMatchingName(owners, searchText ||"");
              windowDispatch(openWindow(WindowId.PersonInformationDialog, {
                navn:selectedOwnerData.navn, 
                postnr: selectedOwnerData.postnr, 
                foedselsdato: selectedOwnerData.foedselsdato && new Date(selectedOwnerData.foedselsdato), 
                adresse: selectedOwnerData.adresse
              }));
            }),
            new StednavnAutoComplete((data:any) => {
              (mapitState.map as MapFacadeMaplibre).zoomToData(data.bbox, 150)
            }),
            // new BFEAutocomplete((data:any)=> {
            //   // todo: Open PropertyInfo for bfe = data.bfe_nr
            //   alert("not implemented yet");
            // }),
            new FeatureLayerAutoComplete(Object.values(mapitState.featureLayers), mapitState.selectedFeatureLayerKeys , (selected) => {
              const show = !mapitState.selectedFeatureLayerKeys.includes(selected.layer.label)
              const title = selected.layer.translationTable?.[Localization.getLanguage()]?.[selected.layer.label] || selected.layer.label
              mapitStateDispatch(actionSetFeatureLayerVisibility(title, show, [{key: selected.layer.label, layer: selected}], true));
            })
          ]}
          callbackOnSelection = {(type:string, props:any) => { alert (`got callback Type:${type} props: ${JSON.stringify(props)}`)} }
          />
        </ProtectedFeature>
        <MapInteractionStateInfoBox />
        <BacktoMapit3Banner />
        </div>
        <div style={{position:"absolute", right:"0px", padding:"10px", gap:"10px", display:"flex", flexDirection:"column", alignItems:"center"}}>
        <ProtectedFeature feature={Feature.WebsocketGlobalChat} contentsIfNoAccess={<></>} >
          <NotificationButton />
        </ProtectedFeature>
        <MapDescriptionButton />
        
        </div>

    </>
  )
}


function NotificationButton() {
  const {state: windowState, dispatch: windowDispatch} = useWindowContext();
  const [Notifications, setNotifications] = useState(0);
  const [connections, setConnections] = useState(0);

  useEffect(() => {
    const onMessage = (data: WebSocketActions["ping"]) => {
      data.data.connections && setConnections(data.data.connections);
    };
    MitWebsocket.subscribe("ping", onMessage);
    return () => {
      MitWebsocket.unsubscribe("ping", onMessage);
    }

  },[]);

  useEffect(() => {
    if (windowState.find((a) => a.id === WindowId.ChatWindow)?.state !== WindowState.Open) {
      let onMessage = (data: WebSocketActions["broadcast"]) => {
        setNotifications((old) => old+1);
      };
      

      MitWebsocket.subscribe("broadcast", onMessage);
      return () => {
        MitWebsocket.unsubscribe("broadcast", onMessage);
      }
    }
  },[windowState]);

  
  return (
    <div className={"OnMapButton"+ (Notifications ? " circle" : "") + (connections ? " square":"") } data-users={connections} data-notis={Notifications > 99 ? "!+" : Notifications} style={{marginLeft:"auto"}}
      onClick={() => {
        if (windowState.find((a) => a.id === WindowId.ChatWindow)?.state === WindowState.Open) {
          windowDispatch(closeWindow(WindowId.ChatWindow));
        } else {
          windowDispatch(openWindow(WindowId.ChatWindow));
          setNotifications(0);
        }
      }}
    >
      <FiBell />
    </div>
  )
}

export function MapDescriptionButton() {
  const {state: windowState, dispatch: windowDispatch} = useWindowContext();
  const {state: mapitState} = useContext(MapitStateContext);
  const { hasAccessToFeature } = useContext(ApplicationStateContext);

  if (!(mapitState.mapInfo.mapDescription || hasAccessToFeature(Feature.EditMapDescription))) {
    return <></>
  }

  return (
      <div className={"OnMapButton" + (mapitState.mapInfo.mapDescription ? " square":"")} data-users={"!"} onClick={() => {
        windowDispatch(toggleWindow(windowState, WindowId.MapDescription));
      }} >
        <FiAlignRight />
      </div>
  )
}