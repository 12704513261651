import { Fragment, useContext, useEffect, useState } from "react";
import JSZip from 'jszip';
import { Col, Row, Table } from "react-bootstrap"
import { AppMessagesContext, Localization, SettingsManager, Utils, actionClearInfoMessage, actionClearMessages, actionSetErrorMessage, actionSetInfoMessage, actionSetProgressMessage } from "@viamap/viamap2-common";
import { MitLatLng } from "src/managers/MapFacade"
import { MapitStateContext, MitLayerId, actionSetMapInteractionStateDefault } from "src/states/MapitState";
import { CatchmentIcons, CatchmentSelectionState } from "./CatchmentSelector";
import { LayerInfo, LayerType, MitDataType } from "src/common/managers/Types";
import { MapitUtils } from "src/managers/MapitUtils";
import { CatchmentHelperMaplibre } from "src/managers/CatchmentHelperMaplibre";
import { CatchmentInterface, TravelMode, TravelSource, TravelTarget } from "src/managers/CatchmentInterface";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { FormatUtils } from "src/managers/FormatUtils";
import { ProtectedFeature } from "./ProtectedFeature";
import { LegendScaleForCatchment } from "./LegendScaleForCatchment";
import { CatchmentState, CalculationMode, CatchmentStateContext, actionGenerateCatchmentSimple, actionSetCatchmentState } from "src/states/CatchmentState";
import { ExportData } from "src/managers/ExportData";
import { ADModalBody, ADModalFooter, ADModalInfo, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassButton } from "./GlassButtons";
import { GlassCheckbox, GlassInfo, LegendGrid } from "./MitGlassComponents";
import { GlassInputGroup, GlassTextInputTransformer } from "./GlassInput";
import { GlassFoldUdBox } from "src/propertyInfoTemplates/PropertyComponents";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, openWindow, WindowId } from "src/WindowManager/WindowState";

type CalculationResult = {
  name?: string,
  travelTime: number,  // seconds
  distance?: number,   // meters
  segments?: any[]  // after routing
}

type DataMap = {
  layerId: number,
  [MitDataType.CatchmentTargetName]?: string,
  [MitDataType.CatchmentWindowStartTime]?: string,
  [MitDataType.CatchmentWindowEndTime]?: string,
  [MitDataType.CatchmentFileName]?: string,
} | undefined;

export type DialogMode = "PointToPoint"|"Polygons";

export function MultiPointTravelDialog (
  props: { showWindow: number, 
  callbackOnClose: () => void, 
  callbackOnExport?: (fileName:string, polygonsGeoJSON:any) => void,
  options: {dialogMode:DialogMode, latlng?:MitLatLng, layerId?:MitLayerId}}
  ) {
  const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
  const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
  const { state: catchmentState, dispatch: catchmentStateDispatch } = useContext(CatchmentStateContext);
  const { hasAccessToFeature } = useContext(ApplicationStateContext);
  const { dispatch: windowDispatch } = useWindowContext();

  const NO_OF_RESULTS_TO_SHOW = 5; // Matches the max amount of sources that Targomo can calculate in one go. This limits the TRANSIT calculation only. Not other Modes of Transport.

  // const [localCatchmentState, setLocalCatchmentState] = useState<CatchmentState>(defaultCatchmentState);
  // const [isEditingLegend, setIsEditingLegend] = useState(false)
  const [availableLayers, setAvailableLayers] = useState<any>([]);
  const [selectedLayerId, setSelectedLayerId] = useState<number>();
  // const [selectedLayerInfo, setSelectedLayerInfo] = useState<LayerInfo>();
  const [dataSourceSpecificLayer, setDataSourceSpecificLayer] = useState<boolean>(true);
  const [useDefaultFromSettings, setUseDefaultFromSettings] = useState<boolean>(true);
  const [selectedColumnIdParam1, setSelectedColumnIdParam1] = useState<string>("");
  const [selectedColumnIdParam2, setSelectedColumnIdParam2] = useState<string>("");
  const [selectedColumnIdParam3, setSelectedColumnIdParam3] = useState<string>("");
  const [calculationResults, setCalculationResults] = useState<CalculationResult[]>();
  const [noOfResultsToShow, setNoOfResultsToShow] = useState<number>(NO_OF_RESULTS_TO_SHOW);
  const [dataByType, setDataByType] = useState<DataMap>(undefined);
  const [legendIsInEditMode, setLegendIsInEditMode] = useState<boolean>(false);
  const [calculateAndDownloadSeparately, setCalculateAndDownloadSeparately] = useState<boolean>(false);
  const [selectedColumnIdParam4, setSelectedColumnIdParam4] = useState<string>("");
  const [downloadFilePrefix, setDownloadFilePrefix] = useState<string>(Localization.getText("Travel Time"));

  // After catchment is generated the dialog is replaced with small catchment selector
  const [showCatchmentSelectorState, setShowCatchmentSelectorState] = useState<boolean>(false);

  useEffect(() => {
    if (props.showWindow) {
      windowDispatch(closeWindow(WindowId.CatchmentSelector))
    } else {
      CatchmentInterface.clearCache();
    }
    mapitStateDispatch(actionSetMapInteractionStateDefault())
  }, [props.showWindow]);

  useEffect(() => {
    let relevantLayers = Object.values(mapitState.layers).filter((layerInfo: LayerInfo) => { return (!layerInfo.hasNoDataBehind && layerInfo.datasheet) || layerInfo.type === LayerType.GeoJSON_Point});
    let relevantLayerKeys = relevantLayers.map((lr) => { return lr.layerId}).sort();
    let availableLayerKeys = availableLayers.map((lr) => { return lr.layerId}).sort();
    // only update if there are new layers - not on any (e.g. data or styling change)
    if (!(relevantLayerKeys+"" === availableLayerKeys+"")) {
      setAvailableLayers(relevantLayers);
      if (relevantLayers && relevantLayers.length > 0) {
        let found = relevantLayers.find((lr) => lr.layerId === selectedLayerId);
        if (!found) {
          // default to first available layer
          setSelectedLayerId(relevantLayers[0].layerId);
        }
      } else {
        setSelectedLayerId(undefined);
      }
    }
  }, [mapitState.layers]);

  useEffect(() => {
    // reset counter when results are updated
    setNoOfResultsToShow(NO_OF_RESULTS_TO_SHOW);
  }, [calculationResults]);

  useEffect(() => {
    // reset results when moved to a new location or when changing parameters
    setCalculationResults(undefined);
  }, [props.options, selectedLayerId, useDefaultFromSettings, selectedColumnIdParam1, selectedColumnIdParam2, selectedColumnIdParam3]);

  
  useEffect(() => {
    // Clear selections when selected layerid is changed
    clearFieldSelections();
  }, [selectedLayerId]);

  useEffect(() => {
    // Clear parameters then changing source of parameters
    if (useDefaultFromSettings) {
      setDataByType(undefined);
      clearFieldSelections();
    }
  }, [useDefaultFromSettings]);

  function coordToName(latlng: MitLatLng) { return latlng.lat.toFixed(1) + "," + latlng.lng.toFixed(1); }



  function prop(feature,dataColumn) {
    return feature.properties[dataColumn]
  }

  function clearFieldSelections() {
    setSelectedColumnIdParam1("");
    setSelectedColumnIdParam2("");
    setSelectedColumnIdParam3("");
    setSelectedColumnIdParam4("");
  }

  /**
   * Extracts points to calculate. From layers and possibly also getting properties from selected columns
   * @returns 
   */
  let getPoints = function (): TravelSource[] {
    let result: TravelSource[] = [];
    // get points from selected layer - or from all visible point layers if no layer is selected.
    Object.keys(mapitState.layers).filter((key) => !selectedLayerId || key === ""+selectedLayerId).forEach((key, i) => {
      let layer = mapitState.layers[key];
      if (MapitUtils.isPointLayer(layer.type) && (layer.visible || key === ""+selectedLayerId)) {
        switch (layer.type) {
          case LayerType.GeoJSON_Point: {
            let name, early, late, fileName;
            if (dataByType?.layerId === layer.layerId) {
              name = dataByType["CatchmentTargetName"]
              early = dataByType["CatchmentWindowStartTime"]
              late =  dataByType["CatchmentWindowEndTime"]
              fileName = dataByType["CatchmentFileName"];
            }
            // ToDo: check for 'crs'. That it is lat/lng
            let featureCollection = layer.geoJson;
            featureCollection.features.forEach((feature, idx) => {
              let [lng, lat, ..._] = feature.geometry.coordinates as number[]
              let pName = prop(feature, name) || layer.datasetname + "-line" + (idx + 1) || "" + i * 1000 + idx;
              let pEarl = convertToSecondsSinceMidnightIfPresent(prop(feature, early)) || 0; 
              let pLate = convertToSecondsSinceMidnightIfPresent(prop(feature, late))
              if (!pLate) {
                let val = SettingsManager.getSystemSetting("catchmentStateDefaults.time", undefined, true);
                pLate = val;
              } 
              let fName = prop(feature, fileName);
              
              let latlng = new MitLatLng(lat, lng);
              result.push({ latlng, name: pName || `${layer.datasetname}-"line" + ${(idx + 1) || "" + i * 1000 + idx} `, earliestTime:pEarl, latestTime:pLate, fileName:fName });
            });
            break;
          }
        }
      }
    });
    return result;
  };

  /**
   * Calculates distance and travel time to the closest points in the target list (layer).
   */
  function doMultipointCalculation() {
    // Verify that all preconditions are met
    // call CatchmentInterface for each pair.
    // Visualize the result

    let points = getPoints();
    if (points.length > 0) {
      if (props.options.layerId){
        // filter the point that was clicked - and which is the center of the calculation
        // ToDo:
      }

      try {
        appMessageDispatch(actionSetInfoMessage(Localization.getText("Calculating...")));

        CatchmentInterface.getTravelTimeFromServer(
          catchmentState.selectedModeOfTransport as TravelMode,
          points.map<TravelTarget>((pt, idx) => {
            return {
              ...pt,
              name: pt.name || "source" + idx,
              latestTime: SettingsManager.getSystemSetting("catchmentStateDefaults.time", false) || 8 * 60 * 60
            }
          }),
          [{
            latlng: props.options.latlng,
            name: "Punkt" + coordToName(props.options.latlng!),
            latestTime: SettingsManager.getSystemSetting("catchmentStateDefaults.time", false) || 8 * 60 * 60
          } as TravelSource],
          {
            reverse: SettingsManager.getSystemSetting("catchmentStateDefaults.reverse", false),
            earliestArrival: SettingsManager.getSystemSetting("catchmentStateDefaults.earliestArrival", true),
            date: SettingsManager.getSystemSetting("catchmentStateDefaults.date", undefined, true) ?? DateToDay(),
            duration: SettingsManager.getSystemSetting("catchmentStateDefaults.duration", 180) * 60,
            rushHourMode: catchmentState.rushHourMode
          }
        )
          .catch((error) => {
            appMessageDispatch(actionClearInfoMessage());
            appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Error:Generating Catchment {error}", { error: (error.message || error) })));
            setCalculationResults(undefined);
          })
          .then((result) => {
            appMessageDispatch(actionClearInfoMessage());

            if (!(result && result.length === 1)) {
              throw Utils.createErrorEventObject("Expected exactly one source. Found:" + (result ? result.length : "null"));
            }
            let rec = result[0];
            let res: CalculationResult[] = rec.targets.map((targ) => {
              return {
                name: targ.id,
                travelTime: targ.travelTime, // seconds
                distance: targ.length   // meters
              }
            })
            setCalculationResults(res);

            if (catchmentState.selectedModeOfTransport as TravelMode === TravelMode.transit) {
              // find the 5 nearest targets for more detailed analysis. Targomo license allows only 5.
              let nearestTargets = rec.targets.map((res) => {
                let pt = points.find((pt) => pt.name === res.id);
                if (!pt) {
                  throw Utils.createErrorEventObject("Point not found matching result");
                }
                return { ...pt, travelTime: res.travelTime, distance: res.length }
              }).sort((a, b) => a.travelTime - b.travelTime).filter((val, idx) => idx < NO_OF_RESULTS_TO_SHOW);

              if (nearestTargets && nearestTargets.length > 0) {
                doRouting(nearestTargets);
              }
            }

            // Visualization of results:::
            // let layer = CatchmentHelperMaplibre.createLayerForRoutes(result.routes, {});
            // mapitState.map.updateLayer(layer);
            //  let layerInfo = LayerFunc.createLayerInfoForManyPoints(viewTag, points);
            //  mapitStateDispatch(actionAddDataLayer(layerInfo, false, true));
            //  appMessageDispatch(actionSetInfoMessage(Localization.getText("New map layer created from project")+" "+viewTag));

          })

      } catch (error: any) {
        appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Error:Generating Catchment {error}", { error: (error.message || error) })));
        setCalculationResults(undefined);
      }
    } else {
      appMessageDispatch(actionSetErrorMessage(Localization.getText("Error:No points found")));
      setCalculationResults(undefined);
    }
  }

  function doRefinedCalculation(points: TravelSource[]) {

    appMessageDispatch(actionSetInfoMessage(Localization.getText("Calculating...")));

    CatchmentInterface.getTravelTimeFromServer(
      catchmentState.selectedModeOfTransport as TravelMode,
      [{
        latlng: props.options.latlng,
        name: "Bolig" + coordToName(props.options.latlng!)
      } as TravelSource],
      points.map<TravelTarget>((pt, idx) => {
        return {
          ...pt,
          name: pt.name || "source" + idx,
        }
      }),
      {
        reverse: SettingsManager.getSystemSetting("catchmentStateDefaults.reverse", false),
        earliestArrival: SettingsManager.getSystemSetting("catchmentStateDefaults.earliestArrival", true),
        date: SettingsManager.getSystemSetting("catchmentStateDefaults.date", undefined, true) ?? DateToDay(),
        duration: SettingsManager.getSystemSetting("catchmentStateDefaults.duration", 180) * 60,
        rushHourMode: catchmentState.rushHourMode
      }
    )
      .catch((error) => {
        appMessageDispatch(actionClearInfoMessage());
        appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Error:Generating Catchment {error}", { error: (error.message || error) })));
        setCalculationResults(undefined);
      })
      .then((result) => {
        appMessageDispatch(actionClearInfoMessage());

        let res: CalculationResult[] = result.map((rec) => {
          if (!(rec.targets && rec.targets.length === 1)) {
            throw Utils.createErrorEventObject("Exactly one target per rec expected");
          }
          let targ = rec.targets[0];
          return {
            name: rec.id,
            travelTime: targ.travelTime, // seconds
            distance: targ.length   // meters
          }
        })
        setCalculationResults(res);

        doRouting(points);
        // Visualization of results:::
        // let layer = CatchmentHelperMaplibre.createLayerForRoutes(result.routes, {});
        // mapitState.map.updateLayer(layer);
        //  let layerInfo = LayerFunc.createLayerInfoForManyPoints(viewTag, points);
        //  mapitStateDispatch(actionAddDataLayer(layerInfo, false, true));
        //  appMessageDispatch(actionSetInfoMessage(Localization.getText("New map layer created from project")+" "+viewTag));
      })

  }

  function doRouting(points: TravelSource[]) {
    // ----------------------------------- create routes for debugging

    appMessageDispatch(actionSetInfoMessage(Localization.getText("Calculating...")));

    let source = [{
      latlng: props.options.latlng,
      name: "Bolig" + coordToName(props.options.latlng!)
    } as TravelSource];
    let targets = points.map<TravelTarget>((pt, idx) => {
      return {
        ...pt,
        name: pt.name || "source" + idx
      }
    });
    CatchmentInterface.getRouteFromServer( // use when visualizing routes
      catchmentState.selectedModeOfTransport as TravelMode,
      source,
      targets,
      {
        reverse: SettingsManager.getSystemSetting("catchmentStateDefaults.reverse", false),
        earliestArrival: SettingsManager.getSystemSetting("catchmentStateDefaults.earliestArrival", true),
        date: SettingsManager.getSystemSetting("catchmentStateDefaults.date", undefined, true) ?? DateToDay(),
        duration: SettingsManager.getSystemSetting("catchmentStateDefaults.duration", 180) * 60,
        rushHourMode: catchmentState.rushHourMode
      }
    )
      .catch((error) => {
        appMessageDispatch(actionClearInfoMessage());
        appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Error:Generating Catchment {error}", { error: (error.message || error) })));
        setCalculationResults(undefined);
      })
      .then((result) => {
        appMessageDispatch(actionClearInfoMessage());

        let res: CalculationResult[] = result.data.routes.map((rte) => {
          return {
            name: rte.source_id,
            travelTime: rte.travelTime, // seconds
            distance: rte.length,   // meters
            segments: rte.segments
          }
        })
        setCalculationResults(res);

        // Visualization of results:::
        // let lines = result.routes.map((rte) => {
        //   let sourceId = rte.source_id;
        //   let sourceLatLng = props.options.latlng;
        //   let targetId = rte.target_id;
        //   let targetLatLng = targets.find((tg) => tg.name === targetId)?.latlng;
        //   return turf.lineString([turf.point([sourceLatLng?.lng,sourceLatLng?.lat]),turf.point([targetLatLng?.lng, targetLatLng?.lat])], {time:rte.travelTime, distance:rte.length});
        // });
        // let layer = CatchmentHelperMaplibre.createLayerForLinesWithDistances(multiLines);
        // mapitState.map.updateLayer(layer);
        // setLocalCatchmentState({...localCatchmentState, polygons:result!, layerHandle:layer})
        // setShowCatchmentSelectorState(true);

      })
  }

  function doCreateCatchments(fileName: string, travelTimes: number[], modeOfTransport: CatchmentSelectionState,
    colorScale: any[], legendLabels: string[], calculateSeperately: boolean = false, download:boolean) {

    let points:TravelSource[] = getPoints();

    // Logger.logAction("MapScreen", "MultipleCatchments", "point count:" + points.length);
    if (points.length > 0) {

      try {

        // if individual properties are set for each source - we need to calculate separately and combine the results afterwards
        let calculateOneAtATime : boolean = !useDefaultFromSettings || calculateSeperately || modeOfTransport === "transitorbike";

        if (calculateOneAtATime) {
          appMessageDispatch(actionSetProgressMessage(Localization.getText("Retrieving Travel Times"),0));
        } else {
          appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving Travel Times")));
        }
        let fileNamesArr:string[]=[];
        if (calculateSeperately) {
          const hasDuplicates = (array: any[]) => Boolean(array.filter((item, index) => array.indexOf(item) !== index).length);
          fileNamesArr = points.map((ts, idx) => { 
            if (ts.fileName) {
              // Remove any characters not consistent with a filename
              return (""+ts.fileName).replace(/[\\/:"*?<>|]/g, '-');
            } else {
              let defaultPrefix:string = Localization.getText("Select column for file naming:defaultValue");
              return defaultPrefix+(idx+1)}
            }
          );
          if (hasDuplicates(fileNamesArr)) {
            throw new Error("Selected field for Filenames must not contain duplicates");
          }
        }

        CatchmentInterface.generateCatchmentGeoJSONMultiple(
          modeOfTransport as TravelMode,
          travelTimes,
          points,
          {...catchmentState.getTravelTimeOptions(), rushHourMode:catchmentState.rushHourMode},
          calculateOneAtATime,
          (started:number, completed:number) => {
            if (calculateOneAtATime) {
              appMessageDispatch(actionSetProgressMessage(Localization.getText("Retrieving Travel Times"),Math.round(completed*100/started)));
            }
          }          
        )
          .catch((error) => {
            appMessageDispatch(actionClearMessages());
            appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Error:Generating Catchment {error}", { error: (error.message || error) })));
          })
          .then((result:any) => {
            try {
            appMessageDispatch(actionClearMessages());
            // if doing multiple calculations - combine results
            if (Array.isArray(result) && result.length > 0) {
              function sortByTime(a,b) {
                return b.properties.time - a.properties.time;
              }
              let origResult = result;
              // if multiple calculations are to be downloaded in separate files (in one zip file)
              if (calculateSeperately && origResult.length) {
                const downloadGeoJsonList = (geoJson: JSON[], filenames: any[], fileName:string) => {
                  let zip = new JSZip();
                  let prefix = SettingsManager.getSystemSetting("catchmentSeparateZipfileNamesPrefix","TravelTime_");
                  prefix = prefix ? Localization.getText(prefix) : "";
                  geoJson.forEach((separateResult, index) => {
                    console.info(`index ${index} size ${JSON.stringify(separateResult).length}`);
                    zip.file(prefix + filenames[index] + ".geojson", JSON.stringify(separateResult));
                  });
                  zip.generateAsync({ type: "blob", compression:"DEFLATE" }).then(function (content: any) {
                    ExportData.downloadBlob(fileName + ".zip", content);
                  });
                };
                downloadGeoJsonList(origResult, fileNamesArr, fileName);
              }
              // We have to combine the results here...
              // Sort the buckets such that like data is added.
              result = {...origResult[0], features: origResult[0].features.sort(sortByTime)};
              origResult.forEach((featColl, idx) => {
                if (idx > 0) {
                   featColl.features.sort(sortByTime).forEach((ft, ftIdx) => {
                      // Find the right bucket to add features to - by travel time.
                      let resultIndex= ftIdx;
                      if (result.features[resultIndex].properties.time !== ft.properties.time) {
                        throw new Error(`Time ${ft.properties.time} not matching time at result=${result.features[resultIndex].properties.time}`);
                      }
                     result.features[resultIndex].geometry.coordinates = [...result.features[ftIdx].geometry.coordinates, ...ft.geometry.coordinates];
                   })
                }
              })

            }

            // Fallthrough to show polygon (possibly combined from several results)
            let layer = CatchmentHelperMaplibre.createLayerForPolygons(result, { travelTimes, colorScale, catchmentIn3d: true, heightFactor: 10 });
            mapitState.map.updateLayer(layer);
            let newState:CatchmentState = {...catchmentState, layerHandle:layer, polygons:result!, calculationMode: CalculationMode.MultiPoint_RegenerateFromMultiPointFormOnly};
            catchmentStateDispatch(actionSetCatchmentState(newState));
            windowDispatch(openWindow(WindowId.CatchmentSelector))
            windowDispatch(closeWindow(WindowId.PointDistanceTable))
          } catch (error:any) {
            appMessageDispatch(actionClearMessages());
            appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Error:Generating Catchment {error}", { error: (error.message || error) })));
          }
        })

      } catch (error: any) {
        appMessageDispatch(actionClearMessages());
        appMessageDispatch(actionSetErrorMessage(Localization.getFormattedText("Error:Generating Catchment {error}", { error: (error.message || error) })));
      }
    } else {
      appMessageDispatch(actionClearMessages());
      appMessageDispatch(actionSetErrorMessage(Localization.getText("Error:No points found")));
    }

  }

  function onFormPointsSubmit(e: any) {
    e && e.preventDefault();
    doMultipointCalculation();
  }

  function onFormPolygonSubmit(e: any) {
    e && e.preventDefault();
    doCreateCatchments("MultipleCatchment", catchmentState.travelTimes, catchmentState.selectedModeOfTransport, catchmentState.colorScale,
      catchmentState.legendLabels, calculateAndDownloadSeparately, true);
  }

  function validateForm() {
    if (legendIsInEditMode) {
      return false;
    }
    if (dataSourceSpecificLayer)
      return Boolean(selectedLayerId);
    else 
      return true;
  }

  
  type catmentMitType = MitDataType.CatchmentTargetName | MitDataType.CatchmentWindowStartTime | MitDataType.CatchmentWindowEndTime | MitDataType.CatchmentFileName;
  function copyValuesFromSelectedColumn(columnId: string, selectedLayerId: number, targetDataType: catmentMitType) {
    let layerInfo = mapitState.layers[selectedLayerId];
    let newDataByType:DataMap = {
        layerId: selectedLayerId,
    }
    newDataByType[targetDataType] = columnId

    if (dataByType?.layerId == layerInfo.layerId) {
      setDataByType((a) => (a ? {...a, ...newDataByType}:newDataByType))
      return
    }
    setDataByType(newDataByType);
  }

  // ------------------------------------------------------------------------ Presentation ----------------------------------------------------------

  const RenderLegend = () => {
    return (
        <div className="Legend">
          <LegendScaleForCatchment
          callBackOnEditStateChange={(isEditing) => {
            setLegendIsInEditMode(isEditing);
          }}/>
        </div>      
    );
  }

  const SelectColumn = (props: { title: string, layerId: any, sKey: string, onSelect: (value: string) => void }) => {
    let layerInfo = mapitState.layers[props.layerId];
    return (
      <>
        <select onChange={e => {
          props.onSelect(e.target.value);
        }}
          className="form-control input-sm"
          style={{ width: "auto" }}
          value={props.sKey}>
          <option key={"default"} value={""} >{Localization.getText("Use default")}</option>
          {layerInfo && layerInfo.propertiesInGeoJson?.map((property) => {
            return (
              <option key={"" + property} value={property} >{property}</option>
            )
          })}
        </select>
      </>
    )
  }
  const SelectLayer = (props: { title: string, onSelect: (value: number|undefined) => void }) => {
    return (
      <>
        <select onChange={e => {
          let layerId:number|undefined = Number.parseInt(e.target.value);
          if (Number.isNaN(layerId)) {
            layerId=undefined;
          }
          props.onSelect(layerId);
        }}
          className="form-control input-sm"
          style={{ width: "auto" }}
          value={selectedLayerId}>
          <option key={"default"} value={""} >{Localization.getText("PointSource:ChooseALayer")}</option>
          {availableLayers.map((layerInfo: LayerInfo, index) => {
        return (
          <option key={"" + layerInfo.layerId + index} value={layerInfo.layerId}>{layerInfo.datasetname}</option>
        );
      })}
        </select>
      </>
    )
  }
  /*
      <select onChange={e => {
      let layerId = Number.parseInt(e.target.value);
      setSelectedLayerId(layerId);

      // Clear selections of columns, and data from columns - must be repeated for the new data source
      setSelectedColumnIdParam1("");
      setSelectedColumnIdParam2("");
      setSelectedColumnIdParam3("");
      setSelectedColumnIdParam4("");
      setDataByType(undefined);
    } }
      className="form-control input-sm"
      style={{ width: "auto" }}
      value={selectedLayerId}>
      {availableLayers.map((layerInfo: LayerInfo, index) => {
        return (
          <option key={"" + layerInfo.layerId + index} value={layerInfo.layerId}>{layerInfo.datasetname}</option>
        );
      })}
    </select>
  )} />
  */


  let commitpointsbutton = (
    <GlassButton onClick={(e) => onFormPointsSubmit(e)} disabled={!validateForm()} >
      {Localization.getText("MultiPointSubmitButton")}
    </GlassButton>
  );
  let commitpolygonbutton = (
    <GlassButton onClick={(e) => onFormPolygonSubmit(e)} disabled={!validateForm()} >
      {Localization.getText("MultipleCatchmentSubmitButton")}
    </GlassButton>
  );
  let cancelbutton = (
    <GlassButton onClick={(e) => props.callbackOnClose()}>
      {Localization.getText("Cancel")}
    </GlassButton>
  );
  let clearbutton = (
    <GlassButton type="button" onClick={(e) => setCalculationResults(undefined)}>
      {Localization.getText("Clear")}
    </GlassButton>
  );
  let column1 = 4, column2 = 12 - column1;

  const FormControlElement = (props: { labelElement, controlElement }) => {
    return (
      <GlassTextInputTransformer label={props.labelElement} >
        {props.controlElement}
      </GlassTextInputTransformer>
    )
  }

  const FormatSegments = (props: { segments: any[] }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    return (
      <>
        <u style={{ textAlign: "center", cursor: "pointer" }} onClick={() => { setIsCollapsed(!isCollapsed) }}>{Localization.getText(isCollapsed ? "Show more" : "Show less")}</u>
        {isCollapsed ? null :
          (
            <Table>
              <tbody>
                {props.segments.map((seg) => {
                  return (
                    <tr key={seg.departureTime}>
                      <td>{seg.type}</td>
                      <td>{seg.startname}</td>
                      <td>{seg.endname}</td>
                      <td>{FormatUtils.formatTimeFromSeconds(seg.travelTime)}</td>
                      <td>{seg.routeShortName}</td>
                      <td>{FormatUtils.formatTimeFromSeconds(seg.departureTime)}</td>
                      <td>{FormatUtils.formatTimeFromSeconds(seg.arrivalTime)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
      </>
    )
  }

  const RenderCalculationResults = () => {
    return (
      <>
        <Row style={{ fontSize: "smaller" }}>
          <Col xs={12}>
            <Table>
              <thead>
                <tr>
                  <td>{Localization.getText("Name")}</td>
                  <td>{Localization.getText("Time")}</td>
                  <td>{Localization.getText("Distance") + " (m)"}</td>
                </tr>
              </thead>
              <tbody>
                {calculationResults && calculationResults.sort((a, b) => a.travelTime - b.travelTime).filter((val, idx) => idx < noOfResultsToShow).map((res, idx) => {
                  return (
                    <Fragment key={"resulttable" + res.name + idx}>
                      <tr >
                        <td>{res.name || "no name"}</td>
                        <td>{FormatUtils.formatTimeFromSeconds(res.travelTime)}</td>
                        <td>{(res.distance && FormatUtils.formatDistanceMeters(res.distance)) ?? Localization.getText("no distance")}</td>
                      </tr>
                        <ProtectedFeature feature={Feature.Debugging} contentsIfNoAccess={<></>}>
                          {res.segments ? (
                      <tr>
                        <td>
                            <FormatSegments segments={res.segments} />
                        </td>
                      </tr>
                            ) : null}
                        </ProtectedFeature>
                    </Fragment >
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        {(calculationResults!.length > noOfResultsToShow) ? (
          <>
              <GlassButton style={{width:"33%", marginInline:"auto"}} onClick={() => { setNoOfResultsToShow(noOfResultsToShow + NO_OF_RESULTS_TO_SHOW) }}>{Localization.getText("Show more")}</GlassButton>
          </>
        ) : null}
      </>
    )
  }

  const RenderChooseDataSource = () => {
  return (<FormControlElement
  labelElement={(
    <>{Localization.getText("PointSource")}</>
  )}
  controlElement={(
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    }}>
      <GlassCheckbox
        className="radio input"
        checked={!dataSourceSpecificLayer}
        onChange={(e) => {
          setDataSourceSpecificLayer(!e.target.checked);
          // Clear selections
          if (e.target.checked) {
          setSelectedLayerId(undefined);
          clearFieldSelections();
          }
        } } >
          {Localization.getText("PointSource:AllActivePoints")}
        </GlassCheckbox>
      <GlassCheckbox
        className="radio input"
        checked={dataSourceSpecificLayer}
        onChange={(e) => {
          if (e.target.checked) {
          setDataSourceSpecificLayer(e.target.checked);
          }
        } } >
          {Localization.getText("PointSource:SpecificLayer")}
        </GlassCheckbox>
    </div>
  )} />
  )};

const RenderSelectLayerAsSource = () => {
return (<FormControlElement
  labelElement={(
    <>{Localization.getText("MultiPointDataLayer")}</>
  )}
  controlElement={(
    <SelectLayer
    title={Localization.getText("MultiPointDataLayer")}
    onSelect={(layerId: any) => {
      setSelectedLayerId(layerId);
    } } />
  )}
/>)
    };

const RenderSourceOfSettings = () => {
  return (
  <FormControlElement
  labelElement={(
    <>{Localization.getText("MultiPointParameters")}</>
  )}
  controlElement={(
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    }}>
      <GlassCheckbox
        className="radio input"
        checked={useDefaultFromSettings}
        onChange={(e) => { setUseDefaultFromSettings(e.target.checked); } } >{Localization.getText("MultiPointParameters:UserSettings")}</GlassCheckbox>
      <GlassCheckbox
        className="radio input"
        checked={!useDefaultFromSettings}
        onChange={(e) => { setUseDefaultFromSettings(!e.target.checked); } } >{Localization.getText("MultiPointParameters:FromDataLayer")}</GlassCheckbox>
    </div>
  )} />)
};

const RenderCalculateAndDownloadSeparately = () => { return (<FormControlElement
labelElement={(
  <>{Localization.getText("Calculate travel time separately")}</>
)}
controlElement={(
  <GlassCheckbox checked={calculateAndDownloadSeparately} onClick={(e, chkd) => {
    setCalculateAndDownloadSeparately(chkd);
  } } />
)} />)};

const RenderSelectMultiPointName = () => { return (<FormControlElement
labelElement={(
  <>{Localization.getText("MultiPointTargetName")}</>
)}
controlElement={(
  <SelectColumn
    title={Localization.getText("MultiPointTargetName")}
    layerId={selectedLayerId}
    sKey={selectedColumnIdParam3}
    onSelect={(columnId: any) => {
      setSelectedColumnIdParam3(columnId);
      copyValuesFromSelectedColumn(columnId, selectedLayerId!, MitDataType.CatchmentTargetName);
    } } />
)} />)};

const RenderSelectWindowEnd = () => { return (<FormControlElement
labelElement={(
  <>{Localization.getText("MultiPointWindowEnd")}</>
)}
controlElement={(
  <SelectColumn
    title={Localization.getText("MultiPointWindowEnd")}
    layerId={selectedLayerId}
    sKey={selectedColumnIdParam2}
    onSelect={(columnId: any) => {
      setSelectedColumnIdParam2(columnId);
      copyValuesFromSelectedColumn(columnId, selectedLayerId!, MitDataType.CatchmentWindowEndTime);
    } } />
)} />)};

const RenderSelectWindowStart = () => { return (<FormControlElement
labelElement={(
  <>{Localization.getText("MultiPointWindowStart")}</>
)}
controlElement={(
  <SelectColumn
    title={Localization.getText("MultiPointWindowStart")}
    layerId={selectedLayerId}
    sKey={selectedColumnIdParam1}
    onSelect={(columnId: any) => {
      setSelectedColumnIdParam1(columnId);
      copyValuesFromSelectedColumn(columnId, selectedLayerId!, MitDataType.CatchmentWindowStartTime);
    } } />
)} />)
};

const RenderSelectColumnForFileName = ()=>{ return (<FormControlElement
labelElement={(
  <>{Localization.getText("Select column for file naming")}</>
)}
controlElement={(
  <SelectColumn
    title={Localization.getText("Select column for file naming")}
    layerId={selectedLayerId}
    sKey={selectedColumnIdParam4}
    onSelect={(columnId: any) => {
      setSelectedColumnIdParam4(columnId);
      // ToDo: store the filename component somewhere
      copyValuesFromSelectedColumn(columnId, selectedLayerId!, MitDataType.CatchmentFileName);
    } } />
)} />)};


function onChangeTravel(modeOfTransport:string, rushHourMode:boolean) {
  let newState:CatchmentState = {...catchmentState, selectedModeOfTransport:modeOfTransport, rushHourMode};
  catchmentStateDispatch(actionSetCatchmentState(newState))
  if (catchmentState.calculationMode === CalculationMode.Simple_RegenerateAfterEachParameterChange) {
    catchmentState.catchmentLocation && catchmentStateDispatch(actionGenerateCatchmentSimple(newState , mapitState, catchmentState.catchmentLocation));
  }
}



  if (!props.showWindow) {
    return <></>
  }

  return (

  <AdvancedDragModal 
    PositionToSave={{left:true, top:true}}
    topUpdate={props.showWindow}
    allowUserMiniMize={true}
    PosDefault={{top: "100px", left: "100px", width:"520px"}}
    saveKey="MultiPointTravel"
    onClose={() => props.callbackOnClose()}
    variant={"NSDark"}
    title={props.options.dialogMode === "Polygons" ? Localization.getText("Combined catchment area") :
      Localization.getText("Point Distance")}
  >
    
      {props.options.dialogMode === "Polygons" ? (
        <ADModalInfo>
                  {Localization.getText("GenerateMultipleCatchmentDescription")}
                </ADModalInfo>
      ) : null}
    <ADModalBody>
      <GlassInputGroup
        autoFocus={true}
      >
        {dataSourceSpecificLayer ? ( 
                              <>
                              {availableLayers && availableLayers.length > 0 ? (
                                <RenderSelectLayerAsSource/>
                                ) : (
              <FormControlElement
              labelElement={null}
              controlElement={(
                <>
                <GlassInfo variant={"warning"}>
                        {Localization.getText("MultiPointWarning:PleaseLoadDataLayer")}
                </GlassInfo>
                </>
              )}
            />
            )}
            </>
            ) : null }

        {props.options.dialogMode === "Polygons" ? (
          <FormControlElement
          labelElement={(
            <>{Localization.getText("Travel Times")}</>
            )}
            controlElement={(
              <RenderLegend />
          )}
          />
          ) : null}
        <FormControlElement
          labelElement={(
            <>{Localization.getText("Mode of Transport")}</>
          )}
          controlElement={(
            <>
            <div className="Horizontal_Radio_Button_group">
            {CatchmentInterface.transportModes.filter((key) => key.type !== 'transitorbike').map((key, idx) => {
          let label = Localization.getText("ModeOfTransport:" + key.type);
          let activeClass = catchmentState.selectedModeOfTransport === key.type ? " active" : "";
          return (
            <button key={key.type} data-info={label} className={activeClass} onClick={() => onChangeTravel(key.type, catchmentState.rushHourMode)} >
              <CatchmentIcons type={key.type} />
            </button>
            
          )
        })
      }
        { catchmentState.selectedModeOfTransport == "car" ?
          <>
          <div style={{width:"2px", background:"#ddd"}}></div>
          <button data-info={Localization.getText("Click to toggle Rush Hour mode")} className={catchmentState.rushHourMode? "active":""} onClick={() => onChangeTravel("car", !catchmentState.rushHourMode)} >
              <CatchmentIcons type={"rushHour"} />
            </button>
          </>
        : <></>}


            </div>
            </>
          )}
        />

{dataSourceSpecificLayer ? (
  <>
        <RenderSourceOfSettings/>
        {useDefaultFromSettings ? (
          <FormControlElement
            labelElement={null}
            controlElement={(
              <>
                <GlassInfo key={"info"} variant={"info"}>
                {Localization.getText("MultiPointExplanation:UserSettings")}
                </GlassInfo>
              </>
            )}
          />
        ) : (
          <>
            <FormControlElement
              labelElement={null}
              controlElement={(
                <>
                  <GlassInfo>
                    {Localization.getText("MultiPointExplanation:FromDataLayer")}                  </GlassInfo>
                </>
              )}
            />


            {selectedLayerId ? (
              <>
                <RenderSelectWindowStart/>
                <RenderSelectWindowEnd/>
                {props.options.dialogMode === "PointToPoint" ? (
                  <RenderSelectMultiPointName/>
                )
                 : null }
              </>
            ) : null}
          </>
        )}

        {props.options.dialogMode === "Polygons" && hasAccessToFeature(Feature.TravelTimeCalculateSeparately) && selectedLayerId ? (
          <>
            <RenderCalculateAndDownloadSeparately/>
            {calculateAndDownloadSeparately ? (
              <RenderSelectColumnForFileName/>
              ) : null}
          </>
        ) : null }
 </>
) : null }

      </GlassInputGroup>
      {calculationResults ? (
        <>
        <GlassFoldUdBox
          title={Localization.getText("Result")}
          foldetUd={true}
        >
        <RenderCalculationResults />
        </GlassFoldUdBox>
        </>
      ) : null}
    </ADModalBody>
    <ADModalFooter>
          {props.options.dialogMode === "Polygons" ? (commitpolygonbutton) : (commitpointsbutton)}
          {props.options.dialogMode === "Polygons" && catchmentState.layerHandle ? <GlassButton onClick={(e) => catchmentState.layerHandle && mapitState.map.removeLayerByHandle(catchmentState.layerHandle as any)} >Ryd</GlassButton> :<></>}
          {cancelbutton}     
    </ADModalFooter>
  </AdvancedDragModal>
        )
      }


// as 20231229 for dec 29th, 2023
function DateToDay() {
  return new Date().toISOString().split('T')[0].replaceAll("-","");
}

function convertToSecondsSinceMidnightIfPresent(dateAsString?: string): number | undefined {
  if (dateAsString) {
    var d = new Date(dateAsString);
    return d.getHours() * 60 * 60 + d.getMinutes() * 60 + d.getSeconds();
  } else {
    return undefined;
  }
}