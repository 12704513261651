import { useContext, useEffect, useId, useRef, useState } from "react"
import { GeoJSONSource, ImageSource } from "maplibre-gl"
import { MapInteractionState, MapitStateContext, actionSetMapInteractionState } from "src/states/MapitState"
import { MapFacadeMaplibre } from "src/managers/MapFacadeMaplibre"
import { FeatureLayer } from "src/managers/WmsLayerFunc"
import * as Turf from "@turf/turf";
import jsPDF from "jspdf"
import { Localization, SettingsManager, SimpleTranslationTable } from "@viamap/viamap2-common"
import { ProgressBar, Spinner } from "react-bootstrap"
import { MdBlock, MdCenterFocusStrong, MdEdit } from "react-icons/md"
import { FiMove } from "react-icons/fi"
import { TbRotate } from "react-icons/tb"
import { VectorLayer } from "src/managers/VectorLayerFunc"
import { FaEraser, FaEye, FaEyeSlash } from "react-icons/fa"
import { IgnoreBlock, LegendEditorPlot } from "./LegendEditorPlot"
import { MapitUtils } from "src/managers/MapitUtils"
import { DataDivisionType, LayerInfo, LayerType } from "src/common/managers/Types"
import { GenerateGeom } from "src/managers/GenerateGeom"
import { ProtectedFeature } from "./ProtectedFeature"
import { Feature } from "src/states/ApplicationStateFeatures"
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal"
import { RightPaneContext } from "src/states/RightPaneState"
import { GlassButton } from "./GlassButtons"
import { GlassInputControl, GlassInputGroup, GlassTextInputTransformer } from "./GlassInput"
import { GlassFoldUdBox } from "src/propertyInfoTemplates/PropertyComponents"
import { canvasToPDF, drawDrawing, drawFoldMarkers, maxWebGLSize, paperToSize, PlotLayerCornerArr, PromiseImage, saveWithTimeStampt } from "src/functions/PaperCanvas"
import { GeojsonWFS } from "src/managers/WFSGeojsonLayerFunc"
import { useWindowContext } from "src/WindowManager/useWindowContext"
import { closeWindow, WindowId } from "src/WindowManager/WindowState"

const moduses = ["Standard Plot", "Seperate Layer Plot", "Matrikel Info Plot"] as const;

type PlotScreenState = {
  modus: typeof moduses[number];
  format: string;
  fold: boolean;
  foldFormat: string;
  center: number[];
  bearing: number;
  scale: number;
  margin: number;
  title: string;
  description: string;
  showLegend: boolean;
  includeDate: boolean;
  legendIgnoreBlocks: IgnoreBlock[]
  logoImg: Promise<"" | HTMLImageElement>;
  arrowImg: Promise<"" | HTMLImageElement>;
  imgCache: {[key:string]: Promise<"" | HTMLImageElement>};
  dataLayer: LayerInfo[]
  legendImg: {
    img: Promise<"" | HTMLImageElement>;
    ftl: FeatureLayer;
    label?: string;
  }[];
  attrib: string[];
}

type Props = {
  callbackOnClose: () => void,
  externalSettings?: Partial<PlotScreenState>
}


const mm = 8 //pixels

//Global Feature to hold Square feature
let GB_feature: null|any = null


// MARK: Component Plotscreen
export const PlotScreen = (props:Props) => {
  // const {dispatch:rightPaneDispatch} = useContext(RightPaneContext);
  let {dispatch: windowDispatch} = useWindowContext();
  let [moreSettings, setMoreSettings] = useState(false);
  let [editLegend, setEditLegend] = useState(false);
  
  let [loading, _setLoading] = useState(true);
  let [loadingBar, setLoadingBar] = useState({taskDone:0, TaskToDo:0})
  let [showCustomScale, setShowCustomScale] = useState(false);
  let [tempScaleValue, setTempScaleValue] = useState("0");
  
  let [activeTool, setActiveTool] = useState("none")
  let activeToolRef = useRef("none")

  const debug = SettingsManager.getSystemSetting("debuggingShowExtraDebuggingPlot",false)
  
  function setLoading(b) {
    if (debug) {
      console.debug("Loading Plot",b)
    }
    _setLoading(b)
  }

  useEffect(() => {
    activeToolRef.current = activeTool
  },[activeTool])
  
  
  
  let {state:mapState,dispatch:mapDispatch} = useContext(MapitStateContext);
  
  let uniqueMapID = useId()
  let uniqueCanvasID = useId()
  let uniqueLegendID = useId();
  let mapRef = useRef<MapFacadeMaplibre | null>(null)
  let downLoadRef = useRef<HTMLAnchorElement | null>(null)
  
  let [lState, setLState] = useState<PlotScreenState>({
    modus: "Standard Plot",
    
    //force Reload
    format: "A3L",
    
    //force Map update
    center: mapState.map.getCenter().get2dArr().toReversed(),
    bearing: mapState.map.getBearing(),
    scale: parseInt((0.75*200000000*Math.exp(-0.703*mapState.map.getZoom())).toLocaleString("da-dk", {maximumSignificantDigits:2}).replace(".","")), // Magic 
    
    //force Canvas update
    fold: false,
    foldFormat: "A4",
    margin: 6, //in mm, - 1 mm = 8pixels
    title: "Default Map Title",
    description: "Default Description",
    showLegend: true,
    includeDate: true,
    legendIgnoreBlocks:[],
    dataLayer: Object.values(mapState.layers || []),
    logoImg: PromiseImage(logoImage()), //Cache img
    arrowImg: PromiseImage(new URL("/Mapit/north.svg", import.meta.url)), //Cache img
    
    // LegendImg cache
    legendImg: mapState.selectedFeatureLayerKeys.toReversed().flatMap((a) => {
      let ftl =  mapState.featureLayers[a]
      if (ftl == undefined) {
        return []
      }
      if ((ftl.layer as any)?.type === "vectorTile" || (ftl.layer as any)?.type === "geojsonWFS") {
        if ((ftl.layer as any)?.legend) {
          return {ftl: ftl, img: PromiseImage((ftl.layer as any)?.legend)}
        }
        return []
      }
      if (ftl.layer.layers.split(",").length > 1) {
        let url = ftl.getLegendURL();
        return ftl.layer.layers.split(",").map((a) => url.replace(ftl.layer.layers, a)).filter((a,b) => !ftl.layer.legendExclude?.includes(b)).map((imgUrl, idx) => ({
          ftl: ftl, img: PromiseImage(imgUrl),
          label: ftl.layer.legendLabels?.["da"]?.[idx]
        }))
      }
      return {ftl: ftl, img: PromiseImage(ftl.getLegendURL()),
        label: ftl.layer.legendLabels?.["da"]?.[0]
      }
    }),
    
    imgCache: {},
    
    // Attributions:
    attrib: [...new Set([...["viamap ApS", mapState.selectedBackgroundLayerKey !== "BlankMap" ? "OpenStreetMap": ""].filter((a) => a) ,...mapState.selectedFeatureLayerKeys.toReversed().map((a) => {
      let ftl =  mapState.featureLayers[a]
      return ftl.layer?.["attrib"] || ""
    })])],
    ...(props.externalSettings || {})
  })
  
  
  useEffect(() => {
    mapDispatch(actionSetMapInteractionState(MapInteractionState.Override))
    return () => mapDispatch(actionSetMapInteractionState(MapInteractionState.Normal))
  },[])
  
  useEffect(() => {
    GenerateLegend(
      document.getElementById(uniqueLegendID) as HTMLCanvasElement,
      lState
    )
    function DrawCanvas() {
      const canvas = document.getElementById(uniqueCanvasID) as HTMLCanvasElement;
      const legend = document.getElementById(uniqueLegendID) as HTMLCanvasElement;
      const ctx = canvas && canvas.getContext("2d");
      const img = (!loading && mapRef.current!.getCanvas()) || new Image();
      if (ctx) {
        drawDrawing(
          ctx,
          canvas,
          legend,
          img, 
          lState
        )
        drawFoldMarkers(ctx, canvas, lState)
      }
    }
    DrawCanvas()
    

  },[lState, loading])
  
  // Update CanvasMap
  useEffect(() => {
    setLoading(true);
    let rMap = mapState.map.getMapPop();
    (rMap.getSource("CanvasPlotSource") as ImageSource)?.setCoordinates(PlotLayerCornerArr(lState.center, lState.bearing, lState.scale, lState.format).filter((a, idx) => idx < 4) as any);
    GB_feature = Turf.polygon([PlotLayerCornerArr(lState.center, lState.bearing, lState.scale, lState.format)]);
    ((rMap.getSource("Square") as GeoJSONSource | undefined)?.setData(GB_feature));
    if (mapRef.current) {
      const Corners = PlotLayerCornerArr(lState.center, 0, lState.scale, lState.format) // Zero Rotation, Happens in two steps
      mapRef.current.once("moveend",() => { //Make Sure FitBounds happens
        mapRef.current?.getMapPop().setBearing(lState.bearing) // Seconds step
        mapRef.current?.once("idle",async () => {
          while (mapRef.current?.customLoading() === true) {
            await new Promise((resolve) => setTimeout(resolve, 100))
          }
          mapRef.current?.once?.("idle",async () => {
            setLoading(false)
          });
        })
      })
      mapRef.current.getMapPop().fitBounds(
        Corners.filter((a, idx) => idx % 2 == 0) as any
      )
    }
    
    // return () => {}
  }, [lState.bearing,lState.center,lState.scale])
  
  useEffect(() => {
    setLoading(true);
    const CornersUpRight = PlotLayerCornerArr(lState.center,0,lState.scale, lState.format)
    const Corners = PlotLayerCornerArr(lState.center,lState.bearing,lState.scale, lState.format)
    let x = paperToSize(lState.format)
    let max = maxWebGLSize()
    let options = {
      preserveDrawingBuffer: true,
      interactive: false,
      hash:false,
      maxCanvasSize: [max, max] as [number, number],
      bounds: CornersUpRight.filter((a, idx) => idx % 2 == 0) as any,
      bearing: lState.bearing,
      pixelRatio: 2,
    }
    let MapFac = new MapFacadeMaplibre(uniqueMapID, false, options)
    let rMap = mapState.map.getMapPop()
    MapFac.getMapPop().setBearing(lState.bearing)
    rMap.addSource("CanvasPlotSource", {...CanvasSource(lState.center, lState.bearing,lState.scale,lState.format, uniqueCanvasID) as any})
    let squareSource = PlotSource(lState.center, lState.bearing,lState.scale,lState.format)
    GB_feature = (squareSource as any).data
    rMap.addSource("Square", squareSource)
    rMap.addLayer(CanvasStyle())
    // rMap.addLayer(PlotStyleLine("SquareL","Square"))
    rMap.addLayer(PlotStyleSpec("SquareF","Square"))
    const canvas = document.getElementById(uniqueCanvasID) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    
    const moveEnter = (e) => MouseEnter(e, "SquareF" ,activeToolRef, {move: (a,b) => setLState((c) => ({...c, center: [c.center[0]+a,c.center[1]+b]})),rotate: (ang) => setLState((c) => ({...c, bearing: c.bearing + ang})),setLoading: () => setLoading(true)})
    rMap.on("mouseenter", "SquareF", moveEnter)
    mapRef.current = MapFac
    mapRef.current.getMapPop().setPixelRatio(2)
    mapRef.current.once('load', () => {
      mapRef.current?.setBackgroundLayer(mapState.selectedBackgroundLayerKey)
      mapState.selectedFeatureLayerKeys.forEach((a) => {
        if (mapState.featureLayers[a] instanceof VectorLayer || mapState.featureLayers[a] instanceof GeojsonWFS) {
          mapRef.current?.addVectorLayer(mapState.featureLayers[a] as VectorLayer);
          return 
        }
        let layer = (mapState.featureLayers[a] as FeatureLayer);
        layer && mapRef.current?.addFeatureLayer(a, layer)
      })
      Object.keys(mapState.layers).forEach((a) => {
        let layer = mapState.layers[a].visible && mapState.layers[a].handle
        layer && mapRef.current?.addLayer(layer)
      })
      mapState.printLayers.forEach((a) => {
        a.type === "Vector" && mapRef.current?.addVectorLayer(a.handle);
        a.type === "Raster" && mapRef.current?.addFeatureLayer(a.handle.layer.label, a.handle)
        if (a.type === "MitLayer") {
          let layer = a.handle
          layer && mapRef.current?.addLayer(layer)
        }
      })
    })
    mapRef.current.once("idle",async () => {
      while (mapRef.current?.customLoading() === true) {
        await new Promise((resolve) => setTimeout(resolve, 100))
      }
      mapRef.current?.once?.("idle",async () => {
        setLoading(false)
      });
    })
    
    return () => {
      const rMap = mapState.map.getMapPop() as maplibregl.Map
      rMap.getLayer("CanvasPlot") && rMap.removeLayer("CanvasPlot")
      rMap.getSource("CanvasPlotSource") && rMap.removeSource("CanvasPlotSource")
      rMap.getLayer("SquareL") && rMap.removeLayer("SquareL")
      rMap.getLayer("SquareF") && rMap.removeLayer("SquareF")
      rMap.getSource("Square") && rMap.removeSource("Square")
      rMap.off("mouseenter", "SquareF", moveEnter);
      mapRef?.current?.getMapPop()?.remove?.()
    }
  }, [lState.format])
  
  function setText(key, value:string) {
    let matches = value.match(/(^https:\/.+\.(?:svg|png|jpeg))/gm)
    let x = {}
    matches?.forEach((a) => {
      if (!lState.imgCache[a])
        x = {...x,[a]:PromiseImage(a)}
    })
    setLState((a) => ({...a, imgCache: {...a.imgCache,...x}}))
    
    setLState((a) => ({...a, [key]: value}))
  }
  
  
  async function printPDF() {
    let paper = (""+lState.format).toLowerCase()
    
    const doc = new jsPDF({
      format: paper.slice(0, - 1),
      orientation: paper.at(-1) as any
    });
    
    let paperS = paperToSize(lState.format)
    doc.addImage(document.getElementById(uniqueCanvasID) as HTMLCanvasElement, 0 ,0, paperS.width, paperS.height)
    doc.autoPrint()
    window.open(doc.output('bloburl'), '_blank');
  }
  
  function waitIdle() {
    return new Promise<boolean>((resolve) => {
      if (mapRef.current) {
        mapRef.current.once("idle", () => resolve(true))
      } else {
        resolve(false)
      }
    });
  }
  
  async function SuperPDF() {
    if (mapState.selectedFeatureLayerKeys.length === 0) {
      canvasToPDF(uniqueCanvasID, lState.format);
      return
    }
    setLoadingBar({taskDone:0, TaskToDo: mapState.selectedFeatureLayerKeys.length})
    let paper = (""+lState.format).toLowerCase()
    
    const doc = new jsPDF({
      format: paper.slice(0, - 1),
      orientation: paper.at(-1) as any
    });
    
    let paperS = paperToSize(lState.format)
    mapState.selectedFeatureLayerKeys.forEach((a) => {
      if (mapState.featureLayers[a] instanceof VectorLayer || mapState.featureLayers[a] instanceof GeojsonWFS) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[a] as VectorLayer, "none");
        return 
      }
      let layer = (mapState.featureLayers[a] as FeatureLayer);
      layer && mapRef.current?.changeFeatureVisibility(a, "none")
      
    })
    let keys = [...mapState.selectedFeatureLayerKeys]
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      let groupKey = [key]
      if (key.startsWith("\\")) {
        let uuid = key.split("\\")[1]
        groupKey = keys.filter((a) => a.includes(uuid))
      }

      
      for (let key of groupKey) {
      if (mapState.featureLayers[key] instanceof VectorLayer || mapState.featureLayers[key] instanceof GeojsonWFS) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[key] as VectorLayer, "visible"); 
      } else {
        let layer = (mapState.featureLayers[key] as FeatureLayer);
        layer && mapRef.current?.changeFeatureVisibility(key, "visible")
      }
      }
      const translatedLabel = () => {
        if (mapState.featureLayers[key].layer.translationTable) {
          return Localization.getTextSpecificTable(mapState.featureLayers[key].layer.label, mapState.featureLayers[key].layer.translationTable as SimpleTranslationTable)
        }
        return mapState.featureLayers[key].layer.label
      }
      


      await waitIdle()
      await CompletedDrawing({
        description: lState.description,
        attrib: [...new Set([...["viamap ApS", mapState.selectedBackgroundLayerKey !== "BlankMap" ? "OpenStreetMap": ""].filter((a) => a) ,...[key].map((a) => {
          let ftl =  mapState.featureLayers[a]
          return ftl.layer?.["attrib"] || ""
        })])],
        legendImg: groupKey.toReversed().flatMap((a) => {
          let ftl =  mapState.featureLayers[a]
          if ((ftl.layer as any)?.type === "vectorTile" || (ftl.layer as any)?.type === "geojsonWFS") {
            if ((ftl.layer as any)?.legend) {
              return {ftl: ftl, img: PromiseImage((ftl.layer as any)?.legend)}
            }
            return []
          }
          if (ftl.layer.layers.split(",").length > 1) {
            let url = ftl.getLegendURL();
            return ftl.layer.layers.split(",").map((a) => url.replace(ftl.layer.layers, a)).filter((a,b) => !ftl.layer.legendExclude?.includes(b)).map((imgUrl, idx) => ({
              ftl: ftl, img: PromiseImage(imgUrl),
              label: ftl.layer.legendLabels?.["da"]?.[idx]
            }))
          }
          return {ftl: ftl, img: PromiseImage(ftl.getLegendURL()),
            label: ftl.layer.legendLabels?.["da"]?.[0]
          }
        }),
      })
      i !== 0 && doc.addPage(
        (paper.slice(0, - 1).toString() as any),
        paper.at(-1) as any
      )
      doc.addImage(document.getElementById(uniqueCanvasID) as HTMLCanvasElement, 0 ,0, paperS.width, paperS.height)
      setLoadingBar({taskDone:i + 1, TaskToDo: mapState.selectedFeatureLayerKeys.length})
      for (let key of groupKey) {
      if (mapState.featureLayers[key] instanceof VectorLayer || mapState.featureLayers[key] instanceof GeojsonWFS) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[key] as VectorLayer,"none");
        
      } else {
        let layer = (mapState.featureLayers[key] as FeatureLayer);
        layer && mapRef.current?.changeFeatureVisibility(key, "none")
      }
    }
      i = i + groupKey.length - 1
    }
    // doc.autoPrint()
    doc.save(saveWithTimeStampt("mapit-Plot")+".pdf");
    mapRef.current?.setBackgroundLayer(mapState.selectedBackgroundLayerKey)
    mapState.selectedFeatureLayerKeys.forEach((a) => {
      if (mapState.featureLayers[a] instanceof VectorLayer || mapState.featureLayers[a] instanceof GeojsonWFS) {
        mapRef.current?.changeVectorVisibility(mapState.featureLayers[a] as VectorLayer, "visible");
        return 
      }
      let layer = (mapState.featureLayers[a] as FeatureLayer);
      layer && mapRef.current?.changeFeatureVisibility(a, "visible")
    })
    await waitIdle()
    await CompletedDrawing({})
    setLoadingBar({taskDone:0, TaskToDo: 0})
  }
  
  function SaveImg() {
    (downLoadRef.current as HTMLAnchorElement).download = saveWithTimeStampt("mapit-Plot")+".png";
    (downLoadRef.current as HTMLAnchorElement).href = (document.getElementById(uniqueCanvasID) as HTMLCanvasElement).toDataURL();
    (downLoadRef.current as HTMLAnchorElement).click()
  }
  
  function UpdateLocation() {
    mapState.map
    setLState((a) => ({
      ...a,
      center: mapState.map.getCenter().get2dArr().toReversed(),
      bearing: mapState.map.getBearing()
    }))
  }

  
async function CompletedDrawing(overWritelState) {
  await GenerateLegend(
    document.getElementById(uniqueLegendID) as HTMLCanvasElement,
    {...lState, ...overWritelState}
  )
  async function DrawCanvas() {
    const canvas = document.getElementById(uniqueCanvasID) as HTMLCanvasElement;
    const legend = document.getElementById(uniqueLegendID) as HTMLCanvasElement;
    const ctx = canvas && canvas.getContext("2d");
    const img = (!loading && mapRef.current!.getCanvas()) || new Image();
    if (ctx) {
    await drawDrawing(
      ctx,
      canvas,
      legend,
      img, 
      {...lState, ...overWritelState}
    )
    drawFoldMarkers(ctx, canvas, {...lState, ...overWritelState})
    }
  } 
  await DrawCanvas()
}
  
  let scaleValues = [500,1000,2000,2500,5000,7500,10000,12000,12500,15000,20000,25000,50000];
  
  // MARK: Plotscreen return
  return (
    <>
    <AdvancedDragModal 
      PosDefault={{left:"100px", top:"50%", transform:"translateY(-50%)"}}
      title={Localization.getText("Plot")}
      onClose={(e) => windowDispatch(closeWindow(WindowId.PlotScreen))}
    >
    {editLegend && lState.modus === "Standard Plot" ?
    <LegendEditorPlot canvasId={uniqueLegendID} callBack={(next) => setLState((pre) => ({...pre, legendIgnoreBlocks: next}))} />
    : <></>}
    <ADModalBody>
    <GlassInputGroup>
    {/* <div className="Setting_Label">{Localization.getText("Plot modus")}</div> */}
    {/* <div className="Setting_Input"> */}
    {/* <select value={lState.modus} onChange={((e) => setLState((a) => ({...a, modus: (e.target.value as PlotScreenState["modus"])})))} > */}
    {/* <option value={"Standard Plot"}>{"Standard Plot"}</option> */}
    {/* <option value={"Seperate Layer Plot"}>{"Seperate Layer Plot"}</option> */}
    {/* <option value={"Matrikel Info Plot"}>{"Matrikel Info Plot"}</option> */}
    {/* </select> */}
    {/* </div> */}
    
    <GlassTextInputTransformer label={Localization.getText("Paper")}>
      <GlassInputControl>
    <select value={lState.format} onChange={((e) => setLState((a) => ({...a, format: e.target.value})))}>
    <option value={"A0L"}>{Localization.getFormattedText("{size} Landscape",{size:"A0"})}</option>
    <option value={"A0P"}>{Localization.getFormattedText("{size} Potrait",{size:"A0"})}</option>
    <option value={"A1L"}>{Localization.getFormattedText("{size} Landscape",{size:"A1"})}</option>
    <option value={"A1P"}>{Localization.getFormattedText("{size} Potrait",{size:"A1"})}</option>
    <option value={"A2L"}>{Localization.getFormattedText("{size} Landscape",{size:"A2"})}</option>
    <option value={"A2P"}>{Localization.getFormattedText("{size} Potrait",{size:"A2"})}</option>
    <option value={"A3L"}>{Localization.getFormattedText("{size} Landscape",{size:"A3"})}</option>
    <option value={"A3P"}>{Localization.getFormattedText("{size} Potrait",{size:"A3"})}</option>
    <option value={"A4L"}>{Localization.getFormattedText("{size} Landscape",{size:"A4"})}</option>
    <option value={"A4P"}>{Localization.getFormattedText("{size} Potrait",{size:"A4"})}</option>
    <option value={"A5L"}>{Localization.getFormattedText("{size} Landscape",{size:"A5"})}</option>
    <option value={"A5P"}>{Localization.getFormattedText("{size} Potrait",{size:"A5"})}</option>
    <option value={"A6L"}>{Localization.getFormattedText("{size} Landscape",{size:"A6"})}</option>
    </select>
    </GlassInputControl>
    </GlassTextInputTransformer>



    <GlassTextInputTransformer label={Localization.getText("Scale")}>
    <GlassInputControl>
    <button className={"Setting_toogle"} onClick={() => {
      setShowCustomScale((a) => {
        return !a
      })
      if (!showCustomScale) {
        setTempScaleValue(lState.scale + "")
      }
    }}><MdEdit /></button>
    {!showCustomScale ? 
      <select value={lState.scale} onChange={((e) => setLState((a) => ({...a, scale: parseInt(e.target.value)})))}>
      {scaleValues.includes(lState.scale) ? <></> :
      <option value={lState.scale} >1:{(lState.scale).toLocaleString()}</option>
      }
      {scaleValues.map((a) => {
        return <option key={"val"+a} value={a} >1:{(a).toLocaleString("da-dk")}</option>
      })}
      
      </select>
      :
      <>
      <input value={tempScaleValue} onBlur={(e) => {setLState((a) => ({...a, scale: parseInt(tempScaleValue.replace(".",""))}))}} onChange={(e) => setTempScaleValue(e.target.value)} ></input>
      </>
    }
    </GlassInputControl>
    </GlassTextInputTransformer>
    <GlassTextInputTransformer label={Localization.getText("Title")}>
    <GlassInputControl>
    <textarea onChange={(e) => setText("title", e.target.value)} value={lState.title}></textarea>
    </GlassInputControl>
    </GlassTextInputTransformer>
    <GlassTextInputTransformer label={Localization.getText("Text")}>
    <GlassInputControl>
    <textarea onChange={(e) => setText("description", e.target.value)} value={lState.description}></textarea>
    </GlassInputControl>
    </GlassTextInputTransformer>
    <GlassTextInputTransformer label={Localization.getText("Legend")}>
    <div className="Horizontal_Radio_Button_group">
    <button data-info={Localization.getText("Show")} className={lState.showLegend ? "active":""} onClick={() => setLState((a) => ({...a, showLegend:true}))} ><FaEye /></button>
    <button data-info={Localization.getText("Hide")} className={!lState.showLegend ? "active":""} onClick={() => setLState((a) => ({...a, showLegend:false}))} ><FaEyeSlash /></button>
    <button data-info={Localization.getText("Edit")} style={{marginLeft:"auto"}} onClick={() => setEditLegend((a) => !a)} ><FaEraser /></button>
    </div>
    </GlassTextInputTransformer>
    <GlassTextInputTransformer label={Localization.getText("Action")}>
    <div className="Horizontal_Radio_Button_group">
    <button data-info={Localization.getText("Inactive")} className={activeTool == "none" ? "active":""} onClick={() => setActiveTool("none")} ><MdBlock /></button>
    <button data-info={Localization.getText("Move")} className={activeTool == "move" ? "active":""} onClick={() => setActiveTool("move")} ><FiMove /></button>
    <button data-info={Localization.getText("Rotate")} className={activeTool == "rota" ? "active":""} onClick={() => setActiveTool("rota")} ><TbRotate /></button>
    
    <button data-info={Localization.getText("Capture")} style={{marginLeft:"auto"}} onClick={() => UpdateLocation()} ><MdCenterFocusStrong /></button>
    </div>
    </GlassTextInputTransformer>
    </GlassInputGroup>
    <GlassFoldUdBox
      variant="Small"
      title={Localization.getText("More Settings")}
      foldetUd={false}
    >
      <GlassInputGroup>
          <GlassTextInputTransformer label={Localization.getText("Margin")}>
          <GlassInputControl>
      <input value={lState.margin} min={0} max={50} type="number" onChange={((e) => setLState((a) => ({...a, margin: parseInt(e.target.value)})))} ></input>
      <span>mm</span>
      </GlassInputControl>
    </GlassTextInputTransformer>
    <GlassTextInputTransformer label={Localization.getText("Fold Markers")}>
      <div className="Horizontal_Radio_Button_group">
      <button data-info={Localization.getText("Show")} className={lState.fold ? "active":""} onClick={() => setLState((a) => ({...a, fold:true}))} ><FaEye /></button>
      <button data-info={Localization.getText("Hide")} className={!lState.fold ? "active":""} onClick={() => setLState((a) => ({...a, fold:false}))} ><FaEyeSlash /></button>
      </div>
    </GlassTextInputTransformer>
    <GlassTextInputTransformer label={Localization.getText("Fold to")}>
      <GlassInputControl>
      
      <select value={lState.foldFormat} onChange={((e) => setLState((a) => ({...a, foldFormat: e.target.value})))}>
      <option value={"A0"}>A0</option>
      <option value={"A1"}>A1</option>
      <option value={"A2"}>A2</option>
      <option value={"A3"}>A3</option>
      <option value={"A4"}>A4</option>
      <option value={"A5"}>A5</option>
      </select>
      </GlassInputControl>
    </GlassTextInputTransformer>
    </GlassInputGroup>
      </GlassFoldUdBox>
      
      </ADModalBody>
      <ADModalFooter>
      {loadingBar.TaskToDo ? <ProgressBar now={loadingBar.taskDone/loadingBar.TaskToDo*100} label={`${loadingBar.taskDone} / ${loadingBar.TaskToDo}`} style={{flex:1,borderRadius:"5px", ...{"--bs-progress-bar-bg":"#3ae580", "--bs-progress-height":"2rem"}}} /> :
       loading ? <Spinner size="sm" />:
      <>
      <ProtectedFeature feature={Feature.MapPlotSuper} contentsIfNoAccess={<></>}>
      <GlassButton title={Localization.getText("Seperate layer plot")} onClick={() => {SuperPDF()}} >S-PDF</GlassButton>
      </ProtectedFeature>
      <GlassButton onClick={() => {SaveImg()}} >PNG</GlassButton>
      <GlassButton onClick={() => {canvasToPDF(uniqueCanvasID, lState.format)}} >PDF</GlassButton>
      <GlassButton onClick={() => {printPDF()}} >PRINT</GlassButton>
      </>
    }
    </ADModalFooter>
    </AdvancedDragModal>
    
    
    
    <a ref={downLoadRef} style={{display:"none"}} href="" download >Click</a>
    <div className="CanvasCon" style={{width:"420px", height:"594px", position:"absolute", backgroundColor:"white", zIndex:(debug ? 2 : -1), pointerEvents:"none", opacity:(debug ? 1 : 0)}} >
    <canvas id={uniqueCanvasID} width={paperToSize(lState.format).width*8} height={paperToSize(lState.format).height*8} style={{width:"100%"}} />
    </div>
    <div className="CanvasCon" style={{position:"absolute", left:"50%", backgroundColor:"white", zIndex:(debug ? 2 : -1), pointerEvents:"none", opacity:(debug ? 1 : 0)}} >
    <canvas id={uniqueLegendID} width={paperToSize(lState.format).width*8} height={paperToSize(lState.format).height*8} style={{width:"100%"}} />
    </div>
    <div className="CanvasCon" style={{width:"420px", height:"594px", position:"absolute", backgroundColor:"white", zIndex:(debug ? 1 : -1), pointerEvents:"none", opacity:(debug ? 1 : 0)}} >
    <div className="PlotScreen" id={uniqueMapID} style={{width:paperToSize(lState.format).width*4+"px", height:paperToSize(lState.format).height*4+"px", position:"absolute", backgroundColor:"white", zIndex:1, pointerEvents:"none", opacity:1}} />
    </div>
    </>
  );
}

function PlotSource(center, rot, scale, format):maplibregl.SourceSpecification {
  
  return {
    type: "geojson",
    data: Turf.polygon([PlotLayerCornerArr(center, rot, scale, format)])
  }
}

function CanvasSource(center, rot, scale, format, canvas):maplibregl.CanvasSourceSpecification {
  return {
    type: "canvas",
    canvas: canvas,
    coordinates: PlotLayerCornerArr(center, rot, scale, format).filter((a, idx) => idx < 4) as any,
    animate: true
  }
}

function CanvasStyle():maplibregl.LayerSpecification {
  return {
    id: "CanvasPlot",
    source: "CanvasPlotSource",
    type: "raster"
  }
}

function PlotSquare(center, rot, scale, format):maplibregl.SourceSpecification {
  let corner = PlotLayerCornerArr(center, rot, scale, format)
  
  return {
    type: "geojson",
    data: Turf.lineString([center, corner[0]]),
  }
}

function PlotStyleSpec(id, source):maplibregl.LayerSpecification {
  return {
    id: id,
    type: "fill",
    source:source,
    paint: {
      "fill-color":"#fff0",
      "fill-outline-color":"grey",
    }
  }
}


function MouseEnter(e, layer ,action, callBack) {
  let feature:undefined|any = undefined
  let center:undefined| any = undefined
  let start:(number|undefined)[] = [undefined, undefined];
  let end:(number|undefined)[] = [undefined, undefined];
  let startAngle:number|undefined = undefined;
  let endAngle:number|undefined = undefined;
  
  if (action.current == "move") {
    e.target.getCanvas().style.cursor = "move";
  }
  if (action.current == "rota") {
    let url = `url('${new URL("/cursor/rotate.svg",import.meta.url)}') 10 10 , auto`;
    e.target.getCanvas().style.cursor = url;
  }
  
  
  const mouseUp = (e) => {
    end = [e.lngLat.lng,e.lngLat.lat]
    endAngle = center && Turf.bearing(center, end as [number,number])
    if (action.current == "move" && end[0] && start[0] && end[1] && start[1]) {
      callBack.move(end[0]-start[0], end[1]-start[1])
    }
    if (action.current == "rota" && endAngle && startAngle) {
      callBack.rotate(endAngle-startAngle)
    }
    e.target.off("mouseup", mouseUp);
    e.target.off("mousemove", mouseMove)
  }
  
  const mouseMove = (e) => {
    let cur = [e.lngLat.lng,e.lngLat.lat]
    let curAngle = center && Turf.bearing(center, cur as [number,number])
    
    if (action.current == "move" && cur[0] && start[0] && cur[1] && start[1]) {
      let lngC = cur[0] - start[0]
      let latC = cur[1] - start[1]
      let newFeature = GB_feature && {
        ...feature,
        geometry:{ ...feature.geometry,
          coordinates: [feature.geometry.coordinates[0].map((a) => [a[0]+ lngC, a[1]+latC])]}
        }
        GB_feature = newFeature
        e.target.getSource("Square")?.setData(GB_feature);
        (e.target.getSource("CanvasPlotSource") as ImageSource)?.setCoordinates(newFeature.geometry.coordinates[0].filter((a,idx) => idx < 4))
      }
      if (action.current == "rota" && curAngle && startAngle) {
        let newFeature = feature && Turf.transformRotate(feature, curAngle-startAngle);
        GB_feature = newFeature
        e.target.getSource("Square")?.setData(GB_feature);
        (e.target.getSource("CanvasPlotSource") as ImageSource)?.setCoordinates(newFeature.geometry.coordinates[0].filter((a,idx) => idx < 4))
      }
      
      
    }
    
    const mouseDown = (e) => {
      feature = GB_feature
      // feature = e.features[0];
      center = Turf.center(feature);
      start = [e.lngLat.lng,e.lngLat.lat]
      startAngle = center && Turf.bearing(center, start as [number,number])
      e.preventDefault();
      let points = e.points
      callBack.setLoading?.()
      e.target.on("mouseup", mouseUp);
      e.target.on("mousemove", mouseMove)
    }
    
    //LAST cleaning
    const mouseLeave = () => {
      e.target.getCanvas().style.cursor = ""
      e.target.off("mouseleave", layer, mouseLeave);
      e.target.off("mousedown", layer, mouseDown);
    }
    
    if (action.current !== "none") {
      e.target.on("mousedown", layer, mouseDown);
      e.target.on("mouseleave", layer, mouseLeave);
    }
  }
  
  
  function Move(e:any) {
    e.preventDefault();
  }
  
  function Rotate(e:any) {
    e.preventDefault();
  }
  
  
  // MARK: LEGEND
  async function GenerateLegend(
    canvas: HTMLCanvasElement, 
    drawingOptions: PlotScreenState
  ) {
    
    let dataL = await dataLayerLegend(drawingOptions)
    
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.font = "30px Arial";
    }
    const awaitedImages = await Promise.all(drawingOptions.legendImg.map(async(a) => ({...a, img: await a.img})))
    const legendWidth = Math.max(Math.max(...awaitedImages.map((a) => (a.img ? a.img.width : 0))), ctx?.measureText("Signaturforklaring").width || 0) 
    let legendScaling = 1.5;
    let legendHeight = 55 + awaitedImages.reduce((a,b) => (b.img !== "" && !("noLegend" in b.ftl.layer && b.ftl.layer.noLegend === true)) ? ((b.ftl.layer.legendCrop||b.img.height)*legendScaling + a) : a , 0)
    legendHeight = legendHeight ? legendHeight + 0 : legendHeight
    
    canvas.height = legendHeight + dataL.reduce((pre, cur) => pre + cur.height,0);
    canvas.width = legendWidth * legendScaling;
    
    if (!ctx) {
      return
    }
    
    ctx.fillStyle = "#fff";
    ctx.fillRect(0,0,legendWidth,legendHeight)
    
    let currTop = 20
    ctx.fillStyle = "#000";
    
    ctx.font = "30px Arial";
    ctx.fillText("Signaturforklaring", 5, currTop+20)
    
    // DATA Legend color
    currTop += 35
    dataL.forEach((a) => {
      currTop += DrawDataIcon(ctx, currTop, a.icon, a.layerInfo)
      currTop += DrawColorByValue(ctx, currTop, a.valueColorLegend, a.layerInfo)
      currTop += DrawSizeByValue(ctx, currTop, a.valueSizeLegend, a.layerInfo)
    })
    
    // FEATURELAYER
    currTop = dataL.reduce((pre, cur) => pre + cur.height,0) + 55;
    ctx.font = "20px Arial";
    
    awaitedImages.forEach((a) => {
      if (a.img == "" || ("noLegend" in a.ftl.layer && a.ftl.layer.noLegend === true)) {
        return
      }
      ctx.filter = `hue-rotate(${a.ftl.layer.paint?.["raster-hue-rotate"] || 0}deg) saturate(${(a.ftl.layer.paint?.["raster-saturation"] || 0)+1})`;
      ctx.drawImage(a.img, 
        0, a.ftl.layer.legendOffset || 0, a.img.width, a.ftl.layer.legendCrop || a.img.height,
        5, currTop, a.img.width * legendScaling, (a.ftl.layer.legendCrop || a.img.height) * legendScaling
      )
      if (a.label) {
        
        ctx.fillText(a.label, 5 + a.img.width * legendScaling + 2, currTop + ((a.ftl.layer.legendCrop || a.img.height) * legendScaling / 2) + 5)
      }
      currTop += (a.ftl.layer.legendCrop || a.img.height) * legendScaling
    })
  }
  
  function DrawSizeByValue(ctx:CanvasRenderingContext2D, currTop:number, colorByValue:any, layerInfo:LayerInfo) {
    if (!layerInfo.styling.sizeByProperty || !layerInfo.styling.sizeByValue?.divisions) {
      return 0
    }
    ctx.fillText(layerInfo.styling.sizeByProperty, 55, currTop + 20)
    let x = 25
    const increas = (layerInfo.styling.sizeByValue.useSizeRange || 0) % 2
    layerInfo.styling.sizeByValue?.divisions.list.forEach((a, idx, list) => {
      ctx.fillStyle = layerInfo.styling.color || "#000";
      ctx.beginPath()
      if (!increas) {
        let radius = (4*(idx) + 7) / 2;
        ctx.arc(50+radius, x + currTop+12, radius, 0, 2*Math.PI);
        ctx.fill();
      } else {
        let radius = (4*(list.length - idx) + 7) / 2
        ctx.arc(50+radius, x + currTop+12, radius, 0, 2*Math.PI);
        ctx.fill();
      }
      ctx.fillStyle = "#000"
      ctx.fillText(a.to?.toString() ? "<=" : " >", 90, currTop + 20 + x)
      ctx.fillText(a.to?.toString() || a.from?.toString() || "", 120 ,currTop + 20 + x)
      x += 25
    })
    return x
  }
  
  function DrawColorByValue(ctx:CanvasRenderingContext2D, currTop:number, colorByValue:any, layerInfo:LayerInfo) {
    if (!layerInfo.styling.colorByProperty || !layerInfo.styling.colorByValue?.divisions) {
      return 0
    }
    ctx.fillText(layerInfo.styling.colorByProperty, 55, currTop + 20)
    let x = 25
    layerInfo.styling.colorByValue?.divisions.list.forEach((a, idx, list) => {
      ctx.fillStyle = a.color || "#000"
      ctx.fillRect(50, currTop + x, 20, 20)
      ctx.fillStyle = "#000"
      if (a.label) {
        ctx.fillText(a.label, 90, currTop + 20 + x)
      } else if (a.value) {
        ctx.fillText(a.value, 90, currTop + 20 + x)
      } else {
        ctx.fillText(a.to?.toString() ? "<=" : " >", 90, currTop + 20 + x)
        ctx.fillText(a.to?.toString() || a.from?.toString() || "", 120 ,currTop + 20 + x)
      }
      x += 25
    })
    return x
  }
  
  function DrawDataIcon(ctx:CanvasRenderingContext2D, currTop:number, icon:HTMLImageElement | "", layerInfo:LayerInfo) {
    if (icon == "") {
      return 0
    }
    ctx.font = "20px Arial"
    ctx.drawImage(icon, 15, currTop+2, 16, 22)
    ctx.fillText(layerInfo.datasetname, 50, 20 + currTop)
    return 25
  }
  
  async function dataLayerLegend(drawingOptions: PlotScreenState) {
    async function svgToHtmlImage(svgString):Promise<HTMLImageElement | ""> {
      return new Promise((res) => {
        var svg = new Blob([svgString], {
          type: "image/svg+xml;charset=utf-8"
        });
        
        var url = URL.createObjectURL(svg);
        
        var img = new Image();
        img.onload = () => {
          res(img)
          URL.revokeObjectURL(url);
        }
        img.onerror = () => {
          res("")
        }
        img.width = 50;
        img.height = 82;
        img.src = url
      });
    } 
    
    const sizePLine = 20;
    const layer = drawingOptions.dataLayer.filter((a) => a.visible)
    
    return await Promise.all(layer.map(async (layerInfo) => {
      
      
      
      const layerIcon =
      layerInfo.type === LayerType.GeoJSON_Point ||
      layerInfo.type === LayerType.PointWGS84 ? (
        await svgToHtmlImage(GenerateGeom.MitPinSvg(layerInfo!.styling!.color))
      ) : (
        await svgToHtmlImage(GenerateGeom.MitAreaSvg(layerInfo!.styling!.color))
      );
      let valueColorLegend = layerInfo!.styling!.colorByProperty
      let valueSizeLegend = layerInfo!.styling!.sizeByProperty
      let noDataLegend = MapitUtils.isAreaLayer(layerInfo.type) && layerInfo.styling.areaNoData?.show

      let areaFillColorLegendValue =
      MapitUtils.isAreaLayer(layerInfo.type) &&
      !layerInfo!.styling!.colorByProperty
      let otherValueDataLegendValue =
      layerInfo!.styling!.colorByValue &&
      layerInfo!.styling!.colorByValue!.divisions &&
      layerInfo!.styling!.colorByValue!.divisions!.type ===
      DataDivisionType.Discrete &&
      (layerInfo!.styling!.colorByValue!.divisions!.otherValuesCount || 0) > 0
      return {
        icon: layerIcon,
        name: layerInfo.datasetname,
        layerInfo: layerInfo,
        valueColorLegend: valueColorLegend,
        valueSizeLegend: valueSizeLegend,
        noDataLegend: noDataLegend,
        areaFillColorLegendValue: areaFillColorLegendValue,
        otherValueDataLegendValue: otherValueDataLegendValue,
        height: 30 + 
        (layerInfo.styling.colorByValue?.divisions?.list?.length || 0)*25 + (layerInfo.styling.colorByValue?.divisions?.list?.length ? 25 : 0) +
        (layerInfo.styling.sizeByValue?.divisions?.list?.length || 0)*25 +  (layerInfo.styling.sizeByValue?.divisions?.list?.length ? 25 : 0) 
      }
    }))
  }
  
  function logoImage() {
    let url = SettingsManager.getSystemSetting("plot.logo", "default", true) as string
    if (url === "default" || !url) {
      return new URL("/Mapit/MapitLogoTissegul.svg", import.meta.url).href
    }
    return url
  }