import { SettingsManager } from "@viamap/viamap2-common";
import { useEffect, useContext } from "react";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";

export type MitThemes = ('Theme-Newsec' | 'Theme-Default' | undefined)

export function ThemeLoader() {
  const {hasAccessToFeature} = useContext(ApplicationStateContext)

  useEffect(() => {
    const hostname = window.location.hostname;
    let theme = SettingsManager.getSystemSetting('ApplicationTheme', 'Theme-Default', true);
    let favicon = "";
    if (hostname.includes("estate") || hostname.includes("explore") || hasAccessToFeature(Feature.ThemeNewSec)) {
      theme = "Theme-Newsec";
      favicon = "/Estate_Explorer_favikon.png";
    }
    const save = '' // Theme only controlled by feature or hostname  MitStorage.getValue<string>("MitStyle") || '';

    var link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }

    setStyleHandler(theme);
    link.href = new URL(favicon || '/favicon.png', import.meta.url).href
  },[hasAccessToFeature])

  return <></>
}



function currentTheme():MitThemes {
  let currentTheme:MitThemes = undefined
  document.body.classList.forEach((a) => {
    if (a.includes("Theme-"))
      currentTheme = a as MitThemes;
  })
  return currentTheme
}

function setStyleHandler(style: MitThemes) {
  let currentThemeVal = currentTheme()
  if (currentThemeVal && style) {
    document.body.classList.replace(currentThemeVal, style)
  } else if (style) {
    document.body.classList.add(style)
  } else if (currentThemeVal) {
    document.body.classList.remove(currentThemeVal)
  }
  let theme = SettingsManager.getSystemSetting('ApplicationTheme', 'Theme-Default', true);
  // MitStorage.setValue("MitStyle",theme+"---"+style);
}