import { useContext, useEffect, useState } from "react"
import { Spinner, Table } from "react-bootstrap";
import { ESInterface, UTMGridData, UTMGridItem } from "./ESInterface";
import { ExploreSearchContext, removeFilter, addFilter, FilterValue, FilterType } from "../states/ExploreSearchState";
import { BBOXTURF, EmbeddedVectorMap, MapState } from "./EmbeddedVectorMap";
import proj4 from 'proj4';
import './Results.css';
import * as turf from '@turf/turf';
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { AppMessagesContext, Localization, SettingsManager, actionClearProgressMessage, actionSetErrorMessage, actionSetInfoMessage, actionSetProgressMessage } from "@viamap/viamap2-common";
import { ProjectContext, handleToBeAdded } from "src/states/ProjectState";
import { ProtectedFeature } from "src/components/ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";
import { HeaderWithOpenCloseChevron } from "src/components/ComponentUtils";
import { BsArrowUpRightSquareFill, BsHouseUpFill } from "react-icons/bs";
import { ExportData } from "src/managers/ExportData";
import { SheetFunc } from "src/managers/SheetFunc";
import { ViewButtonCaret, ViewButtonIcon, ViewButtonText } from "src/propertyInfoTemplates/PropertyComponents";
import { actionAddDataLayer, MapitStateContext } from "src/states/MapitState";
import { LayerFunc } from "src/managers/LayerFunc";
import { ApplicationStateContext} from "src/states/ApplicationState";
import intersect from "@turf/intersect";
import { GlassCard } from "src/components/MitGlassComponents";
import { Persistence } from "src/managers/Persistence";

export const Results = (props: {showClose: boolean,callBackOnClose:() => void, selectBFECallback: (a:number, b:[number,number]) => void}) => {
   const zoomLevelShowingItemsInsteadOfGroups=8;
   let {state: projState, dispatch: projDispatch} = useContext(ProjectContext);

   let [selectedInfo,setSelectedInfo] = useState<any>({})

   const {dispatch: mapitDispatch} = useContext(MapitStateContext);
   const {dispatch: appMessageDispatch} = useContext(AppMessagesContext);
   const {hasAccessToFeature} = useContext(ApplicationStateContext);
   let { state: explorerSearchState, dispatch } = useContext(ExploreSearchContext);
   let [loading, setLoading] = useState<boolean>(false);
   let [matchCount, setMatchCount] = useState<number>();
   let [showItems, setShowItems] = useState<boolean>(false);
   let [itemsToDisplay, setItemsToDisplay] = useState<any[]>([]);
   let [showMap, setShowMap] = useState<boolean>(true);
   let [countsToDisplay, setCountsToDisplay] = useState<UTMGridData>([]);
   let [filterJSON, setFilterJson] = useState<any>(combinedFilterJson([explorerSearchState.activeFilters["drawn_area"],explorerSearchState.activeFilters["polygon"],explorerSearchState.activeFilters["map_layer"]]))
   let [bboxItemsDisplay, setBboxItemsToDisplay] = useState<any[]>([]);
   let [mapState, setMapState] = useState<MapState>();
   let [showListView, setShowListView] = useState<boolean>(false);
   let [listItemsDisplay, setListItemsToDisplay] = useState<any[]>([]);
   const listViewMaxRows=SettingsManager.getSystemSetting("explore.listViewMaxRows");
   const maxExcelRows = SettingsManager.getSystemSetting("explore.downloadToExcelMaxRows")
   const addToProjectMaxRows=SettingsManager.getSystemSetting("explore.addToProjectMaxRows");

   const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");

   useEffect(() => {
      let ifx = new ESInterface(elasticIndex);
      setLoading(true);
      ifx.doCount(explorerSearchState.selectableFilterList, explorerSearchState.activeFilters)
         .then((count) => {
            setMatchCount(count);
         })
         .catch((err) => {
            setMatchCount(0);
            appMessageDispatch(actionSetErrorMessage("Got error:" + (err.message || err)))
         })
         .finally(() => {
            setLoading(false);
         })
      if (showMap) {
         ifx.doGetGroups(explorerSearchState.selectableFilterList, explorerSearchState.activeFilters)
            .then((items: UTMGridData) => {
               setCountsToDisplay(items);
            })
            .catch((err) => {
               setCountsToDisplay([]);
               appMessageDispatch(actionSetErrorMessage("Got error:" + (err.message || err)))
            })
      }

      // Handle showing filterArea on the map
      if (showMap) {
         setFilterJson(combinedFilterJson([explorerSearchState.activeFilters["drawn_area"], explorerSearchState.activeFilters["polygon"], explorerSearchState.activeFilters["map_layer"]]))
      }

      if (mapState && mapState.zoom > zoomLevelShowingItemsInsteadOfGroups) {
         getDataForBBOX(mapState.bbox || []);
      }
   }, [explorerSearchState.activeFilters, showItems, showMap]);

   function handleSelect(a: number, b: [number, number]) {
      setSelectedInfo({bfe_nr:a, coords:b})
   
      props.selectBFECallback(a,b)
   }

   useEffect(() => {
      if (mapState && mapState.zoom > zoomLevelShowingItemsInsteadOfGroups) {
         getDataForBBOX(mapState.bbox || []);
      }
   }, [mapState]);

   
  useEffect(() => {
   if (showListView && matchCount) {
      getDataForList();
   }

}, [showListView, matchCount])

   function callbackOnMapMove(mapState:MapState) {
      setMapState(mapState);
   }

   function transform2GeoJsonGrid(data:UTMGridData):any {
      return data ? {
          "type": "FeatureCollection",
          "features": data.map((ugi:UTMGridItem) => {
               let {lng, lat} = convertFromUTMtoLatLng({x:ugi.centerUTM32_easting, y:ugi.centerUTM32_northing});
               return {
                       type: "Feature",
                       properties: {
                        doc_count: ugi.doc_count,
                        key: ugi.key
                       },
                       geometry: {
                           "type": "Point",
                           "coordinates": [lng, lat]
                       }
                   }
               })
      } : {};
   }

   function transform2GeoJsonItem(data:any):any {
      return data ? {
          "type": "FeatureCollection",
          "features": data.map((item:any) => {
               return {
                       type: "Feature",
                       properties: { ...item
                       },
                       geometry: {
                           "type": "Point",
                           "coordinates": [item.koord_lng, item.koord_lat]
                       }
                   }
               })
      } : {};
   }

   function transform2GeoJsonFeatureCollection(features:any[]):any {
      return features ? {
          "type": "FeatureCollection",
          "features": features
      } : {};
   }

   function convertFromUTMtoLatLng(utm:{ x: number, y: number}): { lat: number, lng: number } {
      let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees';
      let utm32 = '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs';
      let coords = proj4(utm32,wgs84,[utm.x, utm.y]);
      return { lat: coords[1], lng: coords[0] };
   }

   function TilføjHandler(data: Promise<any>) {
      data.then(async (obj) => {
         const dataArr = await Promise.all(obj.map(async (a) => {
            try {
               const json = await PropertyInfoInterface.EBRadresseForBFE(a.bfe_nr)
               return {
                  bfeNr:a.bfe_nr, 
                  name:json.features?.[0]?.properties?.husnummer?.[0]?.adgangsadressebetegnelse || a.bfe_nr, 
                  latLng: [a.koord_lat,a.koord_lng],
                  tags:[]
               }
            } catch {
               return undefined
            }
         }))
         projDispatch(handleToBeAdded(dataArr.filter((a) => a)))
      }).catch((a) => {
         console.error("Errr")
      })
   }

   async function handleAddToMap(asSFE:boolean=false) {
      function NoTranslationRemoval(obj:{[key:string]:any}) {
         let res = {}
         Object.keys(obj).forEach((a) => {
            if (!a.includes("No translation found for")) {
               res[a] = obj[a]
            }
         })
         return res
      }

      let ifx = new ESInterface(elasticIndex);      
      ifx.doSearch(explorerSearchState.selectableFilterList, explorerSearchState.activeFilters,maxExcelRows).then(async (obj) => {
         let ExportInstance = new ExportData()
         let nextLayer;
         if (asSFE) {
            async function geojsonForProperty(sfeEjdNr:number, sfeProperties:any): Promise<any> {
               appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving geometry data")));
               let geoJson = await PropertyInfoInterface.getGeojsonOfSFE(sfeEjdNr)
                 .then(result => result)
                 .catch(() => {
                   console.error("No geometry data for this property", sfeEjdNr);
                   return undefined;
                 });
               geoJson.type = "FeatureCollection";
               geoJson.features.forEach((feature:any) => {
                 let combinedProps = {...sfeProperties, ...feature.properties};
                 let formatedProps = ExportInstance.filterAndFormatProps(combinedProps, explorerSearchState.selectableFilterList);
                 let addSFEProps = {...formatedProps, SFEnummer: sfeProperties.bfe_nr};
                 feature.properties = NoTranslationRemoval(addSFEProps);
               })
               return geoJson;
            }
            // let j = await await transform2GeoJsonFeatureCollection(await Promise.all(obj.map(async (item) => {return await geojsonForProperty(item.bfe_nr)})));
            let features:any[] = [];
            for (let i=0; i<obj.length; i++) {
               if (obj[i].bfe_ejendomsType === "SamletFastEjendom") {
                  let fc = await geojsonForProperty(obj[i].bfe_nr, obj[i]);
                  features = [...features, ...fc.features];
               }
            }
            let fc = {
               "type": "FeatureCollection",
               "features": features
            };
            nextLayer = LayerFunc.createLayerInfoFromGeoJsonFeatureCollection(
               fc,
               "Added SFEs From Explorer"
            )
            nextLayer.propertiesToDisplay = Object.keys(ExportInstance.filterAndFormatProps({
               bfe_nr: "", bfe_ejendomsType: "", bfe_adresse:"", jords_matrikelnr:"", jords_ejerlavskode:"", sfe_registreretArealSamlet:"",
               poi_dist_stop: ""
            },explorerSearchState.selectableFilterList)).map((a) => {return {name: a}})
            mapitDispatch(actionAddDataLayer(
               nextLayer
            ,false))
            appMessageDispatch(actionSetInfoMessage(Localization.getText("Search results (only SFEs) has been added to the map")))
         } else {
            nextLayer = LayerFunc.createLayerInfoFromGeoJsonFeatureCollection(
               transform2GeoJsonItem(obj.map((item) => {return {...NoTranslationRemoval(ExportInstance.filterAndFormatProps(item, explorerSearchState.selectableFilterList)),koord_lng: item.koord_lng,koord_lat: item.koord_lat}})),
               "Added From Explorer"
            )
            nextLayer.propertiesToDisplay = Object.keys(ExportInstance.filterAndFormatProps({
               bfe_nr: "", bfe_ejendomsType: "", bfe_adresse:"", jords_matrikelnr:"", jords_ejerlavskode:"", sfe_registreretArealSamlet:"",
               poi_dist_stop: ""
            },explorerSearchState.selectableFilterList)).map((a) => {return {name: a}})
            mapitDispatch(actionAddDataLayer(
               nextLayer
            ,false))
            appMessageDispatch(actionSetInfoMessage(Localization.getText("Search results has been added to the map")))
         }
      }).catch((a) => {
         console.error("Error", a)
      })
   }

   async function handleExportExcel() {
      try {
        let createdDate = new Date();
        let ifx = new ESInterface(elasticIndex);      
        ifx.doSearch(explorerSearchState.selectableFilterList, explorerSearchState.activeFilters,maxExcelRows)
        .then(async (items) => {
           let count = items.length || 0;
           
            let wb = await (new ExportData()).createExcelExportForSearchResults(items, createdDate, explorerSearchState.selectableFilterList, explorerSearchState.activeFilters,
               (progress:number) => {
                  appMessageDispatch(actionSetProgressMessage("Export", Math.round(100*progress/count)));
               },
               hasAccessToFeature(Feature.ExportFloodingProperties));
               appMessageDispatch(actionClearProgressMessage());
               let blob = SheetFunc.createBlob(wb);
            let fileName = "SearchResultExport_"+createdDate.toLocaleDateString()+"_"+createdDate.toLocaleTimeString();
            fileName += ".xlsx";
            ExportData.downloadBlob(fileName, blob);
            appMessageDispatch(actionSetInfoMessage(Localization.getText("Search results has been exported to excel")));
            })
         .catch((err) => {
            appMessageDispatch(actionSetErrorMessage(err));
         })
      }
      catch(error:any) {
        appMessageDispatch(actionSetErrorMessage(error));
      }
    }
  
   function callbackOnUserPolygon(poly:any) {
      // alert("Got polygon callback "+JSON.stringify(poly));
      if (poly && poly.features && poly.features.length > 0) {
         let feature=poly.features[0];
         if (false) {
            let bbox = turf.bbox(feature);
            dispatch(addFilter("drawn_area", bbox));
         } else {
            dispatch(addFilter("polygon", feature.geometry.coordinates));
         }
      } else {
         dispatch(removeFilter("drawn_area"));
         dispatch(removeFilter("polygon"));
      }
   }

   function getDataForBBOX(bbox:BBOXTURF) {
      // Return items within the bbox
      let ifx = new ESInterface(elasticIndex);
      let bboxFilter = {"tmp_bbox": { title: "Temporary bbox", filterType: FilterType.BoundingBox, default:[11.68,55.06,12.29,55.42]} };
      let bboxFilterValue = {"tmp_bbox": bbox};
      let tmpFilters : any = {...explorerSearchState.selectableFilterList, ...bboxFilter} as any;
      let tmpFiltersValues = {...explorerSearchState.activeFilters, ...bboxFilterValue};
      ifx.doSearch(tmpFilters, tmpFiltersValues,4000)
      .then((items) => {
         setBboxItemsToDisplay(items);
      })
      .catch((err) => {
         setBboxItemsToDisplay([]);
         appMessageDispatch(actionSetErrorMessage("Got error:" + (err.message || err)))
      })
   }

   function getDataForList() {
      // Return items to display in list
      let ifx = new ESInterface(elasticIndex);
      ifx.doSearch(explorerSearchState.selectableFilterList, explorerSearchState.activeFilters,listViewMaxRows)
      .then((items) => {
         setListItemsToDisplay(items);
      })
      .catch((err) => {
         setListItemsToDisplay([]);
         appMessageDispatch(actionSetErrorMessage("Got error:" + (err.message || err)))
      })
   }



   function ItemHitList(props:{items:any[]}) {
      const tableColumns={
         "bfe_nr":"BFE nr",
         "bfe_ejendomsType":"Ejendomstype",
         // "koord_status":"Koord",
         "ejf_ejere":"Ejere",
         "ejf_cvr":"CVR"
      }
      const allPropsFilter = (prop:string) => {
         if (hasAccessToFeature(Feature.Debugging)) {
            // Show all 
            return true;
         } else {
            // Hide all 'technical' properties.
            return !(
               prop.startsWith("id_")
               || prop.startsWith("error_")
               || prop.startsWith("time_")
               || prop === "time"
               || prop.startsWith("bygningspunkt_")
               || prop.startsWith("import_")
               || ["time", "koord_status", "geopoint", "ejf_ejere_liste"].includes(prop)
            )
         }
      }

      function TableLine(props:{idx:number, item:any}) {
         let [expanded, setExpanded] = useState<boolean>(false)
         return (
         <tr key={props.idx}>
         <td>
         <HeaderWithOpenCloseChevron
                  onClick={(e:React.MouseEvent)=>{setExpanded(!expanded)}}
                  isOpen={expanded}
                  title={""}
               />
         </td>
         { !expanded ? (
            <>
         {
            Object.keys(tableColumns).map((key,idx) => {
               return (
                  <td key={idx}>{props.item[key]}</td>
                  );
               })
            }
            </>
         ) : (
                  <td colSpan={Object.keys(tableColumns).length}>
                  <table>
                     <tbody>
               <>
               {
                  Object.keys(props.item).filter(allPropsFilter).map((key,idx) => {
                     let val = props.item[key];
                     // Some properties may be an object or array
                     if (
                        ((typeof val === 'object')
                         || Array.isArray(val)) &&
                        val !== null
                    ) {
                        val = JSON.stringify(val, undefined, 3);
                    }
                     return (
                        <tr key={idx}>
                     <td>{key}</td>
                     <td>{val}</td>
                        </tr>
                     );
                  })
               }
               </>
                     </tbody>
                  </table>
                  </td>
            ) 
         }
            </tr>
         )
      }

      return (
         <div style={{}}>
            <Table striped bordered hover>
               <thead>
                  {(matchCount||0) > listViewMaxRows ? (
                  <tr>
                     <th colSpan={Object.keys(tableColumns).length+1} style={{fontStyle:"italic", fontSize:"larger"}}>
                        {Localization.getFormattedText("Shows first {listViewMaxRows} rows",{listViewMaxRows})}
                     </th>
                  </tr>
                  ):null}
                  <tr key={"x"}>
                     <>
                     <th></th>
                     {
                        Object.values(tableColumns).map((col,idx) => {
                           return (
                           <th key={idx}>{col}</th>
                           );
                        })
                     }
                     </>
                  </tr>
                     </thead>
               <tbody>
                  <>
                  {  
                     props.items.map((itm, idx) => {
                        return (
                           <TableLine
                              key={idx}
                              idx ={idx}
                              item={itm}
                           />
                        );
                     })
                  }
                  </>                
               </tbody>
            </Table>
         </div>
      )
   }

   return (
      <>
      <GlassCard
         variant={showListView ? "" : "noScroll GlassCard-FlexBody"}
         title={
            <>
         <span>
         <span>{Localization.getText("Result")}</span>
         <span style={{marginLeft:"2em", opacity:0.75, fontSize:"16px"}}>{matchCount}</span>
            {loading ? (
               <Spinner size="sm" animation="border" variant="success" />
            ):null}
            </span>
            <div style={{display:"flex", gap:"5px", flexWrap:"wrap", justifyContent:"flex-end", flex:"auto"}} >
         <ProtectedFeature feature={Feature.ExplorerShowAsList} contentsIfNoAccess={<></>} >
               <ViewButtonCaret
                  onClick={(e:React.MouseEvent)=>{setShowListView(!showListView)}}
                  isOpen={showListView}
               >{Localization.getText("Show list")}</ViewButtonCaret>
         </ProtectedFeature>
         <ProtectedFeature feature={Feature.AddToProjectUnassignedList} contentsIfNoAccess={<></>} >
         <>
         {(projState.isLoaded ? 
         <>
         <ViewButtonText onClick={(e) => {TilføjHandler(Promise.resolve([selectedInfo]))}} disabled={! Boolean(selectedInfo?.bfe_nr)} >{Localization.getText("Add Selected")}</ViewButtonText>
         <ViewButtonText onClick={(() => {let ifx = new ESInterface(elasticIndex); TilføjHandler(ifx.doSearch(explorerSearchState.selectableFilterList, explorerSearchState.activeFilters, addToProjectMaxRows))})} disabled={!(matchCount && matchCount <= addToProjectMaxRows)} >{Localization.getFormattedText("Add Search ({count} max)",{count:addToProjectMaxRows})}</ViewButtonText>
         </>
         : null)}
         </>
         </ProtectedFeature>
         { props.showClose ? (
         <ViewButtonText onClick={() => props.callBackOnClose()} disabled={!props.showClose} >{Localization.getText("Return to Search")}</ViewButtonText>
         ) : null }
         <ProtectedFeature feature={Feature.ExplorerToExcel} contentsIfNoAccess={<></>} >
            <ViewButtonIcon 
               disabled={!((matchCount|| 0) > 0 && (matchCount|| 0) <= maxExcelRows)}
               title={Localization.getText("Export results to Excel") + " max "+maxExcelRows} className="" onClick={(e) => handleExportExcel()} ><img src="./images/NewSecSVG/excel.svg"></img></ViewButtonIcon>
         </ProtectedFeature>
         <ProtectedFeature feature={Feature.ExplorerSaveAsNewLayer} contentsIfNoAccess={<></>} >
         <ViewButtonIcon disabled={!((matchCount|| 0) > 0 && (matchCount|| 0) <= maxExcelRows)}
            onClick={(e) => handleAddToMap()}
            title={Localization.getText("Add as layer")}
            ><BsArrowUpRightSquareFill /></ViewButtonIcon>
         </ProtectedFeature>
         <ProtectedFeature feature={Feature.ExplorerSaveAsNewLayer} contentsIfNoAccess={<></>} >
         <ViewButtonIcon disabled={!((matchCount|| 0) > 0 && (matchCount|| 0) <= maxExcelRows)}
            onClick={(e) => handleAddToMap(true)}
            title={Localization.getText("Add as SFE layer")}
            ><BsHouseUpFill /></ViewButtonIcon>
         </ProtectedFeature>
         </div>
         </>
         }>
      
      <div className="ResultInfo">
         
      </div>
         {showListView ? (
            <ItemHitList items={listItemsDisplay}/>
         ) : (
            <div className="MapWrapper">
            <EmbeddedVectorMap 
            orthoPhoto={false}
            dataSourceGeoJSON={transform2GeoJsonGrid(countsToDisplay)}
            onPolygon={(poly) => callbackOnUserPolygon(poly)}
            onMove={callbackOnMapMove}
            selectBFE={handleSelect}
            dataSourceFilterJSON={filterJSON}
            dataSourceItemsJSON={transform2GeoJsonItem(bboxItemsDisplay)}
            cadasters={false}
            selectedItems={[]}
            />
            </div>
         ) }
         </GlassCard>
      </>
   )
}


function combinedFilterJson(geometries:any[]) {
   const EmptyJSON = {
      "type": "FeatureCollection",
      "features": []
   };

   const valid = geometries.filter((a) => a) || []
   if (valid.length == 0) {
      return EmptyJSON
   }
   let outerBounds = valid.map((a) => {
      return turf.polygon(a)
   }) || [EmptyJSON]
   if (outerBounds.length < 2) {
      return turf.featureCollection(outerBounds)
   }
   let interSection = intersect(turf.featureCollection(outerBounds), {properties: {lineColor:"orange"}}) || EmptyJSON
   return turf.featureCollection([...outerBounds as any, interSection as any])
}